var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
export default function LoadingIndicator(_a) {
    var { loading = false } = _a, props = __rest(_a, ["loading"]);
    return (React.createElement(Root, Object.assign({}, props, { "$loading": loading }),
        React.createElement("div", null)));
}
const animation = keyframes `
    0% {
        transform: translateX(-100%) translateZ(0);
    }

    45% {
        transform: translateX(0%) translateZ(0);
    }

    55% {
        transform: translateX(0%) translateZ(0);
    }

    100% {
        transform: translateX(100%) translateZ(0);
    }
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1ui3ugp" }) `
    height: 0;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    will-change: opacity;

    ${({ $loading = false }) => $loading &&
    css `
            opacity: 1;
        `}

    div {
        height: 2px;
        position: relative;
        overflow: hidden;
    }

    div:after {
        animation: 2s infinite ${animation};
        background: #0424ff;
    }

    div:after,
    div:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
    }
`;
