var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cx from 'classnames';
import Spinner from '$shared/components/Spinner';
import styles from './newButton.pcss';
const darkBgs = new Set(['primary', 'destructive']);
const handleClick = (e, onClick) => {
    if (e.currentTarget instanceof HTMLElement) {
        // Make sure we make the button lose focus after click
        e.currentTarget.blur();
    }
    if (onClick != null) {
        onClick(e);
    }
};
const Button = (_a) => {
    var { className, tag: Tag = 'button', size = 'normal', kind = 'primary', variant, outline = false, disabled = false, waiting = false, onClick, children, external } = _a, args = __rest(_a, ["className", "tag", "size", "kind", "variant", "outline", "disabled", "waiting", "onClick", "children", "external"]);
    return (React.createElement(Tag, Object.assign({}, args, { className: cx(styles.root, {
            [styles.primary]: kind === 'primary',
            [styles.secondary]: kind === 'secondary',
            [styles.destructive]: kind === 'destructive',
            [styles.link]: kind === 'link',
            [styles.special]: kind === 'special',
            [styles.mini]: size === 'mini',
            [styles.normal]: size === 'normal',
            [styles.big]: size === 'big',
            [styles.dark]: variant === 'dark',
            [styles.outline]: outline,
            [styles.primary2]: kind === 'primary2',
            [styles.transparent]: kind === 'transparent',
        }, className), onClick: disabled
            ? (e) => e.preventDefault()
            : (e) => handleClick(e, onClick), disabled: disabled || waiting, tabIndex: disabled ? -1 : 0 }),
        children,
        waiting && (React.createElement(Spinner, { color: !outline && darkBgs.has(kind) ? 'white' : 'gray', containerClassname: styles.spinnerContainer }))));
};
export default Button;
