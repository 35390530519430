import React from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import Button from '$shared/components/Button';
import { useCurrentDraft, useDraftId, useStreamEditorStore, } from '$shared/stores/streamEditor';
import address0 from '$utils/address0';
import NewStreamPermissionsModal from '$app/src/modals/NewStreamPermissionsModal';
import { isAbandonment } from '$app/src/modals/ProjectModal';
import { Layer } from '$utils/Layer';
import PermissionItem from './PermissionItem';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ms97wv" }) `
    background: #f1f1f1;
    border-radius: 4px;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1em;
    padding: 1.5em;
    margin-top: 16px;
`;
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-9sx04n" }) `
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 8px;
    align-items: center;
`;
const PermissionList = ({ disabled }) => {
    const { permissions } = useCurrentDraft();
    const permissionList = Object.entries(permissions);
    const count = permissionList.length - (permissions[address0] ? 1 : 0);
    const { setPermissions } = useStreamEditorStore();
    const draftId = useDraftId();
    return (React.createElement(Container, null,
        permissionList.map(([key, { bits = null } = {}]) => key !== address0 && (React.createElement(PermissionItem, { key: key, address: key, permissionBits: bits || 0, disabled: disabled }))),
        React.createElement(Footer, null,
            React.createElement("span", null,
                count,
                " Ethereum account",
                count === 1 ? '' : 's'),
            React.createElement(Button, { kind: "primary", type: "button", disabled: disabled, outline: true, onClick: async () => {
                    try {
                        const { account, bits } = await toaster(NewStreamPermissionsModal, Layer.Modal).pop({
                            onBeforeSubmit(payload) {
                                const { bits = null, persistedBits = null } = (permissions === null || permissions === void 0 ? void 0 : permissions[payload.account.toLowerCase()]) || {};
                                const currentBits = persistedBits === null && bits === null
                                    ? null
                                    : bits || 0;
                                if (currentBits !== null) {
                                    throw new Error('Permissions for this address already exist');
                                }
                            },
                        });
                        if (draftId) {
                            setPermissions(draftId, account, bits);
                        }
                    }
                    catch (e) {
                        if (isAbandonment(e)) {
                            return;
                        }
                        console.warn('Could not add permissions for a new account', e);
                    }
                } }, "Add a new account"))));
};
export default PermissionList;
