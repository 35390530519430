import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LogoLink, Navbar, NavbarItem, NavContainer } from '$shared/components/Layout/Nav';
import Logo from '$shared/components/Logo';
import Button from '$shared/components/Button';
import { REGULAR } from '$shared/utils/styled';
import { useIsCurrentProjectDraftClean, useIsNewProject, useIsProjectBusy, } from '$app/src/shared/stores/projectEditor';
import routes from '$routes';
const FlexNavbar = styled(Navbar).withConfig({ displayName: "FlexNavbar", componentId: "sc-vixwm" }) `
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const FlexNavbarItem = styled(NavbarItem).withConfig({ displayName: "FlexNavbarItem", componentId: "sc-4kliy4" }) `
    display: flex;
    align-items: center;

    button {
        margin-left: 10px;
    }

    h1 {
        font-size: 18px;
        font-weight: ${REGULAR};
        margin: 0;
        padding-left: 16px;
    }
`;
export default function EditorNav() {
    const busy = useIsProjectBusy();
    const clean = useIsCurrentProjectDraftClean();
    const isNew = useIsNewProject();
    return (React.createElement(NavContainer, null,
        React.createElement(FlexNavbar, null,
            React.createElement(FlexNavbarItem, null,
                React.createElement(LogoLink, { href: routes.root() },
                    React.createElement(Logo, null)),
                React.createElement("h1", null, isNew ? React.createElement(React.Fragment, null, "Create a project") : React.createElement(React.Fragment, null, "Edit a project"))),
            React.createElement(FlexNavbarItem, null,
                React.createElement(Button, { tag: Link, to: routes.projects.index(), kind: "transparent" }, "Exit"),
                React.createElement(Button, { disabled: busy || clean, onClick: () => void console.info('Publish clicked!') }, "Publish")))));
}
