import formatConfigUrl from '$utils/formatConfigUrl';
import getConfig from '$app/src/getters/getConfig';
// TODO add typing
export default function formatRpc(rpc) {
    if (!rpc || typeof rpc !== 'object' || !('rpcs' in rpc)) {
        return rpc;
    }
    const { client } = getConfig();
    return Object.assign(Object.assign({}, rpc), { rpcs: rpc.rpcs.map((r) => ({
            timeout: r.timeout != null ? r.timeout : client === null || client === void 0 ? void 0 : client.chainTimeout,
            url: formatConfigUrl(r.url),
        })) });
}
