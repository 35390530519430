var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import ImageChecked from './checkbox-checked.svg';
import ImageUnchecked from './checkbox.svg';
const Tick = styled.div.withConfig({ displayName: "Tick", componentId: "sc-1faxfpb" }) `
    background: url(${ImageUnchecked}) no-repeat;
    height: 16px;
    position: relative;
    width: 16px;

    ::after {
        background: url(${ImageChecked}) no-repeat;
        bottom: 0;
        content: ' ';
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.1s;
    }

    :disabled {
        filter: grayscale(1);
        cursor: not-allowed;
    }

    ${({ checked }) => !!checked &&
    css `
            ::after {
                opacity: 1;
            }
        `}
`;
const noop = () => { };
const UnstyledCheckbox = (_a) => {
    var { value, onChange = noop, onClick = noop } = _a, props = __rest(_a, ["value", "onChange", "onClick"]);
    return (React.createElement(Tick, Object.assign({}, props, { as: "input", type: "checkbox", checked: !!value, onChange: onChange, onClick: onClick })));
};
const Checkbox = styled(UnstyledCheckbox).withConfig({ displayName: "Checkbox", componentId: "sc-pvb3zl" }) `
    appearance: none;
    border: 0;
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 0.5em;
    top: 0;

    :focus {
        outline: none;
    }
`;
Object.assign(Checkbox, {
    Tick,
});
export default Checkbox;
