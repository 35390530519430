module.exports    = {
	"core": {
		"dataUnionPublishMemberLimit": 1,
		"platformOriginUrl": "https://streamr.network",
		"streamrUrl": "https://streamr.network",
		"uniswapAdaptorContractAddress": "0xBe99DB4Ea1964ea9A9E80de41517901Da6ef9307",
		"web3TransactionConfirmationBlocks": 1,
		"marketplaceChains": [
			"gnosis",
			"polygon"
		],
		"dataunionChains": [
			"gnosis",
			"polygon"
		],
		"streamChains": [
			"polygon"
		],
		"projectsChain": "polygon",
		"theGraphUrl": "https://api.thegraph.com",
		"mainnetInfuraUrl": "https://mainnet.infura.io/v3/17c3985baecb4c4d94a1edc2c4d23206",
		"theHubGraphName": "streamr-dev/projects",
		"streamsGraphName": "streamr-dev/streams",
		"streamIndexerUrl": "https://stream-metrics.streamr.network/api",
		"reverseRecordsAddress": "0x3671aE578E63FdF66ad4F3E12CC0c0d71Ac7510C",
		"marketplaceContractCreationBlocks": [
			{
				"chainId": 1,
				"blockNumber": 13575716
			},
			{
				"chainId": 100,
				"blockNumber": 23758654
			},
			{
				"chainId": 137,
				"blockNumber": 32026284
			}
		],
		"dataunionGraphNames": [
			{
				"chainId": 100,
				"name": "dataunions/data-unions-gnosis"
			},
			{
				"chainId": 137,
				"name": "dataunions/data-unions-polygon"
			}
		],
		"storageNodes": [
			{
				"name": "Streamr Germany",
				"address": "0x31546eEA76F2B2b3C5cC06B1c93601dc35c9D916"
			}
		],
		"ipfs": {
			"projectId": "2KjYUpR265h6R5M5njkSue5RGm7",
			"apiSecretKey": "c39492773b28820c8b3654178bf26946",
			"ipfsUploadEndpoint": "https://ipfs.infura.io:5001/api/v0/add",
			"ipfsGatewayUrl": "https://streamr-hub.infura-ipfs.io/ipfs/"
		},
		"projectRegistry": {
			"chainId": 137
		}
	},
	"client": {
		"mainchain": {
			"dataTokenAddress": "0x8f693ca8D21b157107184d29D398A8D082b38b76",
			"rpc": {
				"chainId": 1,
				"rpcs": [
					{
						"url": "https://mainnet.infura.io/v3/17c3985baecb4c4d94a1edc2c4d23206"
					}
				]
			}
		},
		"sidechain": {
			"dataTokenAddress": "0xE4a2620edE1058D61BEe5F45F6414314fdf10548"
		}
	}
}