import React, { useCallback, useContext, useMemo } from 'react';
import TokenSelector from '$mp/containers/ProjectEditing/TokenSelector';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { getConfigForChain } from '$shared/web3/config';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
export const DataUnionTokenSelector = ({ editMode, }) => {
    var _a;
    const { state: project } = useContext(ProjectStateContext);
    const { updateSalePoints } = useEditableProjectActions();
    const chain = useMemo(() => {
        if (!project.dataUnionChainId) {
            return undefined;
        }
        return getConfigForChain(project.dataUnionChainId);
    }, [project.dataUnionChainId]);
    const handleChange = useCallback((pricingData) => {
        if (chain && pricingData) {
            updateSalePoints({
                [chain.name]: {
                    chainId: chain.id,
                    pricingTokenAddress: pricingData.tokenAddress,
                    price: pricingData.price || undefined,
                    pricePerSecond: pricingData.pricePerSecond || undefined,
                    timeUnit: pricingData.timeUnit,
                    beneficiaryAddress: undefined,
                },
            });
        }
    }, [chain, updateSalePoints]);
    const pricingData = useMemo(() => {
        var _a;
        const currentChainSalePoint = chain && project.salePoints && project.salePoints[chain.name];
        if (currentChainSalePoint) {
            const result = {
                tokenAddress: currentChainSalePoint.pricingTokenAddress,
                price: currentChainSalePoint.price,
                timeUnit: (_a = currentChainSalePoint.timeUnit) !== null && _a !== void 0 ? _a : undefined,
                pricePerSecond: currentChainSalePoint.pricePerSecond,
                beneficiaryAddress: undefined,
            };
            return result;
        }
        return undefined;
    }, [chain, project.salePoints]);
    return (React.createElement(TokenSelector, { disabled: !project.dataUnionChainId, onChange: handleChange, chain: chain, validationFieldName: `salePoints.${(_a = chain === null || chain === void 0 ? void 0 : chain.name) !== null && _a !== void 0 ? _a : 'no_chain_available'}`, tokenChangeDisabled: editMode, value: pricingData }));
};
