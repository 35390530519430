import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDoesUserHaveAccess, useIsProjectBusy, useProject, } from '$shared/stores/projectEditor';
import { ProjectPermission, useCurrentProjectAbility, } from '$shared/stores/projectAbilities';
import Layout from '$shared/components/Layout';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import { DetailsPageHeader } from '$shared/components/DetailsPageHeader';
import LoadingIndicator from '$shared/components/LoadingIndicator';
import Button from '$shared/components/Button';
import SvgIcon from '$shared/components/SvgIcon';
import PP, { ProjectPageContainer } from '$shared/components/ProjectPage';
import { WhiteBox } from '$shared/components/WhiteBox';
import { StreamConnect } from '$shared/components/StreamConnect';
import { StreamPreview } from '$shared/components/StreamPreview';
import Terms from '$mp/components/ProductPage/Terms';
import Streams from '$mp/containers/ProjectPage/Streams';
import ProjectHero from '$mp/containers/ProjectPage/Hero/ProjectHero2';
import routes from '$routes';
import ProjectLinkTabs from './ProjectLinkTabs';
import AccessManifest from './AccessManifest';
import GetAccess from './GetAccess';
const PageTitleContainer = styled.div.withConfig({ displayName: "PageTitleContainer", componentId: "sc-1pttkq5" }) `
    align-items: center;
    display: flex;
`;
const EditButton = styled(Button).withConfig({ displayName: "EditButton", componentId: "sc-1j3z65z" }) `
    border-radius: 100%;
    height: 32px;
    margin-left: 10px;
    width: 32px;
`;
const ProjectTitle = styled.span.withConfig({ displayName: "ProjectTitle", componentId: "sc-1unljvl" }) `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const Separator = styled.span.withConfig({ displayName: "Separator", componentId: "sc-jthhtf" }) `
    white-space: pre-wrap;
`;
function ProjectOverviewPage() {
    const { id, name, description, termsOfUse, imageUrl, streams, type, contact, salePoints = {}, } = useProject();
    if (!id) {
        return null;
    }
    return (React.createElement(PP, null,
        React.createElement(ProjectPageContainer, null,
            React.createElement(ProjectHero, { contact: contact, description: description, imageUrl: imageUrl || undefined, name: name }),
            React.createElement(AccessManifest, { projectId: id, projectType: type, salePoints: Object.values(salePoints).filter(Boolean) }),
            React.createElement(Streams, { streams: streams }),
            React.createElement(Terms, { terms: termsOfUse }))));
}
function ProjectConnectPage() {
    const hasAccess = useDoesUserHaveAccess();
    const { id, name, type, streams, salePoints = {} } = useProject();
    if (!id) {
        return null;
    }
    return (React.createElement(PP, null,
        React.createElement(ProjectPageContainer, null, hasAccess ? (React.createElement(WhiteBox, { className: 'with-padding' },
            React.createElement(StreamConnect, { streams: streams }))) : (React.createElement(GetAccess, { projectId: id, projectName: name, projectType: type, salePoints: Object.values(salePoints) })))));
}
function ProjectLiveDataPage() {
    const hasAccess = useDoesUserHaveAccess();
    const { id, name, type, streams, salePoints = {} } = useProject();
    if (!id) {
        return null;
    }
    return hasAccess ? (React.createElement(StreamPreview, { streamsList: streams })) : (React.createElement(PP, null,
        React.createElement(ProjectPageContainer, null,
            React.createElement(GetAccess, { projectId: id, projectName: name, projectType: type, salePoints: Object.values(salePoints) }))));
}
export default function TabbedPage({ tab }) {
    const { id, name, creator } = useProject();
    const busy = useIsProjectBusy();
    const canEdit = useCurrentProjectAbility(ProjectPermission.Edit);
    return (React.createElement(Layout, { gray: true },
        React.createElement(MarketplaceHelmet, { title: name }),
        React.createElement(DetailsPageHeader, { backButtonLink: routes.projects.index(), pageTitle: React.createElement(PageTitleContainer, null,
                React.createElement(ProjectTitle, null,
                    name,
                    !!creator && (React.createElement(React.Fragment, null,
                        React.createElement(Separator, null, " by "),
                        React.createElement("strong", null, creator)))),
                canEdit && (React.createElement(EditButton, { tag: Link, to: routes.projects.edit({ id }), kind: "secondary", size: "mini" },
                    React.createElement(SvgIcon, { name: "pencilFull" })))), rightComponent: React.createElement(ProjectLinkTabs, { projectId: id }) }),
        React.createElement(LoadingIndicator, { loading: busy }),
        tab === 'overview' && React.createElement(ProjectOverviewPage, null),
        tab === 'connect' && React.createElement(ProjectConnectPage, null),
        tab === 'live-data' && React.createElement(ProjectLiveDataPage, null)));
}
