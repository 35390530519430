import React from 'react';
import { Img as ReactImg } from 'react-image';
import cx from 'classnames';
import Logo from '$shared/components/Logo';
import styles from './fallbackImage.pcss';
const DefaultImagePlaceholder = ({ alt, className }) => (React.createElement("div", { className: cx(styles.defaultImagePlaceholder, className) },
    React.createElement(Logo, { color: "black", opacity: "0.15" }),
    React.createElement("img", { src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAA\n                AA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=", alt: alt })));
const FallbackImage = ({ src, alt, className, placeholder }) => (React.createElement(ReactImg, { className: className, src: src, alt: alt, unloader: placeholder || React.createElement(DefaultImagePlaceholder, { alt: alt, className: className }) }));
export default FallbackImage;
