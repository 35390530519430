var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { keyframes, css } from 'styled-components';
const UnstyledSkeleton = (_a) => {
    var { disabled, children, width, block } = _a, props = __rest(_a, ["disabled", "children", "width", "block"]);
    return (disabled ? children || null : (React.createElement("span", Object.assign({}, props), "\u200C")));
};
const animation = keyframes `
    0% {
        background-position: -200px 0;
    }

    100% {
        background-position: calc(200px + 100%) 0;
    }
`;
const Skeleton = styled(UnstyledSkeleton).withConfig({ displayName: "Skeleton", componentId: "sc-1f0xa9l" }) `
    animation: ${animation} 1.2s ease-in-out infinite;
    background-color: #eeeeee;
    background-image: linear-gradient(90deg, #eeeeee, #f5f5f5, #eeeeee);
    background-repeat: no-repeat;
    background-size: 200px 100%;
    border-radius: 2px;
    display: inline-block;
    line-height: 1;
    width: 100%;

    ${({ width }) => width != null &&
    css `
            width: ${width};
        `}

    ${({ block }) => !!block &&
    css `
            border-radius: 0;
            display: block;
        `}
`;
export default Skeleton;
