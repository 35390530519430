import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '$shared/components/Button';
import styles from './buttons.pcss';
export const Buttons = ({ actions, className }) => (React.createElement("div", { className: classNames(styles.buttons, className) }, actions &&
    Object.keys(actions)
        .filter((key) => actions && actions[key].visible !== false)
        .map((key) => {
        const { title, onClick, linkTo, href, kind, disabled, outline, spinner, className: cn, type, } = (actions && actions[key]) || {};
        return (React.createElement(Button, { key: key, tag: linkTo != null ? Link : href != null ? 'a' : 'button', to: linkTo, href: href, onClick: onClick, disabled: disabled, kind: kind, outline: outline, waiting: spinner, type: type, className: classNames(styles[kind], cn) }, title));
    })));
Buttons.defaultProps = {
    actions: {},
};
export default Buttons;
