var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState, forwardRef } from 'react';
import '$shared/types/common-types';
const FlushHistoryDecorator = (WrappedComponent) => {
    const FlushHistoryDecoratorWrapper = (_a, ref) => {
        var { onBlur: onBlurProp } = _a, props = __rest(_a, ["onBlur"]);
        const [blurCount, setBlurCount] = useState(0);
        const onBlur = useCallback((e) => {
            // `blurCount` is used as `key` of the actual control. Changing it replaces the control
            // with a new instance thus the old instance along with its change history gets forgotten.
            setBlurCount((count) => count + 1);
            if (onBlurProp) {
                onBlurProp(e);
            }
        }, [onBlurProp]);
        return React.createElement(WrappedComponent, Object.assign({}, props, { ref: ref, key: blurCount, onBlur: onBlur }));
    };
    const FlushHistoryDecoratorWrapperFR = forwardRef(FlushHistoryDecoratorWrapper);
    const OptInFlushHistoryDecorator = (_a, ref) => {
        var { flushHistoryOnBlur = false } = _a, props = __rest(_a, ["flushHistoryOnBlur"]);
        return flushHistoryOnBlur ? (React.createElement(FlushHistoryDecoratorWrapperFR, Object.assign({}, props, { ref: ref }))) : (React.createElement(WrappedComponent, Object.assign({}, props, { ref: ref })));
    };
    return forwardRef(OptInFlushHistoryDecorator);
};
export default FlushHistoryDecorator;
