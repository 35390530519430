import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { maxFileSizeForImageUpload } from '$shared/utils/constants';
import PngIcon from '$shared/components/PngIcon';
import useIsMounted from '$shared/hooks/useIsMounted';
import useFilePreview from '$shared/hooks/useFilePreview';
import { errorToast } from '$utils/toast';
import styles from './imageUpload.pcss';
const ImageUpload = ({ setImageToUpload, originalImage, className, dropZoneClassName, noPreview, disabled, }) => {
    const [uploading, setUploading] = useState(false);
    const { preview, createPreview } = useFilePreview();
    const isMounted = useIsMounted();
    const onDrop = useCallback((files) => {
        if (!isMounted()) {
            return;
        }
        const [image] = files;
        if (image) {
            // Save image to the state also so that a preview can be shown
            const imagePreview = createPreview(image);
            setUploading(true);
            if (setImageToUpload) {
                // $FlowFixMe property `preview` is missing in  `File`.
                setImageToUpload(Object.assign(image, {
                    preview: imagePreview,
                }));
            }
        }
    }, [createPreview, setImageToUpload, isMounted]);
    const onDropAccepted = useCallback(() => {
        if (!isMounted()) {
            return;
        }
        setUploading(false);
    }, [isMounted]);
    const onDropRejected = useCallback(([rejection]) => {
        if (!isMounted()) {
            return;
        }
        if (rejection.file.size > maxFileSizeForImageUpload) {
            errorToast({
                title: `Image file size must be less than ${Math.floor(maxFileSizeForImageUpload / 1e6)}MB`,
            });
        }
        setUploading(false);
    }, [isMounted]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        maxSize: maxFileSizeForImageUpload,
        onDrop,
        onDropAccepted,
        onDropRejected,
        disabled,
    });
    const srcImage = useMemo(() => {
        if (noPreview) {
            return originalImage;
        }
        return preview || originalImage;
    }, [noPreview, originalImage, preview]);
    return (React.createElement("div", Object.assign({}, getRootProps({
        className: cx(styles.root, styles.ImageUpload, {
            [styles.dropzoneAdviceImageLoading]: !!uploading,
            [styles.imageUploaded]: !uploading && !!srcImage,
            [styles.dragEntered]: isDragActive,
        }, className),
        'aria-disabled': disabled,
    })),
        React.createElement("input", Object.assign({}, getInputProps())),
        React.createElement("div", { className: cx(styles.dropzoneAdvice, dropZoneClassName) },
            React.createElement(PngIcon, { className: styles.icon, name: "imageUpload", alt: "" }),
            React.createElement("p", null, srcImage ? (React.createElement("span", null,
                "Drag & drop to replace your cover image ",
                React.createElement("br", null),
                " or click to browse for one")) : (React.createElement(Fragment, null,
                React.createElement("span", { className: cx(styles.uploadAdvice, styles.uploadAdviceDesktop) },
                    "Drag & drop to upload a cover image ",
                    React.createElement("br", null),
                    " or click to browse for one"),
                React.createElement("span", { className: cx(styles.uploadAdvice, styles.uploadAdviceTablet) }, "Tap to take a photo or browse for one"),
                React.createElement("span", { className: cx(styles.uploadAdvice, styles.uploadAdviceMobile) },
                    "Tap to take a photo",
                    React.createElement("br", null),
                    "or browse for one"))))),
        srcImage && (React.createElement("img", { className: styles.previewImage, src: srcImage, alt: "Uploaded" }))));
};
export default ImageUpload;
