import formatConfigUrl from '$utils/formatConfigUrl';
import formatRpc from '$utils/formatRpc';
import formatTrackers from '$utils/formatTrackers';
import getConfig from '$app/src/getters/getConfig';
export default function getClientConfig(mods = {}) {
    var _a, _b, _c, _d, _e, _f;
    const { client } = getConfig();
    const config = {
        metrics: false,
    };
    if ((_a = client === null || client === void 0 ? void 0 : client.network) === null || _a === void 0 ? void 0 : _a.trackers) {
        config.network = {
            trackers: formatTrackers((_b = client === null || client === void 0 ? void 0 : client.network) === null || _b === void 0 ? void 0 : _b.trackers),
        };
    }
    const contracts = {};
    [
        {
            condition: !!((_c = client === null || client === void 0 ? void 0 : client.mainchain) === null || _c === void 0 ? void 0 : _c.rpc),
            key: 'mainChainRPCs',
            value: formatRpc((_d = client === null || client === void 0 ? void 0 : client.mainchain) === null || _d === void 0 ? void 0 : _d.rpc),
        },
        {
            condition: !!(client === null || client === void 0 ? void 0 : client.streamRegistryChainAddress),
            key: 'streamRegistryChainAddress',
            value: client === null || client === void 0 ? void 0 : client.streamRegistryChainAddress,
        },
        {
            condition: !!((_e = client === null || client === void 0 ? void 0 : client.streamRegistryChain) === null || _e === void 0 ? void 0 : _e.rpc),
            key: 'streamRegistryChainRPCs',
            value: formatRpc((_f = client === null || client === void 0 ? void 0 : client.streamRegistryChain) === null || _f === void 0 ? void 0 : _f.rpc),
        },
        {
            condition: !!(client === null || client === void 0 ? void 0 : client.streamStorageRegistryChainAddress),
            key: 'streamStorageRegistryChainAddress',
            value: client === null || client === void 0 ? void 0 : client.streamStorageRegistryChainAddress,
        },
        {
            condition: !!(client === null || client === void 0 ? void 0 : client.graphUrl),
            key: 'theGraphUrl',
            value: formatConfigUrl(client === null || client === void 0 ? void 0 : client.graphUrl),
        },
    ].forEach((configCase) => {
        if (configCase.condition) {
            contracts[configCase.key] = configCase.value;
        }
    });
    if (Object.keys(contracts).length > 0) {
        config.contracts = contracts;
    }
    return Object.assign(Object.assign({}, config), mods);
}
