import React, { useCallback } from 'react';
import cx from 'classnames';
import styles from './slider.pcss';
const Slider = ({ min, max, value, className, sliderClassname, onChange: onChangeProp, disabled, }) => {
    const onChange = useCallback((e) => {
        if (onChangeProp) {
            onChangeProp(parseInt(e.currentTarget.value, 10));
        }
    }, [onChangeProp]);
    return (React.createElement("div", { className: cx(styles.container, className) },
        React.createElement("input", { className: cx(styles.sliderInput, sliderClassname), type: "range", min: min, max: max, value: value, onChange: onChange, disabled: disabled })));
};
export default Slider;
