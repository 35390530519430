import BN from 'bignumber.js';
import * as yup from 'yup';
import { isAddress } from 'web3-utils';
import { contractCurrencies as currencies, projectStates } from '$shared/utils/constants';
import InvalidHexStringError from '$shared/errors/InvalidHexStringError';
import { ProjectType } from '$shared/types';
import { validateSalePoint } from './validate';
import { fromDecimals, toDecimals } from './math';
import { getPrefixedHexString, getUnprefixedHexString, isValidHexString, } from './smartContract';
export const isPaidProject = (project) => project.type !== ProjectType.OpenData;
export const isProjectOwnedBy = ({ permissions }, address) => {
    const { canGrant = false } = permissions.find((p) => p.userAddress.toLowerCase() === address.toLowerCase()) ||
        {};
    return !!canGrant;
};
export const isDataUnionProject = (project) => {
    return project.isDataUnion;
};
export const isDataUnionProduct = (productOrProductType) => {
    const { type } = typeof productOrProductType === 'string'
        ? {
            type: productOrProductType,
        }
        : (productOrProductType || {});
    return type === ProjectType.DataUnion;
};
export const validateProductPriceCurrency = (priceCurrency) => {
    const currencyIndex = Object.keys(currencies).indexOf(priceCurrency);
    if (currencyIndex < 0) {
        throw new Error(`Invalid currency: ${priceCurrency}`);
    }
};
export const validateApiProductPricePerSecond = (pricePerSecond) => {
    if (new BN(pricePerSecond).isLessThan(0)) {
        throw new Error('Product price must be equal to or greater than 0');
    }
};
export const validateContractProductPricePerSecond = (pricePerSecond) => {
    if (new BN(pricePerSecond).isLessThanOrEqualTo(0)) {
        throw new Error('Product price must be greater than 0 to publish');
    }
};
export const mapPriceFromContract = (pricePerSecond, decimals) => fromDecimals(pricePerSecond, decimals).toString();
export const mapPriceToContract = (pricePerSecond, decimals) => toDecimals(pricePerSecond, decimals).toFixed(0);
export const mapProductFromContract = (id, result, chainId, pricingTokenDecimals) => {
    const minimumSubscriptionSeconds = parseInt(result.minimumSubscriptionSeconds, 10);
    return {
        id,
        name: result.name,
        ownerAddress: result.owner,
        beneficiaryAddress: result.beneficiary,
        pricePerSecond: result.pricePerSecond,
        minimumSubscriptionInSeconds: Number.isNaN(minimumSubscriptionSeconds)
            ? 0
            : minimumSubscriptionSeconds,
        state: Object.keys(projectStates)[result.state],
        chainId,
        pricingTokenAddress: result.pricingTokenAddress,
        pricingTokenDecimals: pricingTokenDecimals.toNumber(),
    };
};
export const mapProductFromApi = (product) => {
    // TODO map the project from contract
    // const pricePerSecond = mapPriceFromApi(product.pricePerSecond)
    return Object.assign({}, product);
};
export const mapAllProductsFromApi = (products) => products.map(mapProductFromApi);
export const getValidId = (id, prefix = true) => {
    if (!isValidHexString(id) || parseInt(id, 16) === 0) {
        throw new InvalidHexStringError(id);
    }
    return prefix ? getPrefixedHexString(id) : getUnprefixedHexString(id);
};
const urlValidator = yup.string().trim().url();
const emailValidator = yup.string().trim().email();
const descriptionRegExp = new RegExp(/^(\\+\n?)*$/);
export const validate = (project) => {
    const invalidFields = {};
    ['name', 'description'].forEach((field) => {
        invalidFields[field] = !project[field];
    });
    if (!!project.description && descriptionRegExp.test(project.description)) {
        invalidFields.description = true;
    }
    invalidFields.imageUrl = !project.imageUrl && !project.newImageToUpload;
    invalidFields.streams = !project.streams || project.streams.length <= 0;
    if (!project.creator || project.creator.length > 256) {
        invalidFields.creator = true;
    }
    if (project.contact) {
        ;
        ['url', 'social1', 'social2', 'social3', 'social4'].forEach((field) => {
            if (project.contact[field] &&
                project.contact[field].length > 0) {
                invalidFields[`contact.${field}`] = !urlValidator.isValidSync(project.contact[field]);
            }
        });
        if (project.contact.email && project.contact.email.length > 0) {
            const result = emailValidator.isValidSync(project.contact.email);
            invalidFields['contact.email'] = !result && !!project.contact.email;
        }
    }
    // applies only to data union
    if (project.type === ProjectType.DataUnion) {
        invalidFields.adminFee =
            !!project.isDeployingNewDU &&
                (project.adminFee === undefined ||
                    +project.adminFee < 0 ||
                    +project.adminFee > 100);
        invalidFields.dataUnionChainId = !project.dataUnionChainId;
        invalidFields.existingDUAddress =
            !project.isDeployingNewDU &&
                (!project.existingDUAddress || !isAddress(project.existingDUAddress));
    }
    // applies to paid projects and data unions
    if ([ProjectType.PaidData, ProjectType.DataUnion].includes(project.type)) {
        if (!(project === null || project === void 0 ? void 0 : project.salePoints) || !Object.values((project === null || project === void 0 ? void 0 : project.salePoints) || {}).length) {
            invalidFields.salePoints = true;
        }
        if (Object.keys((project === null || project === void 0 ? void 0 : project.salePoints) || {}).length) {
            Object.keys((project === null || project === void 0 ? void 0 : project.salePoints) || {}).forEach((chainName) => {
                var _a;
                const salePoint = (_a = project.salePoints) === null || _a === void 0 ? void 0 : _a[chainName];
                if (salePoint == null) {
                    return;
                }
                const invalidSalePointFields = validateSalePoint(salePoint, project.type === ProjectType.DataUnion);
                if (!!invalidSalePointFields && invalidSalePointFields.length) {
                    invalidFields[`salePoints.${chainName}`] = true;
                }
            });
        }
    }
    return invalidFields;
};
