import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '$shared/components/Button';
import getTransactionalClient from '$app/src/getters/getTransactionalClient';
import { useCurrentDraft } from '$shared/stores/streamEditor';
import routes from '$routes';
import Section from './Section';
const Description = styled.p.withConfig({ displayName: "Description", componentId: "sc-20zy00" }) `
    margin-bottom: 3rem;
`;
export default function DeleteSection() {
    const navigate = useNavigate();
    const { streamId } = useCurrentDraft();
    return (React.createElement(Section, { title: "Delete stream" },
        React.createElement(Description, null, "Delete this stream forever. You can't undo this."),
        React.createElement(Button, { type: "button", kind: "destructive", disabled: !streamId, onClick: async () => {
                if (!streamId) {
                    return;
                }
                const client = await getTransactionalClient();
                await client.deleteStream(streamId);
                /**
                 * @FIXME: If the user navigates away before the above transaciton is
                 * done the app is gonna navigate to streams/index.
                 */
                navigate(routes.streams.index());
            } }, "Delete")));
}
