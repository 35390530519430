import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import { COLORS, DESKTOP, TABLET } from '$shared/utils/styled';
import Button from '$shared/components/Button';
import Layout from '$shared/components/Layout';
import SearchBar from '$shared/components/SearchBar';
import { IndexerOrderBy, IndexerOrderDirection, TheGraphOrderBy, TheGraphOrderDirection, getPagedStreams, getPagedStreamsFromIndexer, getStreams, getStreamsFromIndexer, } from '$app/src/services/streams';
import { ActionBarContainer, FiltersBar, FiltersWrap, SearchBarWrap, } from '$mp/components/ActionBar/actionBar.styles';
import { PageWrap } from '$shared/components/PageWrap';
import styles from '$shared/components/Layout/layout.pcss';
import StreamTable, { OrderBy, OrderDirection } from '$shared/components/StreamTable';
import LoadingIndicator from '$shared/components/LoadingIndicator';
import Tabs, { Tab } from '$shared/components/Tabs';
import { RouteMemoryKey, useRecall, useRemember } from '$shared/stores/routeMemory';
import { useWalletAccount } from '$shared/stores/wallet';
import address0 from '$app/src/utils/address0';
import routes from '$routes';
var StreamSelection;
(function (StreamSelection) {
    StreamSelection["All"] = "All";
    StreamSelection["Your"] = "Your";
})(StreamSelection || (StreamSelection = {}));
const PAGE_SIZE = 10;
const DEFAULT_ORDER_BY = OrderBy.MessagesPerSecond;
const DEFAULT_ORDER_DIRECTION = OrderDirection.Desc;
const mapOrderByToIndexer = (orderBy) => {
    switch (orderBy) {
        case OrderBy.Id: {
            return IndexerOrderBy.Id;
        }
        case OrderBy.MessagesPerSecond: {
            return IndexerOrderBy.MsgPerSecond;
        }
        case OrderBy.PeerCount: {
            return IndexerOrderBy.PeerCount;
        }
        default:
            return IndexerOrderBy.MsgPerSecond;
    }
};
const mapOrderDirectionToIndexer = (orderDirection) => {
    if (orderDirection === OrderDirection.Desc) {
        return IndexerOrderDirection.Desc;
    }
    return IndexerOrderDirection.Asc;
};
const mapOrderByToGraph = (orderBy) => {
    switch (orderBy) {
        case OrderBy.Id: {
            return TheGraphOrderBy.Id;
        }
        default:
            return TheGraphOrderBy.Id;
    }
};
const mapOrderDirectionToGraph = (orderDirection) => {
    if (orderDirection === OrderDirection.Desc) {
        return TheGraphOrderDirection.Desc;
    }
    return TheGraphOrderDirection.Asc;
};
const shouldUseIndexer = (orderBy) => {
    return orderBy === OrderBy.MessagesPerSecond || orderBy === OrderBy.PeerCount;
};
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-8i919b" }) `
    background-color: ${COLORS.secondary};

    padding: 24px 24px 80px 24px;

    @media ${TABLET} {
        padding: 45px 40px 90px 40px;
    }

    @media ${DESKTOP} {
        padding: 60px 0 130px;
    }
`;
const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-1eukk0z" }) `
    border-radius: 16px;
    background-color: white;
`;
const NewStreamListingPage = () => {
    var _a, _b, _c, _d, _e;
    const [search, setSearch] = useState('');
    const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
    const [orderDirection, setOrderDirection] = useState(DEFAULT_ORDER_DIRECTION);
    const [streamsSelection, setStreamsSelection] = useState(useRecall(RouteMemoryKey.lastStreamListingSelection()) ||
        StreamSelection.All);
    const account = useWalletAccount();
    const remember = useRemember();
    useEffect(() => {
        remember(RouteMemoryKey.lastStreamListingSelection(), streamsSelection);
    }, [remember, streamsSelection]);
    const streamsQuery = useInfiniteQuery({
        queryKey: ['streams', search, streamsSelection, account, orderBy, orderDirection],
        queryFn: async (ctx) => {
            const owner = streamsSelection === StreamSelection.Your
                ? account || address0
                : undefined;
            let result;
            if (shouldUseIndexer(orderBy)) {
                result = await getPagedStreamsFromIndexer(PAGE_SIZE, ctx.pageParam, owner, search, mapOrderByToIndexer(orderBy), mapOrderDirectionToIndexer(orderDirection));
            }
            else {
                result = await getPagedStreams(PAGE_SIZE, ctx.pageParam, owner, search, mapOrderByToGraph(orderBy), mapOrderDirectionToGraph(orderDirection));
            }
            // Fetch stats
            statsQuery.fetchNextPage({
                pageParam: {
                    streamIds: result.streams.map((s) => s.id),
                    useIndexer: !shouldUseIndexer(orderBy),
                },
            });
            return result;
        },
        getNextPageParam: (lastPage) => {
            const theGraphResult = lastPage;
            if (theGraphResult.lastId) {
                return theGraphResult.hasNextPage ? theGraphResult.lastId : null;
            }
            const indexerResult = lastPage;
            if (indexerResult.cursor) {
                return indexerResult.hasNextPage ? indexerResult.cursor : null;
            }
            return null;
        },
        staleTime: 60 * 1000,
        keepPreviousData: true,
    });
    const statsQuery = useInfiniteQuery({
        queryKey: ['streamStats'],
        queryFn: async (ctx) => {
            if (ctx.pageParam == null) {
                return;
            }
            if (ctx.pageParam.useIndexer) {
                const indexerStats = await getStreamsFromIndexer(ctx.pageParam.streamIds);
                return indexerStats;
            }
            const indexerStats = await getStreams(ctx.pageParam.streamIds);
            return indexerStats;
        },
        staleTime: 60 * 1000,
        keepPreviousData: true,
    });
    // If indexer errors fall back to using The Graph
    useEffect(() => {
        if (streamsQuery.isError && shouldUseIndexer(orderBy)) {
            setOrderBy(OrderBy.Id);
            setOrderDirection(OrderDirection.Asc);
        }
    }, [streamsQuery.isError, orderBy]);
    return (React.createElement(Layout, { innerClassName: styles.greyInner },
        React.createElement(MarketplaceHelmet, { title: "Streams" }),
        React.createElement(ActionBarContainer, null,
            React.createElement(SearchBarWrap, null,
                React.createElement(SearchBar, { value: search, onChange: (value) => {
                        setSearch(value);
                    } })),
            React.createElement(FiltersBar, null,
                React.createElement(FiltersWrap, null,
                    React.createElement(Tabs, { selection: streamsSelection, onSelectionChange: (id) => void setStreamsSelection(id) },
                        React.createElement(Tab, { id: StreamSelection.All }, "All streams"),
                        React.createElement(Tab, { id: StreamSelection.Your, disabled: !account, title: account
                                ? undefined
                                : 'Connect your wallet to view your streams' }, "Your streams"))),
                React.createElement(Button, { tag: Link, to: routes.streams.new() }, "Create stream"))),
        React.createElement(LoadingIndicator, { loading: streamsQuery.isLoading ||
                streamsQuery.isFetching ||
                streamsQuery.isFetchingNextPage }),
        React.createElement(PageWrap, null,
            React.createElement(Container, null,
                React.createElement(TableContainer, null,
                    React.createElement(StreamTable, { title: `${streamsSelection === StreamSelection.All ? 'All' : 'Your'} Streams`, streams: (_b = (_a = streamsQuery.data) === null || _a === void 0 ? void 0 : _a.pages.flatMap((d) => d.streams)) !== null && _b !== void 0 ? _b : [], streamStats: Object.fromEntries(((_d = (_c = statsQuery.data) === null || _c === void 0 ? void 0 : _c.pages) !== null && _d !== void 0 ? _d : [])
                            .filter((p) => p != null)
                            .flatMap((p) => p)
                            .map((s) => [s.id, s])), loadMore: () => streamsQuery.fetchNextPage(), hasMoreResults: (_e = streamsQuery.hasNextPage) !== null && _e !== void 0 ? _e : false, showGlobalStats: streamsSelection === StreamSelection.All, orderBy: orderBy, orderDirection: orderDirection, onSortChange: (orderBy, orderDirection) => {
                            setOrderBy(orderBy);
                            setOrderDirection(orderDirection);
                        }, noStreamsText: streamsSelection === StreamSelection.Your && !search ? (React.createElement(React.Fragment, null, "You haven't created any streams yet")) : (void 0) }))))));
};
export default NewStreamListingPage;
