var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import styled from 'styled-components';
import SvgIcon from '$shared/components/SvgIcon';
import { COLORS, MEDIUM } from '$shared/utils/styled';
import IconButton from './IconButton';
const Button = styled(IconButton).withConfig({ displayName: "Button", componentId: "sc-1otkz5t" }) `
    :not(:last-child) {
        margin-left: 16px;
    }

    + div {
        margin: 0 12px;
        white-space: nowrap;
    }

    svg {
        width: 8px;
        height: 14px;
    }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-afpt1z" }) `
    font-weight: ${MEDIUM};
`;
const UnstyledSelector = (_a) => {
    var { active, onChange, options = [], title } = _a, props = __rest(_a, ["active", "onChange", "options", "title"]);
    const current = options.indexOf(active);
    const prevOption = options[Math.max(0, current - 1)];
    const prev = useCallback(() => {
        onChange(prevOption);
    }, [onChange, prevOption]);
    const nextOption = options[Math.min(current + 1, options.length)];
    const next = useCallback(() => {
        onChange(nextOption);
    }, [onChange, nextOption]);
    if (options.length <= 1) {
        return null;
    }
    return (React.createElement("div", Object.assign({}, props),
        React.createElement(Title, null, title),
        React.createElement(Button, { disabled: current <= 0, onClick: prev, type: "button" },
            React.createElement(SvgIcon, { name: "back" })),
        React.createElement("div", null,
            React.createElement("strong", null, current + 1),
            " of ",
            React.createElement("strong", null, options.length)),
        React.createElement(Button, { disabled: current >= options.length - 1, onClick: next, type: "button" },
            React.createElement(SvgIcon, { name: "forward" }))));
};
const Selector = styled(UnstyledSelector).withConfig({ displayName: "Selector", componentId: "sc-1l3npwj" }) `
    align-items: center;
    color: ${COLORS.primary};
    display: flex;
    button {
        color: ${COLORS.primary};
    }
`;
export default Selector;
