var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import formatConfigUrl from './formatConfigUrl';
// TODO add typing
export default function formatTrackers(trackers) {
    if (!trackers) {
        return trackers;
    }
    return trackers.map((t) => {
        if (!t || typeof t !== 'object') {
            return t;
        }
        const { wsUrl, httpUrl } = t, u = __rest(t, ["wsUrl", "httpUrl"]);
        return Object.assign(Object.assign({}, u), { ws: formatConfigUrl(wsUrl, {
                protocol: 'ws',
            }), http: formatConfigUrl(httpUrl) });
    });
}
