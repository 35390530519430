import qs from 'query-string';
import pick from 'lodash/pick';
import { parse, compile } from 'path-to-regexp';
import getCoreConfig from '$app/src/getters/getCoreConfig';
import definitions from './definitions.json';
/**
 * Generates a route function.
 * @param pathstr Path format.
 * @param getVariables Variable collection getter.
 * @returns Route function.
 */
export const define = (pathstr, getVariables) => (params, options = {}) => {
    let path = pathstr;
    if (params) {
        const tokenNames = parse(path)
            .map((t) => t.name)
            .filter(Boolean);
        const queryKeys = Object.keys(params).filter((key) => !tokenNames.includes(key));
        const { encode, validate, hash } = Object.assign({ encode: true, validate: true, hash: undefined }, (options || {}));
        const toPath = compile(path, {
            encode: (value) => (encode ? encodeURIComponent(value) : value),
            validate: !!validate,
        });
        const uri = `${toPath(params)}?${qs.stringify(pick(params, queryKeys))}`.replace(/\?$/, '');
        path = hash ? `${uri}#${hash}` : uri;
    }
    const route = Object.entries(getVariables()).reduce((acc, [name, value]) => {
        const val = value || '';
        const strippedValue = val.length > 1 ? val.replace(/\/$/, '') : val;
        return acc.replace(new RegExp(`<${name}>`, 'g'), strippedValue);
    }, path);
    const unsetVariableNames = (route.match(/<[^>]+>/g) || []).map((s) => s.replace(/[<>]/g, ''));
    if (unsetVariableNames.length) {
        throw new Error(`Expected ${unsetVariableNames
            .map((s) => `"${s}"`)
            .join(', ')} variable(s) to be defined`);
    }
    return route;
};
/**
 * Generates final route object.
 */
export const buildRoutes = (paths, getVariables) => Object.entries(paths).reduce((acc, [name, route]) => {
    const value = route;
    return Object.assign(Object.assign({}, acc), { [name]: typeof value === 'string'
            ? define(value, getVariables)
            : buildRoutes(value, getVariables) });
}, {});
const routes = buildRoutes(definitions, () => {
    const { streamrUrl: streamr, platformOriginUrl: platform, landingPageUrl: landingPage, } = getCoreConfig();
    const api = `${streamr}/api/v2`;
    return {
        api,
        landingPage,
        platform,
        streamr,
    };
});
export default routes;
