import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import { ProjectHeroTitleStyles } from '$mp/containers/ProjectPage/Hero/ProjectHero2.styles';
import EnhancedText from '$ui/Text';
import { COLORS } from '$shared/utils/styled';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
const ProjectNameInputContainer = styled.div.withConfig({ displayName: "ProjectNameInputContainer", componentId: "sc-y94dsw" }) `
    ${ProjectHeroTitleStyles};
`;
const ProjectNameInput = styled(EnhancedText).withConfig({ displayName: "ProjectNameInput", componentId: "sc-1yt6dyu" }) `
    padding: 0;
    border: none;
    line-height: 44px;
    font-size: 34px;

    &:not(:disabled):focus {
        border: none;
    }

    ::placeholder {
        color: ${COLORS.primaryDisabled};
    }
`;
const ProjectName = ({ disabled }) => {
    const { state: product } = useContext(ProjectStateContext);
    const { updateName } = useEditableProjectActions();
    const onChange = useCallback((e) => {
        updateName(e.target.value);
        e.preventDefault();
    }, [updateName]);
    return (React.createElement(ProjectNameInputContainer, null,
        React.createElement(ProjectNameInput, { name: 'projectName', disabled: disabled, placeholder: 'Project name', value: product.name || '', onChange: onChange, autoFocus: true })));
};
export default ProjectName;
