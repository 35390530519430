import { useContext, useMemo, useCallback } from 'react';
import { ValidationContext } from './ValidationContextProvider';
const useValidation = (fieldName) => {
    const { status, setStatus: setStatusState, clearStatus: clearStatusState, } = useContext(ValidationContext);
    const setStatus = useCallback((level, message) => {
        setStatusState(fieldName, level, message);
    }, [setStatusState, fieldName]);
    const clearStatus = useCallback(() => {
        clearStatusState(fieldName);
    }, [clearStatusState, fieldName]);
    const result = status[fieldName];
    const isValid = useMemo(() => !result, [result]);
    const level = useMemo(() => result && result.level, [result]);
    const message = useMemo(() => result && result.message, [result]);
    return useMemo(() => ({
        isValid,
        level,
        message,
        setStatus,
        clearStatus,
    }), [isValid, level, message, setStatus, clearStatus]);
};
export default useValidation;
