import axios from 'axios';
import merge from 'lodash/merge';
import RequestError from '$shared/errors/RequestError';
export const getData = ({ data }) => data;
export default function request({ url, options, method = 'get', data = null, }) {
    const defaultOptions = {
        headers: {},
    };
    if (data !== null) {
        defaultOptions.headers = Object.assign(Object.assign({}, defaultOptions.headers), { 'Content-Type': 'application/json' });
    }
    // Merge options with defaults
    const requestOptions = merge(defaultOptions, options);
    return axios
        .request(Object.assign(Object.assign({}, requestOptions), { url, method, data }))
        .then((res) => getData(res))
        .catch((res) => {
        throw new RequestError(res);
    });
}
