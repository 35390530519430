import React, { useCallback } from 'react';
import styled from 'styled-components';
import useModal from '$shared/hooks/useModal';
import { ProjectTypeChooser } from '$mp/components/ProjectTypeChooser';
import ModalPortal from '$shared/components/ModalPortal';
import ModalDialog from '$shared/components/ModalDialog';
const StyledProjectTypeChooser = styled(ProjectTypeChooser).withConfig({ displayName: "StyledProjectTypeChooser", componentId: "sc-1ge0vgx" }) `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const CreateProjectModal = ({ api }) => {
    const onClose = useCallback(() => {
        api.close();
    }, [api]);
    return (React.createElement(ModalPortal, null,
        React.createElement(ModalDialog, { onClose: onClose, fullpage: true },
            React.createElement(StyledProjectTypeChooser, { onClose: onClose }))));
};
const CreateProjectModalWrap = () => {
    const { api, isOpen } = useModal('marketplace.createProduct');
    if (!isOpen) {
        return null;
    }
    return React.createElement(CreateProjectModal, { api: api });
};
export default CreateProjectModalWrap;
