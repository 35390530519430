var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { isDataUnionProject } from '$mp/utils/product';
import { MarketplaceProductTile as UnstyledMarketplaceProductTile } from '$shared/components/Tile';
import { REGULAR, DESKTOP, COLORS, TABLET, MAX_BODY_WIDTH, LAPTOP, } from '$shared/utils/styled';
import Button from '$shared/components/Button';
import ProductPageSpinner from '../ProductPageSpinner';
import Error from '../Error';
import styles from './projects.pcss';
import NoProductsView from './NoProductsView';
export const MarketplaceProductTile = styled(UnstyledMarketplaceProductTile).withConfig({ displayName: "MarketplaceProductTile", componentId: "sc-jtt5da" }) `
    margin-top: 16px;
`;
export const MarketplaceProjectRow = styled.div.withConfig({ displayName: "MarketplaceProjectRow", componentId: "sc-1mp4aih" }) `
    display: grid;
    grid-gap: 36px;
    margin: 0;
    grid-template-columns: 1fr;

    @media ${TABLET} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${LAPTOP} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;
export const MarketplaceProjectCol = styled.div.withConfig({ displayName: "MarketplaceProjectCol", componentId: "sc-1xsa6uw" }) `
    padding: 0;
`;
const listProjects = (projects, currentUserAddress, isFetching) => {
    const isEditable = (projectId) => {
        if (!currentUserAddress) {
            return false;
        }
        const project = projects.find((p) => p.id === projectId);
        if (project != null) {
            const perm = project.permissions.find((permission) => permission.userAddress.toLowerCase() ===
                currentUserAddress.toLowerCase());
            if (perm != null) {
                return perm.canEdit;
            }
            return false;
        }
        return false;
    };
    return (React.createElement(MarketplaceProjectRow, { className: classnames(styles.productsRow, {
            [styles.fetching]: isFetching,
        }) }, projects.map((project) => (React.createElement(MarketplaceProjectCol, { key: project.id },
        React.createElement(MarketplaceProductTile, { product: project, showDataUnionBadge: isDataUnionProject(project), showEditButton: isEditable(project.id) }))))));
};
export const ProjectsContainer = styled.div.withConfig({ displayName: "ProjectsContainer", componentId: "sc-vk9i3j" }) `
    padding: 40px 30px 3.5em 30px;
    background-color: ${COLORS.secondary};
    max-width: ${MAX_BODY_WIDTH}px;
    margin: 0 auto;

    @media ${DESKTOP} {
        padding: 72px 0 7em 0;
    }
`;
export const ProjectsHeader = styled.h3.withConfig({ displayName: "ProjectsHeader", componentId: "sc-yms0zb" }) `
    font-size: 34px;
    line-height: 34px;
    font-weight: ${REGULAR};
    margin-bottom: 16px;

    @media ${TABLET} {
        margin-bottom: 40px;
    }
`;
export const LoadMoreButton = styled(Button).withConfig({ displayName: "LoadMoreButton", componentId: "sc-dtwhl2" }) `
    display: block !important;
    margin: 130px auto 80px;
`;
const UnstyledProjects = (_a) => {
    var { projects, type, error, isFetching, loadProducts, hasMoreSearchResults, header, currentUserAddress, noOwnProjects = false } = _a, props = __rest(_a, ["projects", "type", "error", "isFetching", "loadProducts", "hasMoreSearchResults", "header", "currentUserAddress", "noOwnProjects"]);
    return (React.createElement("div", Object.assign({}, props),
        header && React.createElement(ProjectsHeader, null, header),
        React.createElement(Error, { source: error }),
        isFetching || projects.length > 0 ? (listProjects(projects, currentUserAddress, isFetching)) : (React.createElement(NoProductsView, { noOwnProjects: noOwnProjects })),
        loadProducts && !isFetching && hasMoreSearchResults && (React.createElement(LoadMoreButton, { onClick: loadProducts, kind: 'primary2' }, "Load more")),
        isFetching && React.createElement(ProductPageSpinner, { className: styles.spinner })));
};
const ProjectsComponent = styled(UnstyledProjects).withConfig({ displayName: "ProjectsComponent", componentId: "sc-1okvnuk" }) ``;
export default ProjectsComponent;
