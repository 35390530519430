import getCoreConfig from '$app/src/getters/getCoreConfig';
import { post } from '$shared/utils/api';
import { getConfigForChainByName } from '$shared/web3/config';
import address0 from '$utils/address0';
import { getWalletWeb3Provider } from '$shared/stores/wallet';
import getDefaultWeb3Account from '$utils/web3/getDefaultWeb3Account';
import { getGraphUrl, getProjectRegistryContract } from '$app/src/getters';
import networkPreflight from '$utils/networkPreflight';
import { deployDataUnion } from '../marketplace/modules/dataUnion/services';
const getProjectRegistryChainId = () => {
    const { projectsChain } = getCoreConfig();
    const config = getConfigForChainByName(projectsChain);
    return config.id;
};
const projectFields = `
    id
    domainIds
    score
    metadata
    streams
    minimumSubscriptionSeconds
    createdAt
    updatedAt
    isDataUnion
    paymentDetails {
        domainId
        beneficiary
        pricingTokenAddress
        pricePerSecond
    }
    subscriptions {
        userAddress
        endTimestamp
    }
    permissions {
        userAddress
        canBuy
        canDelete
        canEdit
        canGrant
    }
    purchases {
        subscriber
        subscriptionSeconds
        price
        fee
        purchasedAt
    }
`;
const mapProject = (project) => {
    try {
        const metadata = JSON.parse(project.metadata);
        project.metadata = metadata;
    }
    catch (e) {
        console.error(`Could not parse metadata for project ${project.id}`, e);
        project.metadata = {};
    }
    return project;
};
export var ProjectListingTypeFilter;
(function (ProjectListingTypeFilter) {
    ProjectListingTypeFilter["openData"] = "openData";
    ProjectListingTypeFilter["paidData"] = "paidData";
    ProjectListingTypeFilter["dataUnion"] = "dataUnion";
})(ProjectListingTypeFilter || (ProjectListingTypeFilter = {}));
export const getProject = async (id) => {
    const theGraphUrl = getGraphUrl();
    const result = await post({
        url: theGraphUrl,
        data: {
            query: `
                query {
                    projects(
                        where: { id: "${id.toLowerCase()}" }
                    ) {
                        ${projectFields}
                    }
                }
            `,
        },
    });
    if (result.data) {
        const projects = result.data.projects.map((p) => mapProject(p));
        if (projects && projects.length > 0) {
            return projects[0];
        }
    }
    return null;
};
const prepareProjectResult = (results, pageSize) => {
    let hasNextPage = false;
    const projects = results.map((p) => mapProject(p));
    if (projects.length > pageSize) {
        hasNextPage = true;
        // Remove last item
        projects.splice(pageSize, 1);
    }
    return {
        projects,
        hasNextPage,
        lastId: projects.length === 0 ? null : projects[projects.length - 1].id,
    };
};
const getProjectFilterQuery = (type) => {
    switch (type) {
        case ProjectListingTypeFilter.openData:
            return `paymentDetails_: {beneficiary: "${address0}"}`;
        case ProjectListingTypeFilter.paidData:
            return `paymentDetails_: {beneficiary_not: "${address0}"}`;
        case ProjectListingTypeFilter.dataUnion:
            return `isDataUnion: true`;
    }
};
export const getProjects = async (owner, first = 20, skip = 0, type, streamId) => {
    const theGraphUrl = getGraphUrl();
    const filters = [];
    if (owner) {
        filters.push(`permissions_: { userAddress: "${owner}", canGrant: true }`);
    }
    if (type) {
        filters.push(getProjectFilterQuery(type));
    }
    if (streamId) {
        filters.push(`streams_contains: ["${streamId}"]`);
    }
    const result = await post({
        url: theGraphUrl,
        data: {
            query: `
                query {
                    projects(
                        first: ${first + 1},
                        skip: ${skip},
                        orderBy: score,
                        orderDirection: desc,
                        ${filters.length > 0 ? `where: { ${filters} }` : ''},
                    ) {
                        ${projectFields}
                    }
                }
            `,
        },
    });
    if (result.data) {
        return prepareProjectResult(result.data.projects, first);
    }
    return {
        projects: [],
        hasNextPage: false,
        lastId: null,
    };
};
export const searchProjects = async (search, first = 20, skip = 0, type) => {
    const theGraphUrl = getGraphUrl();
    const typeFilter = type != null ? getProjectFilterQuery(type) : null;
    const result = await post({
        url: theGraphUrl,
        data: {
            query: `
                query {
                    projectSearch(
                        first: ${first + 1},
                        skip: ${skip},
                        text: "${search}",
                        ${typeFilter !== null ? `where: {${typeFilter}}` : ''}
                    ) {
                        ${projectFields}
                    }
                }
            `,
        },
    });
    if (result.data) {
        return prepareProjectResult(result.data.projectSearch, first);
    }
    return {
        projects: [],
        hasNextPage: false,
        lastId: null,
    };
};
const getDomainIds = (paymentDetails) => {
    return paymentDetails.map((p) => p.chainId);
};
const getPaymentDetails = (paymentDetails) => {
    return paymentDetails.map((d) => ({
        beneficiary: d.beneficiaryAddress,
        pricingTokenAddress: d.pricingTokenAddress,
        pricePerSecond: d.pricePerSecond.toString(),
    }));
};
export async function createProject(project) {
    const chainId = getProjectRegistryChainId();
    const { id, paymentDetails, streams, minimumSubscriptionInSeconds, isPublicPurchasable, metadata, } = project;
    const from = await getDefaultWeb3Account();
    await networkPreflight(chainId);
    const web3 = await getWalletWeb3Provider();
    await new Promise((resolve, reject) => {
        getProjectRegistryContract({ chainId, web3 })
            .methods.createProject(id, getDomainIds(paymentDetails), getPaymentDetails(paymentDetails), streams, minimumSubscriptionInSeconds, isPublicPurchasable, metadata)
            .send({
            from,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        })
            .on('error', (error) => {
            reject(error);
        })
            .once('confirmation', () => {
            resolve();
        });
    });
}
export async function updateProject(project) {
    const chainId = getProjectRegistryChainId();
    const { id, paymentDetails, streams, minimumSubscriptionInSeconds, metadata } = project;
    const from = await getDefaultWeb3Account();
    await networkPreflight(chainId);
    const web3 = await getWalletWeb3Provider();
    await new Promise((resolve, reject) => {
        getProjectRegistryContract({ chainId, web3 })
            .methods.updateProject(id, getDomainIds(paymentDetails), getPaymentDetails(paymentDetails), streams, minimumSubscriptionInSeconds, metadata)
            .send({
            from,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        })
            .on('error', (error) => {
            reject(error);
        })
            .once('confirmation', () => {
            resolve();
        });
    });
}
export async function deleteProject(projectId) {
    if (!projectId) {
        throw new Error('No project');
    }
    const chainId = getProjectRegistryChainId();
    const from = await getDefaultWeb3Account();
    await networkPreflight(chainId);
    const web3 = await getWalletWeb3Provider();
    await new Promise((resolve, reject) => {
        getProjectRegistryContract({ chainId, web3 })
            .methods.deleteProject(projectId)
            .send({
            from,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        })
            .on('error', (error) => {
            reject(error);
        })
            .once('confirmation', () => {
            resolve();
        });
    });
}
export async function deployDataUnionContract(projectId, adminFee, chainId) {
    await networkPreflight(chainId);
    return new Promise((resolve, reject) => deployDataUnion({
        productId: projectId,
        chainId,
        adminFee,
    })
        .onTransactionHash((contractAddress) => {
        // deployDataUnion() returns the calculated contract address as the tx hash
    })
        .onTransactionComplete(({ contractAddress }) => {
        if (contractAddress == null) {
            reject(new Error('DU contract deploy did not return an address!'));
        }
        else {
            resolve(contractAddress);
        }
    })
        .onError((e) => {
        console.error(e);
        reject(e);
    }));
}
