var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, } from 'react';
import { compose } from '$shared/utils/compose';
import FlushHistoryDecorator from './FlushHistoryDecorator';
import OnCommitDecorator from './OnCommitDecorator';
import RevertOnEscapeDecorator from './RevertOnEscapeDecorator';
import SelectAllOnFocusDecorator from './SelectAllOnFocusDecorator';
import StyledInput from './StyledInput';
export { SpaciousTheme } from './StyledInput';
const Text = (_a, ref) => {
    var { tag: Tag = 'input', unstyled } = _a, props = __rest(_a, ["tag", "unstyled"]);
    return unstyled ? (React.createElement(Tag, Object.assign({}, props, { ref: ref }))) : (React.createElement(StyledInput, Object.assign({}, props, { as: Tag, ref: ref })));
};
const EnhancedText = compose(FlushHistoryDecorator, OnCommitDecorator, SelectAllOnFocusDecorator, RevertOnEscapeDecorator)(forwardRef(Text));
EnhancedText.displayName = 'Text';
export default EnhancedText;
