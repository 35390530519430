import React, { useEffect, useMemo, useState, } from 'react';
import ReactSelect from 'react-select';
import { DropdownMenu, DropdownToggle } from 'reactstrap';
import SvgIcon from '$shared/components/SvgIcon';
import { DetailEditorDropdown, StyledDetailEditorDropdownOption, getDetailEditorDropdownControlStyles, getDetailEditorDropdownMenuListStyles, getDetailEditorDropdownMenuStyles, getDetailEditorDropdownPlaceholderStyles, getDetailEditorDropdownOptionStyles, } from './DetailEditor.styles';
import EnterIcon from './enter.svg';
export const DetailEditor = ({ defaultIcon, unsetValueText, hasValueIcon, value, showValue = false, showValueFormatter, optional = false, selectOptions, instructionText, ctaButtonText, placeholder, validation, onChange, className, disabled, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState();
    const [validationError, setValidationError] = useState();
    const [inputTouched, setInputTouched] = useState(false);
    const [inputIsFocused, setInputIsFocused] = useState();
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        setInputValue(value);
    }, [value]);
    useEffect(() => {
        if (!optional && !inputValue && inputTouched) {
            return setValidationError('This field is required');
        }
        if (!!inputValue && validation) {
            validation.forEach((validationSchema) => {
                if (!validationSchema.validator(inputValue)) {
                    setValidationError(validationSchema.message);
                }
                else {
                    setValidationError('');
                }
            });
        }
        if (!inputValue) {
            setValidationError('');
        }
    }, [inputValue, inputTouched, optional, validation]);
    const handleSubmit = () => {
        if (!validationError) {
            onChange(inputValue);
            setIsOpen(false);
        }
    };
    const handleSelection = (value) => {
        onChange(value);
        setInputValue(value);
        setIsOpen(false);
        return;
    };
    const handleClear = () => {
        setInputValue('');
        onChange('');
        setIsOpen(false);
    };
    const valueToDisplay = useMemo(() => {
        if (!value) {
            return unsetValueText;
        }
        if (showValueFormatter) {
            return showValueFormatter(value);
        }
        return value;
    }, [value, unsetValueText, showValueFormatter]);
    return (React.createElement(DetailEditorDropdown, { isOpen: isOpen, toggle: handleToggle, className: className },
        React.createElement(DropdownToggle, { disabled: disabled, "data-testid": 'detailEditorToggle' },
            value ? hasValueIcon || defaultIcon : defaultIcon,
            showValue && (React.createElement("span", { "data-testid": 'detailEditorValue', className: 'value' +
                    (!value ? ' value-unset' : '') +
                    (defaultIcon || hasValueIcon ? ' has-icon' : '') }, valueToDisplay))),
        React.createElement(DropdownMenu, null,
            React.createElement("div", { className: 'header' },
                React.createElement("span", { className: 'instruction', "data-testid": 'detailEditorInstructionText' }, instructionText),
                React.createElement("span", { className: 'optional', "data-testid": 'detailEditorOptional' }, optional && 'Optional')),
            !selectOptions && (React.createElement("div", null,
                React.createElement("form", { className: 'text-input-container', onSubmit: (event) => {
                        event.preventDefault();
                        handleSubmit();
                    } },
                    React.createElement("input", { "data-testid": 'detailEditorInput', onChange: (event) => setInputValue(event.target.value), onFocus: () => {
                            setInputTouched(true);
                            setInputIsFocused(true);
                        }, onBlur: (event) => {
                            setInputValue(event.target.value);
                            setInputIsFocused(false);
                            handleSubmit();
                        }, placeholder: placeholder, className: 'text-input ' +
                            (validationError ? 'invalid-input' : ''), defaultValue: inputValue, autoFocus: true }),
                    React.createElement("img", { src: EnterIcon, className: 'enter-icon ' +
                            (inputIsFocused && inputValue ? 'visible' : ''), onClick: handleSubmit })),
                !validationError && !inputValue && !!value && (React.createElement("button", { className: 'detail-input-cta', type: 'button', onClick: handleClear },
                    React.createElement(SvgIcon, { name: 'close', className: 'cta-icon' }),
                    React.createElement("span", null,
                        "Remove ",
                        ctaButtonText))),
                !validationError && !(!inputValue && !!value) && (React.createElement("button", { className: 'detail-input-cta', type: 'button', onClick: handleSubmit, disabled: !inputValue, "data-testid": 'detailEditorAddCTA' },
                    React.createElement(SvgIcon, { name: 'plusSmall', className: 'cta-icon' }),
                    React.createElement("span", null,
                        "Add ",
                        ctaButtonText))),
                validationError && (React.createElement("span", { "data-testid": 'detailEditorValidationError', className: 'validation-error' }, validationError)))),
            selectOptions && (React.createElement(ReactSelect, { options: selectOptions, placeholder: placeholder, isSearchable: false, isClearable: false, defaultValue: value
                    ? selectOptions.find((option) => option.value === value)
                    : null, components: {
                    IndicatorSeparator: () => React.createElement(React.Fragment, null),
                    Option: StyledDetailEditorDropdownOption,
                }, onChange: (element) => {
                    handleSelection(element.value);
                }, styles: {
                    placeholder: (styles) => getDetailEditorDropdownPlaceholderStyles(styles),
                    control: (styles, props) => getDetailEditorDropdownControlStyles(styles, props.isFocused),
                    menu: (styles) => getDetailEditorDropdownMenuStyles(styles),
                    menuList: (styles) => getDetailEditorDropdownMenuListStyles(styles),
                    option: (styles, props) => getDetailEditorDropdownOptionStyles(styles, props.isSelected),
                } })))));
};
