import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MarketplaceProductTile } from '$mp/components/Projects';
import { TABLET, PHONE } from '$shared/utils/styled';
import { getProjects } from '$app/src/services/projects';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-rwi3z2" }) `
    margin-top: 80px;
    margin-bottom: 120px;
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-18ae4vb" }) `
    font-size: 34px;
    line-height: 34px;
    color: #000000;
    padding-bottom: 40px;
`;
const ProjectGrid = styled.div.withConfig({ displayName: "ProjectGrid", componentId: "sc-tp6csp" }) `
    display: grid;
    grid-template-columns: 1fr;
    gap: 36px;

    @media (${PHONE}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (${TABLET}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;
export default function RelatedProjects({ streamId }) {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        let mounted = true;
        const loadProjects = async () => {
            const result = await getProjects(undefined, 4, 0, undefined, streamId);
            if (mounted) {
                setProjects(result.projects);
            }
        };
        loadProjects();
        return () => {
            mounted = false;
        };
    }, [streamId]);
    if (projects.length === 0) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(Header, null, "This stream belongs to following projects"),
        React.createElement(ProjectGrid, null, projects.map((project) => (React.createElement(MarketplaceProductTile, { key: project.id, product: project, showDataUnionBadge: false, showEditButton: false }))))));
}
