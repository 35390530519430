import { produce } from 'immer';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useClient } from 'streamr-client-react';
import { StreamPermission, } from 'streamr-client';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import uniqueId from 'lodash/uniqueId';
import { Link, useMatch } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { create } from 'zustand';
import address0 from '$app/src/utils/address0';
import NoStreamIdError from '$shared/errors/NoStreamIdError';
import getTransactionalClient from '$app/src/getters/getTransactionalClient';
import { Layer } from '$app/src/utils/Layer';
import TransactionListToast, { notify, } from '$shared/toasts/TransactionListToast';
import routes from '$app/src/routes';
import requirePositiveBalance from '$shared/utils/requirePositiveBalance';
import StreamNotFoundError from '$shared/errors/StreamNotFoundError';
import { isMessagedObject } from '$app/src/utils';
export class DraftValidationError extends Error {
    constructor(key, message) {
        super(message);
        this.key = key;
        this.message = message;
        this.name = 'DraftValidationError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DraftValidationError);
        }
        Object.setPrototypeOf(this, DraftValidationError.prototype);
    }
}
const initialState = {
    streamDraftMapping: {},
    cache: {},
};
const initialMetadata = {
    description: '',
    config: {
        fields: [],
    },
    storageDays: undefined,
    inactivityThresholdHours: undefined,
    partitions: 1,
};
const initialDraft = {
    abandoned: false,
    errors: {},
    fetchingStream: false,
    loadedMetadata: initialMetadata,
    loadError: undefined,
    metadata: initialMetadata,
    metadataChanged: false,
    permissionAssignments: [],
    permissions: {},
    fetchingPermissions: false,
    persisting: false,
    storageNodes: {},
    fetchingStorageNodes: false,
    streamId: undefined,
    transientStreamId: '',
};
export const Bits = {
    [StreamPermission.DELETE]: /*    */ 1 << 0,
    [StreamPermission.EDIT]: /*      */ 1 << 1,
    [StreamPermission.GRANT]: /*     */ 1 << 2,
    [StreamPermission.PUBLISH]: /*   */ 1 << 3,
    [StreamPermission.SUBSCRIBE]: /* */ 1 << 4,
};
export function setBits(bitsA, bitsB) {
    return (bitsA |= bitsB);
}
export function unsetBits(bitsA, bitsB) {
    return (bitsA &= ~bitsB);
}
export function matchBits(bitsA, bitsB) {
    return (bitsA & bitsB) === bitsA;
}
function formatStorageOperationLabel(current = 0, total = 0) {
    if (total <= 1) {
        return 'Update storage nodes';
    }
    return `Update storage nodes (${current} of ${total})`;
}
export const useStreamEditorStore = create((set, get) => {
    function isPersisting(draftId) {
        var _a;
        return ((_a = get().cache[draftId]) === null || _a === void 0 ? void 0 : _a.persisting) === true;
    }
    function getStreamId(draftId) {
        var _a;
        const streamId = (_a = get().cache[draftId]) === null || _a === void 0 ? void 0 : _a.streamId;
        if (!streamId) {
            throw new NoStreamIdError();
        }
        return streamId;
    }
    function setDraft(draftId, update, { force = false } = {}) {
        set((draft) => produce(draft, (state) => {
            if (!state.cache[draftId] && !force) {
                return;
            }
            state.cache[draftId] = produce(state.cache[draftId] || initialDraft, update);
        }));
    }
    return Object.assign(Object.assign({}, initialState), { init(draftId, streamId, streamrClient) {
            const recycled = !!get().cache[draftId];
            setDraft(draftId, (draft) => {
                draft.streamId = streamId;
                draft.abandoned = false;
            }, {
                force: true,
            });
            if (!streamId) {
                setDraft(draftId, (next) => {
                    next.loadError = null;
                });
                return;
            }
            if (recycled) {
                return;
            }
            get().fetchStream(draftId, streamrClient);
            async function fetchStorageNodes() {
                try {
                    await get().fetchStorageNodes(draftId, streamrClient);
                }
                catch (e) {
                    console.warn('Could not load storage nodes', e);
                }
            }
            fetchStorageNodes();
            async function fetchPermissions() {
                try {
                    await get().fetchPermissions(draftId, streamrClient);
                }
                catch (e) {
                    console.warn('Could not fetch permissions', e);
                }
            }
            fetchPermissions();
        },
        async fetchStream(draftId, streamrClient) {
            const streamId = getStreamId(draftId);
            let stream;
            try {
                setDraft(draftId, (state) => {
                    state.fetchingStream = true;
                });
                stream = await streamrClient.getStream(streamId);
                if (!stream) {
                    throw new StreamNotFoundError(streamId);
                }
                setDraft(draftId, (next) => {
                    next.loadError = null;
                });
            }
            catch (e) {
                if (isMessagedObject(e) && /not_found/i.test(e.message)) {
                    return void setDraft(draftId, (next) => {
                        next.loadError = new StreamNotFoundError(streamId);
                    });
                }
                setDraft(draftId, (next) => {
                    next.loadError = e;
                });
            }
            finally {
                setDraft(draftId, (state) => {
                    if (stream) {
                        state.metadata = stream.getMetadata();
                        state.loadedMetadata = state.metadata;
                        state.metadataChanged = false;
                    }
                    state.fetchingStream = false;
                });
            }
        },
        updateMetadata(draftId, update) {
            setDraft(draftId, (state) => {
                state.metadata = produce(state.metadata, update);
                state.metadataChanged = !isEqual(state.metadata, state.loadedMetadata);
            });
        },
        async fetchStorageNodes(draftId, streamrClient) {
            if (isPersisting(draftId)) {
                return;
            }
            try {
                setDraft(draftId, (state) => {
                    state.fetchingStorageNodes = true;
                });
                const streamId = getStreamId(draftId);
                const storageNodes = await streamrClient.getStorageNodes(streamId);
                const result = {};
                storageNodes.forEach((address) => {
                    result[address.toLowerCase()] = {
                        enabled: true,
                        persistedEnabled: true,
                    };
                });
                setDraft(draftId, (state) => {
                    state.storageNodes = result;
                });
            }
            finally {
                setDraft(draftId, (state) => {
                    state.fetchingStorageNodes = false;
                });
            }
        },
        toggleStorageNode(draftId, address, fn) {
            if (isPersisting(draftId)) {
                return;
            }
            const addr = address.toLowerCase();
            setDraft(draftId, (state) => {
                if (state.fetchingStorageNodes) {
                    return;
                }
                const node = state.storageNodes[addr];
                const enabled = fn(!!(node === null || node === void 0 ? void 0 : node.enabled));
                if ((node === null || node === void 0 ? void 0 : node.enabled) === enabled) {
                    // Nothing to do.
                    return;
                }
                if (node && typeof node.persistedEnabled === null && !enabled) {
                    // Abandon local mods.
                    return void delete state.storageNodes[addr];
                }
                if (node) {
                    return void (node.enabled = enabled);
                }
                if (!enabled) {
                    // No `node` and we're disabling? Do nothing.
                    return;
                }
                state.storageNodes[addr] = {
                    enabled,
                    persistedEnabled: null,
                };
            });
        },
        async persist(draftId, { onCreate, onPermissionsChange }) {
            if (isPersisting(draftId)) {
                return;
            }
            const { transientStreamId = '', streamId, metadata, metadataChanged, permissionAssignments, storageNodes, } = get().cache[draftId] || initialDraft;
            let stream;
            let client;
            let toast = toaster(TransactionListToast, Layer.Toast);
            const updateOperation = {
                id: uniqueId('operation-'),
                label: streamId ? 'Update stream' : 'Create stream',
            };
            const permissionsOperation = {
                id: uniqueId('operation-'),
                label: 'Update access settings',
            };
            const storageOperation = {
                id: uniqueId('operation-'),
                label: '',
            };
            const operations = [];
            if (transientStreamId || metadataChanged) {
                operations.push(updateOperation);
            }
            if (permissionAssignments.length) {
                operations.push(permissionsOperation);
            }
            const storageNodeChanges = Object.entries(storageNodes)
                .filter(([, { enabled = false, persistedEnabled = false } = {}]) => !enabled !== !persistedEnabled)
                .map(([address, { enabled = false } = {}]) => [address, !!enabled]);
            storageOperation.label = formatStorageOperationLabel(0, storageNodeChanges.length);
            if (storageNodeChanges.length) {
                operations.push(storageOperation);
            }
            if (!operations.length) {
                return;
            }
            const firstOperation = operations[0];
            firstOperation.state = 'ongoing';
            if (streamId) {
                updateOperation.action = getOpenStreamLink(streamId);
            }
            notify(toast, operations);
            try {
                setDraft(draftId, (draft) => {
                    draft.persisting = true;
                });
                if (!transientStreamId && !streamId) {
                    throw new DraftValidationError('streamId', 'is required');
                }
                if (streamId) {
                    set((store) => produce(store, ({ streamDraftMapping }) => {
                        streamDraftMapping[streamId] = draftId;
                    }));
                }
                if (transientStreamId) {
                    client = await getTransactionalClient({ passiveNetworkCheck: true });
                    try {
                        if (await client.getStream(transientStreamId)) {
                            throw new DraftValidationError('streamId', 'already exists, please try a different one');
                        }
                    }
                    catch (e) {
                        if (e instanceof DraftValidationError) {
                            throw e;
                        }
                        // Ignore other errors.
                    }
                }
                stream = await (async () => {
                    client = await getTransactionalClient();
                    const address = await client.getAddress();
                    await requirePositiveBalance(address);
                    if (transientStreamId) {
                        return client.createStream(Object.assign({ id: transientStreamId }, metadata));
                    }
                    if (!streamId) {
                        throw new DraftValidationError('streamId', 'is invalid');
                    }
                    if (metadataChanged) {
                        return client.updateStream(Object.assign(Object.assign({}, metadata), { id: streamId }));
                    }
                    return client.getStream(streamId);
                })();
                const currentStreamId = stream.id;
                const currentMetadata = stream.getMetadata();
                setDraft(draftId, (draft) => {
                    draft.streamId = currentStreamId;
                    draft.transientStreamId = '';
                    draft.loadedMetadata = currentMetadata;
                    draft.metadata = currentMetadata;
                    draft.metadataChanged = false;
                });
                if (transientStreamId) {
                    set((store) => produce(store, ({ streamDraftMapping }) => {
                        streamDraftMapping[currentStreamId] = draftId;
                    }));
                    onCreate === null || onCreate === void 0 ? void 0 : onCreate(currentStreamId);
                }
                updateOperation.action = getOpenStreamLink(currentStreamId);
                updateOperation.state = 'complete';
                permissionsOperation.state = 'ongoing';
                notify(toast, operations);
                if (permissionAssignments.length) {
                    client = await getTransactionalClient();
                    await client.setPermissions({
                        streamId: currentStreamId,
                        assignments: permissionAssignments,
                    });
                    onPermissionsChange === null || onPermissionsChange === void 0 ? void 0 : onPermissionsChange(currentStreamId, permissionAssignments);
                    setDraft(draftId, (draft) => {
                        draft.permissionAssignments = [];
                        for (const addr in draft.permissions) {
                            const cache = draft.permissions[addr];
                            if (!cache ||
                                !Object.prototype.hasOwnProperty.call(draft.permissions, addr)) {
                                continue;
                            }
                            cache.persistedBits = cache.bits;
                        }
                    });
                }
                permissionsOperation.state = 'complete';
                storageOperation.state = 'ongoing';
                notify(toast, operations);
                for (let i = 0; i < storageNodeChanges.length; i++) {
                    const [address, enabled] = storageNodeChanges[i];
                    storageOperation.label = formatStorageOperationLabel(i + 1, storageNodeChanges.length);
                    if (i !== 0) {
                        // Already notifying above.
                        notify(toast, operations);
                    }
                    client = await getTransactionalClient();
                    if (enabled) {
                        await client.addStreamToStorageNode(stream.id, address);
                    }
                    else {
                        await client.removeStreamFromStorageNode(stream.id, address);
                    }
                    setDraft(draftId, (draft) => {
                        const node = draft.storageNodes[address];
                        if (node) {
                            node.enabled = enabled;
                            node.persistedEnabled = enabled;
                        }
                    });
                }
                storageOperation.state = 'complete';
                notify(toast, operations);
            }
            catch (e) {
                operations.forEach((op) => {
                    if (op.state === 'ongoing') {
                        op.state = 'error';
                    }
                });
                notify(toast, operations);
                throw e;
            }
            finally {
                setDraft(draftId, (draft) => {
                    draft.persisting = false;
                });
                setTimeout(() => {
                    toast === null || toast === void 0 ? void 0 : toast.discard();
                    toast = undefined;
                }, 1000);
                get().teardown(draftId, { onlyAbandoned: true });
            }
        },
        setTransientStreamId(draftId, streamId) {
            setDraft(draftId, (state) => {
                state.transientStreamId = streamId;
            });
        },
        async fetchPermissions(draftId, streamrClient) {
            if (isPersisting(draftId)) {
                return;
            }
            const streamId = getStreamId(draftId);
            try {
                setDraft(draftId, (state) => {
                    state.fetchingPermissions = true;
                });
                const permissions = await streamrClient.getPermissions(streamId);
                const result = {};
                permissions.forEach((pa) => {
                    const user = 'user' in pa ? pa.user.toLowerCase() : address0;
                    const cache = result[user] || {
                        bits: 0,
                        persistedBits: 0,
                    };
                    cache.bits = pa.permissions.reduce((memo, permission) => memo | Bits[permission], cache.bits || 0);
                    cache.persistedBits = cache.bits;
                    result[user] = cache;
                });
                setDraft(draftId, (state) => {
                    state.permissions = result;
                });
            }
            finally {
                setDraft(draftId, (state) => {
                    state.fetchingPermissions = false;
                });
            }
        },
        setPermissions(draftId, account, bits) {
            if (isPersisting(draftId)) {
                return;
            }
            setDraft(draftId, (state) => {
                if (state.fetchingPermissions) {
                    return;
                }
                const addr = account.toLowerCase();
                const entry = state.permissions[addr] || {
                    bits: null,
                    persistedBits: null,
                };
                entry.bits = bits;
                state.permissions[addr] = entry;
                const assignments = [];
                Object.entries(state.permissions).forEach(([account, { bits = null, persistedBits = null } = {}]) => {
                    if (bits === persistedBits || (!bits && !persistedBits)) {
                        return;
                    }
                    const permissions = !bits
                        ? []
                        : Object.keys(Bits).filter((perm) => matchBits(Bits[perm], bits));
                    if (account === address0) {
                        return void assignments.push({
                            public: true,
                            permissions,
                        });
                    }
                    assignments.push({
                        user: account,
                        permissions,
                    });
                });
                state.permissionAssignments = assignments;
            });
        },
        setError(draftId, key, message) {
            setDraft(draftId, (state) => {
                if (!message) {
                    return void delete state.errors[key];
                }
                state.errors[key] = message;
            });
        },
        abandon(draftId) {
            set((store) => produce(store, ({ cache }) => {
                const draft = cache[draftId];
                if (!draft) {
                    return;
                }
                draft.abandoned = true;
            }));
            if (!isPersisting(draftId)) {
                get().teardown(draftId);
            }
        },
        teardown(draftId, { onlyAbandoned = false } = {}) {
            set((store) => produce(store, ({ streamDraftMapping, cache }) => {
                const draft = cache[draftId];
                if (!draft) {
                    return;
                }
                if (!onlyAbandoned || draft.abandoned) {
                    const streamId = draft.streamId || draft.transientStreamId;
                    if (streamId) {
                        delete streamDraftMapping[streamId];
                    }
                    delete cache[draftId];
                }
            }));
        } });
});
function useRecyclableDraftId(streamId) {
    return useStreamEditorStore(({ streamDraftMapping }) => streamId ? streamDraftMapping[streamId] : undefined);
}
export function useInitStreamDraft(streamId) {
    const recycledDraftId = useRecyclableDraftId(streamId);
    const draftId = useMemo(() => {
        return recycledDraftId || uniqueId('draft-');
        /**
         * We give each new stream id a new draft id (unless we recycle), thus we've gotta
         * disable react-hooks/exhaustive-deps for the next line (`streamId` may seem redundant).
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamId, recycledDraftId]);
    const { init, abandon } = useStreamEditorStore(({ init, abandon }) => ({
        init,
        abandon,
    }));
    const client = useClient();
    useEffect(() => {
        if (!client) {
            return () => void 0;
        }
        init(draftId, streamId, client);
    }, [draftId, init, client, streamId]);
    useEffect(() => () => void abandon(draftId), [draftId, abandon]);
    return draftId;
}
export const StreamDraftContext = createContext(undefined);
export function useDraftId() {
    return useContext(StreamDraftContext);
}
export function useCurrentDraft() {
    const draftId = useDraftId();
    const cache = useStreamEditorStore(({ cache }) => (draftId ? cache[draftId] : undefined)) ||
        initialDraft;
    return cache;
}
export function useIsCurrentDraftClean() {
    const { metadataChanged, permissionAssignments, storageNodes, transientStreamId } = useCurrentDraft();
    return (!transientStreamId &&
        !metadataChanged &&
        !permissionAssignments.length &&
        !Object.values(storageNodes).some(({ enabled = false, persistedEnabled = false, } = {}) => !enabled !== !persistedEnabled));
}
export function useIsCurrentDraftBusy() {
    const { fetchingStream, persisting, fetchingPermissions, fetchingStorageNodes } = useCurrentDraft();
    return fetchingStream || persisting || fetchingPermissions || fetchingStorageNodes;
}
export function useUpdateCurrentMetadata() {
    const draftId = useDraftId();
    const updateMetadata = useStreamEditorStore(({ updateMetadata }) => updateMetadata);
    return useCallback((fn) => {
        if (!draftId) {
            return;
        }
        updateMetadata(draftId, fn);
    }, [draftId, updateMetadata]);
}
export function useSetCurrentDraftError() {
    const draftId = useDraftId();
    const setError = useStreamEditorStore(({ setError }) => setError);
    return useCallback((key, message) => {
        if (!draftId) {
            return;
        }
        setError(draftId, key, message);
    }, [draftId, setError]);
}
export function useCurrentDraftError(key) {
    return useCurrentDraft().errors[key] || undefined;
}
export function useSetCurrentDraftTransientStreamId() {
    const draftId = useDraftId();
    const setTransientStreamId = useStreamEditorStore(({ setTransientStreamId }) => setTransientStreamId);
    return useCallback((streamId) => {
        if (!draftId) {
            return;
        }
        setTransientStreamId(draftId, streamId);
    }, [draftId, setTransientStreamId]);
}
export function useToggleCurrentStorageNode() {
    const draftId = useDraftId();
    const toggleStorageNode = useStreamEditorStore(({ toggleStorageNode }) => toggleStorageNode);
    return useCallback((address, fn) => {
        if (!draftId) {
            return;
        }
        toggleStorageNode(draftId, address, fn);
    }, [draftId, toggleStorageNode]);
}
export function usePersistCurrentDraft() {
    const draftId = useDraftId();
    const persist = useStreamEditorStore(({ persist }) => persist);
    return useCallback(({ onCreate, onPermissionsChange, }) => {
        if (!draftId) {
            throw new Error('No draft id');
        }
        return persist(draftId, { onCreate, onPermissionsChange });
    }, [draftId, persist]);
}
export function usePersistingDraftIdsForStream(streamId) {
    return useStreamEditorStore(({ cache }) => streamId
        ? Object.entries(cache)
            .filter(([, draft]) => (draft === null || draft === void 0 ? void 0 : draft.persisting) &&
            (draft.streamId === streamId ||
                draft.transientStreamId === streamId))
            .map(([draftId]) => draftId)
        : []);
}
export function useIsPersistingAnyStreamDraft() {
    return useStreamEditorStore(({ cache }) => {
        return Object.values(cache).some((draft) => draft === null || draft === void 0 ? void 0 : draft.persisting);
    });
}
const NewStreamLink = styled(Link).withConfig({ displayName: "NewStreamLink", componentId: "sc-8nn2dh" }) `
    display: block;
    font-size: 14px;

    :hover {
        text-decoration: underline;
    }
`;
function getOpenStreamLink(streamId) {
    return function OpenStreamLink() {
        var _a;
        const id = decodeURIComponent(((_a = useMatch(routes.streams.overview())) === null || _a === void 0 ? void 0 : _a.params['id']) || '');
        if (!streamId || id === streamId) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement(NewStreamLink, { to: routes.streams.overview({ id: streamId }) }, "Open"));
    };
}
