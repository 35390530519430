import { useCallback, useContext, useMemo } from 'react';
import { ValidationContext } from '$mp/containers/ProductController/ValidationContextProvider';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
export const useEditableProjectActions = () => {
    const { state, updateState } = useContext(ProjectStateContext);
    const { setTouched } = useContext(ValidationContext);
    const updateProject = useCallback((project) => {
        updateState(project);
    }, [updateState]);
    const updateName = useCallback((name) => {
        updateState({ name });
        setTouched('name');
    }, [updateState, setTouched]);
    const updateDescription = useCallback((description) => {
        updateState({ description });
        setTouched('description');
    }, [updateState, setTouched]);
    const updateDataUnionChainId = useCallback((chainId) => {
        updateState({ dataUnionChainId: chainId });
        setTouched('dataUnionChainId');
    }, [updateState, setTouched]);
    const updateSalePoints = useCallback((salePoints) => {
        updateState({ salePoints });
        setTouched('salePoints');
    }, [updateState, setTouched]);
    const updateImageFile = useCallback((image) => {
        updateState({ newImageToUpload: image });
        setTouched('imageUrl');
    }, [updateState, setTouched]);
    const updateStreams = useCallback((streams) => {
        updateState({ streams });
        setTouched('streams');
    }, [updateState, setTouched]);
    const updateAdminFee = useCallback((adminFee) => {
        updateState({ adminFee });
        setTouched('adminFee');
    }, [updateState, setTouched]);
    const updateExistingDUAddress = useCallback((address, didTouch = true) => {
        updateState({
            existingDUAddress: address,
        });
        if (didTouch) {
            setTouched('existingDUAddress');
        }
    }, [updateState, setTouched]);
    const updateType = useCallback((type) => {
        updateState({ type });
        setTouched('type');
    }, [updateState, setTouched]);
    const updateTermsOfUse = useCallback((termsOfUse) => {
        updateState({ termsOfUse });
        setTouched('termsOfUse');
    }, [updateState, setTouched]);
    const updateContactUrl = useCallback((url) => {
        updateState({
            contact: Object.assign(Object.assign({}, (state.contact || {})), { url }),
        });
        setTouched('contact.url');
    }, [updateState, state, setTouched]);
    const updateContactEmail = useCallback((email) => {
        updateState({
            contact: Object.assign(Object.assign({}, (state.contact || {})), { email }),
        });
        setTouched('contact.email');
    }, [updateState, state, setTouched]);
    const updateSocialUrl = useCallback((platform, url) => {
        updateState({
            contact: Object.assign(Object.assign({}, (state.contact || {})), { [platform]: url }),
        });
        setTouched(`contact.${platform}`);
    }, [updateState, state, setTouched]);
    const updateCreator = useCallback((creatorName) => {
        updateState({ creator: creatorName });
        setTouched('creator');
    }, [updateState, setTouched]);
    const updateIsDeployingNewDU = useCallback((isDeployingNewDU) => {
        updateState({ isDeployingNewDU });
        setTouched('isDeployingNewDU');
    }, [updateState, setTouched]);
    return useMemo(() => ({
        updateProject,
        updateName,
        updateDescription,
        updateDataUnionChainId,
        updateSalePoints,
        updateImageFile,
        updateStreams,
        updateAdminFee,
        updateExistingDUAddress,
        updateType,
        updateTermsOfUse,
        updateContactUrl,
        updateContactEmail,
        updateSocialUrl,
        updateCreator,
        updateIsDeployingNewDU,
    }), [
        updateProject,
        updateName,
        updateDescription,
        updateDataUnionChainId,
        updateSalePoints,
        updateImageFile,
        updateStreams,
        updateAdminFee,
        updateExistingDUAddress,
        updateType,
        updateTermsOfUse,
        updateContactUrl,
        updateContactEmail,
        updateSocialUrl,
        updateCreator,
        updateIsDeployingNewDU,
    ]);
};
