var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled, { css } from 'styled-components';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import { NavProvider } from '@streamr/streamr-layout';
import cx from 'classnames';
import useScrollToTop from '$shared/hooks/useScrollToTop';
import useCurrentLocation from '$shared/hooks/useCurrentLocation';
import Nav from './Nav';
import Footer from './Footer';
import styles from './layout.pcss';
const Inner = styled.div.withConfig({ displayName: "Inner", componentId: "sc-1k9jkya" }) `
    background-color: white;

    ${({ $gray = false }) => $gray &&
    css `
            background-color: #f5f5f5;
        `}
`;
const DefaultTheme = {};
const Layout = (_a = {}) => {
    var { theme = DefaultTheme, footer = true, nav = React.createElement(Nav, null), framedClassName, innerClassName, gray = false } = _a, props = __rest(_a, ["theme", "footer", "nav", "framedClassName", "innerClassName", "gray"]);
    useScrollToTop();
    const current = useCurrentLocation();
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(NavProvider, { highlight: current },
            React.createElement("div", { className: cx(styles.framed, framedClassName) },
                React.createElement(Inner, { className: cx(styles.inner, innerClassName), "$gray": gray },
                    nav,
                    React.createElement("div", Object.assign({}, props))),
                !!footer && React.createElement(Footer, null)))));
};
export default Layout;
