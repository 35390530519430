export const ResourceType = {
    PRODUCT: 'Product',
    STREAM: 'Stream',
};
export default class ResourceNotFoundError extends Error {
    constructor(resourceType, resourceId, ...args) {
        super(resourceId
            ? `${resourceType}#${resourceId} could not be found`
            : `${resourceType} without an ID could not be found`, ...args);
        this.noReport = true;
        this.name = 'ResourceNotFoundError';
        this.resourceType = resourceType;
        this.resourceId = resourceId;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ResourceNotFoundError);
        }
        Object.setPrototypeOf(this, ResourceNotFoundError.prototype);
    }
}
