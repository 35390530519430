var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import ReactModal2 from 'react-modal2';
import BodyClass, { NO_SCROLL } from '$shared/components/BodyClass';
import './accessibility';
const Fullpage = styled.div.withConfig({ displayName: "Fullpage", componentId: "sc-1mkgrpq" }) `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(239, 239, 239, 0.98);
    z-index: 1;

    ${({ noScroll }) => !noScroll &&
    css `
            overflow-y: scroll;
        `}
`;
const ModalDialog = (_a) => {
    var { onClose, children, className, backdropClassName, fullpage, noScroll = false } = _a, otherProps = __rest(_a, ["onClose", "children", "className", "backdropClassName", "fullpage", "noScroll"]);
    return (React.createElement(ReactModal2, Object.assign({ onClose: onClose, backdropClassName: backdropClassName, modalClassName: className }, otherProps),
        React.createElement(BodyClass, { className: NO_SCROLL }),
        fullpage ? React.createElement(Fullpage, { noScroll: noScroll }, children) : children));
};
export default ModalDialog;
