var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useRef, forwardRef } from 'react';
import '$shared/types/common-types';
const sanitise = (value) => (value == null ? '' : value);
const normalize = (value) => typeof value === 'string' ? value.trim() : String(sanitise(value));
const OnCommitDecorator = (WrappedComponent) => {
    const OnCommitDecoratorWrapper = (_a, ref) => {
        var { noEmptyCommit, onBlur: onBlurProp, onChange: onChangeProp, onCommit: onCommitProp, onFocus: onFocusProp, onKeyDown: onKeyDownProp, smartCommit } = _a, props = __rest(_a, ["noEmptyCommit", "onBlur", "onChange", "onCommit", "onFocus", "onKeyDown", "smartCommit"]);
        const valueRef = useRef(null);
        const onCommit = useCallback((value, requireChanged = false) => {
            if (onCommitProp) {
                const newValue = noEmptyCommit ? normalize(value) : value;
                if ((!requireChanged || valueRef.current !== value) &&
                    (newValue || !noEmptyCommit)) {
                    onCommitProp(value);
                }
            }
        }, [onCommitProp, noEmptyCommit]);
        const onChange = useCallback((e) => {
            if (!smartCommit) {
                onCommit(e.target.value);
            }
            if (onChangeProp) {
                onChangeProp(e);
            }
        }, [onChangeProp, onCommit, smartCommit]);
        const onKeyDown = useCallback((e) => {
            if (e.key === 'Enter' &&
                smartCommit &&
                e.target instanceof HTMLInputElement) {
                onCommit(e.target.value);
            }
            if (onKeyDownProp) {
                onKeyDownProp(e);
            }
        }, [onKeyDownProp, onCommit, smartCommit]);
        const onFocus = useCallback((e) => {
            if (e.target instanceof HTMLInputElement ||
                e.target instanceof HTMLTextAreaElement) {
                valueRef.current = e.target.value;
            }
            if (onFocusProp) {
                onFocusProp(e);
            }
        }, [onFocusProp]);
        const onBlur = useCallback((e) => {
            if (e.target instanceof HTMLInputElement ||
                e.target instanceof HTMLTextAreaElement) {
                const { value } = e.target;
                if (smartCommit) {
                    onCommit(value, true);
                }
            }
            if (onBlurProp) {
                onBlurProp(e);
            }
        }, [onBlurProp, onCommit, smartCommit]);
        return (React.createElement(WrappedComponent, Object.assign({}, props, { onBlur: onBlur, onChange: onChange, onFocus: onFocus, onKeyDown: onKeyDown, ref: ref })));
    };
    const OnCommitDecoratorWrapperFR = forwardRef(OnCommitDecoratorWrapper);
    const OptInOnCommitDecorator = (_a, ref) => {
        var { onCommit, smartCommit, noEmptyCommit } = _a, props = __rest(_a, ["onCommit", "smartCommit", "noEmptyCommit"]);
        return onCommit ? (React.createElement(OnCommitDecoratorWrapperFR, Object.assign({}, props, { noEmptyCommit: noEmptyCommit, onCommit: onCommit, ref: ref, smartCommit: smartCommit }))) : (React.createElement(WrappedComponent, Object.assign({}, props, { ref: ref })));
    };
    return forwardRef(OptInOnCommitDecorator);
};
export default OnCommitDecorator;
