var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import SvgIcon from '$shared/components/SvgIcon';
import Text from '$ui/Text';
const UnstyledButtons = (_a) => {
    var { onUpClick, onDownClick, disabled } = _a, props = __rest(_a, ["onUpClick", "onDownClick", "disabled"]);
    return (React.createElement("div", Object.assign({}, props),
        React.createElement("button", { disabled: disabled, type: "button", onClick: onUpClick },
            React.createElement(SvgIcon, { name: "caretUp" })),
        React.createElement("button", { disabled: disabled, type: "button", onClick: onDownClick },
            React.createElement(SvgIcon, { name: "caretDown" }))));
};
const Buttons = styled(UnstyledButtons).withConfig({ displayName: "Buttons", componentId: "sc-1c9vfa2" }) `
    display: inline-grid;
    grid-template-rows: 50% 50%;
    position: absolute;
    right: 0;
    margin: 1px; /* We don't want to overlap with text field's border */

    button {
        width: 24px;
        height: 19px;
        background-color: transparent;
        border: 0;
        border-left: 1px solid #efefef;
        border-radius: 0;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    button,
    button:active,
    button:focus,
    button:hover,
    button:visited {
        outline: none;
    }

    button:disabled {
        cursor: not-allowed;
    }

    button:not(:disabled):active {
        background-color: #efefef;
    }

    button + button {
        border-top: 1px solid #efefef;
    }

    svg {
        display: flex;
        align-self: center;
        color: #323232;
        width: 8px;
        height: 8px;
    }

    button:disabled > svg {
        color: #32323280;
    }
`;
const Numeric = (_a) => {
    var { value, min, max, step, hideButtons = false, onChange: onChangeProp, disabled } = _a, props = __rest(_a, ["value", "min", "max", "step", "hideButtons", "onChange", "disabled"]);
    const [internalValue, setInternalValue] = useState(value);
    const inputRef = useRef();
    const onChange = useCallback((event) => {
        setInternalValue(event.target.value);
        if (onChangeProp) {
            onChangeProp(event);
        }
    }, [onChangeProp]);
    useEffect(() => {
        setInternalValue(value);
    }, [value]);
    const addValue = useCallback((event, val) => {
        let parsedValue = Number.parseFloat(internalValue != null ? internalValue : '');
        let parsedStep = Number.parseFloat(val.toString());
        if (Number.isNaN(parsedValue)) {
            parsedValue = 0;
        }
        if (Number.isNaN(parsedStep)) {
            parsedStep = 1;
        }
        let newValue = parsedValue + parsedStep;
        if (min != null && newValue < min) {
            newValue = min;
        }
        if (max != null && newValue > max) {
            newValue = max;
        }
        if (inputRef.current) {
            inputRef.current.focus();
        }
        // eslint-disable-next-line no-param-reassign
        event.target.value = newValue.toString();
        onChange(event);
    }, [min, max, internalValue, onChange]);
    const onIncrease = useCallback((event) => {
        event.preventDefault();
        addValue(event, step != null ? step : 1);
    }, [addValue, step]);
    const onDecrease = useCallback((event) => {
        event.preventDefault();
        addValue(event, -(step != null ? step : 1));
    }, [addValue, step]);
    return (React.createElement("div", { style: {
            position: 'relative',
        } },
        React.createElement(Text, Object.assign({}, props, { type: "number", min: min, max: max, step: step, value: internalValue != null ? internalValue : '', onChange: onChange, disabled: disabled, ref: inputRef })),
        !hideButtons && (React.createElement(Buttons, { disabled: disabled, onUpClick: onIncrease, onDownClick: onDecrease }))));
};
export default Numeric;
