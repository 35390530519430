var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import BrowserNotSupportedPng from '$shared/assets/images/browser_not_supported.png';
import BrowserNotSupportedPng2x from '$shared/assets/images/browser_not_supported@2x.png';
import DiscardChangesPng from '$shared/assets/images/discard_changes.png';
import DiscardChangesPng2x from '$shared/assets/images/discard_changes@2x.png';
import MetamaskPng from '$shared/assets/images/metamask.png';
import MetamaskPng2x from '$shared/assets/images/metamask@2x.png';
import PublishFailedPng2x from '$shared/assets/images/publish_failed@2x.png';
import PublishFailedPng from '$shared/assets/images/publish_failed.png';
import ImageUploadPng from '$shared/assets/images/imgupload.png';
import ImageUploadPng2x from '$shared/assets/images/imgupload@2x.png';
import WalletPng from '$shared/assets/images/wallet.png';
import WalletPng2x from '$shared/assets/images/wallet@2x.png';
import WalletNoDataPng from '$shared/assets/images/wallet_no_data.png';
import WalletNoDataPng2x from '$shared/assets/images/wallet_no_data@2x.png';
import WalletNoEthPng from '$shared/assets/images/wallet_no_eth.png';
import WalletNoEthPng2x from '$shared/assets/images/wallet_no_eth@2x.png';
import WalletErrorPng from '$shared/assets/images/wallet_error.png';
import WalletErrorPng2x from '$shared/assets/images/wallet_error@2x.png';
const sources = {
    browserNotSupported: (React.createElement("img", { src: BrowserNotSupportedPng, srcSet: `${BrowserNotSupportedPng2x} 2x`, alt: "" })),
    discardChanges: (React.createElement("img", { src: DiscardChangesPng, srcSet: `${DiscardChangesPng2x} 2x`, alt: "" })),
    imageUpload: React.createElement("img", { src: ImageUploadPng, srcSet: `${ImageUploadPng2x} 2x`, alt: "" }),
    metamask: React.createElement("img", { src: MetamaskPng, srcSet: `${MetamaskPng2x} 2x`, alt: "" }),
    publishFailed: (React.createElement("img", { src: PublishFailedPng, srcSet: `${PublishFailedPng2x} 2x`, alt: "" })),
    wallet: React.createElement("img", { src: WalletPng, srcSet: `${WalletPng2x} 2x`, alt: "" }),
    walletNoData: React.createElement("img", { src: WalletNoDataPng, srcSet: `${WalletNoDataPng2x} 2x`, alt: "" }),
    walletNoEth: React.createElement("img", { src: WalletNoEthPng, srcSet: `${WalletNoEthPng2x} 2x`, alt: "" }),
    walletError: React.createElement("img", { src: WalletErrorPng, srcSet: `${WalletErrorPng2x} 2x`, alt: "" }),
    txFailed: React.createElement("img", { src: WalletErrorPng, srcSet: `${WalletErrorPng2x} 2x`, alt: "" }),
};
const PngIcon = (_a) => {
    var { name } = _a, props = __rest(_a, ["name"]);
    return React.cloneElement(sources[name], Object.assign({}, props));
};
PngIcon.names = Object.keys(sources).sort();
export default PngIcon;
