import { useRef } from 'react';
import isEqual from 'lodash/isEqual';
function useDeepEqualMemo(value) {
    const ref = useRef(undefined);
    if (!isEqual(ref.current, value)) {
        ref.current = value;
    }
    return ref.current;
}
export default useDeepEqualMemo;
