var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useReducer, useRef, useState, } from 'react';
import styled, { css } from 'styled-components';
import throttle from 'lodash/throttle';
import isPreventable from '$utils/isPreventable';
export const Tab = () => null;
/**
 * Mainy used for type gating. This function checks if the given `child` is a `Tab`.
 * @param child Any instance of a ReactNode.
 * @returns `true` if the child is a `Tab`. `false` otherwise.
 */
function isTab(child) {
    return !!child && typeof child === 'object' && 'type' in child && child.type === Tab;
}
const Item = styled.button.withConfig({ displayName: "Item", componentId: "sc-13mmj8y" }) `
    border: 0;
    background: none;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: inherit;
    padding: 0 20px;
    border-radius: 6px;
    flex-shrink: 0;
    font-size: inherit;
    justify-content: center;
    text-align: center;
    min-width: 0;

    button& {
        appearance: none;
        cursor: pointer;
    }

    &,
    :hover,
    :focus,
    :active,
    :visited {
        color: #323232 !important;
        text-decoration: none;
    }

    :disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    ${({ $flexBasis }) => typeof $flexBasis === 'string' &&
    css `
            flex-basis: ${$flexBasis};
        `}
`;
const ItemContent = styled.div.withConfig({ displayName: "ItemContent", componentId: "sc-1c603yc" }) `
    ${({ $truncate = false }) => $truncate &&
    css `
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `}
`;
const Outer = styled.div.withConfig({ displayName: "Outer", componentId: "sc-q02zc8" }) `
    height: 34px;
    position: relative;
`;
const Inner = styled.div.withConfig({ displayName: "Inner", componentId: "sc-1sv8hww" }) `
    align-items: center;
    display: flex;
    height: 100%;
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-wbuqzm" }) `
    background: #f5f5f5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px;
    width: max-content;
    font-size: 14px;
    font-weight: 500;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.025);
    user-select: none;
    overflow: hidden;
`;
const Trolley = styled.div.withConfig({ displayName: "Trolley", componentId: "sc-vc414o" }) `
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    padding: 0 20px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    min-width: 0;
    width: 100%;
    justify-content: center;
    text-align: center;

    ${({ $animated = false }) => $animated &&
    css `
            transition: 200ms ease-out;
            transition-property: visibility, opacity;
        `}

    ${({ $selected = false }) => $selected &&
    css `
            visibility: visible;
            opacity: 1;
        `}
`;
const Rails = styled.div.withConfig({ displayName: "Rails", componentId: "sc-gd0hos" }) `
    background: #323232;
    color: #ffffff;
    border-radius: 6px;
    height: 100%;
    position: absolute;
    transition: 200ms ease-out;
    transition-property: visiblilty, opecity;
    visibility: visible;
    opacity: 1;
    pointer-events: none;
    z-index: 1;

    ${({ $animated = false }) => $animated &&
    css `
            transition: 200ms ease-out;
            transition-property: visiblilty, opecity, transform, width;
        `}
`;
export default function Tabs(_a) {
    var { children, onSelectionChange, selection: selectionProp, spreadEvenly = false } = _a, props = __rest(_a, ["children", "onSelectionChange", "selection", "spreadEvenly"]);
    /**
     * Since `Tab` returns `null` its props is all we care about. It's a carrier. Remember
     * Rick's butter passing robot? Yeah, this here passes props – that's its purpose.
     */
    const tabs = useMemo(() => React.Children.toArray(children)
        .filter(isTab)
        .map(({ props }) => props), [children]);
    const selectedId = useMemo(() => {
        var _a;
        return (_a = tabs.find((_a) => {
            var { selected = 'id', tag } = _a, rest = __rest(_a, ["selected", "tag"]);
            /**
             * First we check it `selected` is a string. If it is then we use it as
             * a peroperty key. We take its property value and check if it matches
             * `selection` passed to `Tabs`.
             */
            if (typeof selected === 'string') {
                return (typeof selectionProp !== 'undefined' &&
                    Object.prototype.hasOwnProperty.call(rest, selected) &&
                    rest[selected] === selectionProp);
            }
            /**
             * If `selected` is a boolean then the situation is simple. If it's
             * a function though, we call it with current Tab's props.
             */
            return typeof selected === 'function' ? selected(rest) : selected;
        })) === null || _a === void 0 ? void 0 : _a.id;
    }, [tabs, selectionProp]);
    /**
     * `windowWidth` is listed as a dependency for the left and width calculation for
     * the `Rails` component later or. It changes on window resize.
     *
     * We only use the width here because the height doesn't affect the component.
     */
    const [windowWidth, setWindowWidth] = useState(0);
    useEffect(() => {
        let mounted = true;
        const onResize = throttle(() => {
            if (mounted) {
                setWindowWidth(window.outerWidth);
            }
        }, 200);
        window.addEventListener('resize', onResize);
        return () => {
            mounted = false;
            window.removeEventListener('resize', onResize);
        };
    }, []);
    /**
     * We collect HTML elements associated with each tab using callback stored in a ref.
     * The following is an index-to-callback map that holds the setters for each mounted
     * element. There's a reason to this maddness.
     *
     * For one element, to avoid extensive updates on `ref`, we'd use `useCallback`. In our
     * case we avoid it (as much as possible) using the following contraption.
     *
     * In other words, unless `children` change, 0th element will always set its ref
     * using the callback at 0th index in the map, and so on. Simple as that.
     */
    const elementSettersRef = useRef({});
    useEffect(() => {
        /**
         * We reset the setters when the number of tabs change. Ideally we'd do it only when
         * reducing the number of tabs, but that'd add complexity.
         */
        elementSettersRef.current = {};
    }, [tabs.length]);
    /**
     * `elements` is a list of mounted `Item` elements. We use it for left/width
     * calculations later.
     */
    const [elements, setElement] = useState([]);
    function setElementAt(id, index) {
        let setter = elementSettersRef.current[index];
        if (typeof setter !== 'function') {
            setter = (el) => {
                setElement((current) => {
                    const newElements = [...current];
                    newElements[index] = [id, el];
                    return newElements;
                });
            };
            elementSettersRef.current[index] = setter;
        }
        return setter;
    }
    const [left, width] = useMemo(() => {
        let left = 0;
        /**
         *  Use `windowWidth` here to avoid ignoring the `react-hooks/exhaustive-deps`
         * rule. It's safer this way. Plus, pretty much costs nothing.
         */
        windowWidth;
        let width = 0;
        for (let i = 0; i < elements.length; i++) {
            const [id, el] = elements[i];
            const w = (el === null || el === void 0 ? void 0 : el.getBoundingClientRect().width) || 0;
            if (id === selectedId) {
                width = w;
                break;
            }
            left += w;
        }
        return [left, width];
    }, [elements, selectedId, windowWidth]);
    /**
     * Animation is tricky. We have to delay any of it before all the `Item` elements
     * are mounted and we know at list the width of the current one.
     */
    const canAnimate = width !== 0;
    /**
     * We cannot enable animations immediately after having the width. We have to
     * let components mount without transition first to avoid making them transition
     * from an unknown location and 0 width.
     */
    const [animated, enableAnimation] = useReducer(() => true, false);
    useEffect(() => {
        if (canAnimate) {
            /**
             * At this point we know the proper width is there and the components
             * mounted. Let's give animations green light!
             */
            enableAnimation();
        }
    }, [canAnimate]);
    return (React.createElement(Root, Object.assign({}, props),
        React.createElement(Outer, null,
            React.createElement(Rails, { "$animated": animated, style: {
                    transform: `translateX(${left}px)`,
                    width: `${width}px`,
                } }, tabs.map(({ id, children }) => (React.createElement(Trolley, { key: id, "$selected": id === selectedId, "$animated": animated },
                React.createElement(ItemContent, { "$truncate": spreadEvenly }, children))))),
            React.createElement(Inner, null, tabs.map((_a, index) => {
                var { id, tag = 'button', onClick, disabled = false, selected, children } = _a, rest = __rest(_a, ["id", "tag", "onClick", "disabled", "selected", "children"]);
                return (React.createElement(Item, Object.assign({}, rest, { "$flexBasis": spreadEvenly ? `${100 / tabs.length}%` : undefined, as: tag, key: id, ref: setElementAt(id, index), disabled: disabled, onClick: (e, ...otherArgs) => {
                        const preventable = isPreventable(e);
                        if (disabled) {
                            if (preventable) {
                                e.preventDefault();
                            }
                            /**
                             * If a Tab is disabled we don't go any further.
                             * Additionally, if `e` is a default-preventable,
                             * then at this point it's also taken care of.
                             */
                            return;
                        }
                        /**
                         * Anything that the *og* onClick has given us we pass
                         * onto the `onClick` coming from the outside.
                         */
                        onClick === null || onClick === void 0 ? void 0 : onClick(e, ...otherArgs);
                        /**
                         * It is possible to prevent selection outside of the
                         * component. Just prevent event's default behaviour.
                         */
                        if (preventable && e.defaultPrevented) {
                            return;
                        }
                        onSelectionChange === null || onSelectionChange === void 0 ? void 0 : onSelectionChange(id);
                    }, "$selected": id === selectedId }),
                    React.createElement(ItemContent, { "$truncate": spreadEvenly }, children)));
            })))));
}
