var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { toaster } from 'toasterhea';
import { useLocation } from 'react-router-dom';
import { Button, HamburgerButton, Logo, Menu as UnstyledMenu, NavDropdown, NavOverlay, } from '@streamr/streamr-layout';
import { MD as TABLET, LG as DESKTOP, COLORS, REGULAR, MEDIUM, } from '$shared/utils/styled';
import Link from '$shared/components/Link';
import SvgIcon from '$shared/components/SvgIcon';
import AvatarImage from '$shared/components/AvatarImage';
import { truncate } from '$shared/utils/text';
import ConnectModal from '$app/src/modals/ConnectModal';
import { Layer } from '$app/src/utils/Layer';
import { useEns, useWalletAccount } from '$shared/stores/wallet';
import toast from '$app/src/utils/toast';
import routes from '$routes';
import { Avatarless, Name, Username } from './User';
const MOBILE_LG = 576;
const CaretDownIcon = styled(SvgIcon).withConfig({ displayName: "CaretDownIcon", componentId: "sc-1jsav32" }) `
    opacity: 1;
`;
const CaretUpIcon = styled(SvgIcon).withConfig({ displayName: "CaretUpIcon", componentId: "sc-1huo1z9" }) `
    opacity: 0;
`;
const DropdownToggle = styled.div.withConfig({ displayName: "DropdownToggle", componentId: "sc-16xx8ag" }) `
    background: #f8f8f8;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    position: relative;
    margin-top: 1px;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        transition: 200ms opacity;
    }
`;
const Menu = styled(UnstyledMenu).withConfig({ displayName: "Menu", componentId: "sc-10aji00" }) ``;
const MenuItem = styled(Menu.Item).withConfig({ displayName: "MenuItem", componentId: "sc-i3u711" }) `
    &.user-info {
        padding: 0 16px !important;
    }
    &.disconnect {
        padding: 0 !important;
        .disconnect-text {
            padding: 12px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;
const MenuDivider = styled(Menu.Divider).withConfig({ displayName: "MenuDivider", componentId: "sc-flb4se" }) `
    margin: 0;
`;
const WalletAddress = styled.div.withConfig({ displayName: "WalletAddress", componentId: "sc-1r8y1oe" }) `
    margin-left: 13px;
    display: flex;
    flex-direction: column;
    span {
        font-size: 14px;
        line-height: 18px;
        user-select: none;
        color: ${COLORS.primary};
        font-weight: 400;
        &.ens-name {
            font-weight: 500;
        }
    }
`;
const SignedInUserMenu = styled(NavDropdown).withConfig({ displayName: "SignedInUserMenu", componentId: "sc-o80xex" }) `
    ${Menu} {
        width: 260px;
        padding: 0;

        ${Menu.Item}:first-child {
            padding: 0 4px;
            margin-bottom: 10px;
        }

        ${Avatarless} {
        }

        ${Name},
        ${Username} {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    :hover ${DropdownToggle} {
        ${CaretDownIcon} {
            opacity: 0;
        }

        ${CaretUpIcon} {
            opacity: 1;
        }
    }
`;
export const Navbar = styled.div.withConfig({ displayName: "Navbar", componentId: "sc-kxub1o" }) `
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
`;
const MenuGrid = styled.div.withConfig({ displayName: "MenuGrid", componentId: "sc-10i2i0w" }) `
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    align-items: center;
`;
const NavLink = styled.a.withConfig({ displayName: "NavLink", componentId: "sc-c5g156" }) ``;
export const NavbarItem = styled.div.withConfig({ displayName: "NavbarItem", componentId: "sc-2h8exd" }) `
    ${MenuGrid} & + & {
        margin-left: 16px;
    }
`;
const LinkWrapper = styled.div.withConfig({ displayName: "LinkWrapper", componentId: "sc-1uaudu3" }) `
    ${NavLink} {
        display: block;
        color: ${COLORS.primaryLight};
        text-transform: uppercase;
        font-weight: ${MEDIUM};
        letter-spacing: 2px;
        white-space: nowrap;
        text-decoration: none !important;
    }

    &:hover {
        ${NavLink} {
            color: ${COLORS.primary};
        }
    }
`;
const UnstyledNavbarLink = (_a) => {
    var { highlight, children } = _a, props = __rest(_a, ["highlight", "children"]);
    return React.createElement(LinkWrapper, Object.assign({}, props), children);
};
const NavbarLinkDesktop = styled(UnstyledNavbarLink).withConfig({ displayName: "NavbarLinkDesktop", componentId: "sc-1mr5vq8" }) `
    position: relative;

    ${NavLink} {
        font-size: 12px;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
    }

    &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: 2px;
        left: 50%;
        transition: width 0.2s ease-out;
        width: 0;
        height: 2px;
        background-color: ${COLORS.primary};
        transform: translateX(-50%);
    }

    &:hover {
        &:after {
            transition: width 0.2s ease-in;
            width: 20px;
        }
    }

    ${({ highlight }) => highlight &&
    css `
            &:after {
                left: 50%;
                width: 20px;
            }

            ${NavLink} {
                color: ${COLORS.primary};
            }
        `}
`;
const NavbarLinkMobile = styled(UnstyledNavbarLink).withConfig({ displayName: "NavbarLinkMobile", componentId: "sc-1scr0hq" }) `
    position: relative;
    border-bottom: 1px solid #efefef;

    ${NavLink} {
        font-size: 18px;
        line-height: 100px;
    }

    ${({ highlight }) => highlight &&
    css `
            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -24px;
                width: 3px;
                height: 32px;
                background-color: ${COLORS.primary};
            }

            ${NavLink} {
                color: ${COLORS.primary};
            }

            @media (min-width: ${MOBILE_LG}px) {
                &:after {
                    left: -64px;
                }
            }
        `}
`;
const NavbarItemAccount = styled.div.withConfig({ displayName: "NavbarItemAccount", componentId: "sc-1gktxaf" }) `
    margin-left: auto;
    margin-right: 15px;

    @media (min-width: ${TABLET}px) {
        margin-right: 0;
    }
`;
const UnstyledLogoLink = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return React.createElement("a", Object.assign({}, props), children);
};
export const LogoLink = styled(UnstyledLogoLink).withConfig({ displayName: "LogoLink", componentId: "sc-1o1rraf" }) `
    color: #f65f0a !important;
    display: block;
    max-width: 64px;
    width: 32px;

    @media (min-width: ${DESKTOP}px) {
        width: 40px;
    }
`;
const Avatar = styled(AvatarImage).withConfig({ displayName: "Avatar", componentId: "sc-zbkpio" }) `
    width: 32px;
    height: 32px;
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    background-color: white;

    @media (min-width: ${DESKTOP}px) {
        width: 40px;
        height: 40px;
    }
`;
const MenuItemAvatarContainer = styled.div.withConfig({ displayName: "MenuItemAvatarContainer", componentId: "sc-1vf0gl2" }) `
    background-color: ${COLORS.secondaryLight};
    padding: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 16px 0;
`;
const UserInfoMobile = styled.div.withConfig({ displayName: "UserInfoMobile", componentId: "sc-19e7djf" }) `
    background-color: #f8f8f8;
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    border-radius: 4px;

    ${Avatar} {
        width: 45px;
        height: 45px;
        background-color: #fff;
        margin-right: 8px;
    }

    ${Avatarless} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        ${Name} {
            font-size: 14px;
            font-weight: ${REGULAR};
            line-height: 1.25em;
        }
        ${Username} {
            padding: 3px;
            font-size: 12px;
            font-weight: ${MEDIUM};
            background-color: #fff;
            color: #848484;
            margin: 3px 0;
        }
    }
`;
const UnstyledDesktopNav = (props) => {
    const { pathname } = useLocation();
    const account = useWalletAccount();
    const ensName = useEns(account);
    return (React.createElement("div", Object.assign({}, props, { "data-testid": 'desktop-nav' }),
        React.createElement(Navbar, null,
            React.createElement(NavbarItem, null,
                React.createElement(LogoLink, { href: routes.root() },
                    React.createElement(Logo, { "data-testid": 'logo' }))),
            React.createElement(MenuGrid, { "data-desktop-only": true },
                React.createElement(NavbarItem, null,
                    React.createElement(NavbarLinkDesktop, { highlight: pathname.startsWith(routes.projects.index()) },
                        React.createElement(NavLink, { as: Link, to: routes.projects.index() }, "Projects"))),
                React.createElement(NavbarItem, null,
                    React.createElement(NavbarLinkDesktop, { highlight: pathname.startsWith(routes.streams.index()) },
                        React.createElement(NavLink, { as: Link, to: routes.streams.index() }, "Streams"))),
                React.createElement(NavbarItem, null,
                    React.createElement(NavbarLinkDesktop, { highlight: false },
                        React.createElement(NavLink, { as: Link, href: routes.networkExplorer(), rel: "noopener noreferrer", target: "_blank" }, "Network")))),
            !account && (React.createElement(Fragment, null,
                React.createElement(NavbarItemAccount, null,
                    React.createElement(Button, { kind: "primary", size: "mini", outline: true, type: "button", onClick: async () => {
                            try {
                                await toaster(ConnectModal, Layer.Modal).pop();
                            }
                            catch (e) {
                                console.warn('Wallet connecting failed', e);
                            }
                        } }, "Connect")))),
            !!account && (React.createElement(Fragment, null,
                React.createElement(NavbarItemAccount, null,
                    React.createElement(SignedInUserMenu, { edge: true, alignMenu: "right", nodeco: true, toggle: React.createElement(Avatar, { username: account }), menu: React.createElement(Menu, null,
                            React.createElement(MenuItem, { className: 'user-info' },
                                React.createElement(MenuItemAvatarContainer, null,
                                    React.createElement(Avatar, { username: account }),
                                    React.createElement(WalletAddress, null,
                                        !!ensName && (React.createElement("span", { className: 'ens-name' }, truncate(ensName))),
                                        React.createElement("span", null, truncate(account))))),
                            React.createElement(MenuDivider, null),
                            React.createElement(MenuItem, { className: "disconnect", onClick: () => {
                                    toast({
                                        title: 'Use the "Lock" button in your wallet.',
                                    });
                                } },
                                React.createElement("div", { className: 'disconnect-text' },
                                    React.createElement("span", null, "Disconnect"),
                                    React.createElement(SvgIcon, { name: 'disconnect' })))) })))),
            React.createElement(HamburgerButton, { idle: true }))));
};
const UnstyledMobileNav = ({ className }) => {
    const account = useWalletAccount();
    const { pathname } = useLocation();
    return (React.createElement(NavOverlay, { className: className },
        React.createElement(NavOverlay.Head, null,
            React.createElement(Navbar, null,
                React.createElement(NavbarItem, null,
                    React.createElement(LogoLink, { href: routes.root() },
                        React.createElement(Logo, null))),
                React.createElement(NavbarItem, null,
                    React.createElement(HamburgerButton, null)))),
        React.createElement(NavOverlay.Body, null,
            !!account && (React.createElement(UserInfoMobile, null,
                React.createElement(Avatar, { username: account }),
                React.createElement(Avatarless, { "data-testid": 'avatarless', source: account }))),
            React.createElement(NavbarLinkMobile, { highlight: pathname.startsWith(routes.projects.index()) },
                React.createElement(NavLink, { as: Link, to: routes.projects.index() }, "Projects")),
            React.createElement(NavbarLinkMobile, { highlight: pathname.startsWith(routes.streams.index()) },
                React.createElement(NavLink, { as: Link, to: routes.streams.index() }, "Streams")),
            React.createElement(NavbarLinkMobile, { highlight: false },
                React.createElement(NavLink, { as: Link, href: routes.networkExplorer(), rel: "noopener noreferrer", target: "_blank" }, "Network"))),
        React.createElement(NavOverlay.Footer, null, !!account ? (React.createElement(Button, { kind: "secondary", size: "normal", type: "button", onClick: () => {
                toast({
                    title: 'Use the "Lock" button in your wallet.',
                });
            } }, "Disconnect")) : (React.createElement(Button, { type: "button", onClick: async () => {
                try {
                    await toaster(ConnectModal, Layer.Modal).pop();
                }
                catch (e) {
                    console.warn('Wallet connecting failed', e);
                }
            }, kind: "primary", size: "normal" }, "Connect Wallet")))));
};
const DesktopNav = styled(UnstyledDesktopNav).withConfig({ displayName: "DesktopNav", componentId: "sc-1k2joqi" }) `
    position: relative;

    ${Navbar} {
        > ${NavbarItem}:first-child {
            flex-grow: initial;
        }

        > ${NavbarItem}:nth-child(2) {
            flex-grow: 1;
        }
    }

    @media (min-width: ${DESKTOP}px) {
        ${Navbar} > ${NavbarItem}:first-child {
            flex-grow: 1;
        }
    }

    &[data-shadow='true'] {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.02);
    }

    ${Avatarless} {
        line-height: 20px;
        padding: 4px 0 8px;
    }

    ${Name} {
        font-size: 14px;
        margin-bottom: 4px;
    }

    ${Username} {
        font-size: 12px;
    }
`;
const MobileNav = styled(UnstyledMobileNav).withConfig({ displayName: "MobileNav", componentId: "sc-1soj07e" }) `
    ${NavLink}:not([href]) {
        color: #cdcdcd;
    }

    ${HamburgerButton} {
        margin-left: auto;
    }

    ${NavOverlay.Body} {
        padding: 36px 24px 0 24px;

        ${UserInfoMobile} {
            margin-bottom: 24px;
        }

        ${NavbarLinkMobile} {
            border-top: 1px solid #efefef;

            + ${NavbarLinkMobile} {
                border-top: none;
            }
        }

        > :first-child {
            border-top: none;
        }
    }

    ${NavOverlay.Footer} {
        background-color: #ffffff;
        padding: 24px;

        ${Button} {
            width: 100%;
        }
    }

    @media (min-width: ${MOBILE_LG}px) {
        ${NavOverlay.Body} {
            padding: 36px 64px 0 64px;

            ${UserInfoMobile} {
                margin-bottom: 64px;
            }
        }

        ${NavOverlay.Footer} {
            padding: 64px;

            ${Button} {
                width: auto;
            }
        }
    }
`;
const UnstyledContainer = (props) => React.createElement("div", Object.assign({}, props));
export const NavContainer = styled(UnstyledContainer).withConfig({ displayName: "NavContainer", componentId: "sc-xxuk2" }) `
    background-color: #ffffff;
    color: #323232;

    ${HamburgerButton} {
        background-color: #f8f8f8;
    }

    ${Navbar} {
        padding: 20px 24px;

        @media (min-width: ${TABLET}px) {
            padding: 20px 24px;
        }

        @media (min-width: ${DESKTOP}px) {
            padding: 20px 40px;
        }

        > ${HamburgerButton} {
            display: flex;
        }

        ${NavbarItem}:empty {
            display: none;
        }

        > [data-desktop-only='true'] {
            display: none;
        }
    }

    ${Button} {
        padding: 0 16px;
    }

    > [data-mobile-only='true'] {
        display: block;
    }

    @media (min-width: ${TABLET}px) {
        ${Navbar} > [data-desktop-only='true'] {
            display: grid;
        }

        ${Navbar} > ${HamburgerButton} {
            display: none;
        }

        > [data-mobile-only='true'] {
            display: none;
        }
    }
`;
const N = (_a) => {
    var { children, shadow } = _a, props = __rest(_a, ["children", "shadow"]);
    return (React.createElement(NavContainer, Object.assign({}, props),
        React.createElement(DesktopNav, { "data-shadow": !!shadow }),
        React.createElement(MobileNav, null)));
};
Object.assign(N, {
    Container: NavContainer,
});
export default N;
