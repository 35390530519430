import React from 'react';
import styled from 'styled-components';
import Editor from 'rich-markdown-editor';
import light from 'rich-markdown-editor/dist/styles/theme';
import { ProjectHeroContainer, ProjectHeroDescriptionStyles, ProjectHeroImage, ProjectHeroMetadataContainer, ProjectHeroTitle, } from '$mp/containers/ProjectPage/Hero/ProjectHero2.styles';
import { COLORS } from '$shared/utils/styled';
import { DetailDisplay } from '$shared/components/DetailEditor/DetailDisplay';
import { ProjectDetailIcon } from '$mp/containers/ProjectEditing/ProjectDetails.styles';
const DescriptionEditor = styled(Editor).withConfig({ displayName: "DescriptionEditor", componentId: "sc-1tyspv4" }) `
    ${ProjectHeroDescriptionStyles};
    justify-content: flex-start;

    .block-menu-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const customTheme = Object.assign(Object.assign({}, light), { toolbarBackground: COLORS.primaryContrast, toolbarHoverBackground: COLORS.primaryDisabled, toolbarItem: COLORS.primaryLight, placeholder: COLORS.primaryDisabled, link: COLORS.link, text: COLORS.primaryLight });
export default function ProjectHero2({ name, description, imageUrl, contact = {}, }) {
    return (React.createElement(ProjectHeroContainer, null,
        React.createElement(ProjectHeroImage, { src: imageUrl, alt: name, noBorderRadius: true, key: imageUrl || '' }),
        React.createElement(ProjectHeroTitle, null, name),
        React.createElement(DescriptionEditor, { value: description, readOnly: true, theme: customTheme }),
        React.createElement(ProjectHeroMetadataContainer, null, contact && (React.createElement(React.Fragment, null,
            contact.url && (React.createElement(DetailDisplay, { icon: React.createElement(ProjectDetailIcon, { name: 'web' }), value: contact.url, link: contact.url })),
            contact.email && (React.createElement(DetailDisplay, { icon: React.createElement(ProjectDetailIcon, { name: 'email' }), value: contact.email, link: 'mailto:' + contact.email })),
            contact.twitter && (React.createElement(DetailDisplay, { icon: React.createElement(ProjectDetailIcon, { name: 'twitter', className: 'twitterColor' }), link: contact.twitter })),
            contact.telegram && (React.createElement(DetailDisplay, { icon: React.createElement(ProjectDetailIcon, { name: 'telegram', className: 'telegramColor' }), link: contact.telegram })),
            contact.reddit && (React.createElement(DetailDisplay, { icon: React.createElement(ProjectDetailIcon, { name: 'reddit', className: 'redditColor' }), link: contact.reddit })),
            contact.linkedIn && (React.createElement(DetailDisplay, { icon: React.createElement(ProjectDetailIcon, { name: 'linkedin', className: 'linkedInColor' }), link: contact.linkedIn })))))));
}
