import React, { useCallback } from 'react';
import { StreamPermission } from 'streamr-client';
import styled, { css } from 'styled-components';
import { toaster } from 'toasterhea';
import { Link, Route, Routes, useNavigate, useLocation, useParams, Navigate, } from 'react-router-dom';
import { StreamPreview } from '$shared/components/StreamPreview';
import { StreamConnect } from '$shared/components/StreamConnect';
import { useCurrentStreamAbility, useInvalidateStreamAbilities, } from '$shared/stores/streamAbilities';
import { DraftValidationError, StreamDraftContext, useCurrentDraft, useInitStreamDraft, useIsCurrentDraftBusy, useIsCurrentDraftClean, usePersistCurrentDraft, useSetCurrentDraftError, } from '$shared/stores/streamEditor';
import useDecodedStreamId from '$shared/hooks/useDecodedStreamId';
import StreamNotFoundError from '$shared/errors/StreamNotFoundError';
import Layout from '$shared/components/Layout/Core';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import { DetailsPageHeader } from '$shared/components/DetailsPageHeader';
import { truncateStreamName } from '$shared/utils/text';
import { CopyButton } from '$shared/components/CopyButton/CopyButton';
import Tabs, { Tab } from '$shared/components/Tabs';
import { RouteMemoryKey, useKeep } from '$shared/stores/routeMemory';
import Button from '$shared/components/Button';
import usePreventNavigatingAway from '$shared/hooks/usePreventNavigatingAway';
import { DESKTOP, TABLET } from '$shared/utils/styled';
import LoadingIndicator from '$shared/components/LoadingIndicator';
import useIsMounted from '$shared/hooks/useIsMounted';
import { useWalletAccount } from '$shared/stores/wallet';
import InsufficientFundsError from '$shared/errors/InsufficientFundsError';
import getNativeTokenName from '$shared/utils/nativeToken';
import getChainId from '$utils/web3/getChainId';
import { Layer } from '$utils/Layer';
import GetCryptoModal from '$app/src/modals/GetCryptoModal';
import NotFoundPage, { NotFoundPageContent } from '$shared/components/NotFoundPage';
import { GenericErrorPageContent } from '$shared/components/GenericErrorPage';
import routes from '$routes';
import InfoSection from './AbstractStreamEditPage/InfoSection';
import AccessControlSection from './AbstractStreamEditPage/AccessControlSection';
import HistorySection from './AbstractStreamEditPage/HistorySection';
import PartitionsSection from './AbstractStreamEditPage/PartitionsSection';
import DeleteSection from './AbstractStreamEditPage/DeleteSection';
import PersistanceAlert from './AbstractStreamEditPage/PersistanceAlert';
import RelatedProjects from './AbstractStreamEditPage/RelatedProjects';
import CreateProjectHint from './AbstractStreamEditPage/CreateProjectHint';
const getCryptoModal = toaster(GetCryptoModal, Layer.Modal);
function EditPage({ isNew = false }) {
    const { streamId } = useCurrentDraft();
    const canEdit = useCurrentStreamAbility(StreamPermission.EDIT);
    const canDelete = useCurrentStreamAbility(StreamPermission.DELETE);
    const canGrant = useCurrentStreamAbility(StreamPermission.GRANT);
    const busy = useIsCurrentDraftBusy();
    const clean = useIsCurrentDraftClean();
    const disabled = typeof canEdit === 'undefined' || busy;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, { loading: disabled }),
        React.createElement(ContainerBox, { disabled: disabled || clean, showRelatedProjects: !!streamId, showSaveButton: !isNew, streamId: streamId, showProjectCreateHint: canGrant },
            React.createElement(PersistanceAlert, null),
            React.createElement(InfoSection, { disabled: disabled }),
            React.createElement(AccessControlSection, { disabled: disabled }),
            React.createElement(HistorySection, { disabled: disabled }),
            React.createElement(PartitionsSection, { disabled: disabled }),
            canDelete && React.createElement(DeleteSection, null))));
}
function LiveDataPage() {
    const canSubscribe = useCurrentStreamAbility(StreamPermission.SUBSCRIBE);
    const loading = typeof canSubscribe === 'undefined';
    const streamId = useDecodedStreamId();
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, { loading: loading }),
        React.createElement(StreamPreview, { streamsList: [streamId], previewDisabled: !canSubscribe })));
}
function ConnectPage() {
    const streamId = useDecodedStreamId();
    const loading = useCurrentStreamAbility(StreamPermission.EDIT) == null;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, { loading: loading }),
        React.createElement(ContainerBox, { fullWidth: true, showRelatedProjects: true, streamId: streamId },
            React.createElement(StreamConnect, { streams: [streamId] }))));
}
function StreamRedirect() {
    const { id } = useParams();
    return React.createElement(Navigate, { to: routes.streams.overview({ id }), replace: true });
}
function defaultFormEventHandler(e) {
    e.preventDefault();
}
function StreamPageSwitch({ tab }) {
    const { id } = useParams();
    const isNew = id === 'new';
    const { streamId, loadError } = useCurrentDraft();
    const busy = useIsCurrentDraftBusy();
    const clean = useIsCurrentDraftClean();
    const persist = usePersistCurrentDraft();
    const setValidationError = useSetCurrentDraftError();
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const address = useWalletAccount();
    const invalidateAbilities = useInvalidateStreamAbilities();
    usePreventNavigatingAway({
        isDirty: useCallback((dest) => {
            if (streamId) {
                switch (dest) {
                    case routes.streams.overview({ id: streamId }):
                    case routes.streams.connect({ id: streamId }):
                    case routes.streams.liveData({ id: streamId }):
                        return false;
                }
            }
            /**
             * Undefined `dest` means it's a full URL change that's happening outside of the
             * router, or it's a refresh. We block such things here only if the state is dirty.
             *
             * Internal route changes are allowed w/o questions as long as modifications to the
             * current draft are being persisted (see `busy`).
             */
            return !clean && (typeof dest === 'undefined' || !busy);
        }, [clean, busy, streamId]),
    });
    async function onSubmit(e) {
        defaultFormEventHandler(e);
        try {
            await persist({
                onCreate(streamId) {
                    if (!isMounted()) {
                        /**
                         * Avoid redirecting to the new stream's edit page after the stream
                         * page has been unmounted.
                         */
                        return;
                    }
                    navigate(routes.streams.overview({
                        id: streamId,
                    }));
                },
                onPermissionsChange(streamId, assignments) {
                    if (!address) {
                        return;
                    }
                    if (!assignments.some((assignment) => {
                        return ('user' in assignment &&
                            assignment.user.toLowerCase() === address.toLowerCase());
                    })) {
                        return;
                    }
                    invalidateAbilities(streamId, address);
                },
            });
        }
        catch (e) {
            if (e instanceof DraftValidationError) {
                return void setValidationError(e.key, e.message);
            }
            if (e instanceof InsufficientFundsError) {
                return void setTimeout(async () => {
                    try {
                        const chainId = await getChainId();
                        await getCryptoModal.pop({
                            tokenName: getNativeTokenName(chainId),
                        });
                    }
                    catch (_) {
                        // Do nothing.
                    }
                });
            }
            throw e;
        }
    }
    if (typeof loadError === 'undefined') {
        /**
         * We don't know if the stream loaded or not. `loadError` means
         * - still determining if `undefined`,
         * - all good if `null`,
         * - something broke down if anything other than the above 2.
         */
        return (React.createElement("form", { onSubmit: defaultFormEventHandler },
            React.createElement(Layout, null,
                React.createElement(Header, null),
                React.createElement(LoadingIndicator, { loading: true }))));
    }
    if (loadError instanceof StreamNotFoundError) {
        return (React.createElement("form", { onSubmit: defaultFormEventHandler },
            React.createElement(Layout, null,
                React.createElement(Header, null),
                React.createElement(LoadingIndicator, null),
                React.createElement(NotFoundPageContent, null))));
    }
    if (loadError) {
        return (React.createElement("form", { onSubmit: defaultFormEventHandler },
            React.createElement(Layout, null,
                React.createElement(Header, null),
                React.createElement(LoadingIndicator, null),
                React.createElement(GenericErrorPageContent, null))));
    }
    const editView = tab === 'overview' || isNew;
    return (React.createElement("form", { onSubmit: editView ? onSubmit : defaultFormEventHandler },
        React.createElement(Layout, null,
            React.createElement(Header, { isNew: isNew }),
            editView && React.createElement(EditPage, { isNew: isNew }),
            tab === 'connect' && React.createElement(ConnectPage, null),
            tab === 'live-data' && React.createElement(LiveDataPage, null))));
}
export default function StreamPage() {
    const streamId = useDecodedStreamId();
    const draftId = useInitStreamDraft(streamId === 'new' ? undefined : streamId);
    return (React.createElement(StreamDraftContext.Provider, { value: draftId },
        React.createElement(Routes, null,
            React.createElement(Route, { index: true, element: streamId === 'new' ? React.createElement(StreamPageSwitch, null) : React.createElement(StreamRedirect, null) }),
            React.createElement(Route, { path: "overview", element: React.createElement(StreamPageSwitch, { tab: "overview" }) }),
            React.createElement(Route, { path: "connect", element: React.createElement(StreamPageSwitch, { tab: "connect" }) }),
            React.createElement(Route, { path: "live-data", element: React.createElement(StreamPageSwitch, { tab: "live-data" }) }),
            React.createElement(Route, { path: "*", element: React.createElement(NotFoundPage, null) }))));
}
function Header({ isNew = false }) {
    const { streamId, transientStreamId } = useCurrentDraft();
    const { pathname } = useLocation();
    const keep = useKeep();
    const busy = useIsCurrentDraftBusy();
    const clean = useIsCurrentDraftClean();
    return (React.createElement(React.Fragment, null,
        React.createElement(MarketplaceHelmet, { title: streamId ? `Stream ${streamId}` : 'New stream' }),
        React.createElement(DetailsPageHeader, { backButtonLink: routes.streams.index(), pageTitle: React.createElement(TitleContainer, null,
                React.createElement("span", { title: streamId }, streamId
                    ? truncateStreamName(streamId, 50)
                    : transientStreamId || 'New stream'),
                streamId ? React.createElement(CopyButton, { valueToCopy: streamId }) : ''), rightComponent: streamId ? (React.createElement(Tabs, { selection: pathname },
                React.createElement(Tab, { id: "overview", tag: Link, to: routes.streams.overview({
                        id: streamId,
                    }), selected: "to", onClick: () => void keep(RouteMemoryKey.lastStreamListingSelection()) },
                    "Stream overview",
                    !clean && React.createElement(Asterisk, null)),
                React.createElement(Tab, { id: "connect", tag: Link, to: routes.streams.connect({
                        id: streamId,
                    }), selected: "to", onClick: () => void keep(RouteMemoryKey.lastStreamListingSelection()) }, "Connect"),
                React.createElement(Tab, { id: "liveData", tag: Link, to: routes.streams.liveData({
                        id: streamId,
                    }), selected: "to", onClick: () => void keep(RouteMemoryKey.lastStreamListingSelection()) }, "Live data"))) : isNew ? (React.createElement("div", null,
                React.createElement(Button, { disabled: busy || clean, kind: "primary", type: "submit" }, "Save"))) : null })));
}
const TitleContainer = styled.div.withConfig({ displayName: "TitleContainer", componentId: "sc-1emaacm" }) `
    display: flex;
    align-items: center;
`;
const Asterisk = styled.span.withConfig({ displayName: "Asterisk", componentId: "sc-vrizhj" }) `
    :after {
        content: '*';
        position: absolute;
    }
`;
const Inner = styled.div.withConfig({ displayName: "Inner", componentId: "sc-1m5mkao" }) `
    display: grid;
    grid-template-columns: fit-content(680px) auto;
    border-radius: 16px;
    background-color: white;
    padding: 24px;

    ${({ $fullWidth = false }) => $fullWidth &&
    css `
            grid-template-columns: auto;
        `}

    @media ${TABLET} {
        padding: 40px;
    }

    @media ${DESKTOP} {
        padding: 52px;
    }
`;
const SaveButton = styled(Button).withConfig({ displayName: "SaveButton", componentId: "sc-1jjl6fs" }) `
    width: fit-content;
    justify-self: right;
`;
const Outer = styled.div.withConfig({ displayName: "Outer", componentId: "sc-e8w56o" }) `
    width: 100%;
    padding: 24px 24px 80px 24px;

    @media ${TABLET} {
        max-width: 1296px;
        margin: 0 auto;
        padding: 45px 40px 90px 40px;
    }

    @media ${DESKTOP} {
        padding: 60px 0px 130px 0px;
    }
`;
function ContainerBox({ children, disabled, streamId, showSaveButton = false, fullWidth = false, showRelatedProjects = false, showProjectCreateHint = false, }) {
    return (React.createElement(Outer, null,
        React.createElement(Inner, { "$fullWidth": fullWidth },
            React.createElement("div", null, children),
            showSaveButton && (React.createElement(SaveButton, { kind: "primary", type: "submit", disabled: disabled }, "Save"))),
        showRelatedProjects && streamId && React.createElement(RelatedProjects, { streamId: streamId }),
        showProjectCreateHint && React.createElement(CreateProjectHint, { streamId: streamId })));
}
