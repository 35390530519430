import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import '$mp/types/project-types';
import { ProjectType } from '$shared/types';
import Layout from '$shared/components/Layout';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import { DetailsPageHeader } from '$shared/components/DetailsPageHeader';
import { EditorNav } from '$mp/containers/ProjectEditing/EditorNav';
import { ProjectStateContext, ProjectStateContextProvider, } from '$mp/contexts/ProjectStateContext';
import { ValidationContext, ValidationContextProvider, } from '$mp/containers/ProductController/ValidationContextProvider';
import { ProjectEditor } from '$mp/containers/ProjectEditing/ProjectEditor';
import styles from '$shared/components/Layout/layout.pcss';
import usePreventNavigatingAway from '$shared/hooks/usePreventNavigatingAway';
import { ProjectControllerContext, ProjectControllerProvider, } from '$mp/containers/ProjectEditing/ProjectController';
import PrestyledLoadingIndicator from '$shared/components/LoadingIndicator';
import { getProjectTitleForEditor } from '$mp/containers/ProjectPage/utils';
import ProjectLinkTabs from '$app/src/pages/ProjectPage/ProjectLinkTabs';
import { useEditableProjectActions } from '../ProductController/useEditableProjectActions';
const UnstyledNewProjectPage = ({ className }) => {
    const location = useLocation();
    const { state: project } = useContext(ProjectStateContext);
    const { publishInProgress } = useContext(ProjectControllerContext);
    const { type } = qs.parse(location.search);
    const { updateType } = useEditableProjectActions();
    const { isAnyTouched, resetTouched } = useContext(ValidationContext);
    usePreventNavigatingAway({
        isDirty: useCallback(() => {
            if (publishInProgress) {
                return false;
            }
            return isAnyTouched();
        }, [publishInProgress, isAnyTouched]),
    });
    useEffect(() => {
        const typeIsValid = Object.values(ProjectType).includes(type);
        updateType(typeIsValid ? type : ProjectType.OpenData);
    }, [type, updateType]);
    useEffect(() => {
        resetTouched();
    }, [resetTouched]);
    const pageTitle = useMemo(() => {
        return getProjectTitleForEditor(project);
    }, [project]);
    return (React.createElement(Layout, { nav: React.createElement(EditorNav, { isNewProject: true }), innerClassName: styles.greyInner },
        React.createElement(MarketplaceHelmet, { title: 'Create a new project' }),
        React.createElement(DetailsPageHeader, { pageTitle: pageTitle, rightComponent: React.createElement(ProjectLinkTabs, null) }),
        React.createElement(LoadingIndicator, { loading: publishInProgress }),
        React.createElement(ProjectEditor, null)));
};
const StyledNewProjectPage = styled(UnstyledNewProjectPage).withConfig({ displayName: "StyledNewProjectPage", componentId: "sc-1vdn4r2" }) `
    position: absolute;
    top: 0;
    height: 2px;
`;
const LoadingIndicator = styled(PrestyledLoadingIndicator).withConfig({ displayName: "LoadingIndicator", componentId: "sc-9kjijf" }) `
    top: 2px;
`;
const NewProjectPageContainer = (props) => {
    return (React.createElement(ProjectStateContextProvider, null,
        React.createElement(ValidationContextProvider, null,
            React.createElement(ProjectControllerProvider, null,
                React.createElement(StyledNewProjectPage, Object.assign({}, props))))));
};
export default NewProjectPageContainer;
