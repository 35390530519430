var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import Checkbox from '$shared/components/Checkbox';
import Text from '$ui/Text';
import Label from '$ui/Label';
import Errors, { MarketplaceTheme } from '$ui/Errors';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
import useValidation from '../ProductController/useValidation';
const Section = styled.div.withConfig({ displayName: "Section", componentId: "sc-s25tuw" }) `
    background: none;
    max-width: 678px;

    h2 {
        font-size: 34px;
        line-height: 34px;
        color: black;
        margin-bottom: 30px;
        font-weight: 400;
    }

    p {
        color: black;
        font-size: 16px;
    }
`;
const DetailsContainer = styled.div.withConfig({ displayName: "DetailsContainer", componentId: "sc-q1das2" }) `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
`;
const StyledCheckbox = styled(Checkbox).withConfig({ displayName: "StyledCheckbox", componentId: "sc-hl8zfe" }) `
    width: 24px !important;
    height: 24px !important;
`;
const terms = {
    redistribution: 'Redistribution',
    commercialUse: 'Commercial use',
    reselling: 'Reselling',
    storage: 'Storage',
};
const UnstyledTermCheckbox = (_a) => {
    var { id, product, updateTermsOfUse, disabled } = _a, props = __rest(_a, ["id", "product", "updateTermsOfUse", "disabled"]);
    return (React.createElement(CheckboxLabel, Object.assign({}, props, { htmlFor: id }),
        React.createElement(StyledCheckbox, { id: id, name: id, value: product.termsOfUse[id], onChange: (e) => {
                updateTermsOfUse(Object.assign(Object.assign({}, product.termsOfUse), { [id]: e.target.checked }));
            }, disabled: disabled }),
        React.createElement("span", null, terms[id])));
};
const TermCheckbox = styled(UnstyledTermCheckbox).withConfig({ displayName: "TermCheckbox", componentId: "sc-f3z94s" }) `
    align-items: center;
    display: flex;
    margin: 0;
`;
const CheckboxContainer = styled.div.withConfig({ displayName: "CheckboxContainer", componentId: "sc-1hhz0t2" }) `
    margin: 40px 0;
    background: #f1f1f1;
    border-radius: 4px;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;

    ${TermCheckbox} + ${TermCheckbox} {
        margin-left: 1rem;
    }
`;
const CheckboxLabel = styled.label.withConfig({ displayName: "CheckboxLabel", componentId: "sc-1u5t0kr" }) `
    color: black;
`;
export const TermsOfUse = ({ className, disabled }) => {
    var _a, _b;
    const { state: project } = useContext(ProjectStateContext);
    const { updateTermsOfUse } = useEditableProjectActions();
    const { isValid, message } = useValidation('termsOfUse');
    return (React.createElement(Section, { id: "terms", className: className },
        React.createElement("h2", null, "Set terms of use"),
        React.createElement("p", null, "Indicate the terms of use you prefer, either simply, by checking the appropriate boxes below to show usage types are permitted, or optionally, give more detail by providing a link to your own terms of use document."),
        React.createElement(CheckboxContainer, null,
            React.createElement(TermCheckbox, { id: "redistribution", product: project, updateTermsOfUse: updateTermsOfUse, disabled: !!disabled }),
            React.createElement(TermCheckbox, { id: "commercialUse", product: project, updateTermsOfUse: updateTermsOfUse, disabled: !!disabled }),
            React.createElement(TermCheckbox, { id: "reselling", product: project, updateTermsOfUse: updateTermsOfUse, disabled: !!disabled }),
            React.createElement(TermCheckbox, { id: "storage", product: project, updateTermsOfUse: updateTermsOfUse, disabled: !!disabled })),
        React.createElement(DetailsContainer, null,
            React.createElement("div", null,
                React.createElement(Label, null, "Link to detailed terms"),
                React.createElement(Text, { defaultValue: (_a = project === null || project === void 0 ? void 0 : project.termsOfUse) === null || _a === void 0 ? void 0 : _a.termsUrl, onCommit: (text) => {
                        updateTermsOfUse(Object.assign(Object.assign({}, project.termsOfUse), { termsUrl: text }));
                    }, placeholder: "Add a URL here", disabled: !!disabled, selectAllOnFocus: true, smartCommit: true, invalid: !isValid }),
                !isValid && React.createElement(Errors, { theme: MarketplaceTheme }, message)),
            React.createElement("div", null,
                React.createElement(Label, null, "Display name for link"),
                React.createElement(Text, { defaultValue: (_b = project === null || project === void 0 ? void 0 : project.termsOfUse) === null || _b === void 0 ? void 0 : _b.termsName, onCommit: (text) => {
                        updateTermsOfUse(Object.assign(Object.assign({}, project.termsOfUse), { termsName: text }));
                    }, placeholder: "Add a display name", disabled: !!disabled, selectAllOnFocus: true, smartCommit: true })))));
};
