import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import PaymentRate from '$mp/components/PaymentRate';
import { formatChainName } from '$shared/utils/chains';
import Button from '$shared/components/Button';
import ProjectPng from '$shared/assets/images/project.png';
import { MEDIUM } from '$shared/utils/styled';
import { getConfigForChain } from '$shared/web3/config';
import { timeUnits } from '$shared/utils/timeUnit';
import { getProjectTypeName } from '$app/src/getters';
import { useIsProjectBeingPurchased, usePurchaseCallback, } from '$app/src/shared/stores/purchases';
import { errorToast } from '$utils/toast';
import { isAbandonment } from '$app/src/modals/ProjectModal';
const GetAccessContainer = styled.div.withConfig({ displayName: "GetAccessContainer", componentId: "sc-fcenls" }) `
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 488px;
    margin: 0 auto;
    padding: 15px 0 100px;

    img {
        display: block;
    }

    h1 {
        font-weight: ${MEDIUM};
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 19px;
        text-align: center;
    }

    p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 50px;
        text-align: center;
    }
`;
export default function GetAccess({ projectId, projectName, projectType, salePoints, }) {
    const [firstSalePoint, ...otherSalePoints] = salePoints;
    const count = otherSalePoints.length;
    const purchase = usePurchaseCallback();
    const isBeingPurchased = useIsProjectBeingPurchased(projectId);
    if (!firstSalePoint) {
        return null;
    }
    const { pricePerSecond, chainId, pricingTokenAddress } = firstSalePoint;
    return (React.createElement(React.Fragment, null,
        React.createElement(GetAccessContainer, null,
            React.createElement("img", { src: ProjectPng, alt: "Get access", width: "290", height: "265" }),
            React.createElement("h1", null,
                "Get access to ",
                projectName),
            React.createElement("p", null,
                "The streams in this ",
                getProjectTypeName(projectType),
                " can be accessed for",
                React.createElement("br", null),
                React.createElement("strong", null,
                    React.createElement(PaymentRate, { amount: new BigNumber(pricePerSecond), chainId: chainId, pricingTokenAddress: pricingTokenAddress, timeUnit: timeUnits.hour, tag: "span" })),
                ' ',
                "on ",
                React.createElement("strong", null, formatChainName(getConfigForChain(chainId).name)),
                count > 0 && (React.createElement(React.Fragment, null,
                    "and on ",
                    count,
                    " other chain",
                    count > 1 && 's'))),
            React.createElement(Button, { type: "button", waiting: isBeingPurchased, onClick: async () => {
                    try {
                        await purchase(projectId);
                    }
                    catch (e) {
                        if (isAbandonment(e)) {
                            return;
                        }
                        console.warn('Purchase failed', e);
                        errorToast({
                            title: 'Purchase failed',
                        });
                    }
                } }, "Get access"))));
}
