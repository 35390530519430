import React, { useState, useCallback, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import ModalPortal from '$shared/components/ModalPortal';
import Dialog from '$shared/components/Dialog';
import Slider from '$shared/components/Slider';
import styles from './cropImageModal.pcss';
export const MAX_WIDTH = 1024;
// only width is considered because images returned from the cropper will always be squared
export const getCroppedAndResizedBlob = (imageUrl, cropInfo) => {
    const imageElement = document.createElement('img');
    imageElement.src = imageUrl;
    let canvas = document.createElement('canvas');
    const cropCanvasContext = canvas.getContext('2d');
    const x = Math.round(imageElement.width * cropInfo.x);
    const y = Math.round(imageElement.height * cropInfo.y);
    const width = Math.round(imageElement.width * cropInfo.width);
    const height = Math.round(imageElement.height * cropInfo.height);
    canvas.width = width;
    canvas.height = height;
    cropCanvasContext.drawImage(imageElement, x, y, width, height, 0, 0, width, height);
    if (canvas.width > MAX_WIDTH) {
        const resizedCanvas = document.createElement('canvas');
        const resizedCanvasContext = resizedCanvas.getContext('2d');
        // Start with original image size
        resizedCanvas.width = canvas.width;
        resizedCanvas.height = canvas.height;
        // Draw the original image on the (temp) resizing canvas
        resizedCanvasContext.drawImage(canvas, 0, 0);
        // Quickly reduce the size by 50% each time in few iterations until the size is less then
        // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
        // created with direct reduction of very big image to small image
        while (resizedCanvas.width * 0.5 > MAX_WIDTH) {
            resizedCanvas.width *= 0.5;
            resizedCanvas.height *= 0.5;
            resizedCanvasContext.drawImage(resizedCanvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
        }
        // Now do final resize for the resizingCanvas to meet the dimension requirments
        // directly to the output canvas, that will output the final image
        resizedCanvas.width = MAX_WIDTH;
        resizedCanvas.height = (resizedCanvas.width * canvas.height) / canvas.width;
        resizedCanvasContext.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
        canvas = resizedCanvas;
    }
    return new Promise((resolve) => canvas.toBlob(resolve));
};
const CropImageModal = ({ imageUrl, onClose, onSave: onSaveProp }) => {
    const editorRef = useRef();
    const [sliderValue, setSliderValue] = useState(1);
    const onSave = useCallback(async () => {
        if (editorRef.current) {
            const blob = await getCroppedAndResizedBlob(imageUrl, editorRef.current.getCroppingRect());
            const file = new File([blob], 'coverImage.png');
            onSaveProp(file);
        }
    }, [onSaveProp, editorRef, imageUrl]);
    return (React.createElement(ModalPortal, null,
        React.createElement(Dialog, { title: "Scale and crop your image", onClose: onClose, actions: {
                cancel: {
                    title: 'Cancel',
                    kind: 'link',
                    onClick: onClose,
                },
                save: {
                    title: 'Apply',
                    kind: 'primary',
                    onClick: onSave,
                },
            }, contentClassName: styles.contentArea },
            React.createElement("div", { className: styles.inner },
                React.createElement(AvatarEditor, { ref: editorRef, className: styles.editor, image: imageUrl, width: 528, height: 528, border: [0, 0], borderRadius: 0, color: [255, 255, 255, 0.6], scale: (100 + sliderValue) / 100, rotate: 0 }),
                React.createElement(Slider, { min: 0, max: 200, value: sliderValue, onChange: setSliderValue, className: styles.sliderWrapper, sliderClassname: styles.slider })))));
};
export default CropImageModal;
