var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import Buttons from '$shared/components/Buttons';
import PngIcon from '$shared/components/PngIcon';
import { ethereumNetworks } from '$shared/utils/constants';
import Modal from './Modal';
import { Footer, RejectionReason } from './BaseModal';
function getChainName(chainId) {
    return ethereumNetworks[chainId] || `#${chainId}`;
}
export default function SwitchNetworkModal(_a) {
    var { expectedNetwork, actualNetwork, onReject, onResolve } = _a, props = __rest(_a, ["expectedNetwork", "actualNetwork", "onReject", "onResolve"]);
    return (React.createElement(Modal, Object.assign({}, props, { onReject: onReject, title: "Switch network", onBeforeAbort: (reason) => {
            /**
             * Clicking on the backdrop should not accidentally discard the modal
             * in this case. Enforce intentional cancellation via the Cancel button
             * or the Escape key.
             */
            return reason !== RejectionReason.Backdrop;
        } }),
        React.createElement(Content, null,
            React.createElement(IconWrap, null,
                React.createElement(PngIcon, { name: "wallet", alt: "Switch network" })),
            React.createElement(P, null,
                "Please switch to the ",
                React.createElement("em", null, getChainName(expectedNetwork)),
                " network in your Ethereum wallet. It's currently in",
                ' ',
                React.createElement("em", null, getChainName(actualNetwork)),
                "\u00A0network.")),
        React.createElement(Footer, null,
            React.createElement(Buttons, { actions: {
                    cancel: {
                        title: 'Cancel',
                        onClick: () => void (onReject === null || onReject === void 0 ? void 0 : onReject(RejectionReason.CancelButton)),
                        kind: 'link',
                    },
                    add: {
                        title: 'Switch',
                        kind: 'primary',
                        onClick: () => void (onResolve === null || onResolve === void 0 ? void 0 : onResolve()),
                    },
                } }))));
}
const P = styled.p.withConfig({ displayName: "P", componentId: "sc-1684ixz" }) `
    margin: 0;
    text-align: center;
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-6g2xy7" }) `
    width: 98px;
    height: 67px;
    position: relative;
    margin: 0 auto 24px;

    img {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) translateY(-6%);
    }
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1ygiuk9" }) `
    padding: 64px 48px 40px;

    em {
        font-style: normal;
        font-weight: 500;
    }
`;
