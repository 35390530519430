import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/src/app';
const container = document.getElementById('root');
const root = createRoot(container);
if (container) {
    root.render(React.createElement(App, null));
}
else {
    throw new Error('Root element could not be found.');
}
