import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import isPreventable from '$utils/isPreventable';
import Tabs, { Tab } from '$shared/components/Tabs';
import routes from '$routes';
export default function ProjectLinkTabs({ projectId, disabled = false, }) {
    const { pathname } = useLocation();
    if (!projectId || disabled) {
        return (React.createElement(Tabs, null,
            React.createElement(Tab, { id: "overview", tag: Link, selected: true, to: pathname, onClick: (e) => {
                    if (isPreventable(e)) {
                        e.preventDefault();
                    }
                } }, "Project overview"),
            React.createElement(Tab, { id: "connect", disabled: true }, "Connect"),
            React.createElement(Tab, { id: "liveData", disabled: true }, "Live data")));
    }
    return (React.createElement(Tabs, { selection: pathname },
        React.createElement(Tab, { id: "overview", tag: Link, to: routes.projects.overview({ id: projectId }), selected: "to" }, "Project overview"),
        React.createElement(Tab, { id: "connect", tag: Link, to: routes.projects.connect({ id: projectId }), selected: "to" }, "Connect"),
        React.createElement(Tab, { id: "liveData", tag: Link, to: routes.projects.liveData({ id: projectId }), selected: "to" }, "Live data")));
}
