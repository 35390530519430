import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { toaster } from 'toasterhea';
import Button from '$shared/components/Button';
import NetworkIcon from '$shared/components/NetworkIcon';
import { MEDIUM } from '$shared/utils/styled';
import { getConfigForChain } from '$shared/web3/config';
import { formatChainName } from '$shared/utils/chains';
import useIsMounted from '$shared/hooks/useIsMounted';
import { getCustomTokenBalance, getTokenInformation } from '$mp/utils/web3';
import { getUsdRate } from '$shared/utils/coingecko';
import { Layer } from '$utils/Layer';
import ProjectModal, { Actions } from './ProjectModal';
import ConnectModal from './ConnectModal';
import { RejectionReason } from './BaseModal';
const ChainIcon = styled(NetworkIcon).withConfig({ displayName: "ChainIcon", componentId: "sc-184fxn8" }) `
    width: 40px;
    height: 40px;
`;
const ChainName = styled.span.withConfig({ displayName: "ChainName", componentId: "sc-1r28tm4" }) `
    font-size: 18px;
    line-height: normal;
    font-weight: ${MEDIUM};
`;
const Form = styled.form.withConfig({ displayName: "Form", componentId: "sc-1m845ty" }) `
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;
const Item = styled.label.withConfig({ displayName: "Item", componentId: "sc-q6tnt6" }) `
    align-items: center;
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    display: grid;
    gap: 12px;
    grid-template-columns: auto 1fr auto;
    padding: 20px;
    width: 100%;
    cursor: pointer;
    transition: 200ms border-color;
    user-select: none;

    ${({ $selected = false }) => $selected &&
    css `
            border-color: #b3d4ff;
        `}
`;
const Radio = styled.div.withConfig({ displayName: "Radio", componentId: "sc-1y04al0" }) `
    width: 20px;
    height: 20px;
    border: 2px solid #cdcdcd;
    border-radius: 100%;
    transition: 200ms ease-in-out border-color;

    ::before {
        background: #0324ff;
        border-radius: 100%;
        content: '';
        display: block;
        height: 12px;
        opacity: 0;
        margin: 2px 0 0 2px;
        transform: translateZ(0) scale(0.5);
        transition: 200ms ease-in-out;
        transition-property: transform, opacity;
        width: 12px;
    }

    ${({ $selected = false }) => $selected &&
    css `
            border-color: #0324ff;

            ::before {
                opacity: 1;
                transform: translateZ(0) scale(1);
            }
        `}
`;
const Placeholder = styled.div.withConfig({ displayName: "Placeholder", componentId: "sc-je7xqn" }) `
    align-items: center;
    border-radius: 8px;
    display: flex;
    height: 88px;
    justify-content: center;
    width: 100%;
`;
export async function getPurchasePreconditions({ chainId, paymentDetails, }) {
    const paymentDetail = paymentDetails.find(({ domainId }) => Number(domainId) === chainId);
    if (!paymentDetail) {
        throw new Error('No matching payment detail');
    }
    const { pricingTokenAddress: tokenAddress, pricePerSecond } = paymentDetail;
    const tokenInfo = await getTokenInformation(tokenAddress, chainId);
    if (!tokenInfo) {
        throw new Error('Failed to load token information');
    }
    const account = await toaster(ConnectModal, Layer.Modal).pop();
    if (!account) {
        throw new Error('No account');
    }
    const balance = await getCustomTokenBalance(tokenAddress, account, true, chainId);
    const usdRate = await getUsdRate(tokenAddress, chainId);
    return {
        account,
        balance: balance.toString(),
        chainId,
        pricePerSecond,
        tokenAddress,
        tokenDecimals: String(tokenInfo.decimals),
        tokenSymbol: tokenInfo.symbol,
        usdRate,
    };
}
export default function ProjectChainSelectorModal({ projectId, chainIds = [], selectedChainId: selectedChainIdProp, paymentDetails = [], onReject, onResolve, account, }) {
    const [selectedChainId, selectChainId] = useState(selectedChainIdProp);
    useEffect(() => {
        selectChainId(selectedChainIdProp);
    }, [selectedChainIdProp]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const disabled = !Number.isSafeInteger(selectedChainId) || isSubmitting;
    /**
     * It's safe to use `useIsMounted` here because even if we rerender the component
     * for a different purchase it'll be disabled if there's one already being processed.
     */
    const isMounted = useIsMounted();
    return (React.createElement(ProjectModal, { closeOnEscape: true, onReject: onReject, title: "Select chain for payment token" },
        React.createElement(Form, { onSubmit: async (e) => {
                e.preventDefault();
                if (disabled) {
                    return;
                }
                try {
                    setIsSubmitting(true);
                    if (typeof selectedChainId !== 'number') {
                        throw new Error('No selected chain id');
                    }
                    if (!projectId) {
                        throw new Error('No project id');
                    }
                    if (!account) {
                        throw new Error('No account');
                    }
                    const preconditions = await getPurchasePreconditions({
                        chainId: selectedChainId,
                        paymentDetails,
                    });
                    onResolve === null || onResolve === void 0 ? void 0 : onResolve(preconditions);
                }
                catch (e) {
                    onReject === null || onReject === void 0 ? void 0 : onReject(e);
                }
                finally {
                    if (isMounted()) {
                        setIsSubmitting(false);
                    }
                }
            } },
            chainIds.length ? (React.createElement("ul", null, chainIds.map((chainId) => (React.createElement("li", { key: chainId },
                React.createElement(Item, { "$selected": selectedChainId === chainId, onClick: () => void selectChainId((current) => current === chainId ? undefined : chainId) },
                    React.createElement(ChainIcon, { chainId: chainId }),
                    React.createElement(ChainName, null, formatChainName(getConfigForChain(chainId).name)),
                    React.createElement(Radio, { "$selected": selectedChainId === chainId }))))))) : (React.createElement(Placeholder, null, "No available chains.")),
            React.createElement(Actions, null,
                React.createElement(Button, { kind: "link", type: "button", onClick: () => void (onReject === null || onReject === void 0 ? void 0 : onReject(RejectionReason.CancelButton)) }, "Cancel"),
                React.createElement(Button, { type: "submit", disabled: disabled, waiting: isSubmitting }, "Next")))));
}
