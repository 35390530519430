import React from 'react';
const defaultContext = {
    isModalOpen: false,
    registerModal: undefined,
    unregisterModal: undefined,
};
export const ModalPortalContext = React.createContext(defaultContext);
export class ModalPortalProvider extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isModalOpen: false,
            registerModal: this.registerModal.bind(this),
            unregisterModal: this.unregisterModal.bind(this),
        };
        this.count = 0;
    }
    registerModal() {
        this.count = this.count + 1;
        this.setState({
            isModalOpen: true,
        });
    }
    unregisterModal() {
        this.count = this.count - 1;
        if (this.count < 0) {
            throw new Error('Negative number of open modals. Something went surprisingly wrong.');
        }
        this.setState({
            isModalOpen: this.count !== 0,
        });
    }
    render() {
        const { children } = this.props;
        const { isModalOpen, registerModal, unregisterModal } = this.state;
        return (React.createElement("div", { id: "app" },
            React.createElement(ModalPortalContext.Provider, { value: {
                    isModalOpen,
                    registerModal,
                    unregisterModal,
                } }, children)));
    }
}
export { ModalPortalContext as Context, ModalPortalProvider as Provider };
