import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import BodyClass, { PAGE_SECONDARY } from '$shared/components/BodyClass';
import EmptyState from '$shared/components/EmptyState';
import Layout from '$shared/components/Layout';
import pageNotFoundPic from '$shared/assets/images/404_blocks.png';
import pageNotFoundPic2x from '$shared/assets/images/404_blocks@2x.png';
import Button from '$shared/components/Button';
import routes from '$routes';
import styles from './notFoundPage.pcss';
export function NotFoundPageContent() {
    return (React.createElement(Container, null,
        React.createElement(EmptyState, { image: React.createElement("img", { src: pageNotFoundPic, srcSet: `${pageNotFoundPic2x} 2x`, alt: "Not found" }), link: React.createElement(React.Fragment, null,
                React.createElement(Button, { kind: "special", tag: Link, to: routes.streams.index() }, "Go to streams"),
                React.createElement(Button, { kind: "special", tag: Link, to: routes.projects.index() }, "Go to projects"),
                React.createElement(Button, { kind: "special", tag: Link, to: routes.root() }, "Go to public site")), linkOnMobile: true },
            React.createElement("p", null,
                "Whoops! We don't seem to be able to find your data.",
                React.createElement("br", null),
                React.createElement("small", null, "Something might have been moved around or changed.")))));
}
const NotFoundPage = () => (React.createElement(Layout, { className: styles.notFoundPage },
    React.createElement(BodyClass, { className: PAGE_SECONDARY }),
    React.createElement(NotFoundPageContent, null)));
export default NotFoundPage;
