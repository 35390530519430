import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LogoLink, Navbar, NavbarItem, NavContainer } from '$shared/components/Layout/Nav';
import Logo from '$shared/components/Logo';
import Button from '$shared/components/Button';
import { REGULAR } from '$shared/utils/styled';
import { ProjectControllerContext, ValidationError, } from '$mp/containers/ProjectEditing/ProjectController';
import { RejectionReason } from '$app/src/modals/BaseModal';
import { errorToast } from '$utils/toast';
import isCodedError from '$utils/isCodedError';
import routes from '$routes';
const FlexNavbar = styled(Navbar).withConfig({ displayName: "FlexNavbar", componentId: "sc-lpbbc3" }) `
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const FlexNavbarItem = styled(NavbarItem).withConfig({ displayName: "FlexNavbarItem", componentId: "sc-193lzjr" }) `
    display: flex;
    align-items: center;
    button {
        margin-left: 10px;
    }
    h1 {
        font-size: 18px;
        font-weight: ${REGULAR};
        margin: 0;
        padding-left: 16px;
    }
`;
export function EditorNav({ isNewProject = false, editedProductHasChanged = false, }) {
    const { create, publishInProgress, update } = useContext(ProjectControllerContext);
    return (React.createElement(NavContainer, null,
        React.createElement(FlexNavbar, null,
            React.createElement(FlexNavbarItem, null,
                React.createElement(LogoLink, { href: routes.root() },
                    React.createElement(Logo, null)),
                React.createElement("h1", null,
                    isNewProject ? 'Creating' : 'Edit',
                    " a project")),
            React.createElement(FlexNavbarItem, null,
                React.createElement(Button, { tag: Link, to: routes.projects.index(), kind: 'transparent' }, "Exit"),
                React.createElement(Button, { disabled: publishInProgress ||
                        (!isNewProject && !editedProductHasChanged), onClick: async () => {
                        try {
                            if (isNewProject) {
                                return void (await create());
                            }
                            await update();
                        }
                        catch (e) {
                            if (e instanceof ValidationError) {
                                return void errorToast({
                                    title: 'Failed to publish',
                                    desc: (React.createElement("ul", null, e.messages.map((message, index) => (React.createElement("li", { key: index }, message))))),
                                });
                            }
                            if (isCodedError(e) && e.code === 4001) {
                                return;
                            }
                            if (e === RejectionReason.Cancel ||
                                e === RejectionReason.EscapeKey) {
                                return;
                            }
                            console.warn('Failed to publish', e);
                        }
                    } }, "Publish")))));
}
