export default class TransactionRejectedError extends Error {
    constructor() {
        super('Transaction rejected by user');
        this.name = 'TransactionRejectedError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TransactionRejectedError);
        }
        Object.setPrototypeOf(this, TransactionRejectedError.prototype);
    }
}
