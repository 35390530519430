import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
const UnstyledRaw = (props) => {
    const Tag = props.href || !props.to ? 'a' : RouterLink;
    return React.createElement(Tag, Object.assign({}, props));
};
const Raw = styled(UnstyledRaw).withConfig({ displayName: "Raw", componentId: "sc-hnjuh3" }) ``;
const Link = styled(Raw).withConfig({ displayName: "Link", componentId: "sc-1mpoe4y" }) `
    &,
    :link,
    :active,
    :focus,
    :hover,
    :visited {
        outline: 0;
        text-decoration: ${({ decorated }) => (decorated ? 'underline' : 'none')};
    }
`;
Object.assign(Link, {
    Raw,
});
export default Link;
