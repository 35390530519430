var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import SearchBar from '$shared/components/SearchBar';
import { ActionBarContainer, CreateProjectButton, DropdownFilters, FiltersBar, FiltersWrap, MobileFilterText, MobileFilterWrap, SearchBarWrap, SelectFieldWrap, } from '$mp/components/ActionBar/actionBar.styles';
import SelectField2 from '$mp/components/SelectField2';
import MobileFilter from '$shared/components/MobileFilter';
import { ProjectListingTypeFilter } from '$app/src/services/projects';
import Tabs, { Tab } from '$shared/components/Tabs';
import { isValidSearchQuery } from '../../utils/validate';
var ProjectsScope;
(function (ProjectsScope) {
    ProjectsScope["Any"] = "any";
    ProjectsScope["Owned"] = "owned";
})(ProjectsScope || (ProjectsScope = {}));
const productTypeOptions = [
    {
        value: ProjectListingTypeFilter.openData,
        label: 'Open data',
    },
    {
        value: ProjectListingTypeFilter.paidData,
        label: 'Paid data',
    },
    {
        value: ProjectListingTypeFilter.dataUnion,
        label: 'Data union',
    },
];
const UnstyledActionBar = (_a) => {
    var { filter, categories, onCreateProject, onFilterChange: onFilterChangeProp, onSearchChange: onSearchChangeProp, onFilterByAuthorChange, isUserAuthenticated } = _a, props = __rest(_a, ["filter", "categories", "onCreateProject", "onFilterChange", "onSearchChange", "onFilterByAuthorChange", "isUserAuthenticated"]);
    const onSearchChange = useCallback((search) => {
        if (isValidSearchQuery(search)) {
            onSearchChangeProp(search);
        }
    }, [onSearchChangeProp]);
    const onProductTypeChange = useCallback((type) => {
        onFilterChangeProp({
            type,
        });
    }, [onFilterChangeProp]);
    const { categories: category, type } = filter;
    const handleMobileFilterChange = (filters) => {
        onProductTypeChange(filters.type);
    };
    const [scope, setScope] = useState(ProjectsScope.Any);
    return (React.createElement(ActionBarContainer, Object.assign({}, props),
        React.createElement(SearchBarWrap, null,
            React.createElement(SearchBar, { value: filter.search, onChange: onSearchChange })),
        React.createElement(FiltersBar, null,
            React.createElement(FiltersWrap, null,
                React.createElement(Tabs, { selection: scope, onSelectionChange: (id) => {
                        onFilterByAuthorChange(id === ProjectsScope.Owned);
                        setScope(id);
                    } },
                    React.createElement(Tab, { id: ProjectsScope.Any }, "All projects"),
                    React.createElement(Tab, { id: ProjectsScope.Owned, disabled: !isUserAuthenticated, title: isUserAuthenticated
                            ? undefined
                            : 'You need to be connected in to view your projects' }, "Your projects")),
                React.createElement(DropdownFilters, null,
                    React.createElement("span", null, "Filter by"),
                    React.createElement(SelectFieldWrap, null,
                        React.createElement(SelectField2, { placeholder: 'Project type', options: productTypeOptions, value: type, onChange: onProductTypeChange }))),
                React.createElement(MobileFilterWrap, null,
                    React.createElement(MobileFilter, { filters: [
                            {
                                label: 'Project type',
                                value: 'type',
                                options: productTypeOptions,
                            },
                        ], onChange: handleMobileFilterChange, selectedFilters: { category, type } },
                        React.createElement(MobileFilterText, null, "Filter")))),
            React.createElement(CreateProjectButton, { kind: 'primary', type: 'button', onClick: () => onCreateProject() }, "Create project"))));
};
const ActionBar = styled(UnstyledActionBar).withConfig({ displayName: "ActionBar", componentId: "sc-1vxsbno" }) ``;
export default ActionBar;
