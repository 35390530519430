import { useCallback, useEffect, useRef } from 'react';
import { useClient } from 'streamr-client-react';
import useInterrupt from '$shared/hooks/useInterrupt';
import { useWalletAccount } from '$shared/stores/wallet';
export default function useFetchStreams() {
    const client = useClient();
    const itp = useInterrupt();
    const searchRef = useRef();
    const allowPublicRef = useRef();
    const onlyCurrentUserRef = useRef();
    const iteratorRef = useRef();
    const tailStreamRef = useRef();
    const account = useWalletAccount();
    useEffect(() => {
        itp().interruptAll();
    }, [itp, client]);
    return useCallback(async (search, { batchSize = 1, allowPublic = false, onlyCurrentUser = true } = {}, resetSearch = false) => {
        const { requireUninterrupted } = itp(search);
        if (searchRef.current !== search ||
            allowPublicRef.current !== allowPublic ||
            onlyCurrentUserRef.current !== onlyCurrentUser ||
            resetSearch) {
            searchRef.current = search;
            allowPublicRef.current = allowPublic;
            onlyCurrentUserRef.current = onlyCurrentUser;
            iteratorRef.current = undefined;
        }
        if (typeof iteratorRef.current === 'undefined') {
            requireUninterrupted();
            tailStreamRef.current = undefined;
            if (onlyCurrentUserRef.current) {
                const user = account;
                if (!user) {
                    return [[], false, false];
                }
                iteratorRef.current = client.searchStreams(search, {
                    user,
                    allowPublic,
                });
            }
            else {
                iteratorRef.current = client.searchStreams(search, undefined);
            }
        }
        let i = 0;
        const batch = [];
        let hasMore = false;
        const { current: iterator } = iteratorRef;
        // We load 1 extra entry to determine if we show "Load more" button.
        const effectiveBatchSize = batchSize + Number(!tailStreamRef.current);
        while (i < effectiveBatchSize) {
            // eslint-disable-next-line no-await-in-loop
            const { value, done } = await iterator.next();
            requireUninterrupted();
            hasMore = !done;
            if (value) {
                batch.push(value);
            }
            if (done) {
                break;
            }
            i += 1;
        }
        const prevTailStream = tailStreamRef.current;
        if (batch.length === effectiveBatchSize) {
            tailStreamRef.current = batch.pop();
        }
        if (prevTailStream) {
            return [[prevTailStream, ...batch], hasMore, false];
        }
        return [batch, hasMore, !prevTailStream];
    }, [itp, client, account]);
}
