import React, { createContext, useContext, useEffect, useState, } from 'react';
import { useParams } from 'react-router-dom';
import { getProject } from '$app/src/services/projects';
import { mapGraphProjectToDomainModel } from '$mp/utils/project-mapper';
const useLoadedProjectImplementation = (projectId) => {
    const [project, setProject] = useState();
    const [mappedProject, setMappedProject] = useState();
    useEffect(() => {
        const loadData = async () => {
            const theGraphProject = await getProject(projectId);
            setProject(theGraphProject);
            setMappedProject(await mapGraphProjectToDomainModel(theGraphProject));
        };
        try {
            loadData();
        }
        catch (e) {
            console.error(e);
        }
    }, [projectId]);
    return {
        loadedProject: mappedProject,
        theGraphProject: project,
    };
};
export const LoadedProjectContext = createContext(null);
export const LoadedProjectContextProvider = ({ children, }) => {
    const params = useParams();
    return (React.createElement(LoadedProjectContext.Provider, { value: useLoadedProjectImplementation(params.id) }, children));
};
export const useLoadedProject = () => {
    return useContext(LoadedProjectContext);
};
