import { useContext, useMemo } from 'react';
import { Context as ModalContext } from '$shared/contexts/ModalApi';
export default function useModal(modalId) {
    const { modals, openModal, closeModal } = useContext(ModalContext);
    const isOpen = !!modals[modalId];
    const value = isOpen && modals[modalId].value;
    const api = useMemo(() => ({
        open: (values) => openModal(modalId, values),
        close: (values) => closeModal(modalId, values),
    }), [openModal, closeModal, modalId]);
    return useMemo(() => ({
        modalId,
        isOpen,
        api,
        value,
    }), [value, modalId, isOpen, api]);
}
