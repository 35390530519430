export default class WalletLockedError extends Error {
    constructor() {
        super('Please unlock your wallet');
        this.name = 'WalletLockedError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, WalletLockedError);
        }
        Object.setPrototypeOf(this, WalletLockedError.prototype);
    }
}
