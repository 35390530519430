var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import classNames from 'classnames';
import Buttons from '$shared/components/Buttons';
import ModalDialog from '$shared/components/ModalDialog';
import { dialogAutoCloseTimeout } from '$shared/utils/constants';
import LoadingIndicator from '$shared/components/LoadingIndicator';
import Container from './Container';
import TitleBar from './TitleBar';
import ContentArea from './ContentArea';
import HelpToggle from './HelpToggle';
import styles from './dialog.pcss';
class Dialog extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            isHelpOpen: false,
        };
        this.onHelpToggle = () => {
            this.setState({
                isHelpOpen: !this.state.isHelpOpen,
            });
        };
        this.clearCloseTimeout = () => {
            if (this.autoCloseTimeoutId) {
                clearTimeout(this.autoCloseTimeoutId);
                this.autoCloseTimeoutId = null;
            }
        };
        this.autoCloseTimeoutId = null;
    }
    componentDidMount() {
        const { autoCloseAfter, autoClose, onClose } = this.props;
        const timeout = autoCloseAfter || (autoClose && dialogAutoCloseTimeout) || null;
        if (timeout != null) {
            this.clearCloseTimeout();
            this.autoCloseTimeoutId = setTimeout(onClose, timeout);
        }
    }
    componentDidUpdate(prevProps) {
        const { autoCloseAfter, autoClose, onClose } = this.props;
        const timeout = autoCloseAfter || (autoClose && dialogAutoCloseTimeout) || null;
        if (prevProps.autoCloseAfter !== timeout && timeout != null) {
            this.clearCloseTimeout();
            this.autoCloseTimeoutId = setTimeout(onClose, timeout);
        }
    }
    componentWillUnmount() {
        this.clearCloseTimeout();
    }
    render() {
        const _a = this.props, { title, children, waiting, disabled, helpText, actions, className, contentClassName, containerClassname, actionsClassName, backdropClassName, useDarkBackdrop, titleClassName, centerTitle, onClose, showCloseIcon, renderActions } = _a, otherProps = __rest(_a, ["title", "children", "waiting", "disabled", "helpText", "actions", "className", "contentClassName", "containerClassname", "actionsClassName", "backdropClassName", "useDarkBackdrop", "titleClassName", "centerTitle", "onClose", "showCloseIcon", "renderActions"]);
        const { isHelpOpen } = this.state;
        return (React.createElement(ModalDialog, Object.assign({ className: classNames(styles.dialog, className), backdropClassName: classNames(styles.backdrop, backdropClassName, useDarkBackdrop ? styles.darkBackdrop : null), onClose: () => onClose && onClose() }, otherProps),
            React.createElement(Container, { className: classNames(styles.container, containerClassname) },
                React.createElement(TitleBar, { showCloseIcon: showCloseIcon, onClose: onClose, className: classNames(styles.title, titleClassName, centerTitle ? styles.center : null) },
                    title,
                    !!helpText && (React.createElement(HelpToggle, { active: isHelpOpen, onToggle: this.onHelpToggle }))),
                (!helpText || !isHelpOpen) && !!waiting && (React.createElement(LoadingIndicator, { loading: true })),
                React.createElement(ContentArea, { className: classNames(styles.content, contentClassName) },
                    (!helpText || !isHelpOpen) && !waiting && children,
                    !!helpText && isHelpOpen && helpText),
                !waiting &&
                    (!helpText || !this.state.isHelpOpen) &&
                    !renderActions && (React.createElement(Buttons, { className: classNames(styles.buttons, actionsClassName), actions: actions })),
                !waiting &&
                    (!helpText || !this.state.isHelpOpen) &&
                    renderActions &&
                    renderActions(actions || {})),
            !!disabled && React.createElement("div", { className: styles.disabledModal })));
    }
}
Dialog.defaultProps = {
    title: '',
    helpText: null,
    waiting: false,
    autoClose: false,
    useDarkBackdrop: false,
    centerTitle: false,
};
Dialog.classNames = {
    dialog: styles.dialog,
    backdrop: styles.backdrop,
    container: styles.container,
    title: styles.title,
    content: styles.content,
    buttons: styles.buttons,
};
export default Dialog;
