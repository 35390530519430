import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ProjectDraftContext, useInitProject } from '$shared/stores/projectEditor';
import NotFoundPage from '$shared/components/NotFoundPage';
import routes from '$routes';
import TabbedPage from './TabbedPage';
import ProjectEditorPage from './ProjectEditorPage';
function NewProjectPage() {
    throw new Error('Not implemented.');
}
function ProjectRedirect() {
    const { id } = useParams();
    return React.createElement(Navigate, { to: routes.projects.overview({ id }), replace: true });
}
export default function ProjectPage() {
    const { id: projectId = 'new' } = useParams();
    return (React.createElement(ProjectDraftContext.Provider, { value: useInitProject(projectId === 'new' ? undefined : decodeURIComponent(projectId)) },
        React.createElement(Routes, null,
            React.createElement(Route, { path: routes.projects.new(), element: React.createElement(NewProjectPage, null), key: "NewProjectPage" }),
            React.createElement(Route, { path: routes.projects.edit(), element: React.createElement(ProjectEditorPage, null), key: "EditProjectPage" }),
            React.createElement(Route, { index: true, element: React.createElement(ProjectRedirect, null), key: "ProjectRedirect" }),
            React.createElement(Route, { path: "overview", element: React.createElement(TabbedPage, { tab: "overview" }) }),
            React.createElement(Route, { path: "connect", element: React.createElement(TabbedPage, { tab: "connect" }) }),
            React.createElement(Route, { path: "live-data", element: React.createElement(TabbedPage, { tab: "live-data" }) }),
            React.createElement(Route, { path: "*", element: React.createElement(NotFoundPage, null) }))));
}
