import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DESKTOP, TABLET, LAPTOP } from '$shared/utils/styled';
import dataUnionImage2x from '$mp/assets/product_dataunion@2x.png';
import { getProjects } from '$app/src/services/projects';
import Button from '$shared/components/Button';
import useModal from '$shared/hooks/useModal';
import CreateProjectModal from '$mp/containers/CreateProjectModal';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-rz4k5p" }) `
    padding: 76px 32px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: unset;
    gap: 40px;

    @media (${LAPTOP}) {
        padding: 60px 116px;
        grid-template-rows: unset;
        grid-template-columns: auto auto;
    }

    @media (${DESKTOP}) {
        padding: 165px 125px;
        grid-template-rows: unset;
        grid-template-columns: auto auto;
    }
`;
const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1wfdwj7" }) `
    margin-left: 0;
    display: grid;
    grid-template-rows: auto auto;
    gap: 40px;
    align-content: center;

    @media (${DESKTOP}) {
        margin-left: 112px;
    }
`;
const Text = styled.div.withConfig({ displayName: "Text", componentId: "sc-zh2398" }) `
    display: flex;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    justify-self: center;

    @media (${TABLET}) {
        font-size: 34px;
    }

    @media (${DESKTOP}) {
        text-align: left;
        justify-self: left;
    }
`;
const Image = styled.img.withConfig({ displayName: "Image", componentId: "sc-10xt6t0" }) `
    width: 261px;
    justify-self: center;

    @media (${TABLET}) {
        width: 364px;
    }
`;
const CreateButton = styled(Button).withConfig({ displayName: "CreateButton", componentId: "sc-1w8xypn" }) `
    width: fit-content;
    justify-self: center;

    @media (${DESKTOP}) {
        justify-self: left;
    }
`;
export default function CreateProjectHint({ streamId }) {
    const [projects, setProjects] = useState([]);
    const { api: createProductModal } = useModal('marketplace.createProduct');
    useEffect(() => {
        let mounted = true;
        const loadProjects = async () => {
            const result = await getProjects(undefined, 4, 0, undefined, streamId);
            if (mounted) {
                setProjects(result.projects);
            }
        };
        if (streamId) {
            loadProjects();
        }
        return () => {
            mounted = false;
        };
    }, [streamId]);
    // If the stream already has a project, show nothing
    if (projects.length > 0) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(Image, { src: dataUnionImage2x, alt: "Create a project" }),
        React.createElement(TextContainer, null,
            React.createElement(Text, null, "Make your stream more discoverable by creating a project."),
            React.createElement(CreateButton, { onClick: () => createProductModal.open() }, "Create a project")),
        React.createElement(CreateProjectModal, null)));
}
