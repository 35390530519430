import React, { createContext, useContext, useMemo, } from 'react';
import { useStateContainer } from '$shared/hooks/useStateContainer';
import { EmptyProject } from '../utils/empty-project';
export const ProjectStateContext = createContext(null);
export const ProjectStateContextProvider = ({ children, initState }) => {
    const defaultState = useMemo(() => new EmptyProject(), []);
    return (React.createElement(ProjectStateContext.Provider, { value: useStateContainer(initState || defaultState) }, children));
};
export const useProjectState = () => {
    return useContext(ProjectStateContext);
};
