import React, { useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import styled from 'styled-components';
import { COLORS } from '$shared/utils/styled';
import NetworkIcon from '$shared/components/NetworkIcon';
import { Radio } from '$shared/components/Radio';
import { DataUnionChainSelectorContext } from '$mp/containers/ProjectEditing/DataUnionChainSelector/DataUnionChainSelectorContext';
import useIsMounted from '$shared/hooks/useIsMounted';
import { getDataUnionObject, getDataUnionsOwnedByInChain, } from '$mp/modules/dataUnion/services';
import SelectField2 from '$mp/components/SelectField2';
import { useWalletAccount } from '$app/src/shared/stores/wallet';
import { truncate } from '$shared/utils/text';
import { formatChainName } from '$shared/utils/chains';
export const DataUnionChainOption = ({ index, chain, disabled = false, onChange, }) => {
    const isMounted = useIsMounted();
    const account = useWalletAccount();
    const [ownedDataUnions, setOwnedDataUnions] = useState([]);
    const existingDUOptions = useMemo(() => ownedDataUnions.map((du) => ({
        label: truncate(du.name || du.id),
        value: du.id,
    })), [ownedDataUnions]);
    const [currentlySelectedOption] = useContext(DataUnionChainSelectorContext);
    const isSelected = useMemo(() => currentlySelectedOption === index, [currentlySelectedOption, index]);
    const [deployNewDU, setDeployNewDU] = useState(undefined);
    const [existingDUAddress, setExistingDUAddress] = useState(undefined);
    useEffect(() => {
        const load = async () => {
            if (account && chain.id) {
                const dataUnionsWithMetadata = [];
                const dataUnionsOwned = await getDataUnionsOwnedByInChain(account, chain.id);
                for (const du of dataUnionsOwned) {
                    try {
                        let duWithMetadata = du;
                        const duObj = await getDataUnionObject(du.id, chain.id);
                        const metadata = await duObj.getMetadata();
                        if (typeof metadata === 'object') {
                            duWithMetadata = Object.assign(Object.assign({}, du), { name: metadata.name });
                        }
                        dataUnionsWithMetadata.push(duWithMetadata);
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                if (isMounted()) {
                    setOwnedDataUnions(dataUnionsWithMetadata);
                }
            }
        };
        load();
    }, [chain, account, isMounted]);
    const handleSelect = useCallback(() => {
        if (currentlySelectedOption === index) {
            return;
        }
        onChange({
            deployNewDU: true,
            existingDUAddress: undefined,
        });
        setExistingDUAddress(undefined);
        setDeployNewDU(true);
    }, [currentlySelectedOption, index, onChange]);
    const handleExistingDUSelect = useCallback((dataUnionAddress) => {
        setDeployNewDU(false);
        if (dataUnionAddress) {
            setExistingDUAddress(dataUnionAddress);
        }
        onChange({
            deployNewDU: false,
            existingDUAddress: dataUnionAddress,
        });
    }, [onChange]);
    const handleSelectNewDU = useCallback(() => {
        setExistingDUAddress(undefined);
        setDeployNewDU(true);
        onChange({
            deployNewDU: true,
            existingDUAddress: undefined,
        });
    }, [onChange]);
    return (React.createElement(DropdownWrap, null,
        React.createElement(DropdownToggle, { onClick: handleSelect, className: isSelected ? 'is-open' : '' },
            React.createElement("label", null,
                React.createElement(StyledRadio, { name: 'data-union-chain-option', label: '', value: '', checked: isSelected, disabled: disabled, onChange: () => {
                        handleSelect();
                    } })),
            React.createElement(ChainIcon, { chainId: chain.id }),
            React.createElement("span", null, formatChainName(chain.name))),
        React.createElement(DropdownOuter, { className: isSelected ? 'is-open' : '' },
            React.createElement(DropdownInner, { className: isSelected ? 'is-open' : '' },
                React.createElement(Heading, null, "Deployment"),
                React.createElement(SubHeading, null, "You can deploy a new Data Union smart contract, or connect an existing one."),
                React.createElement(DeploymentSelectContainer, null,
                    React.createElement(DeploymentSelectorRadioContainer, null,
                        React.createElement(StyledRadio, { name: `${chain.name}-deployment`, value: 'new', label: 'Deploy a new Data Union', checked: deployNewDU === true, onChange: handleSelectNewDU })),
                    React.createElement(DeploymentSelectorRadioContainer, null,
                        React.createElement(StyledRadio, { name: `${chain.name}-deployment`, value: 'existing', label: 'Connect an existing Data Union', checked: deployNewDU === false, onChange: () => {
                                handleExistingDUSelect();
                            }, className: 'with-margin', disabled: existingDUOptions.length === 0 }),
                        React.createElement(SelectField2, { placeholder: existingDUOptions.length
                                ? 'Select a Data Union'
                                : "You don't have any deployed Data Unions", options: existingDUOptions, disabled: deployNewDU === true || !existingDUOptions.length, fullWidth: true, value: existingDUAddress, isClearable: false, onChange: (selectedDU) => {
                                handleExistingDUSelect(selectedDU);
                            } })))))));
};
const Heading = styled.p.withConfig({ displayName: "Heading", componentId: "sc-10mfm7o" }) `
    font-size: 20px;
    line-height: 34px;
`;
const SubHeading = styled.p.withConfig({ displayName: "SubHeading", componentId: "sc-lqs8f7" }) `
    font-size: 16px;
`;
const DropdownWrap = styled.div.withConfig({ displayName: "DropdownWrap", componentId: "sc-yx88pf" }) `
    overflow: hidden;
    box-shadow: 0 1px 2px 0 #00000026, 0 0 1px 0 #00000040;
    border-radius: 4px;
    margin-bottom: 24px;
    color: ${COLORS.primary};

    &:last-of-type {
        margin-bottom: 0;
    }
`;
const DropdownOuter = styled.div.withConfig({ displayName: "DropdownOuter", componentId: "sc-1gvahsz" }) `
    overflow: hidden;
`;
const DropdownInner = styled.div.withConfig({ displayName: "DropdownInner", componentId: "sc-jhwfg0" }) `
    padding: 24px;
    transition: margin-bottom 0.5s ease-in-out;
    margin-bottom: -200%;

    &.is-open {
        margin-bottom: 0;
    }
`;
const DropdownToggle = styled.div.withConfig({ displayName: "DropdownToggle", componentId: "sc-514eys" }) `
    padding: 12px 24px 12px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover {
        background-color: ${COLORS.secondary};
    }
    &.is-open:hover {
        background-color: inherit;
    }

    label {
        display: flex;
        padding: 12px;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
    }
`;
const StyledRadio = styled(Radio).withConfig({ displayName: "StyledRadio", componentId: "sc-7rn13m" }) `
    cursor: pointer;
    &.with-margin {
        margin-bottom: 24px;
    }
`;
const ChainIcon = styled(NetworkIcon).withConfig({ displayName: "ChainIcon", componentId: "sc-4iueu9" }) `
    width: 32px;
    height: 32px;
    margin-right: 12px;
`;
const DeploymentSelectContainer = styled.div.withConfig({ displayName: "DeploymentSelectContainer", componentId: "sc-k418i3" }) `
    background-color: ${COLORS.inputBackground};
    padding: 24px;
`;
const DeploymentSelectorRadioContainer = styled.div.withConfig({ displayName: "DeploymentSelectorRadioContainer", componentId: "sc-1cdidy1" }) `
    background-color: white;
    padding: 24px;
    margin-bottom: 16px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;
