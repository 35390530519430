import React, { useCallback, useContext, useMemo, } from 'react';
import styled from 'styled-components';
import { REGULAR } from '$shared/utils/styled';
import getCoreConfig from '$app/src/getters/getCoreConfig';
import { DataUnionChainSelectorContext, DataUnionChainSelectorContextProvider, } from '$mp/containers/ProjectEditing/DataUnionChainSelector/DataUnionChainSelectorContext';
import { getConfigForChainByName } from '$shared/web3/config';
import { DataUnionChainOption } from '$mp/containers/ProjectEditing/DataUnionChainSelector/DataUnionChainOption';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
const getChainOptions = (chains) => chains.map((c) => getConfigForChainByName(c));
const DUChainSelector = ({ editMode }) => {
    const [currentlySelectedIndex, setCurrentlySelectedIndex] = useContext(DataUnionChainSelectorContext);
    const { dataunionChains } = getCoreConfig();
    const chainOptions = useMemo(() => {
        return getChainOptions(dataunionChains);
    }, [dataunionChains]);
    const { updateDataUnionChainId, updateExistingDUAddress, updateIsDeployingNewDU } = useEditableProjectActions();
    const handleDataUnionOptionChange = useCallback((index, chain, deployNewDU, existingDUAddress) => {
        if (currentlySelectedIndex !== index) {
            setCurrentlySelectedIndex(index);
        }
        if (!editMode) {
            updateIsDeployingNewDU(deployNewDU);
        }
        updateDataUnionChainId(chain.id);
        updateExistingDUAddress(deployNewDU ? undefined : existingDUAddress);
    }, [
        currentlySelectedIndex,
        editMode,
        setCurrentlySelectedIndex,
        updateDataUnionChainId,
        updateExistingDUAddress,
        updateIsDeployingNewDU,
    ]);
    return (React.createElement(Container, null,
        React.createElement(Heading, null, "Select chain"),
        React.createElement(SubHeading, null, "Select the chain for your Data Union."),
        chainOptions &&
            chainOptions.length &&
            chainOptions.map((chain, index) => {
                return (React.createElement(DataUnionChainOption, { key: index, index: index, chain: chain, onChange: (chainSelection) => {
                        handleDataUnionOptionChange(index, chain, chainSelection.deployNewDU, chainSelection.existingDUAddress);
                    } }));
            })));
};
export const DataUnionChainSelector = ({ editMode, }) => {
    return (React.createElement(DataUnionChainSelectorContextProvider, null,
        React.createElement(DUChainSelector, { editMode: editMode })));
};
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1keke60" }) `
    max-width: 728px;
`;
const Heading = styled.p.withConfig({ displayName: "Heading", componentId: "sc-126gvfy" }) `
    color: black;
    font-weight: ${REGULAR};
    font-size: 34px;
    margin-bottom: 44px;
`;
const SubHeading = styled.p.withConfig({ displayName: "SubHeading", componentId: "sc-13ygw3n" }) `
    font-size: 16px;
    margin-bottom: 24px;
    color: black;
`;
