var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { REGULAR, COLORS } from '$shared/utils/styled';
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-2b7njm" }) `
    margin-bottom: 60px;
`;
const Title = styled.h3.withConfig({ displayName: "Title", componentId: "sc-17yc6dn" }) `
    color: ${COLORS.primary};
    font-size: 24px;
    font-weight: ${REGULAR};
    letter-spacing: 0;
    line-height: 150%;
    margin-bottom: 16px;
`;
const TitleWrapper = styled.div.withConfig({ displayName: "TitleWrapper", componentId: "sc-1h13yl" }) `
    display: inline-flex;
    align-items: center;
    width: 100%;
`;
const TitleText = styled.span.withConfig({ displayName: "TitleText", componentId: "sc-floppi" }) `
    margin-right: 16px;
`;
export default function Section(_a) {
    var { title, status, children } = _a, props = __rest(_a, ["title", "status", "children"]);
    return (React.createElement(Root, Object.assign({}, props),
        React.createElement(Title, null,
            React.createElement(TitleWrapper, null,
                React.createElement(TitleText, null, title),
                !!status && React.createElement("span", null, status))),
        children));
}
