import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import BodyClass, { PAGE_SECONDARY } from '$shared/components/BodyClass';
import EmptyState from '$shared/components/EmptyState';
import Layout from '$shared/components/Layout';
import appCrashedImage from '$shared/assets/images/app_crashed.png';
import appCrashedImage2x from '$shared/assets/images/app_crashed@2x.png';
import Button from '$shared/components/Button';
import routes from '$routes';
import styles from './genericErrorPage.pcss';
export function GenericErrorPageContent() {
    return (React.createElement(Container, null,
        React.createElement(EmptyState, { image: React.createElement("img", { className: styles.image, src: appCrashedImage, srcSet: `${appCrashedImage2x} 2x`, alt: "App crashed" }), link: React.createElement(Button, { kind: "special", tag: Link, to: routes.projects.index(), className: "d-none d-md-flex" }, "Projects"), linkOnMobile: true },
            React.createElement("p", null,
                "Oops. Something has broken down here.",
                React.createElement("br", null),
                "Please try one of the links below",
                React.createElement("br", null),
                "to get things back on track."))));
}
const GenericErrorPage = () => (React.createElement(Layout, { className: styles.genericErrorPage },
    React.createElement(BodyClass, { className: PAGE_SECONDARY }),
    React.createElement(GenericErrorPageContent, null)));
export default GenericErrorPage;
