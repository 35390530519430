import getCoreConfig from '$app/src/getters/getCoreConfig';
import address0 from '$utils/address0';
import { post } from '$shared/utils/api';
const getGraphUrl = () => {
    const { theGraphUrl, streamsGraphName } = getCoreConfig();
    return `${theGraphUrl}/subgraphs/name/${streamsGraphName}`;
};
export var TheGraphOrderBy;
(function (TheGraphOrderBy) {
    TheGraphOrderBy["Id"] = "id";
    TheGraphOrderBy["CreatedAt"] = "createdAt";
    TheGraphOrderBy["UpdatedAt"] = "updatedAt";
})(TheGraphOrderBy || (TheGraphOrderBy = {}));
export var TheGraphOrderDirection;
(function (TheGraphOrderDirection) {
    TheGraphOrderDirection["Asc"] = "asc";
    TheGraphOrderDirection["Desc"] = "desc";
})(TheGraphOrderDirection || (TheGraphOrderDirection = {}));
const calculatePubSubCount = (permissions) => {
    let publisherCount = 0;
    let subscriberCount = 0;
    permissions.forEach((perm) => {
        // If stream has public permissions (zero address), return null for counts which means that anyone can
        // publish or subscribe
        if (perm.userAddress === address0) {
            if (perm.subscribeExpiration >= Math.round(Date.now() / 1000)) {
                subscriberCount = null;
            }
            if (perm.publishExpiration >= Math.round(Date.now() / 1000)) {
                publisherCount = null;
            }
        }
        if (perm.subscribeExpiration >= Math.round(Date.now() / 1000) &&
            subscriberCount != null) {
            subscriberCount += 1;
        }
        if (perm.publishExpiration >= Math.round(Date.now() / 1000) &&
            publisherCount != null) {
            publisherCount += 1;
        }
    });
    return {
        publisherCount,
        subscriberCount,
    };
};
const mapStream = (stream) => {
    const result = Object.assign({}, stream);
    // Get publisher and subscriber counts
    const counts = calculatePubSubCount(stream.permissions);
    result.publisherCount = counts.publisherCount;
    result.subscriberCount = counts.subscriberCount;
    // Try to parse metadata JSON
    if (stream.metadata != null) {
        try {
            const metadata = JSON.parse(stream.metadata);
            result.metadata = metadata;
        }
        catch (e) {
            console.error(`Could not parse metadata for stream ${stream.id}`, e);
            result.metadata = {};
        }
    }
    return result;
};
const prepareStreamResult = (result, pageSize) => {
    var _a;
    let hasNextPage = false;
    const streams = result.map((p) => mapStream(p));
    if (streams.length > pageSize) {
        hasNextPage = true;
        // Remove last item
        streams.splice(pageSize, 1);
    }
    return {
        streams,
        hasNextPage,
        lastId: (_a = streams[streams.length - 1]) === null || _a === void 0 ? void 0 : _a.id,
    };
};
export const getStreams = async (streamIds) => {
    const theGraphUrl = getGraphUrl();
    const result = await post({
        url: theGraphUrl,
        data: {
            query: `
                {
                    streams(
                        where: { id_in: [${streamIds.map((s) => `"${s}"`).join(',')}] }
                    ) {
                        id
                        metadata
                        permissions {
                            userAddress
                            canEdit
                            canGrant
                            canDelete
                            subscribeExpiration
                            publishExpiration
                        }
                    }
                }
            `,
        },
    });
    if (result && result.data && result.data.streams && result.data.streams.length > 0) {
        return result.data.streams.map((s) => mapStream(s));
    }
    return [];
};
export const getPagedStreams = async (first, lastId, owner, search, orderBy, orderDirection) => {
    var _a, _b;
    const theGraphUrl = getGraphUrl();
    // NOTE: Stream name fulltext search is done through subentity "permissions" because we cannot
    // use "id_contains" in query as it's not technically stored as a string on The Graph.
    const searchFilter = search != null && search.length > 0 ? `stream_contains: "${search}"` : null;
    const ownerFilter = owner != null ? `userAddress: "${owner.toLowerCase()}", canGrant: true` : null;
    const allPermissionFilters = [ownerFilter, searchFilter]
        .filter((filter) => !!filter)
        .join(',');
    const permissionFilter = allPermissionFilters.length > 0 && `permissions_: { ${allPermissionFilters} }`;
    const comparisonOperator = orderDirection === TheGraphOrderDirection.Asc ? 'gt' : 'lt';
    const cursorFilter = lastId != null ? `id_${comparisonOperator}: "${lastId}"` : null;
    const allFilters = [cursorFilter, permissionFilter]
        .filter((filter) => !!filter)
        .join(',');
    const result = await post({
        url: theGraphUrl,
        data: {
            query: `
                {
                    streams(
                        first: ${first + 1},
                        orderBy: ${(_a = orderBy === null || orderBy === void 0 ? void 0 : orderBy.toString()) !== null && _a !== void 0 ? _a : TheGraphOrderBy.Id.toString()},
                        orderDirection: ${(_b = orderDirection === null || orderDirection === void 0 ? void 0 : orderDirection.toString()) !== null && _b !== void 0 ? _b : TheGraphOrderDirection.Asc.toString()},
                        ${allFilters != null ? `where: { ${allFilters} }` : ''},
                    ) {
                        id
                        metadata
                        permissions {
                            userAddress
                            canEdit
                            canGrant
                            canDelete
                            subscribeExpiration
                            publishExpiration
                        }
                    }
                }
            `,
        },
    });
    if (result && result.data && result.data.streams && result.data.streams.length > 0) {
        return prepareStreamResult(result.data.streams, first);
    }
    return {
        streams: [],
        hasNextPage: false,
        lastId: null,
    };
};
export var IndexerOrderBy;
(function (IndexerOrderBy) {
    IndexerOrderBy["Id"] = "ID";
    IndexerOrderBy["Description"] = "DESCRIPTION";
    IndexerOrderBy["PeerCount"] = "PEER_COUNT";
    IndexerOrderBy["MsgPerSecond"] = "MESSAGES_PER_SECOND";
    IndexerOrderBy["SubscriberCount"] = "SUBSCRIBER_COUNT";
    IndexerOrderBy["PublisherCount"] = "PUBLISHER_COUNT";
})(IndexerOrderBy || (IndexerOrderBy = {}));
export var IndexerOrderDirection;
(function (IndexerOrderDirection) {
    IndexerOrderDirection["Asc"] = "ASC";
    IndexerOrderDirection["Desc"] = "DESC";
})(IndexerOrderDirection || (IndexerOrderDirection = {}));
export const getPagedStreamsFromIndexer = async (first, cursor, owner, search, orderBy, orderDirection) => {
    var _a, _b;
    const { streamIndexerUrl } = getCoreConfig();
    const ownerFilter = owner != null ? `owner: "${owner}"` : null;
    const searchFilter = search != null && search.length > 0 ? `searchTerm: "${search}"` : null;
    const cursorFilter = cursor != null ? `cursor: "${cursor}"` : null;
    const allFilters = [ownerFilter, searchFilter, cursorFilter]
        .filter((filter) => !!filter)
        .join(',');
    const result = await post({
        url: streamIndexerUrl,
        data: {
            query: `
                {
                    streams(
                        pageSize: ${first},
                        orderBy: ${(_a = orderBy === null || orderBy === void 0 ? void 0 : orderBy.toString()) !== null && _a !== void 0 ? _a : IndexerOrderBy.MsgPerSecond.toString()},
                        orderDirection: ${(_b = orderDirection === null || orderDirection === void 0 ? void 0 : orderDirection.toString()) !== null && _b !== void 0 ? _b : IndexerOrderDirection.Desc.toString()},
                        ${allFilters},
                    ) {
                        items {
                          id
                          description
                          peerCount
                          messagesPerSecond
                          subscriberCount
                          publisherCount
                        }
                        cursor
                    }
                }
            `,
        },
        options: {
            timeout: 2000, // apply timeout so we fall back to using The Graph faster
        },
    });
    const resultObj = result.data.streams;
    if (resultObj) {
        return {
            streams: resultObj.items,
            cursor: resultObj.cursor,
            hasNextPage: resultObj.cursor != null,
        };
    }
    return {
        streams: [],
        cursor: null,
        hasNextPage: false,
    };
};
export const getStreamsFromIndexer = async (streamIds) => {
    const { streamIndexerUrl } = getCoreConfig();
    if (streamIds == null || streamIds.length === 0) {
        return [];
    }
    const result = await post({
        url: streamIndexerUrl,
        data: {
            query: `
                {
                    streams(ids: [${streamIds.map((s) => `"${s}"`).join(',')}]) {
                        items {
                          id
                          description
                          peerCount
                          messagesPerSecond
                          subscriberCount
                          publisherCount
                        }
                    }
                }
            `,
        },
    });
    return result.data.streams.items;
};
export const getGlobalStatsFromIndexer = async () => {
    const { streamIndexerUrl } = getCoreConfig();
    const result = await post({
        url: streamIndexerUrl,
        data: {
            query: `
                {
                    summary {
                        streamCount
                        messagesPerSecond
                    }
                }
            `,
        },
    });
    return result.data.summary;
};
export const getStreamsOwnedBy = async (owner, search, onlyPublic) => {
    const allOwnedStreams = await getPagedStreams(999, undefined, owner, search, TheGraphOrderBy.Id, TheGraphOrderDirection.Asc);
    let result = allOwnedStreams.streams;
    if (onlyPublic) {
        result = result.filter((s) => s.permissions.find((p) => p.userAddress.toLowerCase() === address0.toLowerCase()) != null);
    }
    return result;
};
