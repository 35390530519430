import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '$shared/components/Button';
import { useProjectState } from '$mp/contexts/ProjectStateContext';
import routes from '$routes';
import { useProjectController } from './ProjectController';
const Title = styled.p.withConfig({ displayName: "Title", componentId: "sc-p5g16x" }) `
    font-size: 34px;
    line-height: 34px;
    color: black;
    margin-bottom: 30px;
`;
const Description = styled.p.withConfig({ displayName: "Description", componentId: "sc-1tbl81r" }) `
    color: black;
    font-size: 16px;
    padding-bottom: 28px;
`;
const DeleteProject = () => {
    const { state: project } = useProjectState();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { deleteProject } = useProjectController();
    return (React.createElement("div", null,
        React.createElement(Title, null, "Delete project"),
        React.createElement(Description, null, "Delete this project forever. You can't undo this."),
        React.createElement(Button, { kind: "destructive", onClick: async () => {
                try {
                    await deleteProject();
                    // Navigate away from now gone project (if user stayed on the edit page)
                    if (pathname === routes.projects.edit({ id: project === null || project === void 0 ? void 0 : project.id })) {
                        navigate(routes.projects.index());
                    }
                }
                catch (e) {
                    console.warn('Could not delete project', e);
                }
            } }, "Delete")));
};
export default DeleteProject;
