import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { SM, MONO, MEDIUM } from '$shared/utils/styled';
import SvgIcon from '$shared/components/SvgIcon';
import Button from '$shared/components/Button';
import Spinner from '$shared/components/Spinner';
import Label from '$ui/Label';
import Text from '$ui/Text';
import Select from '$ui/Select';
import Errors, { MarketplaceTheme } from '$ui/Errors';
import useCopy from '$shared/hooks/useCopy';
import { truncate } from '$shared/utils/text';
import { DraftValidationError, useCurrentDraftError, useSetCurrentDraftError, useSetCurrentDraftTransientStreamId, } from '$shared/stores/streamEditor';
import { useWalletAccount } from '$shared/stores/wallet';
import useStreamOwnerOptionGroups, { ADD_ENS_DOMAIN_VALUE, } from './useStreamOwnerOptionGroups';
export const ENS_DOMAINS_URL = 'https://ens.domains';
export function ReadonlyStreamId({ streamId }) {
    const { copy, isCopied } = useCopy();
    return (React.createElement(StreamId, null,
        React.createElement(Pathname, null,
            React.createElement(Label, null, "Stream ID"),
            React.createElement(PathnameField, null,
                React.createElement(Text, { readOnly: true, value: streamId, disabled: true }))),
        React.createElement("div", null,
            React.createElement(Label, null),
            React.createElement(Button, { kind: "secondary", onClick: () => void copy(streamId || '', {
                    toastMessage: 'Stream ID copied',
                }), type: "button" }, isCopied ? 'Copied!' : 'Copy'))));
}
export function EditableStreamId({ disabled = false }) {
    const ownerGroups = useStreamOwnerOptionGroups();
    const validationError = useCurrentDraftError('streamId');
    const setTransientStreamId = useSetCurrentDraftTransientStreamId();
    const setValidationError = useSetCurrentDraftError();
    const owners = useMemo(() => {
        const result = [];
        if (ownerGroups) {
            ownerGroups.forEach(({ options }) => {
                result.push(...options);
            });
        }
        return result;
    }, [ownerGroups]);
    const isOwnersLoading = typeof ownerGroups === 'undefined';
    const account = useWalletAccount();
    const [domain, setDomain] = useState();
    const [pathname, setPathname] = useState('');
    const pathnameRef = useRef(pathname);
    useEffect(() => {
        pathnameRef.current = pathname;
    }, [pathname]);
    const commitRef = useRef(function commit(newDomain, newPathname) {
        setTransientStreamId('');
        setValidationError('streamId', '');
        if (!newDomain || newPathname === '') {
            return;
        }
        try {
            if (/^\//.test(newPathname)) {
                throw new DraftValidationError('streamId', 'cannot start with a slash');
            }
            if (/\/{2,}/.test(newPathname)) {
                throw new DraftValidationError('streamId', 'cannot contain consecutive "/" characters');
            }
            if (/[^\w]$/.test(newPathname)) {
                throw new DraftValidationError('streamId', 'must end with an alpha-numeric character');
            }
            if (/[^\w.\-/_]/.test(newPathname)) {
                throw new DraftValidationError('streamId', 'may only contain alpha-numeric characters, underscores, and dashes');
            }
            setTransientStreamId(`${newDomain}/${newPathname}`);
        }
        catch (e) {
            if (e instanceof DraftValidationError) {
                return void setValidationError(e.key, e.message);
            }
            throw e;
        }
    });
    useEffect(() => {
        setDomain(undefined);
        commitRef.current(account, pathnameRef.current);
    }, [account]);
    return (React.createElement(StreamId, null,
        React.createElement(Domain, null,
            React.createElement(Label, null, "Domain"),
            disabled || isOwnersLoading || !account ? (React.createElement(DisabledDomain, null, isOwnersLoading ? (React.createElement(React.Fragment, null,
                React.createElement("span", null, "Loading domains\u2026"),
                !disabled && React.createElement(Spinner, { size: "small", color: "blue" }))) : (React.createElement("span", null, truncate(domain || account || 'No account'))))) : (React.createElement(Select, { options: ownerGroups, value: owners.find(({ value }) => value.toLowerCase() === (domain || account)), onChange: ({ value }) => {
                    if (value === ADD_ENS_DOMAIN_VALUE) {
                        window.open(ENS_DOMAINS_URL, '_blank', 'noopener noreferrer');
                        return;
                    }
                    setDomain(value);
                    commitRef.current(value || account, pathname);
                }, disabled: disabled, name: "domain" }))),
        React.createElement("div", null,
            React.createElement(Label, null),
            React.createElement(Separator, null)),
        React.createElement(Pathname, null,
            React.createElement(LabelWrap, null,
                React.createElement(Label, null, "Path name"),
                React.createElement(Hint, null,
                    React.createElement(SvgIcon, { name: "outlineQuestionMark" }),
                    React.createElement(Tooltip, null,
                        React.createElement("p", null, "Stream paths can be single or multi-level."),
                        React.createElement("p", null, "Single"),
                        React.createElement("pre", null, "streamr.eth/coffeemachine"),
                        React.createElement("p", null, "Multi"),
                        React.createElement("pre", null, "oxd93...52874/oracles/price"),
                        React.createElement("p", null,
                            "For more information, see the",
                            ' ',
                            React.createElement("a", { href: "https://docs.streamr.network/" }, "docs"),
                            ".")))),
            React.createElement(PathnameField, null,
                React.createElement(Text, { disabled: disabled, invalid: !!validationError, onChange: ({ target }) => {
                        setPathname(target.value);
                        commitRef.current(domain || account, target.value);
                    }, placeholder: "Enter a unique stream path name", value: pathname }),
                pathname && !disabled && (React.createElement(ClearButton, { type: "button", onClick: () => {
                        setPathname('');
                        commitRef.current(domain || account, '');
                    } },
                    React.createElement(SvgIcon, { name: "clear" })))),
            !!validationError && (React.createElement(Errors, { overlap: true, theme: MarketplaceTheme }, validationError)))));
}
const Domain = styled.div.withConfig({ displayName: "Domain", componentId: "sc-1y3pwpq" }) `
    flex-grow: 1;

    @media (min-width: ${SM}px) {
        max-width: 222px;
    }
`;
const SeparatorAttrs = {
    children: '/',
};
const Separator = styled.div.attrs(SeparatorAttrs).withConfig({ displayName: "Separator", componentId: "sc-56k2s3" }) `
    line-height: 40px;
`;
const Pathname = styled.div.withConfig({ displayName: "Pathname", componentId: "sc-1oywyp1" }) `
    flex-grow: 1;

    ${Label} {
        flex-grow: 1;
    }
`;
const LabelWrap = styled.div.withConfig({ displayName: "LabelWrap", componentId: "sc-10x40ni" }) `
    display: flex;
`;
const Hint = styled.div.withConfig({ displayName: "Hint", componentId: "sc-1kvjpvx" }) `
    color: #cdcdcd;
    position: relative;
    transition: 200ms color;

    :hover {
        color: #323232;
    }

    svg {
        display: block;
        left: -2px;
        position: relative;
        top: -2px;
        width: 16px;
    }
`;
const Tooltip = styled.div.withConfig({ displayName: "Tooltip", componentId: "sc-12rbutu" }) `
    background: #323232;
    border-radius: 4px;
    color: #ffffff;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 20px;
    transform: translateY(4px);
    transition: 200ms;
    transition-property: visibility, opacity, transform;
    transition-delay: 200ms, 0s, 0s;
    visibility: hidden;
    width: 250px;
    z-index: 1;

    pre {
        color: inherit;
        font-family: ${MONO};
        font-size: 0.9em;
        font-weight: ${MEDIUM};
        margin: 0;
        padding: 0;
    }

    pre,
    p {
        margin: 0;
    }

    pre + p,
    p + p {
        margin-top: 0.75em;
    }

    a {
        color: inherit !important;
        text-decoration: none;
    }

    a:focus,
    a:hover {
        text-decoration: underline;
    }

    ${Hint}:hover & {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0s;
        transition-duration: 50ms;
        visibility: visible;
    }
`;
const PathnameField = styled.div.withConfig({ displayName: "PathnameField", componentId: "sc-o11gfh" }) `
    position: relative;
`;
const DisabledDomain = styled.div.withConfig({ displayName: "DisabledDomain", componentId: "sc-sfec83" }) `
    align-items: center;
    background-color: #efefef;
    border-radius: 4px;
    border: 1px solid #efefef;
    color: #525252;
    display: flex;
    font-size: 0.875rem;
    height: 40px;
    padding: 0 1rem;
    width: 100%;

    > span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
const ClearButton = styled.button.withConfig({ displayName: "ClearButton", componentId: "sc-o8t7ih" }) `
    width: 40px;
    height: 40px;
    color: #989898;
    position: absolute;
    top: 0;
    line-height: 16px;
    right: 0;
    border: 0;
    background: none;
    outline: none;
    appearance: none;

    :focus {
        outline: none;
    }

    svg {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    :hover circle {
        fill: #525252;
        stroke: #525252;
    }
`;
const StreamId = styled.div.withConfig({ displayName: "StreamId", componentId: "sc-1rywhgu" }) `
    display: flex;

    > * + * {
        margin-left: 16px;
    }
`;
