export default class TransactionError extends Error {
    constructor(message, receipt) {
        super(message);
        this.receipt = receipt;
        // This is because of some bug in babel
        this.__proto__ = TransactionError.prototype; // eslint-disable-line no-proto
    }
    getReceipt() {
        return this.receipt;
    }
}
