import React from 'react';
import { StreamPermission } from 'streamr-client';
import { useCurrentStreamAbility } from '$shared/stores/streamAbilities';
import Section from '../Section';
import StreamTypeSelector from './StreamTypeSelector';
import PermissionList from './PermissionList';
const AccessControlSection = ({ disabled: disabledProp, }) => {
    const canGrant = useCurrentStreamAbility(StreamPermission.GRANT);
    const disabled = disabledProp || !canGrant;
    return (React.createElement(Section, { title: "Access control" },
        React.createElement("p", null,
            "You can make your stream public, or grant access to specific Ethereum accounts. Learn more about stream access control from the",
            ' ',
            React.createElement("a", { href: "https://docs.streamr.network/" }, "docs"),
            "."),
        React.createElement(StreamTypeSelector, { disabled: disabled }),
        React.createElement(PermissionList, { disabled: disabled })));
};
export default AccessControlSection;
