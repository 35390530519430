import EventEmitter from 'events';
import { isHex } from 'web3-utils';
import { checkEthereumNetworkIsCorrect } from '$shared/utils/web3';
import getWeb3 from '$utils/web3/getWeb3';
import getPublicWeb3 from '$utils/web3/getPublicWeb3';
import TransactionError from '$shared/errors/TransactionError';
import getDefaultWeb3Account from '$utils/web3/getDefaultWeb3Account';
import Transaction from '$shared/utils/Transaction';
import TransactionRejectedError from '$shared/errors/TransactionRejectedError';
export const areAddressesEqual = (first, second) => (first || '').toLowerCase() === (second || '').toLowerCase();
export const hexEqualsZero = (hex) => /^(0x)?0+$/.test(hex);
export const getPrefixedHexString = (hex) => hex.replace(/^0x|^/, '0x');
export const getUnprefixedHexString = (hex) => hex.replace(/^0x|^/, '');
/**
 * Tells if the given string is valid hex or not.
 * @param hex string to validate. Can have the 0x prefix or not
 * @returns {boolean}
 */
export const isValidHexString = (hex) => typeof hex === 'string' && isHex(hex);
/**
 * @deprecated Use `web3.eth.Contract` directly. The main reason why this is a utility function
 * is the `usePublicNode` flag. It's going away (work in progress).
 */
export const getContract = ({ abi, address }, usePublicNode = false, chainId) => {
    if (usePublicNode && chainId == null) {
        throw new Error('ChainId must be provided!');
    }
    const web3 = usePublicNode ? getPublicWeb3(chainId) : getWeb3();
    return new web3.eth.Contract(abi, address);
};
export const call = (method) => method.call();
export const send = (method, options) => {
    const emitter = new EventEmitter();
    // NOTE: looks like there's double handling of errors happening here
    // i.e. .catch + on('error')
    const errorHandler = (error) => {
        emitter.emit('error', error);
    };
    const tx = new Transaction(emitter);
    Promise.all([
        getDefaultWeb3Account(),
        checkEthereumNetworkIsCorrect({
            network: options && options.network,
        }),
    ]).then(([account]) => method
        .send({
        gas: options && options.gas,
        from: account,
        value: options && options.value,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
    })
        .on('error', (error) => {
        if (error instanceof Error) {
            errorHandler(error);
        }
        else {
            if (error.code === 4001) {
                // User rejected the transaction in Metamask
                errorHandler(new TransactionRejectedError());
            }
            else {
                errorHandler(new TransactionError('Transaction error', error));
            }
        }
    })
        .on('transactionHash', (hash) => {
        emitter.emit('transactionHash', hash);
    })
        .once('receipt', (receipt) => {
        if (!receipt.status) {
            errorHandler(new TransactionError('Transaction failed', receipt));
        }
        else {
            emitter.emit('receipt', receipt);
        }
    }), errorHandler);
    return tx;
};
