var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, forwardRef, useEffect, useCallback } from 'react';
import '$shared/types/common-types';
const sanitizeValue = (value) => (value != null ? value : '');
const RevertOnEscapeDecorator = (WrappedComponent) => {
    const RevertOnEscapeDecoratorWrapper = (_a, ref) => {
        var { value: valueProp, onKeyDown: onKeyDownProp, onChange: onChangeProp } = _a, props = __rest(_a, ["value", "onKeyDown", "onChange"]);
        const [value, setValue] = useState(sanitizeValue(valueProp));
        useEffect(() => {
            setValue(sanitizeValue(valueProp));
        }, [valueProp]);
        const onKeyDown = useCallback((e) => {
            if (e.key === 'Escape') {
                setValue(sanitizeValue(valueProp));
            }
            if (onKeyDownProp) {
                onKeyDownProp(e);
            }
        }, [valueProp, onKeyDownProp]);
        const onChange = useCallback((e) => {
            setValue(e.target.value);
            if (onChangeProp) {
                onChangeProp(e);
            }
        }, [onChangeProp]);
        return (React.createElement(WrappedComponent, Object.assign({}, props, { onChange: onChange, onKeyDown: onKeyDown, ref: ref, value: value })));
    };
    const RevertOnEscapeDecoratorWrapperFR = forwardRef(RevertOnEscapeDecoratorWrapper);
    const OptInRevertOnEscapeDecoratorWrapper = (_a, ref) => {
        var { revertOnEscape } = _a, props = __rest(_a, ["revertOnEscape"]);
        return revertOnEscape ? (React.createElement(RevertOnEscapeDecoratorWrapperFR, Object.assign({}, props, { ref: ref }))) : (React.createElement(WrappedComponent, Object.assign({}, props, { ref: ref })));
    };
    return forwardRef(OptInRevertOnEscapeDecoratorWrapper);
};
export default RevertOnEscapeDecorator;
