import React from 'react';
import { StreamPermission } from 'streamr-client';
import styled from 'styled-components';
import Label from '$ui/Label';
import UnitizedQuantity from '$shared/components/UnitizedQuantity';
import { useCurrentStreamAbility } from '$shared/stores/streamAbilities';
import { useCurrentDraft, useUpdateCurrentMetadata } from '$shared/stores/streamEditor';
import Section from '../Section';
import StorageNodeList from './StorageNodeList';
const Desc = styled.p.withConfig({ displayName: "Desc", componentId: "sc-xx0y9g" }) `
    /* @TODO: Isn't it the same as for PartitionsSection? -> normalize. */
    margin-bottom: 3.125rem;
`;
export default function HistorySection({ disabled: disabledProp = false }) {
    const canEdit = useCurrentStreamAbility(StreamPermission.EDIT);
    const disabled = disabledProp || !canEdit;
    const { storageNodes, metadata: { storageDays }, } = useCurrentDraft();
    const updateMetadata = useUpdateCurrentMetadata();
    const noStorageEnabled = !Object.values(storageNodes).some((item) => item === null || item === void 0 ? void 0 : item.enabled);
    return (React.createElement(Section, { title: "Data storage" },
        React.createElement(Desc, null, "Enable storage to retain historical data in one or more geographic locations of your choice. You can also choose how long to store your stream's historical data before auto-deletion."),
        React.createElement(StorageNodeList, { disabled: disabled }),
        React.createElement(Label, { htmlFor: "storageAmount" }, "Store historical data for"),
        React.createElement(UnitizedQuantity, { units: {
                day: 1,
                month: 30,
                week: 7,
            }, disabled: disabled || noStorageEnabled, onChange: (value) => void updateMetadata((metadata) => {
                metadata.storageDays = value;
            }), quantity: storageDays })));
}
