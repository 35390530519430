import React, { useMemo, useCallback, useState, useContext, useEffect, } from 'react';
import t from 'prop-types';
import useIsMounted from '$shared/hooks/useIsMounted';
const ROOT = {
    name: '',
    isPending: false,
    setPending: () => { },
    checkPending: () => false,
    updateChildren: () => { },
};
function useNamedCounters() {
    const isMounted = useIsMounted();
    const [counters, setCountersState] = useState({});
    const updateCounter = useCallback((name, direction) => {
        if (!isMounted()) {
            return;
        }
        if (!name) {
            throw new Error('counter needs a name');
        }
        setCountersState((state) => {
            const current = state[name] || 0;
            return Object.assign(Object.assign({}, state), { [name]: Math.max(0, current + direction) });
        });
    }, [setCountersState, isMounted]);
    return [counters, updateCounter];
}
const PendingContext = React.createContext(ROOT);
/* eslint-disable no-shadow */
function usePendingContext(name) {
    const parentContext = useContext(PendingContext);
    const isMounted = useIsMounted();
    const path = [parentContext && parentContext.name, name].filter(Boolean).join('.');
    const [pendingChildren, updatePendingChildren] = React.useState({});
    const updateParent = parentContext !== ROOT && parentContext.updateChildren;
    const [selfPending, setPending] = useNamedCounters();
    const updateChildren = useCallback((childName, value) => {
        if (!isMounted()) {
            return;
        }
        updatePendingChildren((c) => (Object.assign(Object.assign({}, c), { [childName]: value })));
    }, [updatePendingChildren, isMounted]);
    const isSelfPending = Object.values(selfPending).some((value) => !!value);
    const pendingChildrenValues = Object.values(pendingChildren);
    const isChildrenPending = !!pendingChildrenValues.length &&
        pendingChildrenValues.some(({ isPending }) => isPending);
    const isPending = isSelfPending || isChildrenPending;
    const checkPending = useCallback((key) => !!selfPending[key], [selfPending]);
    const value = useMemo(() => ({
        name,
        path,
        isPending,
        checkPending,
        setPending,
        updateChildren,
    }), [path, name, isPending, updateChildren, setPending, checkPending]);
    useEffect(() => {
        if (!updateParent) {
            return;
        }
        updateParent(name, value);
    }, [value, name, updateParent]);
    return value;
}
function PendingContextProvider({ name, children }) {
    return (React.createElement(PendingContext.Provider, { key: name, value: usePendingContext(name) }, children || null));
}
PendingContextProvider.propTypes = {
    name: t.string.isRequired,
};
export { PendingContextProvider as Provider, PendingContext as Context };
