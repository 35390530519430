import React, { createContext, useCallback, useMemo, useState, } from 'react';
import { createSecret, deleteSecret, editSecret, getSecrets, } from '$mp/modules/dataUnion/services';
const useDataUnionSecretsControllerImplementation = () => {
    const [secrets, setSecrets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const mapSecretToDataUnionSecret = (secret) => ({
        id: secret.secret,
        secret: secret.secret,
        name: secret.name,
        contractAddress: secret.dataUnion,
    });
    const load = useCallback(async (dataUnionId, chainId) => {
        try {
            setIsLoading(true);
            const response = await getSecrets({
                dataUnionId,
                chainId,
            });
            const mappedSecrets = response.map(mapSecretToDataUnionSecret);
            setSecrets(mappedSecrets);
            setIsLoaded(true);
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }, [setSecrets, setIsLoaded, setIsLoading]);
    const add = useCallback(async ({ dataUnionId, name, chainId, }) => {
        setIsSaving(true);
        try {
            const response = await createSecret({
                dataUnionId,
                name,
                chainId,
            });
            setSecrets((current) => [
                ...current,
                mapSecretToDataUnionSecret(response),
            ]);
            setIsSaving(false);
        }
        catch (e) {
            console.warn(e);
            setIsSaving(false);
            throw e;
        }
    }, [setSecrets]);
    const edit = useCallback(async ({ dataUnionId, id, name, chainId, }) => {
        setIsSaving(true);
        try {
            const response = await editSecret({
                dataUnionId,
                id,
                name,
                chainId,
            });
            setSecrets((current) => [
                ...current,
                mapSecretToDataUnionSecret(response),
            ]);
            setIsSaving(false);
        }
        catch (e) {
            console.warn(e);
            setIsSaving(false);
            throw e;
        }
    }, [setSecrets]);
    const remove = useCallback(async ({ dataUnionId, id, chainId, }) => {
        setIsSaving(true);
        try {
            await deleteSecret({
                dataUnionId,
                id,
                chainId,
            });
            setSecrets((current) => current.filter((secret) => secret.id !== id));
            setIsSaving(false);
        }
        catch (e) {
            console.warn(e);
            setIsSaving(false);
            throw e;
        }
    }, [setSecrets]);
    return useMemo(() => ({
        load,
        secrets,
        add,
        edit,
        remove,
        isLoading,
        isLoaded,
        isSaving,
    }), [load, secrets, add, edit, remove, isLoading, isLoaded, isSaving]);
};
export const DataUnionSecretsContext = createContext({});
export const DataUnionSecretsContextProvider = ({ children }) => {
    return (React.createElement(DataUnionSecretsContext.Provider, { value: useDataUnionSecretsControllerImplementation() }, children));
};
