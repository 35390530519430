import React from 'react';
import styled from 'styled-components';
import { useCurrentDraft, useDraftId, usePersistingDraftIdsForStream, } from '$shared/stores/streamEditor';
import { COLORS } from '$shared/utils/styled';
import Spinner from '$app/src/shared/components/Spinner';
export default function PersistanceAlert() {
    const draftId = useDraftId();
    const { streamId, transientStreamId } = useCurrentDraft();
    const persistingDraftIds = [
        ...usePersistingDraftIdsForStream(streamId),
        ...usePersistingDraftIdsForStream(transientStreamId),
    ].filter((did) => did !== draftId);
    if (!persistingDraftIds.length) {
        return null;
    }
    return (React.createElement(Root, null,
        React.createElement(Copy, null, "This stream is being persisted as we speak. Consider waiting for it to finish before introducing new changes."),
        React.createElement(SpinnerWrap, null,
            React.createElement(Spinner, { size: "small", color: "blue" }))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-fsy6yk" }) `
    align-items: center;
    background-color: ${COLORS.secondary};
    border-radius: 8px;
    border: 1px solid ${COLORS.inputBackground};
    color: #323232;
    display: flex;
    font-size: 14px;
    line-height: 1.4em;
    margin-bottom: 24px;
    padding: 24px 32px;
`;
const SpinnerWrap = styled.div.withConfig({ displayName: "SpinnerWrap", componentId: "sc-17g0gp8" }) `
    flex-shrink: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 18px;
    width: 18px;
`;
const Copy = styled.div.withConfig({ displayName: "Copy", componentId: "sc-1jf7wgv" }) `
    padding-right: 128px;
    flex-grow: 1;
`;
