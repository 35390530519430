import styled from 'styled-components';
import { COLORS, DESKTOP, MD, MEDIUM, TABLET, MAX_BODY_WIDTH, } from '$shared/utils/styled';
import Button from '$shared/components/Button';
export const SearchBarWrap = styled.div.withConfig({ displayName: "SearchBarWrap", componentId: "sc-1gimir0" }) `
    display: flex;
    justify-content: center;
    padding: 30px 0 50px;
    > * {
        max-width: 770px;
        margin: 0 24px;
        @media (${TABLET}) {
            margin: 0 72px;
        }
        @media (${DESKTOP}) {
            margin: 0;
        }
    }

    @media (min-width: ${MD}px) {
        padding: 40px 0 80px;
    }
`;
export const FiltersBar = styled.div.withConfig({ displayName: "FiltersBar", componentId: "sc-wt6ocr" }) `
    display: flex;
    justify-content: space-between;
    padding: 0 30px 30px;
    max-width: ${MAX_BODY_WIDTH}px;
    margin: 0 auto;

    @media (${DESKTOP}) {
        padding: 0 0 30px;
    }
`;
export const FiltersWrap = styled.div.withConfig({ displayName: "FiltersWrap", componentId: "sc-1hfwj8q" }) `
    display: flex;
    width: 100%;
    align-items: center;
    @media (${TABLET}) {
        width: auto;
        align-items: unset;
    }
`;
export const DropdownFilters = styled.div.withConfig({ displayName: "DropdownFilters", componentId: "sc-zzgi06" }) `
    display: none;
    align-items: center;
    margin-left: 40px;
    span {
        color: ${COLORS.primary};
        font-weight: ${MEDIUM};
        margin-right: 15px;
        font-size: 14px;
    }

    @media (${DESKTOP}) {
        display: flex;
    }
`;
export const SelectFieldWrap = styled.div.withConfig({ displayName: "SelectFieldWrap", componentId: "sc-syhndc" }) `
    margin-right: 10px;
`;
export const MobileFilterWrap = styled.div.withConfig({ displayName: "MobileFilterWrap", componentId: "sc-1t810rj" }) `
    display: block;
    color: ${COLORS.primary};
    margin-left: 20px;

    @media (${DESKTOP}) {
        display: none;
    }
`;
export const MobileFilterText = styled.span.withConfig({ displayName: "MobileFilterText", componentId: "sc-13okcdk" }) `
    margin-right: 15px;
    display: none;
    @media (${TABLET}) {
        display: inline;
    }
`;
export const CreateProjectButton = styled(Button).withConfig({ displayName: "CreateProjectButton", componentId: "sc-320ij6" }) `
    display: none !important;
    @media (${TABLET}) {
        display: inherit !important;
    }
`;
export const ActionBarContainer = styled.div.withConfig({ displayName: "ActionBarContainer", componentId: "sc-1h993bh" }) `
    background-color: ${COLORS.primaryContrast};
`;
