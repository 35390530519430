import React, { useCallback, useEffect, useState, } from 'react';
import styled from 'styled-components';
import { COLORS } from '$shared/utils/styled';
import Checkbox from '$shared/components/Checkbox';
import SvgIcon from '$shared/components/SvgIcon';
import NetworkIcon from '$shared/components/NetworkIcon';
import TokenSelector from '$mp/containers/ProjectEditing/TokenSelector';
import { BeneficiaryAddress } from '$mp/containers/ProjectEditing/BeneficiaryAddress';
import { formatChainName } from '$shared/utils/chains';
export const PricingOption = ({ onToggle, chain, pricingData, onChange, editingSelectionAndTokenDisabled = false, }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(!!pricingData);
    const [pricing, setPricing] = useState(pricingData || {});
    const [beneficiaryAddress, setBeneficiaryAddress] = useState(pricingData === null || pricingData === void 0 ? void 0 : pricingData.beneficiaryAddress);
    const handleToggleClick = useCallback(() => {
        setIsDropdownOpen((isOpen) => !isOpen);
        // this might seem a bit silly, but it's needed for the use case when we are toggling the first Option
        // while having none selected, then it will be marked as selected
        if (onToggle) {
            onToggle(chain.name, {
                chainId: chain.id,
                pricePerSecond: pricing === null || pricing === void 0 ? void 0 : pricing.pricePerSecond,
                timeUnit: pricing.timeUnit,
                price: pricing === null || pricing === void 0 ? void 0 : pricing.price,
                pricingTokenAddress: pricing.tokenAddress,
                beneficiaryAddress: beneficiaryAddress,
            });
        }
    }, [
        onToggle,
        chain,
        pricing.pricePerSecond,
        pricing.timeUnit,
        pricing.price,
        pricing.tokenAddress,
        beneficiaryAddress,
    ]);
    const handlePricingChange = useCallback((newPricing) => {
        const output = isSelected
            ? {
                chainId: chain.id,
                pricePerSecond: newPricing === null || newPricing === void 0 ? void 0 : newPricing.pricePerSecond,
                timeUnit: newPricing.timeUnit,
                price: newPricing === null || newPricing === void 0 ? void 0 : newPricing.price,
                pricingTokenAddress: newPricing.tokenAddress,
                beneficiaryAddress: beneficiaryAddress,
            }
            : null;
        setPricing(newPricing);
        onChange(chain.name, output);
    }, [onChange, isSelected, beneficiaryAddress, chain, setPricing]);
    const handleCheckboxToggle = useCallback((event) => {
        const selected = event.target.checked;
        const output = selected
            ? {
                chainId: chain.id,
                pricePerSecond: pricing === null || pricing === void 0 ? void 0 : pricing.pricePerSecond,
                timeUnit: pricing.timeUnit,
                price: pricing === null || pricing === void 0 ? void 0 : pricing.price,
                pricingTokenAddress: pricing.tokenAddress,
                beneficiaryAddress: beneficiaryAddress,
            }
            : null;
        setIsSelected(selected);
        onChange(chain.name, output);
    }, [onChange, pricing, beneficiaryAddress, chain, setIsSelected]);
    useEffect(() => {
        if (!!pricingData) {
            setIsSelected(true);
        }
    }, [pricingData]);
    return (React.createElement(DropdownWrap, { className: isDropdownOpen ? 'is-open' : '' },
        React.createElement(DropdownToggle, { onClick: handleToggleClick, className: isDropdownOpen ? 'is-open' : '' },
            React.createElement("label", null,
                React.createElement(StyledCheckbox, { value: isSelected, disabled: editingSelectionAndTokenDisabled, onClick: (event) => event.stopPropagation(), onChange: handleCheckboxToggle })),
            React.createElement(ChainIcon, { chainId: chain.id }),
            React.createElement("span", null, formatChainName(chain.name)),
            React.createElement(PlusSymbol, { name: 'plus', className: isDropdownOpen ? 'is-open' : '' })),
        React.createElement(DropdownOuter, { className: isDropdownOpen ? 'is-open' : '' },
            React.createElement(DropdownInner, { className: isDropdownOpen ? 'is-open' : '' },
                React.createElement(TokenSelector, { disabled: !isSelected, chain: chain, onChange: handlePricingChange, validationFieldName: `salePoints.${chain.name}`, value: pricingData
                        ? {
                            tokenAddress: pricingData.tokenAddress,
                            price: pricingData.price,
                            timeUnit: pricingData.timeUnit,
                            pricePerSecond: pricingData.pricePerSecond,
                        }
                        : undefined, tokenChangeDisabled: editingSelectionAndTokenDisabled }),
                React.createElement(BeneficiaryAddress, { beneficiaryAddress: pricingData === null || pricingData === void 0 ? void 0 : pricingData.beneficiaryAddress, onChange: setBeneficiaryAddress, chainName: chain.name })))));
};
const DropdownWrap = styled.div.withConfig({ displayName: "DropdownWrap", componentId: "sc-1imileq" }) `
    overflow: hidden;
    box-shadow: 0 1px 2px 0 #00000026, 0 0 1px 0 #00000040;
    border-radius: 4px;
    margin-bottom: 24px;
    color: ${COLORS.primary};

    &:last-of-type {
        margin-bottom: 0;
    }
`;
const DropdownOuter = styled.div.withConfig({ displayName: "DropdownOuter", componentId: "sc-1gagahn" }) `
    overflow: hidden;
`;
const DropdownInner = styled.div.withConfig({ displayName: "DropdownInner", componentId: "sc-igld39" }) `
    padding: 24px 24px 75px;
    transition: margin-bottom 0.5s ease-in-out;
    margin-bottom: -200%;

    &.is-open {
        margin-bottom: 0;
    }
`;
const DropdownToggle = styled.div.withConfig({ displayName: "DropdownToggle", componentId: "sc-u0whpc" }) `
    padding: 24px 24px 24px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover {
        background-color: ${COLORS.secondary};
    }
    &.is-open:hover {
        background-color: inherit;
    }

    label {
        display: flex;
        padding: 12px;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
    }
`;
const StyledCheckbox = styled(Checkbox).withConfig({ displayName: "StyledCheckbox", componentId: "sc-1cmf2y1" }) `
    cursor: pointer;
    &:disabled {
        opacity: 0.3;
    }
`;
const PlusSymbol = styled(SvgIcon).withConfig({ displayName: "PlusSymbol", componentId: "sc-1ui6ojw" }) `
    width: 14px;
    margin-left: auto;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    &.is-open {
        transform: rotate(45deg);
    }
`;
const ChainIcon = styled(NetworkIcon).withConfig({ displayName: "ChainIcon", componentId: "sc-7u6mkx" }) `
    width: 32px;
    height: 32px;
    margin-right: 12px;
`;
