export default class InvalidHexStringError extends Error {
    constructor(id, ...args) {
        super(`"${id || ''}" is not a valid hex string`, ...args);
        this.name = 'InvalidHexStringError';
        this.id = id;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidHexStringError);
        }
        Object.setPrototypeOf(this, InvalidHexStringError.prototype);
    }
}
