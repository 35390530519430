import React from 'react';
import styled from 'styled-components';
import PngIcon from '$shared/components/PngIcon';
import Button from '$shared/components/Button';
import Link from '$shared/components/Link';
import Buttons from '$shared/components/Buttons';
import Modal from './Modal';
import { Footer } from './BaseModal';
export default function GetCryptoModal({ tokenName, onReject }) {
    return (React.createElement(Modal, { onReject: onReject, title: `No ${tokenName} balance` },
        React.createElement(Copy, null,
            React.createElement(IconWrap, null,
                React.createElement(PngIcon, { name: "walletError", alt: `No ${tokenName} balance` })),
            React.createElement("p", null,
                tokenName,
                " is needed for gas, but you don't have any."),
            React.createElement("p", null, "Please get some and try again")),
        React.createElement(Exchanges, null,
            React.createElement(Button, { kind: "secondary", tag: Link, href: "https://ramp.network/", target: "_blank" }, "Ramp"),
            React.createElement(Button, { kind: "secondary", tag: Link, href: "https://coinbase.com", target: "_blank" }, "Coinbase"),
            React.createElement(Button, { kind: "secondary", tag: Link, href: "https://binance.com", target: "_blank" }, "Binance")),
        React.createElement(Footer, null,
            React.createElement(Buttons, { actions: {
                    cancel: {
                        title: 'Dismiss',
                        onClick: () => void (onReject === null || onReject === void 0 ? void 0 : onReject()),
                        kind: 'primary',
                    },
                } }))));
}
const Copy = styled.div.withConfig({ displayName: "Copy", componentId: "sc-2ohwuy" }) `
    padding: 48px 40px 40px;

    p {
        margin: 0;
        text-align: center;
    }

    p + p {
        margin-top: 0.5em;
    }
`;
const Exchanges = styled.div.withConfig({ displayName: "Exchanges", componentId: "sc-u97mpu" }) `
    align-items: center;
    border-top: 1px solid #f2f1f1;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    padding: 1.5rem 40px;
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-ax2xpa" }) `
    width: 98px;
    height: 67px;
    position: relative;
    margin: 0 auto 24px;
    background: red;

    img {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) translateY(-5%) translateX(-4%);
    }
`;
