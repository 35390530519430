var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import Popover from '$shared/components/Popover';
import PopoverItem from '$shared/components/Popover/PopoverItem';
export const MenuItem = PopoverItem;
const UnstyledMenu = (_a) => {
    var { children, onToggle } = _a, props = __rest(_a, ["children", "onToggle"]);
    return (React.createElement(Popover, Object.assign({}, props, { title: "Select", type: "whiteMeatball", direction: "down", caret: false, onMenuToggle: onToggle, menuProps: {
            right: true,
        } }), children));
};
const Menu = styled(UnstyledMenu).withConfig({ displayName: "Menu", componentId: "sc-56pght" }) `
    right: 16px;
    top: 16px;
    z-index: 1;

    && {
        position: absolute;
    }
`;
export default Menu;
