import React from 'react';
import classNames from 'classnames';
import styles from './emptyState.pcss';
const EmptyState = ({ children, image, className, link, linkOnMobile, }) => (React.createElement("div", { className: classNames(className, styles.emptyState) },
    image && React.createElement("div", { className: styles.imageWrapper }, image),
    children,
    !!link && (React.createElement("div", { className: classNames(styles.linkWrapper, {
            'd-none d-md-block': !linkOnMobile,
        }) }, link))));
export default EmptyState;
