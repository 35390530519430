import React from 'react';
import styled from 'styled-components';
import Button from '$shared/components/Button';
const StyledContainer = styled.div.withConfig({ displayName: "StyledContainer", componentId: "sc-1m75vp8" }) `
    height: 40px;
    display: flex;
    justify-content: center;
    margin-top: 5em;
`;
const LoadMore = ({ onClick, hasMoreSearchResults, preserveSpace, className }) => {
    if (!hasMoreSearchResults) {
        return preserveSpace ? React.createElement(StyledContainer, { className: className }) : null;
    }
    return (React.createElement(StyledContainer, { className: className },
        React.createElement(Button, { kind: "special", variant: "dark", onClick: onClick }, "View more")));
};
export default LoadMore;
