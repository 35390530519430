import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import styled from 'styled-components';
import Button from '$shared/components/Button';
import ModalPortal from '$shared/components/ModalPortal';
import Dialog from '$shared/components/Dialog';
import { COLORS, TABLET } from '$shared/utils/styled';
import Faders from './Faders.svg';
const MobileFilter = ({ filters, onChange, selectedFilters, children, }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selections, setSelections] = useState({});
    useEffect(() => {
        if (selectedFilters &&
            JSON.stringify(selectedFilters) !== JSON.stringify(selections)) {
            setSelections(selectedFilters);
        }
    }, [selectedFilters, selections]);
    const handleSelection = (filter, value) => {
        setSelections(Object.assign(Object.assign({}, selections), { [filter]: value }));
    };
    const saveSelection = useCallback((clear) => {
        onChange(clear ? {} : selections);
        setDialogOpen(false);
    }, [onChange, selections]);
    const modalActions = useMemo(() => {
        return {
            cancel: {
                title: 'Clear' +
                    (Object.keys(selections).length
                        ? ` (${Object.keys(selections).length})`
                        : ''),
                kind: 'secondary',
                onClick: () => {
                    setSelections({});
                    saveSelection(true);
                },
                className: 'filter-clear-button',
            },
            save: {
                title: 'Save',
                onClick: () => saveSelection(),
                className: 'filter-save-button',
            },
        };
    }, [selections, saveSelection]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Trigger, { id: 'mobile-filter-trigger', "data-testid": 'mobile-filter-trigger', onClick: () => setDialogOpen(true), kind: 'secondary' },
            children,
            React.createElement("img", { src: Faders })),
        React.createElement(ModalPortal, null, dialogOpen && (React.createElement(FilterDialog, { title: 'Filter', showCloseIcon: true, contentClassName: 'filter-dialog-content', onClose: () => {
                setDialogOpen(false);
            }, actions: modalActions, actionsClassName: 'filter-action-buttons' }, filters.map((filter, filterIndex) => {
            return (React.createElement(FilterSection, { key: filter.value + filterIndex },
                React.createElement("p", { className: 'filter-name' },
                    "By ",
                    filter.label.toLowerCase()),
                React.createElement("form", { className: 'options-list', "data-testid": 'options-list', onSubmit: (event) => event.preventDefault(), "data-can-have-columns": filter.options.length > 8 }, filter.options.map((filterOption, optionIndex) => {
                    return (React.createElement("div", { key: filterOption.value +
                            optionIndex },
                        React.createElement("label", { htmlFor: filter.value +
                                '-' +
                                filterOption.value, "data-testid": 'filter-element', className: 'filter-option' },
                            React.createElement("input", { type: 'radio', id: filter.value +
                                    '-' +
                                    filterOption.value, name: filter.value, value: filterOption.value, onChange: () => handleSelection(filter.value, filterOption.value), checked: selections[filter.value] ===
                                    filterOption.value }),
                            React.createElement("span", { className: 'filter-options-label' }, filterOption.label))));
                }))));
        }))))));
};
const Trigger = styled(Button).withConfig({ displayName: "Trigger", componentId: "sc-a2oouw" }) `
    padding: 0 10px !important;
`;
const FilterSection = styled.div.withConfig({ displayName: "FilterSection", componentId: "sc-1ta4ga2" }) `
    width: 100%;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid ${COLORS.separator};
    :first-of-type {
        border: none;
        margin: 0;
        padding: 0;
    }

    .options-list {
        &[data-can-have-columns='true'] {
            @media (${TABLET}) {
                column-count: 2;
            }
        }
    }
`;
const FilterDialog = styled(Dialog).withConfig({ displayName: "FilterDialog", componentId: "sc-1agang" }) `
    .filter-dialog-content {
        text-align: left;
        flex: 0;
        align-items: flex-start;
    }

    .filter-name {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 12px;
    }

    .filter-option {
        cursor: pointer;
        margin-bottom: 6px;
    }

    .filter-options-label {
        margin-left: 16px;
        font-size: 18px;
        line-height: 30px;
    }

    .filter-action-buttons {
        button {
            flex: 1;
        }
    }
`;
export default MobileFilter;
