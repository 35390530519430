import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS, DESKTOP, LAPTOP, MAX_BODY_WIDTH, TABLET } from '$shared/utils/styled';
import SvgIcon from '$shared/components/SvgIcon';
import { RouteMemoryKey, useKeep } from '$shared/stores/routeMemory';
export const DetailsPageHeader = ({ backButtonLink, pageTitle, rightComponent, }) => {
    const keep = useKeep();
    return (React.createElement(DetailsPageHeaderBackground, null,
        React.createElement(DetailsPageHeaderContainer, null,
            React.createElement(DetailsPageHeaderBar, null,
                React.createElement(LeftSideContainer, null,
                    !!backButtonLink && (React.createElement(BackLink, { to: backButtonLink, onClick: () => {
                            keep(RouteMemoryKey.lastStreamListingSelection());
                        } },
                        React.createElement(BackButtonIcon, { name: 'backArrow' }))),
                    !!pageTitle && (React.createElement(PageTitleContainer, null, pageTitle))),
                React.createElement(RightSideContainer, null, rightComponent)))));
};
const DetailsPageHeaderBackground = styled.div.withConfig({ displayName: "DetailsPageHeaderBackground", componentId: "sc-man72u" }) `
    background-color: white;
    padding: 45px 0 22px;

    @media (${DESKTOP}) {
        padding: 130px 0 30px;
    }
`;
const DetailsPageHeaderContainer = styled.div.withConfig({ displayName: "DetailsPageHeaderContainer", componentId: "sc-r0gcdr" }) `
    max-width: ${MAX_BODY_WIDTH}px;
    margin: 0 auto;
    padding: 0 24px;

    & & {
        padding: 0 24px;
    }

    @media (${TABLET}) {
        padding: 0 40px;

        & & {
            padding: 0;
        }
    }

    @media (${DESKTOP}) {
        padding: 0;
    }
`;
const DetailsPageHeaderBar = styled.div.withConfig({ displayName: "DetailsPageHeaderBar", componentId: "sc-bdui4" }) `
    display: block;
    @media (${LAPTOP}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const LeftSideContainer = styled.div.withConfig({ displayName: "LeftSideContainer", componentId: "sc-1aw326l" }) `
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
`;
const RightSideContainer = styled.div.withConfig({ displayName: "RightSideContainer", componentId: "sc-ryd7lx" }) `
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media (${LAPTOP}) {
        margin-top: 0;
    }
`;
const BackLink = styled(Link).withConfig({ displayName: "BackLink", componentId: "sc-qd2a48" }) `
    padding: 10px;
    line-height: 30px;
    margin-right: 14px;
`;
const BackButtonIcon = styled(SvgIcon).withConfig({ displayName: "BackButtonIcon", componentId: "sc-1auurh9" }) `
    color: ${COLORS.primaryLight};
`;
const PageTitleContainer = styled.div.withConfig({ displayName: "PageTitleContainer", componentId: "sc-11do3zv" }) `
    margin-top: 3px;
    color: ${COLORS.primary};
    font-size: 16px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px;
`;
