var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from '$shared/components/Skeleton';
import { MEDIUM, REGULAR, MD } from '$shared/utils/styled';
const Name = styled.div.withConfig({ displayName: "Name", componentId: "sc-1vas4ph" }) ``;
const Secondary = styled.div.withConfig({ displayName: "Secondary", componentId: "sc-1qomexr" }) `
    font-size: 16px;
    font-weight: ${REGULAR};
`;
const Description = styled(Secondary).withConfig({ displayName: "Description", componentId: "sc-1nrffff" }) `
    color: #323232;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-weight: ${REGULAR};
    padding-top: 8px;
`;
const Label = styled(Secondary).withConfig({ displayName: "Label", componentId: "sc-191coew" }) `
    text-align: right;
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1les9lm" }) `
    color: #323232;
    font-size: 18px;
    font-weight: ${MEDIUM};
    line-height: 24px;
    padding-top: 16px;

    ${({ label }) => label &&
    css `
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 0.5em;
        `};

    @media (min-width: ${MD}px) {
        padding-top: 24px;
    }
`;
const Summary = (_a) => {
    var { name, description, label, skeletonize } = _a, props = __rest(_a, ["name", "description", "label", "skeletonize"]);
    return (React.createElement(Root, Object.assign({}, props, { label: label }),
        React.createElement("div", null,
            React.createElement(Name, null,
                React.createElement(Skeleton, { disabled: !skeletonize }, name)),
            !!description && (React.createElement(Description, null,
                React.createElement(Skeleton, { disabled: !skeletonize, width: "45%" }, description)))),
        !!label && (React.createElement(Label, null,
            React.createElement(Skeleton, { disabled: !skeletonize, width: "30%" }, label)))));
};
export default Summary;
