var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Dropdown as RsDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import cx from 'classnames';
import SvgIcon from '$shared/components/SvgIcon';
import Meatball from '$shared/components/Meatball';
const UppercaseTitle = styled.span.withConfig({ displayName: "UppercaseTitle", componentId: "sc-17lfpzu" }) `
    font-family: var(--mono);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
`;
export const StyledDropdown = styled(RsDropdown).withConfig({ displayName: "StyledDropdown", componentId: "sc-1onene2" }) `
    .dropdown-menu {
        border: none;
        border-radius: 4px;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
        margin: 8px 0 0;
        font-size: 14px;
        min-width: 6rem;
        padding: 4px 0;

        a,
        button {
            padding: 0 16px 0;
            line-height: 28px;

            &,
            &:link {
                color: #323232;
            }

            &[disabled] {
                color: #adadad;
            }

            &:focus {
                outline: none;
                box-shadow: inset 0 0 0 1.5px var(--focus-border-color);
            }

            &.dropdown-item:active,
            &.dropdown-item.active {
                background-color: #f7f7f9;
                color: var(--greyDark);
            }
        }
    }
`;
export const StyledDropdownToggle = styled(DropdownToggle).withConfig({ displayName: "StyledDropdownToggle", componentId: "sc-w6m0is" }) `
    && {
        color: #a3a3a3;
        outline: 0;
        text-decoration: none;
        cursor: pointer;
        line-height: 32px;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &[disabled] {
            opacity: 0.5;
        }

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            color: var(--greyDark) !important;
            background: inherit;
        }
    }
`;
export const ToggleLabel = styled.span.withConfig({ displayName: "ToggleLabel", componentId: "sc-1oc990d" }) `
    flex: 1;
`;
export const StyledDropdownMenu = styled(DropdownMenu).withConfig({ displayName: "StyledDropdownMenu", componentId: "sc-hukybc" }) `
    min-width: 8rem;
`;
const TextCaret = styled.span.withConfig({ displayName: "TextCaret", componentId: "sc-r6td03" }) `
    margin-left: 0.35em;
    display: inline-block;
    height: 9px;
    line-height: 6px;
    transition: transform 180ms ease-in-out;

    &.open {
        transform: rotate(180deg);
    }
`;
const SvgCaret = styled(SvgIcon).withConfig({ displayName: "SvgCaret", componentId: "sc-14u4kcw" }) `
    width: 11px;
    margin-left: 0.5em;
    transition: transform 180ms ease-in-out;

    &.open {
        transform: rotate(180deg);
    }
`;
export const Caret = ({ open, svg }) => {
    if (svg) {
        return (React.createElement(SvgCaret, { className: cx('caret', {
                open: !!open,
            }), name: "caretDown" }));
    }
    return (React.createElement(TextCaret, { className: cx('caret', {
            open: !!open,
        }) }, "\u25BE"));
};
const Popover = (_a) => {
    var { title, type = 'normal', onMenuToggle, children, className, caret = 'arrow', activeTitle, selectedItem } = _a, _b = _a.toggleProps, _c = _b === void 0 ? {} : _b, { className: toggleClassName } = _c, toggleProps = __rest(_c, ["className"]), _d = _a.menuProps, _e = _d === void 0 ? {} : _d, { className: menuClassName } = _e, menuProps = __rest(_e, ["className"]), { direction, disabled, onChange, leftTick } = _a;
    const [open, setOpen] = useState(false);
    const childrenArray = useMemo(() => React.Children.toArray(children), [children]);
    const selectedIndex = useMemo(() => childrenArray.findIndex((child) => child.props.value === selectedItem), [childrenArray, selectedItem]);
    const currentItem = useMemo(() => (selectedIndex >= 0 && childrenArray[selectedIndex].props.children) || null, [selectedIndex, childrenArray]);
    const titleString = useMemo(() => {
        if (!activeTitle || !currentItem) {
            return title;
        }
        return currentItem;
    }, [activeTitle, title, currentItem]);
    const titleComponent = useMemo(() => {
        switch (type) {
            case 'uppercase':
                return React.createElement(UppercaseTitle, null, titleString);
            case 'meatball':
            case 'whiteMeatball':
            case 'verticalMeatball':
            case 'grayMeatball': {
                const meatballProps = {
                    alt: typeof titleString === 'string' ? titleString : '',
                    gray: type === 'grayMeatball',
                    white: type === 'whiteMeatball',
                    vertical: type === 'verticalMeatball',
                    disabled,
                };
                return React.createElement(Meatball, Object.assign({}, meatballProps));
            }
            default:
                return titleString;
        }
    }, [titleString, type, disabled]);
    const caretComponent = useMemo(() => {
        if (!caret) {
            return null;
        }
        return React.createElement(Caret, { open: open, svg: caret === 'svg' });
    }, [caret, open]);
    const onClick = useCallback((e) => {
        e.preventDefault();
    }, []);
    const toggle = useCallback(() => {
        setOpen((wasOpen) => !wasOpen);
    }, []);
    const getOnItemClick = useCallback((index, originalHandler) => (event) => {
        event.preventDefault();
        if (originalHandler && typeof originalHandler === 'function') {
            originalHandler();
        }
        if (onChange && childrenArray[index]) {
            const { value } = childrenArray[index].props || {};
            onChange(value);
        }
    }, [onChange, childrenArray]);
    const onMenuToggleRef = useRef(onMenuToggle);
    useEffect(() => {
        onMenuToggleRef.current = onMenuToggle;
    }, [onMenuToggle]);
    useEffect(() => {
        if (typeof onMenuToggleRef.current === 'function') {
            onMenuToggleRef.current(open);
        }
    }, [onMenuToggle, open]);
    return (React.createElement(StyledDropdown, { toggle: toggle, isOpen: open, onClick: onClick, direction: direction, className: className },
        React.createElement(StyledDropdownToggle, Object.assign({}, toggleProps, { tag: "div", className: toggleClassName, disabled: !!disabled }),
            React.createElement(ToggleLabel, null, titleComponent),
            caretComponent),
        childrenArray.length > 0 && (React.createElement(StyledDropdownMenu, Object.assign({}, menuProps, { className: menuClassName, container: "body" }), React.Children.map(children, (child, index) => child &&
            React.cloneElement(child, {
                active: !!child.props.value &&
                    child.props.value === selectedItem,
                onClick: getOnItemClick(index, child.props.onClick),
                leftTick: !!leftTick,
            }))))));
};
Popover.defaultProps = {
    toggleProps: {},
    menuProps: {},
    type: 'normal',
};
export default Popover;
