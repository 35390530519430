import BN from 'bignumber.js';
import { ProjectType } from '$shared/types';
import { getConfigForChain } from '$shared/web3/config';
import { getMostRelevantTimeUnit } from '$mp/utils/price';
import { getTokenInformation } from '$mp/utils/web3';
import { fromDecimals } from '$mp/utils/math';
import { timeUnitSecondsMultiplierMap } from '$shared/utils/timeUnit';
import getCoreConfig from '$app/src/getters/getCoreConfig';
/**
 * @deprecated Use `getProjectImageUrl`.
 */
export const mapImageUrl = (graphProject) => {
    const { ipfs } = getCoreConfig();
    const { ipfsGatewayUrl } = ipfs;
    // @ts-expect-error 2339: Metadata might contain old imageUrl property
    let imageUrl = graphProject.metadata.imageUrl;
    if (graphProject.metadata.imageIpfsCid) {
        imageUrl = ipfsGatewayUrl + graphProject.metadata.imageIpfsCid;
    }
    // Replace old ipfs.io references with new IPFS gateway
    if (imageUrl != null && imageUrl.startsWith('https://ipfs.io/ipfs/')) {
        imageUrl = imageUrl.replace('https://ipfs.io/ipfs/', ipfsGatewayUrl);
    }
    return imageUrl;
};
/**
 * @deprecated Use `projectEditor` to obtain both the graph projects and their local reflections.
 */
export const mapGraphProjectToDomainModel = async (graphProject) => {
    return {
        id: graphProject.id,
        type: mapProjectType(graphProject),
        name: graphProject.metadata.name,
        description: graphProject.metadata.description,
        creator: graphProject.metadata.creator,
        imageUrl: mapImageUrl(graphProject),
        imageIpfsCid: graphProject.metadata.imageIpfsCid,
        streams: graphProject.streams,
        termsOfUse: Object.assign({}, graphProject.metadata.termsOfUse),
        contact: Object.assign({}, graphProject.metadata.contactDetails),
        salePoints: await mapSalePoints(graphProject.paymentDetails),
    };
};
/**
 * @deprecated Use `projectEditor` to obtain both the graph projects and their local reflections.
 */
export const mapProjectType = (graphProject) => {
    if (graphProject.isDataUnion) {
        return ProjectType.DataUnion;
    }
    return graphProject.paymentDetails.length === 1 &&
        graphProject.paymentDetails[0].pricePerSecond == '0'
        ? ProjectType.OpenData
        : ProjectType.PaidData;
};
/**
 * @deprecated Use `projectEditor` to obtain both the graph projects and their local reflections.
 */
export const mapSalePoints = async (paymentDetails) => {
    const salePoints = {};
    await Promise.all(paymentDetails.map(async (paymentDetail) => {
        const chainConfig = getConfigForChain(Number(paymentDetail.domainId));
        const tokenInfo = await getTokenInformation(paymentDetail.pricingTokenAddress, chainConfig.id);
        const pricePerSecondFromDecimals = fromDecimals(paymentDetail.pricePerSecond, String(tokenInfo.decimals));
        const timeUnit = getMostRelevantTimeUnit(pricePerSecondFromDecimals);
        salePoints[chainConfig.name] = {
            chainId: chainConfig.id,
            pricingTokenAddress: paymentDetail.pricingTokenAddress.toLowerCase(),
            pricePerSecond: new BN(paymentDetail.pricePerSecond),
            beneficiaryAddress: paymentDetail.beneficiary.toLowerCase(),
            timeUnit,
            price: pricePerSecondFromDecimals.multipliedBy(timeUnitSecondsMultiplierMap.get(timeUnit)),
        };
    }));
    return salePoints;
};
