import { ProjectType } from '$shared/types';
export class EmptyProject {
    constructor() {
        this.id = undefined;
        this.name = '';
        this.creator = '';
        this.adminFee = undefined;
        this.type = ProjectType.OpenData;
        this.contact = {};
        this.imageUrl = undefined;
        this.streams = [];
        this.description = '';
        this.newImageToUpload = undefined;
        this.termsOfUse = {
            commercialUse: false,
            redistribution: false,
            reselling: false,
            storage: false,
            termsName: undefined,
            termsUrl: undefined,
        };
        this.existingDUAddress = undefined;
        this.salePoints = {};
    }
}
