var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { ERROR } from '$ui/StateColors';
export const MarketplaceTheme = {
    color: '#ff5c00',
};
const DefaultTheme = {
    color: ERROR,
};
const UnstyledErrors = (_a) => {
    var { children, theme, overlap } = _a, props = __rest(_a, ["children", "theme", "overlap"]);
    return (React.createElement("div", Object.assign({}, props), children || React.createElement(Fragment, null, "\u200C")));
};
const Errors = styled(UnstyledErrors).withConfig({ displayName: "Errors", componentId: "sc-xgfwg5" }) `
    color: ${({ theme }) => theme.color};
    font-size: 12px;
    line-height: 1.5em;
    margin-top: 6px;
    word-break: break-word;

    ${({ overlap }) => !!overlap &&
    css `
            position: absolute;
        `}
`;
Errors.defaultProps = {
    theme: DefaultTheme,
};
export default Errors;
