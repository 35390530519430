import { z } from 'zod';
import { produce } from 'immer';
import detectProvider from '@metamask/detect-provider';
import { create } from 'zustand';
import Web3 from 'web3';
import { isEthereumAddress } from '$app/src/marketplace/utils/validate';
import { lookupEnsName } from '$shared/utils/lookupEnsName';
function isStringArray(value) {
    return z.array(z.string()).safeParse(value).success;
}
let providerPromise;
/**
 * Detects current MetaMask provider, and explodes if it's not available.
 * @returns a promise that resolves with a MetaMask provider instance.
 */
export function getWalletProvider() {
    if (providerPromise) {
        return providerPromise;
    }
    providerPromise = new Promise((resolve, reject) => {
        setTimeout(async () => {
            var _a, _b;
            let provider = await detectProvider();
            if (!provider) {
                return void reject(new Error('No provider'));
            }
            if ((_a = provider.providers) === null || _a === void 0 ? void 0 : _a.length) {
                // Multiple wallets available? Detect MetaMask.
                provider =
                    (_b = provider.providers.find((p) => p.isMetaMask)) !== null && _b !== void 0 ? _b : provider.providers[0];
            }
            resolve(provider);
        });
    });
    return providerPromise;
}
export async function getWalletWeb3Provider() {
    const provider = await getWalletProvider();
    return new Web3(provider);
}
const promiseMap = new Map();
/**
 * @param options.connect a flag that instructs the function to either
 * - get the account discreetly using `eth_accounts` (if `false`) - default, or
 * - trigger the unlocking with `eth_requestAccounts`.
 *
 * @returns an account address (a string), or `undefined` if there are
 * no available accounts.
 */
export async function getWalletAccount({ connect = false, } = {}) {
    const provider = await getWalletProvider();
    if (!connect) {
        const accounts = await provider.request({
            method: 'eth_accounts',
        });
        return accounts === null || accounts === void 0 ? void 0 : accounts[0];
    }
    const existingPromise = promiseMap.get(provider);
    if (existingPromise) {
        return existingPromise;
    }
    const promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
            try {
                const accounts = await provider.request({
                    method: 'eth_requestAccounts',
                });
                resolve(accounts === null || accounts === void 0 ? void 0 : accounts[0]);
            }
            catch (e) {
                reject(e);
            }
        });
    });
    promiseMap.set(provider, promise);
    setTimeout(async () => {
        try {
            await promise;
        }
        catch (e) {
            // Do nothing.
        }
        finally {
            promiseMap.delete(provider);
        }
    });
    return promise;
}
const useWalletStore = create((set, get) => {
    let lastKnownAccount;
    const ensLookups = {};
    function onAccountsChange(accounts) {
        const [account = undefined] = isStringArray(accounts) ? accounts : [];
        const addr = isEthereumAddress(account || '') ? account : undefined;
        if (lastKnownAccount === addr) {
            return;
        }
        lastKnownAccount = addr;
        if (addr && typeof get().ens[addr] === 'undefined' && !ensLookups[addr]) {
            ensLookups[addr] = true;
            setTimeout(async () => {
                try {
                    const ensName = await lookupEnsName(addr);
                    set((current) => produce(current, (next) => {
                        next.ens[addr.toLowerCase()] = ensName;
                    }));
                }
                catch (e) {
                    console.warn('Failed to fetch ENS domain name', e);
                }
                finally {
                    delete ensLookups[addr];
                }
            });
        }
        set((current) => produce(current, (next) => {
            next.account = addr;
        }));
    }
    setTimeout(async () => {
        try {
            const provider = await getWalletProvider();
            provider.on('accountsChanged', onAccountsChange);
            const accounts = await provider.request({
                method: 'eth_accounts',
            });
            onAccountsChange(accounts);
        }
        catch (e) {
            console.warn('Provider setup failed', e);
        }
    });
    return {
        account: undefined,
        ens: {},
    };
});
/**
 * A hook that gives you the current MetaMask account address, if available.
 * @returns account address (a string), or `undefined` if the wallet is either
 * locked or has no accounts.
 */
export function useWalletAccount() {
    return useWalletStore().account;
}
/**
 * A hook that gives you an ENS domain for an account address.
 * @returns either an ENS domain name, or an empty string.
 */
export function useEns(account) {
    const { ens } = useWalletStore();
    return (account && ens[account]) || '';
}
