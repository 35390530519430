var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { COLORS, SANS, TABLET } from '$shared/utils/styled';
import Buttons from '$shared/components/Buttons';
import SvgIcon from '$shared/components/SvgIcon';
import BaseModal, { Footer, RejectionReason } from './BaseModal';
export default function FormModal(_a) {
    var { title = 'Untitled modal', onSubmit, children, onReject, submitLabel = 'Submit', cancelLabel, canSubmit = true } = _a, props = __rest(_a, ["title", "onSubmit", "children", "onReject", "submitLabel", "cancelLabel", "canSubmit"]);
    const actions = {};
    if (cancelLabel) {
        actions.cancel = {
            title: cancelLabel,
            kind: 'link',
            type: 'button',
            onClick() {
                onReject === null || onReject === void 0 ? void 0 : onReject(RejectionReason.CancelButton);
            },
        };
    }
    actions.ok = {
        title: submitLabel,
        kind: 'primary',
        type: 'submit',
        disabled: !canSubmit,
    };
    return (React.createElement(BaseModal, Object.assign({}, props, { onReject: onReject }),
        React.createElement("form", { onSubmit: async (e) => {
                e.preventDefault();
                await (onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit());
            } },
            React.createElement(Root, null,
                React.createElement(Head, null,
                    React.createElement(Title, null, title),
                    React.createElement(CloseButton, { type: "button", onClick: () => {
                            onReject === null || onReject === void 0 ? void 0 : onReject(RejectionReason.CloseButton);
                        } },
                        React.createElement(SvgIcon, { name: "crossMedium" }))),
                React.createElement(Content, null, children),
                React.createElement(Footer, { "$borderless": true, "$spacious": true },
                    React.createElement(Buttons, { actions: actions }))))));
}
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-wm9kz1" }) `
    padding: 0 40px;
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1bocai8" }) `
    max-width: 672px;
    width: 90vw;
`;
const Head = styled.div.withConfig({ displayName: "Head", componentId: "sc-uvmn3d" }) `
    align-items: center;
    display: flex;
    font-family: ${SANS};
    font-size: 24px;
    font-weight: normal;
    height: 100px;
    line-height: normal;
    margin: 0;
    position: relative;
    padding: 20px 40px 0;
    width: 100%;

    @media ${TABLET} {
        height: 120px;
        padding-top: 0;
    }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1fpupgu" }) `
    flex-grow: 1;
`;
const CloseButton = styled.button.withConfig({ displayName: "CloseButton", componentId: "sc-1djft7a" }) `
    color: ${COLORS.close};
    line-height: 14px;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0;
    background: none;
    outline: none;
    border: none;

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    & > svg {
        width: 14px;
        height: 14px;
    }
`;
