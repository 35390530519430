import React from 'react';
import EmptyState from '$shared/components/EmptyState';
import emptyStateIcon from '$shared/assets/images/empty_state_icon.png';
import emptyStateIcon2x from '$shared/assets/images/empty_state_icon@2x.png';
export default function NoProductsView({ noOwnProjects = false }) {
    return (React.createElement(EmptyState, { image: React.createElement("img", { src: emptyStateIcon, srcSet: `${emptyStateIcon2x} 2x`, alt: "Not found" }) }, noOwnProjects ? (React.createElement("p", null,
        React.createElement("span", null, "You haven't created any projects yet"))) : (React.createElement("p", null,
        React.createElement("span", null, "We couldn't find anything to match your search"),
        React.createElement("small", null, "Please try some different keywords")))));
}
