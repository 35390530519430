import React, { useEffect, useState } from 'react';
import BN from 'bignumber.js';
import { contractCurrencies as currencies } from '$shared/utils/constants';
import { formatPrice } from '$mp/utils/price';
import { getTokenInformation } from '$mp/utils/web3';
import useIsMounted from '$shared/hooks/useIsMounted';
const PaymentRate = (props) => {
    const { amount, pricingTokenAddress, chainId, timeUnit, className, tag: Tag = 'div', } = props;
    const [currency, setCurrency] = useState(currencies.PRODUCT_DEFINED);
    const [symbol, setSymbol] = useState(currencies.DATA);
    const [decimals, setDecimals] = useState(new BN(18));
    const isMounted = useIsMounted();
    useEffect(() => {
        const check = async () => {
            if (pricingTokenAddress != null) {
                const info = await getTokenInformation(pricingTokenAddress, chainId);
                if (isMounted() && info) {
                    setCurrency(currencies.PRODUCT_DEFINED);
                    setSymbol(info.symbol);
                    setDecimals(new BN(info.decimals));
                }
            }
        };
        check();
    }, [pricingTokenAddress, chainId, isMounted]);
    return (React.createElement(Tag, { className: className }, formatPrice(amount, currency, decimals, timeUnit, symbol)));
};
export default PaymentRate;
