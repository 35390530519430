import { useCallback, useReducer } from 'react';
export const useStateContainer = (defaultValue) => {
    // In case uf you are wondering why there is a useReducer hook instead of useState?
    // The answer is - useState was causing consistency issues when changes were happening too fast
    const reducer = (state, action) => (Object.assign(Object.assign({}, state), action.payload));
    const [state, dispatch] = useReducer(reducer, defaultValue || {});
    const updateState = useCallback((value) => {
        dispatch({ payload: value });
        // Disabled because: React Hook useCallback has a missing dependency: 'T'
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    return {
        state,
        updateState,
    };
};
