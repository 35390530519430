import React from 'react';
import styled from 'styled-components';
import Buttons from '$shared/components/Buttons';
import PrestyledLoadingIndicator from '$shared/components/LoadingIndicator';
import { COLORS } from '$shared/utils/styled';
import Modal from './Modal';
import { Footer } from './BaseModal';
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-15shdfi" }) `
    margin: 0 auto;
    padding: 48px 0 56px;
    width: 448px;

    p {
        margin: 0 0 16px;
    }
`;
const LoadingIndicator = styled(PrestyledLoadingIndicator).withConfig({ displayName: "LoadingIndicator", componentId: "sc-19jqovc" }) `
    div {
        border-radius: 8px;
        height: 8px;
    }

    div:before,
    div:after {
        border-radius: 8px;
    }

    div:before {
        background-color: ${COLORS.secondaryHover};
    }
`;
export default function AccessingProjectModal() {
    return (React.createElement(Modal, { title: "Accessing project", onBeforeAbort: () => false, darkBackdrop: true },
        React.createElement(Content, null,
            React.createElement("p", null, "Accessing\u2026"),
            React.createElement(LoadingIndicator, { loading: true })),
        React.createElement(Footer, null,
            React.createElement(Buttons, { actions: {
                    ok: {
                        title: 'Waiting',
                        kind: 'primary',
                        spinner: true,
                    },
                } }))));
}
