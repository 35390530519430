import React, { useContext } from 'react';
import styled from 'styled-components';
import { ProjectPageContainer } from '$shared/components/ProjectPage';
import { ProjectHeroContainer } from '$mp/containers/ProjectPage/Hero/ProjectHero2.styles';
import { CoverImage } from '$mp/containers/ProjectEditing/CoverImage';
import ProjectName from '$mp/containers/ProjectEditing/ProjectName';
import ProjectDescription from '$mp/containers/ProjectEditing/ProjectDescription';
import { ProjectDetails } from '$mp/containers/ProjectEditing/ProjectDetails';
import { WhiteBox } from '$shared/components/WhiteBox';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { ProjectType } from '$shared/types';
import { StreamSelector } from '$mp/containers/ProjectEditing/StreamSelector';
import { TermsOfUse } from '$mp/containers/ProjectEditing/TermsOfUse';
import { SalePointSelector } from '$mp/containers/ProjectEditing/SalePointSelector/SalePointSelector';
import { DataUnionChainSelector } from '$mp/containers/ProjectEditing/DataUnionChainSelector/DataUnionChainSelector';
import { DataUnionTokenSelector } from '$mp/containers/ProjectEditing/DataUnionTokenSelector/DataUnionTokenSelector';
import { DataUnionFee } from '$mp/containers/ProjectEditing/DataUnionFee';
import { ProjectControllerContext } from '$mp/containers/ProjectEditing/ProjectController';
import getCoreConfig from '$app/src/getters/getCoreConfig';
import { DataUnionSecretsContextProvider } from '$mp/modules/dataUnion/dataUnionSecretsContext';
import { ProjectPermission, useProjectAbility } from '$shared/stores/projectAbilities';
import { useWalletAccount } from '$shared/stores/wallet';
import DeleteProject from './DeleteProject';
import { DataUnionSecrets } from './DataUnionSecrets';
const WhiteBoxWithMargin = styled(WhiteBox).withConfig({ displayName: "WhiteBoxWithMargin", componentId: "sc-14gativ" }) `
    margin-top: 24px;
`;
const TransparentBoxWithMargin = styled(WhiteBoxWithMargin).withConfig({ displayName: "TransparentBoxWithMargin", componentId: "sc-1wq19oz" }) `
    background-color: transparent;
    border: 1px solid #cdcdcd;
`;
const EditorOverlay = styled.div.withConfig({ displayName: "EditorOverlay", componentId: "sc-1rw2hmd" }) `
    position: absolute;
    width: 100%;
    height: 100%;
    top: 2px;
    left: 0;
    background-color: white;
    opacity: 0.6;
`;
export const ProjectEditor = ({ nonEditableSalePointChains = [], editMode = false, }) => {
    const { state: project } = useContext(ProjectStateContext);
    const { publishInProgress } = useContext(ProjectControllerContext);
    const { chainId } = getCoreConfig().projectRegistry;
    const canDelete = useProjectAbility(chainId, (project === null || project === void 0 ? void 0 : project.id) || undefined, useWalletAccount(), ProjectPermission.Delete);
    return (React.createElement(ProjectPageContainer, null,
        React.createElement(ProjectHeroContainer, { overflowVisible: true },
            React.createElement(CoverImage, null),
            React.createElement(ProjectName, null),
            React.createElement(ProjectDescription, null),
            React.createElement(ProjectDetails, null)),
        (project === null || project === void 0 ? void 0 : project.type) === ProjectType.PaidData && (React.createElement(WhiteBox, { className: 'with-padding' },
            React.createElement(SalePointSelector, { nonEditableSalePointChains: nonEditableSalePointChains }))),
        (project === null || project === void 0 ? void 0 : project.type) === ProjectType.DataUnion && (React.createElement(React.Fragment, null,
            (project === null || project === void 0 ? void 0 : project.id) == null && (React.createElement(WhiteBox, { className: 'with-padding' },
                React.createElement(DataUnionChainSelector, { editMode: editMode }))),
            React.createElement(WhiteBoxWithMargin, { className: 'with-padding' },
                React.createElement(DataUnionTokenSelector, { editMode: editMode }),
                React.createElement(DataUnionFee, null)))),
        React.createElement(WhiteBoxWithMargin, { className: 'with-padding' },
            React.createElement(StreamSelector, null)),
        React.createElement(WhiteBoxWithMargin, { className: 'with-padding' },
            React.createElement(TermsOfUse, null)),
        (project === null || project === void 0 ? void 0 : project.type) === ProjectType.DataUnion &&
            project.existingDUAddress != null && (React.createElement(WhiteBoxWithMargin, { className: 'with-padding' },
            React.createElement(DataUnionSecretsContextProvider, null,
                React.createElement(DataUnionSecrets, null)))),
        (project === null || project === void 0 ? void 0 : project.id) && canDelete && (React.createElement(TransparentBoxWithMargin, { className: 'with-padding' },
            React.createElement(DeleteProject, null))),
        publishInProgress && React.createElement(EditorOverlay, null)));
};
