import React from 'react';
import { getProjectTypeTitle } from '$app/src/getters';
export const getProjectTitleForEditor = (project) => {
    return (!!project.creator && (React.createElement(React.Fragment, null,
        getProjectTypeTitle(project.type),
        " by",
        React.createElement("strong", null,
            "\u00A0",
            project.creator,
            " "))));
};
