var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { useImage } from 'react-image';
import { getTokenLogoUrl } from '$shared/utils/tokenAssets';
import GenericTokenLogo from './GenericTokenLogo';
const Image = styled.img.withConfig({ displayName: "Image", componentId: "sc-1oownrr" }) `
    width: 24px;
    height: 24px;
`;
const UnstyledTokenLogo = (_a) => {
    var { contractAddress, symbol, chainId } = _a, props = __rest(_a, ["contractAddress", "symbol", "chainId"]);
    const logoUrl = contractAddress ? getTokenLogoUrl(contractAddress, chainId) : '';
    const { src: imgSrc } = useImage({
        useSuspense: false,
        srcList: [logoUrl],
    });
    return imgSrc ? (React.createElement(Image, Object.assign({}, props, { src: imgSrc, alt: "Token logo" }))) : (React.createElement(GenericTokenLogo, { contractAddress: contractAddress, symbol: symbol }));
};
const TokenLogo = styled(UnstyledTokenLogo).withConfig({ displayName: "TokenLogo", componentId: "sc-18rbvz3" }) ``;
export default TokenLogo;
