var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, forwardRef } from 'react';
import useIsMounted from '$shared/hooks/useIsMounted';
const SelectAllOnFocusDecorator = (WrappedComponent) => {
    const SelectAllOnFocusDecoratorWrapper = (_a, ref) => {
        var { onFocus: onFocusProp } = _a, props = __rest(_a, ["onFocus"]);
        const isMounted = useIsMounted();
        const onFocus = useCallback((e) => {
            e.persist();
            setTimeout(() => {
                if (isMounted() &&
                    (e.target instanceof HTMLInputElement ||
                        e.target instanceof HTMLTextAreaElement)) {
                    e.target.select();
                }
            }, 0);
            if (onFocusProp) {
                onFocusProp(e);
            }
        }, [onFocusProp, isMounted]);
        return React.createElement(WrappedComponent, Object.assign({}, props, { onFocus: onFocus, ref: ref }));
    };
    const SelectAllOnFocusDecoratorWrapperFR = forwardRef(SelectAllOnFocusDecoratorWrapper);
    const OptInSelectAllOnFocusDecoratorWrapper = (_a, ref) => {
        var { selectAllOnFocus = false } = _a, props = __rest(_a, ["selectAllOnFocus"]);
        return selectAllOnFocus ? (React.createElement(SelectAllOnFocusDecoratorWrapperFR, Object.assign({}, props, { ref: ref }))) : (React.createElement(WrappedComponent, Object.assign({}, props, { ref: ref })));
    };
    return forwardRef(OptInSelectAllOnFocusDecoratorWrapper);
};
export default SelectAllOnFocusDecorator;
