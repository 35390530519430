import { toaster } from 'toasterhea';
import Toast, { ToastType } from '$shared/toasts/Toast';
import { Layer } from './Layer';
export default function toast(props) {
    setTimeout(async () => {
        try {
            await toaster(Toast, Layer.Toast).pop(props);
        }
        catch (_) {
            // Do nothing
        }
    });
}
export function successToast(props) {
    toast(Object.assign({ type: ToastType.Success }, props));
}
export function errorToast(props) {
    toast(Object.assign({ type: ToastType.Warning }, props));
}
