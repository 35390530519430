var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { SANS } from '$shared/utils/styled';
import BaseModal from './BaseModal';
/**
 * `BaseModal` with centered title bar with a subtle bottom border.
 */
export default function Modal(_a) {
    var { children, title = 'Untitled modal' } = _a, props = __rest(_a, ["children", "title"]);
    return (React.createElement(BaseModal, Object.assign({}, props),
        React.createElement(Root, null,
            React.createElement(Head, null,
                React.createElement(Title, null, title)),
            children)));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1gztylq" }) `
    max-width: 560px;
    width: 90vw;
`;
const Head = styled.div.withConfig({ displayName: "Head", componentId: "sc-1oe2pt" }) `
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    font-family: ${SANS};
    font-size: 20px;
    font-weight: normal;
    height: 80px;
    line-height: 32px;
    margin: 0;
    position: relative;
    text-align: center;
    width: 100%;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-103okee" }) `
    flex-grow: 1;
`;
