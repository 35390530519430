import getPublicWeb3 from '$utils/web3/getPublicWeb3';
import WrongNetworkSelectedError from '$shared/errors/WrongNetworkSelectedError';
import getChainId from '$utils/web3/getChainId';
export const checkEthereumNetworkIsCorrect = async ({ network, } = {}) => {
    const currentChainId = await getChainId();
    if (currentChainId == null ||
        network == null ||
        network.toString() !== currentChainId.toString()) {
        throw new WrongNetworkSelectedError(network, currentChainId);
    }
};
export const hasTransactionCompleted = (txHash, chainId) => getPublicWeb3(chainId)
    .eth.getTransaction(txHash)
    .then((trx) => !!(trx && trx.blockNumber));
export const getTransactionReceipt = (txHash, chainId) => getPublicWeb3(chainId)
    .eth.getTransactionReceipt(txHash)
    .then((receipt) => receipt);
