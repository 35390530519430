var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useLayoutEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
export const history = createBrowserHistory({ window });
/**
 * Custom browser Router that allows us to access `history`.
 */
export function HubRouter(_a) {
    var { history: historyProp = history } = _a, props = __rest(_a, ["history"]);
    const [state, setState] = useState({
        action: historyProp.action,
        location: historyProp.location,
    });
    useLayoutEffect(() => {
        return historyProp.listen(setState);
    }, [historyProp]);
    return (React.createElement(Router, Object.assign({}, props, { location: state.location, navigationType: state.action, navigator: historyProp })));
}
