var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import UnstyledSpinner from '$shared/components/Spinner';
import Link from '$shared/components/Link';
const SharedTheme = {
    backgroundColor: '#525252',
};
const SingleBadge = styled.div.withConfig({ displayName: "SingleBadge", componentId: "sc-7m44pb" }) `
    display: flex;
    align-items: center;
    font-size: 10px;
    height: 15px;
    line-height: 12px;
    padding: 0 4px;
    background-color: #0324ff;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: white !important;

    a,
    a:link,
    a:active,
    a:focus,
    a:hover,
    a:visited {
        color: white !important;
    }

    > * + * {
        margin-left: 8px;
    }
`;
const BadgeContainer = styled.div.withConfig({ displayName: "BadgeContainer", componentId: "sc-191og7b" }) `
    align-items: center;
    color: white !important;
    display: flex;
    cursor: default;
    pointer-events: none;
    user-select: none;
    margin: 0.5em;

    a& {
        cursor: pointer;
        pointer-events: auto;
        text-decoration: none !important;
    }

    ${({ top, left, right, bottom }) => !!(top || left || right || bottom) &&
    css `
            position: absolute;
        `}

    ${({ bottom, top }) => !!bottom &&
    !top &&
    css `
            bottom: 0;
        `}

    ${({ left }) => !!left &&
    css `
            left: 0;
        `}

    ${({ right, left }) => !!right &&
    !left &&
    css `
            right: 0;
        `}

    ${({ top }) => !!top &&
    css `
            top: 0;
        `}

    > ${SingleBadge} {
        margin-left: 1px;
    }

    ${SingleBadge}:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    ${SingleBadge}:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    ${SingleBadge}:only-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;
const Spinner = styled(UnstyledSpinner).withConfig({ displayName: "Spinner", componentId: "sc-1n9j86w" }) `
    height: 10px;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 10px;
`;
const DeployingBadge = (props) => (React.createElement(BadgeContainer, Object.assign({}, props),
    React.createElement(SingleBadge, null,
        React.createElement("span", null, "Deploying"),
        React.createElement(Spinner, { size: "small", color: "white" }))));
const DataUnionBadge = (_a) => {
    var { memberCount, linkTo, linkHref } = _a, props = __rest(_a, ["memberCount", "linkTo", "linkHref"]);
    return (React.createElement(BadgeContainer, Object.assign({}, props),
        React.createElement(SingleBadge, null, "Data Union"),
        memberCount != null && (React.createElement(SingleBadge, null,
            React.createElement(BadgeLink, { to: linkTo, href: linkHref }, memberCount),
            "\u00A0 members"))));
};
const SharedBadge = (props) => (React.createElement(BadgeContainer, Object.assign({}, props),
    React.createElement(SingleBadge, { theme: SharedTheme },
        React.createElement("span", null, "Shared"))));
const BadgeLink = (_a) => {
    var props = __rest(_a, []);
    return React.createElement(Link, Object.assign({}, props));
};
export { DataUnionBadge, DeployingBadge, SharedBadge, SharedTheme, BadgeLink };
