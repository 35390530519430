import React, { useCallback, useContext, useMemo, useState, } from 'react';
import styled from 'styled-components';
import BN from 'bignumber.js';
import { REGULAR } from '$shared/utils/styled';
import getCoreConfig from '$app/src/getters/getCoreConfig';
import { getConfigForChainByName } from '$shared/web3/config';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
import { PricingOption } from './PricingOption';
export const SalePointSelector = ({ nonEditableSalePointChains, }) => {
    const { marketplaceChains } = getCoreConfig();
    const chains = useMemo(() => marketplaceChains.map((chainName) => getConfigForChainByName(chainName)), [marketplaceChains]);
    const { state: project } = useContext(ProjectStateContext);
    const { salePoints } = project;
    const { updateSalePoints } = useEditableProjectActions();
    const [firstToggleDone, setFirstToggleDone] = useState(false);
    const handleSalePointChange = useCallback((chainName, newSalePoint) => {
        const chain = getConfigForChainByName(chainName);
        if (nonEditableSalePointChains.includes(chain.id)) {
            return;
        }
        // handle removal of sale point
        if (!newSalePoint) {
            // case when we are creating a new project and the components emit changes on mounting
            if (!salePoints || !Object.values(salePoints || {}).length) {
                return;
            }
            const newSalePointsObject = Object.assign({}, salePoints);
            delete newSalePointsObject[chainName];
            updateSalePoints(newSalePointsObject);
            return;
        }
        // handle updates & adding a new sale point
        const newSalePointsObject = Object.assign(Object.assign({}, (salePoints || {})), { [chainName]: newSalePoint });
        updateSalePoints(newSalePointsObject);
    }, [nonEditableSalePointChains, salePoints, updateSalePoints]);
    const handleToggle = useCallback((chainName, salePoint) => {
        if (!firstToggleDone &&
            (!salePoints || (salePoints && !Object.values(salePoints).length))) {
            updateSalePoints({ [chainName]: salePoint });
            setFirstToggleDone(true);
        }
    }, [firstToggleDone, salePoints, updateSalePoints]);
    return (React.createElement(SalePointSelectorContainer, null,
        React.createElement(Heading, null, "Select chains"),
        React.createElement(SubHeading, null, "Access to the project data can be purchased on the selected chains. You can set the payment token, price, and beneficiary address on each chain separately."), chains === null || chains === void 0 ? void 0 :
        chains.map((chain, index) => {
            const salePoint = salePoints && salePoints[chain.name];
            const salePointData = salePoint
                ? {
                    tokenAddress: salePoint.pricingTokenAddress,
                    price: salePoint.price ? new BN(salePoint.price) : undefined,
                    timeUnit: salePoint.timeUnit,
                    pricePerSecond: salePoint.pricePerSecond
                        ? new BN(salePoint.pricePerSecond)
                        : undefined,
                    beneficiaryAddress: salePoint.beneficiaryAddress,
                }
                : null;
            return (React.createElement(PricingOption, { key: chain.id, chain: chain, onChange: handleSalePointChange, pricingData: salePointData, onToggle: handleToggle, editingSelectionAndTokenDisabled: nonEditableSalePointChains.includes(chain.id) }));
        })));
};
const SalePointSelectorContainer = styled.div.withConfig({ displayName: "SalePointSelectorContainer", componentId: "sc-n9pp87" }) `
    max-width: 728px;
`;
const Heading = styled.p.withConfig({ displayName: "Heading", componentId: "sc-8g955a" }) `
    color: black;
    font-weight: ${REGULAR};
    font-size: 20px;
    margin-bottom: 24px;
`;
const SubHeading = styled.p.withConfig({ displayName: "SubHeading", componentId: "sc-r15v4o" }) `
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: black;
`;
