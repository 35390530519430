import React, { useContext } from 'react';
import styled from 'styled-components';
import { COLORS, MAX_CONTENT_WIDTH } from '$shared/utils/styled';
import Text from '$ui/Text';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
import useValidation from '$mp/containers/ProductController/useValidation';
import { SeverityLevel } from '$mp/containers/ProductController/ValidationContextProvider';
const Heading = styled.p.withConfig({ displayName: "Heading", componentId: "sc-4v0dtw" }) `
    font-size: 20px;
    color: black;
    margin-top: 30px;
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1vb4z6t" }) `
    background-color: ${COLORS.inputBackground};
    padding: 12px 24px;
    max-width: ${MAX_CONTENT_WIDTH};
`;
const DescriptionText = styled.p.withConfig({ displayName: "DescriptionText", componentId: "sc-69ydrf" }) `
    color: black;
    margin-bottom: 0;
    margin-right: 55px;
    flex-shrink: 0;

    &.with-margin {
        margin-bottom: 15px;
    }
`;
const StyledInput = styled(Text).withConfig({ displayName: "StyledInput", componentId: "sc-l97q4w" }) `
    flex-shrink: 1;
`;
const AdminFeeInputWrap = styled.div.withConfig({ displayName: "AdminFeeInputWrap", componentId: "sc-blqrra" }) `
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    .percent-input {
        padding-right: 40px;
        &:disabled {
            background-color: white;
            opacity: 1;
        }
    }
    .percent-symbol {
        position: absolute;
        right: 12px;
        top: 0;
        height: 100%;
        font-size: 14px;
        border-left: 1px solid ${COLORS.separator};
        padding-left: 10px;
        color: ${COLORS.primaryLight};
        display: flex;
        align-items: center;
    }
`;
export const DataUnionFee = ({ disabled = false, }) => {
    const { state: project } = useContext(ProjectStateContext);
    const { updateAdminFee } = useEditableProjectActions();
    const { setStatus, isValid, clearStatus } = useValidation('adminFee');
    const handleUpdate = (value) => {
        const number = Number(value);
        if (number < 0) {
            setStatus(SeverityLevel.ERROR, 'Data Union admin fee cannot be under 0%');
        }
        else if (number > 100) {
            setStatus(SeverityLevel.ERROR, 'Data Union admin fee cannot be over 100%');
        }
        else {
            clearStatus();
        }
        updateAdminFee(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, null, "Data Union admin fee"),
        React.createElement(Container, null,
            React.createElement(AdminFeeInputWrap, null,
                React.createElement(DescriptionText, null, "Set the fee you receive from this Data Union (% of total sales)"),
                React.createElement(StyledInput, { className: 'percent-input', placeholder: 'Fee', value: project.adminFee || '', disabled: disabled ||
                        !project.dataUnionChainId ||
                        !!project.existingDUAddress, onChange: (event) => {
                        handleUpdate(event.target.value);
                    }, invalid: !isValid }),
                React.createElement("span", { className: 'percent-symbol' }, "%")))));
};
