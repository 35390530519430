var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Img } from 'react-image';
import { ago } from '$shared/utils/time';
import Logo from '$shared/components/Logo';
import Skeleton from '$shared/components/Skeleton';
import Rect from '$shared/components/Rect';
import Link from '$shared/components/Link';
import SvgIcon from '$shared/components/SvgIcon';
import { COLORS } from '$shared/utils/styled';
import { getProjectImageUrl } from '$app/src/getters';
import routes from '$routes';
import { HAPPY, ANGRY, WORRIED } from './Label';
import Summary from './Summary';
import Menu from './Menu';
import { DataUnionBadge, DeployingBadge } from './Badge';
const Image = styled(Img).withConfig({ displayName: "Image", componentId: "sc-ih5ci7" }) `
    img& {
        display: block;
        object-fit: cover;
    }
`;
const Placeholder = styled.div.withConfig({ displayName: "Placeholder", componentId: "sc-17cuhb1" }) `
    background-image: linear-gradient(135deg, #0045ff 0%, #7200ee 100%);

    ${Logo} {
        height: auto;
        left: 50%;
        max-width: 32%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
    }
`;
const UnstyledThumbnail = (_a) => {
    var { src, skeletonize, alt } = _a, props = __rest(_a, ["src", "skeletonize", "alt"]);
    return !!src &&
        (skeletonize ? (React.createElement(Image, Object.assign({}, props, { as: Skeleton, block: true }))) : (React.createElement(Image, Object.assign({}, props, { alt: alt, src: src, loader: React.createElement(Image, Object.assign({}, props, { as: Skeleton, block: true })), unloader: React.createElement(Image, Object.assign({}, props, { as: Placeholder }),
                React.createElement(Logo, { color: "black", opacity: "0.15" })) }))));
};
export const TileThumbnail = styled(UnstyledThumbnail).withConfig({ displayName: "TileThumbnail", componentId: "sc-2sy4p4" }) `
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;
const Tile = styled.div.withConfig({ displayName: "Tile", componentId: "sc-3gn33r" }) `
    position: relative;

    ${Menu} {
        opacity: 0;
        pointer-events: none;
        transition-property: visibility, opacity;
        transition: 200ms;
        visibility: hidden;
    }

    ${Menu}.show,
    :hover ${Menu},
    :focus ${Menu} {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }

    ${({ suppressHover }) => !suppressHover &&
    css `
            ${TileThumbnail} {
                filter: brightness(100%);
                transition: 240ms ease-out filter;
            }

            ${Menu}.show + a ${TileThumbnail},
        :hover ${TileThumbnail} {
                filter: brightness(70%);
                transition-duration: 40ms;
            }
        `}
`;
const EditButton = styled(Link).withConfig({ displayName: "EditButton", componentId: "sc-ca8zj" }) `
    border: none;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    svg {
        color: ${COLORS.primary};
    }
`;
const UnstyledTileImageContainer = (_a) => {
    var { children, height, autoSize: autoSizeProp } = _a, props = __rest(_a, ["children", "height", "autoSize"]);
    const autoSize = autoSizeProp === true || height != null;
    return (React.createElement("div", Object.assign({}, props),
        children,
        !!autoSize && React.createElement(Rect, { height: height })));
};
export const TileImageContainer = styled(UnstyledTileImageContainer).withConfig({ displayName: "TileImageContainer", componentId: "sc-ch0n" }) `
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    & & {
        border-radius: 0;
        overflow: visible;
    }

    &.no-border-radius {
        border-radius: 0;
    }

    &:hover {
        ${EditButton} {
            opacity: 1;
        }
    }
`;
const ImageTile = (_a) => {
    var { alt, height, showDataUnionBadge, src, noBorderRadius } = _a, props = __rest(_a, ["alt", "height", "showDataUnionBadge", "src", "noBorderRadius"]);
    return (React.createElement(Tile, Object.assign({}, props, { suppressHover: true }),
        React.createElement(TileImageContainer, { autoSize: true, height: height, className: noBorderRadius ? 'no-border-radius' : '' },
            React.createElement(TileThumbnail, { alt: alt || '', src: src || '' }),
            !!showDataUnionBadge && React.createElement(DataUnionBadge, { top: true, left: true }))));
};
export const touchedAgo = ({ updated, created }) => `
    ${updated === created ? 'Created' : 'Updated'} ${ago(new Date(updated))}
`;
const remainingTimeToMood = (value) => {
    switch (true) {
        case value <= 0:
            return undefined;
        case value < 300:
            return ANGRY;
        case value < 3600:
            return WORRIED;
        default:
            return HAPPY;
    }
};
const ProductTile = (_a) => {
    var { actions, deployed, published, numMembers, product, showDataUnionBadge, showDeployingBadge } = _a, props = __rest(_a, ["actions", "deployed", "published", "numMembers", "product", "showDataUnionBadge", "showDeployingBadge"]);
    return (React.createElement(Tile, Object.assign({}, props),
        !!actions && React.createElement(Menu, null, actions),
        React.createElement(TileImageContainer, null,
            React.createElement(Link, { to: product.id &&
                    routes.projects.edit({
                        id: product.id,
                    }) },
                React.createElement(TileImageContainer, { autoSize: true },
                    React.createElement(TileThumbnail, { src: getProjectImageUrl(product.metadata) || '' }))),
            !!showDataUnionBadge && (React.createElement(DataUnionBadge, { top: true, left: true, memberCount: numMembers, linkTo: routes.projects.index() })),
            !!showDeployingBadge && React.createElement(DeployingBadge, { bottom: true, right: true })),
        React.createElement(Link, { to: product.id &&
                routes.projects.edit({
                    id: product.id,
                }) },
            React.createElement(Summary, { name: product.name, description: touchedAgo(product) }))));
};
const MarketplaceProductTile = (_a) => {
    var { product, showDataUnionBadge, showEditButton } = _a, props = __rest(_a, ["product", "showDataUnionBadge", "showEditButton"]);
    return (React.createElement(Tile, Object.assign({}, props),
        React.createElement(TileImageContainer, null,
            React.createElement(Link, { to: routes.projects.overview({
                    id: product.id,
                }) },
                React.createElement(TileImageContainer, { autoSize: true },
                    React.createElement(TileThumbnail, { src: getProjectImageUrl(Object.assign(Object.assign({}, product.metadata), { imageIpfsCid: product.metadata.imageIpfsCid || undefined })) || '' }))),
            !!showDataUnionBadge && (React.createElement(DataUnionBadge, { top: true, left: true, memberCount: product.members, linkTo: routes.projects.overview({
                    id: product.id,
                }, 'stats') })),
            showEditButton && (React.createElement(EditButton, { to: routes.projects.edit({ id: product.id }) },
                React.createElement(SvgIcon, { name: 'pencilFull' })))),
        React.createElement(Link, { to: routes.projects.overview({
                id: product.id,
            }) },
            React.createElement(Summary, { name: (product.metadata && product.metadata.name) || 'Untitled project', description: (product.metadata && product.metadata.creator) || '' }))));
};
export { ImageTile, MarketplaceProductTile, ProductTile };
export default Tile;
