import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getStreamsOwnedBy, getStreamsFromIndexer, } from '$app/src/services/streams';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
import { StreamSelectTable } from '$shared/components/StreamSelectTable';
import SearchBar from '$shared/components/SearchBar';
import { DESKTOP, TABLET } from '$shared/utils/styled';
import { ProjectType } from '$app/src/shared/types';
import { useWalletAccount } from '$shared/stores/wallet';
import address0 from '$app/src/utils/address0';
const PAGE_SIZE = 10;
const Heading = styled.div.withConfig({ displayName: "Heading", componentId: "sc-1mh3gce" }) `
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 16px;
    align-items: center;
    padding-bottom: 30px;

    @media ${TABLET} {
        padding-bottom: 45px;
    }

    @media ${DESKTOP} {
        padding-bottom: 55px;
    }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-rdo438" }) `
    font-size: 34px;
    line-height: 48px;
    color: black;
`;
export const StreamSelector = () => {
    var _a;
    const { state: project } = useContext(ProjectStateContext);
    const { updateStreams } = useEditableProjectActions();
    const [search, setSearch] = useState('');
    const [streams, setStreams] = useState([]);
    const [streamStats, setStreamStats] = useState({});
    const projectType = project === null || project === void 0 ? void 0 : project.type;
    const [page, setPage] = useState(0);
    const account = useWalletAccount() || address0;
    const visibleStreams = useMemo(() => streams.slice(0, (page + 1) * PAGE_SIZE), [streams, page]);
    const hasMore = useMemo(() => streams.length > visibleStreams.length, [streams.length, visibleStreams.length]);
    useEffect(() => {
        const load = async () => {
            const res = await getStreamsOwnedBy(account, search, projectType === ProjectType.OpenData);
            setStreams(res);
        };
        load();
    }, [account, projectType, search]);
    useEffect(() => {
        const loadStats = async () => {
            try {
                const start = page * PAGE_SIZE;
                const end = start + PAGE_SIZE;
                const newPageStreams = streams.slice(start, end);
                const stats = await getStreamsFromIndexer(newPageStreams.map((s) => s.id));
                if (stats && stats.length > 0) {
                    setStreamStats((prev) => (Object.assign(Object.assign({}, prev), Object.fromEntries(stats.map((is) => [is.id, is])))));
                }
            }
            catch (e) {
                console.warn('Fetching stream stats failed', e);
            }
        };
        loadStats();
    }, [page, streams]);
    useEffect(() => {
        // Reset current page when needed
        setPage(0);
    }, [search, projectType, account]);
    return (React.createElement("div", null,
        React.createElement(Heading, null,
            React.createElement(Title, null, "Add Streams")),
        React.createElement(SearchBar, { placeholder: 'Search stream', value: search, onChange: (value) => {
                setSearch(value);
            } }),
        React.createElement(StreamSelectTable, { streams: visibleStreams, streamStats: streamStats, loadMore: () => setPage((prev) => prev + 1), hasMoreResults: hasMore, onSelectionChange: updateStreams, selected: (_a = project === null || project === void 0 ? void 0 : project.streams) !== null && _a !== void 0 ? _a : [] })));
};
