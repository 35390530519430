import React, { useContext } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { DetailEditor } from '$shared/components/DetailEditor';
import { LAPTOP } from '$shared/utils/styled';
import { ProjectStateContext } from '$mp/contexts/ProjectStateContext';
import { useEditableProjectActions } from '$mp/containers/ProductController/useEditableProjectActions';
import { ProjectDetailIcon } from '$mp/containers/ProjectEditing/ProjectDetails.styles';
const ProjectDetailsWrap = styled.div.withConfig({ displayName: "ProjectDetailsWrap", componentId: "sc-1arwnfr" }) `
    display: flex;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
    margin-top: 24px;

    @media (${LAPTOP}) {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 3;
        margin: 50px 50px 50px 0;
    }

    .detail {
        flex-shrink: 1;
        margin-right: 6px;
    }
`;
export const ProjectDetails = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { state: project } = useContext(ProjectStateContext);
    const { updateContactUrl, updateContactEmail, updateSocialUrl, updateCreator } = useEditableProjectActions();
    return (React.createElement(ProjectDetailsWrap, null,
        React.createElement(DetailEditor, { className: "detail", defaultIcon: React.createElement(ProjectDetailIcon, { name: 'userFull' }), unsetValueText: "Creator's name", instructionText: "Please provide your name", ctaButtonText: "creator's name", value: project === null || project === void 0 ? void 0 : project.creator, onChange: (value) => updateCreator(value.toString().trim()), showValue: true, optional: false, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().required().trim().max(255);
                        return !!schema.isValidSync(value);
                    },
                    message: 'Name is too long',
                },
                {
                    validator: (value) => {
                        const schema = yup.string().required().trim().min(1);
                        return !!schema.isValidSync(value);
                    },
                    message: 'Name is too short',
                },
            ] }),
        React.createElement(DetailEditor, { className: "detail", unsetValueText: "Site URL", defaultIcon: React.createElement(ProjectDetailIcon, { name: 'web' }), showValue: true, instructionText: "Please add a site URL", ctaButtonText: "site URL", placeholder: "https://siteinfo.com", onChange: updateContactUrl, optional: true, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().trim().url();
                        return !!schema.isValidSync(value);
                    },
                    message: 'Not a valid URL',
                },
            ], value: ((_a = project === null || project === void 0 ? void 0 : project.contact) === null || _a === void 0 ? void 0 : _a.url) || '' }),
        React.createElement(DetailEditor, { className: "detail", unsetValueText: "Contact email", defaultIcon: React.createElement(ProjectDetailIcon, { name: 'email' }), showValue: true, instructionText: "Please add a contact email", ctaButtonText: "contact email", placeholder: "owner@example.com", onChange: updateContactEmail, optional: true, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().trim().email();
                        return !!schema.isValidSync(value);
                    },
                    message: 'Not a valid email address',
                },
            ], value: ((_b = project === null || project === void 0 ? void 0 : project.contact) === null || _b === void 0 ? void 0 : _b.email) || '' }),
        React.createElement(DetailEditor, { className: "detail", defaultIcon: React.createElement(ProjectDetailIcon, { name: 'twitter' }), instructionText: "Please add Twitter link", ctaButtonText: "Twitter link", hasValueIcon: !!((_c = project === null || project === void 0 ? void 0 : project.contact) === null || _c === void 0 ? void 0 : _c.twitter) && (React.createElement(ProjectDetailIcon, { name: 'twitter', className: 'twitterColor' })), value: (_d = project === null || project === void 0 ? void 0 : project.contact) === null || _d === void 0 ? void 0 : _d.twitter, onChange: (value) => updateSocialUrl('twitter', value), optional: true, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().trim().url();
                        return !!schema.isValidSync(value);
                    },
                    message: 'Not a valid URL',
                },
            ] }),
        React.createElement(DetailEditor, { className: "detail", defaultIcon: React.createElement(ProjectDetailIcon, { name: 'telegram' }), instructionText: "Please add Telegram link", ctaButtonText: "Telegram link", hasValueIcon: !!((_e = project === null || project === void 0 ? void 0 : project.contact) === null || _e === void 0 ? void 0 : _e.telegram) && (React.createElement(ProjectDetailIcon, { name: 'telegram', className: 'telegramColor' })), value: (_f = project === null || project === void 0 ? void 0 : project.contact) === null || _f === void 0 ? void 0 : _f.telegram, onChange: (value) => updateSocialUrl('telegram', value), optional: true, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().trim().url();
                        return !!schema.isValidSync(value);
                    },
                    message: 'Not a valid URL',
                },
            ] }),
        React.createElement(DetailEditor, { className: "detail", defaultIcon: React.createElement(ProjectDetailIcon, { name: 'reddit' }), instructionText: "Please add Reddit link", ctaButtonText: "Reddit link", hasValueIcon: !!((_g = project === null || project === void 0 ? void 0 : project.contact) === null || _g === void 0 ? void 0 : _g.reddit) && (React.createElement(ProjectDetailIcon, { name: 'reddit', className: 'redditColor' })), value: (_h = project === null || project === void 0 ? void 0 : project.contact) === null || _h === void 0 ? void 0 : _h.reddit, onChange: (value) => updateSocialUrl('reddit', value), optional: true, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().trim().url();
                        return !!schema.isValidSync(value);
                    },
                    message: 'Not a valid URL',
                },
            ] }),
        React.createElement(DetailEditor, { defaultIcon: React.createElement(ProjectDetailIcon, { name: 'linkedin' }), instructionText: "Please add LinkedIn link", ctaButtonText: "LinkedIn link", hasValueIcon: !!((_j = project === null || project === void 0 ? void 0 : project.contact) === null || _j === void 0 ? void 0 : _j.linkedIn) && (React.createElement(ProjectDetailIcon, { name: 'linkedin', className: 'linkedInColor' })), value: (_k = project === null || project === void 0 ? void 0 : project.contact) === null || _k === void 0 ? void 0 : _k.linkedIn, onChange: (value) => updateSocialUrl('linkedIn', value), optional: true, validation: [
                {
                    validator: (value) => {
                        const schema = yup.string().trim().url();
                        return !!schema.isValidSync(value);
                    },
                    message: 'Not a valid URL',
                },
            ] })));
};
