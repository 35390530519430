import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDiscardableEffect, defer } from 'toasterhea';
import { Link as PrestyledLink } from 'react-router-dom';
import { Logo, Auth, SignInMethod, LoadingIndicator } from '@streamr/streamr-layout';
import { MEDIUM, TABLET } from '$shared/utils/styled';
import Button from '$shared/components/Button';
import useIsMounted from '$shared/hooks/useIsMounted';
import TimeoutError from '$shared/errors/TimeoutError';
import { getWalletAccount, useWalletAccount } from '$shared/stores/wallet';
import isCodedError from '$utils/isCodedError';
import routes from '$routes';
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-q6z1tv" }) `
    background: #f8f8f8;
    color: #323232;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
`;
const Inner = styled.div.withConfig({ displayName: "Inner", componentId: "sc-c6zhyj" }) `
    display: flex;
    flex-direction: column;
    flex-grow: 0.8;
    justify-content: center;
    padding: 0 1em;
    margin: 48px auto 88px;
    color: #323232;

    @media ${TABLET} {
        margin: 152px auto 192px;
    }
`;
const Panel = styled.div.withConfig({ displayName: "Panel", componentId: "sc-301qys" }) `
    margin-top: 40px;

    @media ${TABLET} {
        margin-top: 110px;
    }
`;
const Link = styled(PrestyledLink).withConfig({ displayName: "Link", componentId: "sc-oii0k7" }) `
    display: block;
    margin: 0 auto;
    user-select: none;
    width: 32px;
    outline: 0 !important;

    svg {
        color: #ff5c00;
        display: block;
    }
`;
const TitleBar = styled.div.withConfig({ displayName: "TitleBar", componentId: "sc-1xvvk2c" }) `
    font-size: 18px;
    font-weight: ${MEDIUM};
    line-height: 48px;
    text-align: center;

    @media ${TABLET} {
        font-size: 24px;
    }
`;
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-10yg99z" }) `
    color: #adadad;
    font-size: 12px;
    margin: 60px auto 0;
    max-width: 432px;
    text-align: center;

    @media ${TABLET} {
        margin-top: 120px;

        br {
            display: none;
        }
    }
`;
export default function ConnectModal({ onReject, onResolve }) {
    const [connectDeferral, setConnectDeferral] = useState();
    const connecting = !!connectDeferral;
    const [error, setError] = useState();
    const isMounted = useIsMounted();
    function label() {
        if (error) {
            if (isCodedError(error) && error.code === -32002) {
                return 'Already processing. Please wait.';
            }
            return "Couldn't connect to MetaMask";
        }
        if (connecting) {
            return 'Connecting…';
        }
        return 'MetaMask';
    }
    useDiscardableEffect();
    useEffect(() => {
        function onKeyDown({ key }) {
            if (key === 'Escape') {
                onReject === null || onReject === void 0 ? void 0 : onReject();
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onReject]);
    useEffect(() => () => {
        connectDeferral === null || connectDeferral === void 0 ? void 0 : connectDeferral.reject();
    }, [connectDeferral]);
    const account = useWalletAccount();
    useEffect(() => {
        if (account) {
            onResolve === null || onResolve === void 0 ? void 0 : onResolve(account);
        }
    }, [onResolve, account]);
    return (React.createElement(Root, null,
        React.createElement(Inner, null,
            React.createElement("div", null,
                React.createElement(Link, { to: routes.root() },
                    React.createElement(Logo, null))),
            React.createElement(TitleBar, null, "Streamr Hub"),
            React.createElement("form", { onSubmit: async (e) => {
                    e.preventDefault();
                    if (connectDeferral) {
                        return;
                    }
                    try {
                        const deferral = defer();
                        setError(undefined);
                        setConnectDeferral(deferral);
                        const currentAccount = await Promise.race([
                            getWalletAccount({
                                connect: true,
                            }),
                            deferral.promise,
                            new Promise((resolve, reject) => void setTimeout(() => void reject(new TimeoutError()), 120000)),
                        ]);
                        onResolve === null || onResolve === void 0 ? void 0 : onResolve(currentAccount);
                    }
                    catch (e) {
                        console.warn('Wallet connecting failed', e);
                        if (isMounted()) {
                            setError(e);
                        }
                    }
                    finally {
                        if (isMounted()) {
                            setConnectDeferral(undefined);
                        }
                    }
                } },
                React.createElement(Panel, null,
                    React.createElement(Auth, null,
                        React.createElement(Auth.Panel, null,
                            React.createElement(LoadingIndicator, { loading: connecting }),
                            React.createElement(Auth.PanelRow, null,
                                React.createElement(Auth.Header, null, "Connect a wallet")),
                            React.createElement(Auth.PanelRow, null,
                                React.createElement(SignInMethod, { disabled: connecting, type: "submit", theme: error ? SignInMethod.themes.Error : undefined },
                                    React.createElement(SignInMethod.Title, null, label()),
                                    React.createElement(SignInMethod.Icon, null,
                                        React.createElement(SignInMethod.Icon.Metamask, null)))),
                            React.createElement(Auth.PanelRow, null,
                                React.createElement(Auth.Footer, null,
                                    !error && !connecting && (React.createElement("span", null,
                                        "Need an Ethereum wallet?",
                                        ' ',
                                        React.createElement("a", { href: "https://ethereum.org/en/wallets/", target: "_blank", rel: "nofollow noopener noreferrer" }, "Learn more here"))),
                                    !!connecting && (React.createElement(Button, { kind: "link", size: "mini", onClick: () => void connectDeferral.reject(), type: "button" }, "Cancel")),
                                    !!error && !connecting && (React.createElement(Button, { kind: "secondary", size: "mini", disabled: connecting, waiting: connecting, type: "submit" }, "Try again")))))))),
            React.createElement(Footer, null,
                React.createElement("span", null,
                    "By connecting your wallet and using Streamr ",
                    React.createElement("br", null),
                    "you agree to our",
                    ' ',
                    React.createElement("a", { href: routes.tos(), target: "_blank", rel: "nofollow noopener noreferrer" }, "Terms of Service"))))));
}
