import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '$shared/components/Button';
import useCopy from '$shared/hooks/useCopy';
import SvgIcon from '$shared/components/SvgIcon';
const Btn = styled(Button).withConfig({ displayName: "Btn", componentId: "sc-17eqgyb" }) `
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-left: 10px;
    display: grid;
    svg {
        width: 22px;
    }
    &.white {
        background-color: white;
    }
`;
export const CopyButton = ({ valueToCopy, notificationTitle = 'Stream ID copied', className, }) => {
    const { copy } = useCopy();
    const handleCopy = useCallback(() => {
        copy(valueToCopy, {
            toastMessage: notificationTitle,
        });
    }, [copy, notificationTitle, valueToCopy]);
    return (React.createElement(Btn, { onClick: handleCopy, type: 'button', kind: 'secondary', size: 'mini', className: className },
        React.createElement(SvgIcon, { name: 'copy' })));
};
