var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
const Value = styled.div.withConfig({ displayName: "Value", componentId: "sc-21p5qp" }) `
    position: absolute;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
`;
const UnstyledInitials = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement("div", Object.assign({}, props),
        React.createElement("svg", { viewBox: "0 0 1 1", xmlns: "http://www.w3.org/2000/svg" }),
        React.createElement(Value, null, children)));
};
const Initials = styled(UnstyledInitials).withConfig({ displayName: "Initials", componentId: "sc-10gtvc9" }) `
    background-color: #97bac0;
    color: #ffffff;
    font-family: var(--mono);
    position: relative;
`;
export default Initials;
