export default class NoStreamIdError extends Error {
    constructor() {
        super('No stream id');
        this.name = 'NoStreamIdError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NoStreamIdError);
        }
        Object.setPrototypeOf(this, NoStreamIdError.prototype);
    }
}
