export const SM = 375;
export const MD = 744;
export const MDLG = 1100;
export const LG = 1440;
export const XL = 1920;
export const LIGHT = 300;
export const REGULAR = 400;
export const MEDIUM = 500;
export const TOOLBAR_SHADOW = '0 0 10px rgba(0, 0, 0, 0.1)';
export const MONO = "'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace";
export const SANS = "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif";
export const PHONE = `(min-width: ${SM}px)`;
export const TABLET = `(min-width: ${MD}px)`;
export const LAPTOP = `(min-width: ${MDLG}px)`;
export const DESKTOP = `(min-width: ${LG}px)`;
export const MAX_CONTENT_WIDTH = '678px';
export const MAX_BODY_WIDTH = 1296;
export var COLORS;
(function (COLORS) {
    COLORS["primary"] = "#323232";
    COLORS["primaryLight"] = "#525252";
    COLORS["primaryContrast"] = "#FFF";
    COLORS["primaryDisabled"] = "#A3A3A3";
    COLORS["secondary"] = "#F5F5F5";
    COLORS["secondaryHover"] = "#E7E7E7";
    COLORS["secondaryLight"] = "#F8F8F8";
    COLORS["focus"] = "#9BC1FB";
    COLORS["click"] = "#DEEBFF";
    COLORS["selection"] = "#CCE9FD";
    COLORS["separator"] = "#EFEFEF";
    COLORS["disabled"] = "#ADADAD";
    COLORS["docLink"] = "#F1F1F1";
    COLORS["inputBackground"] = "#F1F1F1";
    COLORS["radioBorder"] = "#CDCDCD";
    COLORS["close"] = "#848484";
    COLORS["link"] = "#0324FF";
    COLORS["error"] = "#D90C25";
    COLORS["warning"] = "#FFF6F1";
    COLORS["dialogBorder"] = "#F3F3F3";
})(COLORS || (COLORS = {}));
