var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { StreamPermission } from 'streamr-client';
import { Bits, matchBits } from '$shared/stores/streamEditor';
import { MEDIUM, TABLET } from '$shared/utils/styled';
import Checkbox from './Checkbox';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ckl4tv" }) `
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 21px;
    align-items: start;
    user-select: none;

    @media ${TABLET} {
        grid-template-rows: unset;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-yh32tt" }) `
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 12px;

    & > span {
        color: #000000;
        font-weight: ${MEDIUM};
        font-size: 12px;
        line-height: 16px;
        justify-self: left;
    }
`;
function UnstyledPermissionEditor(_a) {
    var { address, permissionBits, disabled, onChange } = _a, props = __rest(_a, ["address", "permissionBits", "disabled", "onChange"]);
    return (React.createElement(Container, Object.assign({}, props),
        React.createElement(Column, null,
            React.createElement("span", null, "Read"),
            React.createElement(Checkbox, { operationName: "Subscribe", address: address, value: matchBits(Bits[StreamPermission.SUBSCRIBE], permissionBits), onChange: (value) => onChange(StreamPermission.SUBSCRIBE, value), disabled: disabled })),
        React.createElement(Column, null,
            React.createElement("span", null, "Write"),
            React.createElement(Checkbox, { operationName: "Publish", address: address, value: matchBits(Bits[StreamPermission.PUBLISH], permissionBits), onChange: (value) => onChange(StreamPermission.PUBLISH, value), disabled: disabled })),
        React.createElement(Column, null,
            React.createElement("span", null, "Manage"),
            React.createElement(Checkbox, { operationName: "Grant", address: address, value: matchBits(Bits[StreamPermission.GRANT], permissionBits), onChange: (value) => onChange(StreamPermission.GRANT, value), disabled: disabled }),
            React.createElement(Checkbox, { operationName: "Edit", address: address, value: matchBits(Bits[StreamPermission.EDIT], permissionBits), onChange: (value) => onChange(StreamPermission.EDIT, value), disabled: disabled }),
            React.createElement(Checkbox, { operationName: "Delete", address: address, value: matchBits(Bits[StreamPermission.DELETE], permissionBits), onChange: (value) => onChange(StreamPermission.DELETE, value), disabled: disabled }))));
}
const PermissionEditor = styled(UnstyledPermissionEditor).withConfig({ displayName: "PermissionEditor", componentId: "sc-1r8wsb0" }) ``;
export default PermissionEditor;
