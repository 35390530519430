import React from 'react';
import { Container } from 'toasterhea';
import styled from 'styled-components';
import { Route, Routes, Navigate } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import '$shared/assets/stylesheets';
import '@ibm/plex/css/ibm-plex.css';
import '$utils/setupSnippets';
import StreamrClientProvider from '$shared/components/StreamrClientProvider';
import { Provider as ModalPortalProvider } from '$shared/contexts/ModalPortal';
import { Provider as ModalProvider } from '$shared/contexts/ModalApi';
import NotFoundPage from '$shared/components/NotFoundPage';
import AnalyticsTracker from '$shared/components/AnalyticsTracker';
import GenericErrorPage from '$shared/components/GenericErrorPage';
import ErrorPage from '$shared/components/ErrorPage';
import Analytics from '$shared/utils/Analytics';
import NewStreamListingPage from '$app/src/pages/NewStreamListingPage';
import StreamPage from '$app/src/pages/StreamPage';
import ProjectPage from '$app/src/pages/ProjectPage';
import ProjectsPage from '$mp/containers/Projects';
import NewProjectPage from '$mp/containers/ProjectEditing/NewProjectPage';
import EditProjectPage from '$mp/containers/ProjectEditing/EditProjectPage';
import Globals from '$shared/components/Globals';
import { Layer } from '$utils/Layer';
import routes from '$routes';
import { HubRouter } from '~/consts';
import '../analytics';
const MiscRouter = () => [
    React.createElement(Route, { errorElement: React.createElement(ErrorPage, null), path: routes.root(), element: React.createElement(Navigate, { to: routes.projects.index(), replace: true }), key: "RootRedirect" }),
    React.createElement(Route, { errorElement: React.createElement(ErrorPage, null), path: routes.hub(), element: React.createElement(Navigate, { to: routes.projects.index(), replace: true }), key: "HubRedirect" }),
    React.createElement(Route, { errorElement: React.createElement(ErrorPage, null), path: "/error", element: React.createElement(GenericErrorPage, null), key: "GenericErrorPage" }),
    React.createElement(Route, { errorElement: React.createElement(ErrorPage, null), element: React.createElement(NotFoundPage, null), key: "NotFoundPage" }),
];
// Create client for 'react-query'
const queryClient = new QueryClient();
const App = () => (React.createElement(HubRouter, null,
    React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(StreamrClientProvider, null,
            React.createElement(ModalPortalProvider, null,
                React.createElement(ModalProvider, null,
                    React.createElement(Analytics, null),
                    React.createElement(Globals, null),
                    React.createElement(Routes, null,
                        React.createElement(Route, { path: "/hub/projects/*", errorElement: React.createElement(ErrorPage, null) },
                            React.createElement(Route, { index: true, element: React.createElement(ProjectsPage, null) }),
                            React.createElement(Route, { path: "new", element: React.createElement(NewProjectPage, null) }),
                            React.createElement(Route, { path: ":id/edit", element: React.createElement(EditProjectPage, null) }),
                            React.createElement(Route, { path: ":id/*", element: React.createElement(ProjectPage, null) })),
                        React.createElement(Route, { path: "/hub/streams/*", errorElement: React.createElement(ErrorPage, null) },
                            React.createElement(Route, { index: true, element: React.createElement(NewStreamListingPage, null) }),
                            React.createElement(Route, { path: ":id/*", element: React.createElement(StreamPage, null) })),
                        MiscRouter()),
                    React.createElement(Container, { id: Layer.Modal }),
                    React.createElement(ToastContainer, { id: Layer.Toast }),
                    React.createElement(AnalyticsTracker, null)))))));
export default App;
const ToastContainer = styled(Container).withConfig({ displayName: "ToastContainer", componentId: "sc-1m2itgw" }) `
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-bottom: 24px;
    padding-right: 24px;
    position: fixed;
    z-index: 10;
`;
