export default class InterruptionError extends Error {
    constructor() {
        super('Conditions changed');
        this.name = 'InterruptionError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InterruptionError);
        }
        Object.setPrototypeOf(this, InterruptionError.prototype);
    }
}
