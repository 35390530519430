import React, { useCallback, useEffect, useState, } from 'react';
import styled, { css } from 'styled-components';
import { Stream } from 'streamr-client';
import { Link } from 'react-router-dom';
import LoadMore from '$mp/components/LoadMore';
import { COLORS, MEDIUM, REGULAR, DESKTOP, TABLET } from '$shared/utils/styled';
import { getGlobalStatsFromIndexer, } from '$app/src/services/streams';
import useIsMounted from '$shared/hooks/useIsMounted';
import { truncateStreamName } from '$shared/utils/text';
import routes from '$routes';
import SvgIcon from '../SvgIcon';
const ROW_HEIGHT = 88;
export var OrderBy;
(function (OrderBy) {
    OrderBy[OrderBy["MessagesPerSecond"] = 0] = "MessagesPerSecond";
    OrderBy[OrderBy["PeerCount"] = 1] = "PeerCount";
    OrderBy[OrderBy["Id"] = 2] = "Id";
})(OrderBy || (OrderBy = {}));
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection[OrderDirection["Asc"] = 0] = "Asc";
    OrderDirection[OrderDirection["Desc"] = 1] = "Desc";
})(OrderDirection || (OrderDirection = {}));
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-46c9cz" }) `
    padding-bottom: 80px;
`;
const Row = styled.div.withConfig({ displayName: "Row", componentId: "sc-n5toqn" }) `
    align-items: center;
    padding-left: 24px;

    @media ${TABLET} {
        padding-left: 40px;
    }

    @media ${DESKTOP} {
        padding-left: 60px;
    }
`;
const TableGrid = styled(Row).withConfig({ displayName: "TableGrid", componentId: "sc-r3aqjs" }) `
    display: grid;
    gap: 8px;
    grid-template-columns: minmax(0, 1fr);

    @media ${TABLET} {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }

    @media ${DESKTOP} {
        grid-template-columns: minmax(0, 3fr) repeat(5, minmax(0, 1fr));
    }
`;
const Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-11gnrt9" }) `
    overflow: auto;
`;
const TableHeader = styled(TableGrid).withConfig({ displayName: "TableHeader", componentId: "sc-5w86pq" }) `
    font-weight: ${MEDIUM};
    height: ${ROW_HEIGHT}px;
    font-size: 15px;
    line-height: 26px;
    color: ${COLORS.primaryLight};
    border-bottom: 1px solid #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1;
`;
const TableRows = styled.div.withConfig({ displayName: "TableRows", componentId: "sc-7sm0y9" }) `
    height: ${({ rowCount }) => Math.max(rowCount, 1) * (ROW_HEIGHT + 1)}px;
`;
const TableRow = styled(TableGrid).withConfig({ displayName: "TableRow", componentId: "sc-1v6x3xb" }) `
    font-size: 16px;
    line-height: 26px;
    height: ${ROW_HEIGHT}px;
    max-height: ${ROW_HEIGHT}px;
    box-sizing: content-box;
    color: ${COLORS.primaryLight};
    transition: background-color 100ms ease-in-out;

    &:hover {
        background-color: ${COLORS.secondaryLight};
    }

    &:active {
        background-color: ${COLORS.click};
    }

    &:not(:last-child) {
        border-bottom: 1px solid #f8f8f8;
    }

    &:active,
    &:link,
    &:visited,
    &:hover {
        color: ${COLORS.primaryLight};
    }
`;
const GridCell = styled.span.withConfig({ displayName: "GridCell", componentId: "sc-1t1kyki" }) `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ onClick }) => typeof onClick === 'function' &&
    css `
            cursor: pointer;
        `}

    ${({ onlyDesktop }) => onlyDesktop &&
    css `
            display: none;

            @media ${DESKTOP} {
                display: block;
            }
        `}

    ${({ onlyTablet }) => onlyTablet &&
    css `
            display: none;

            @media ${TABLET} {
                display: block;
            }

            @media ${DESKTOP} {
                display: none;
            }
        `}

    ${({ notOnTablet }) => notOnTablet &&
    css `
            display: block;

            @media ${TABLET} {
                display: none;
            }

            @media ${DESKTOP} {
                display: block;
            }
        `}
`;
const NoStreams = styled.div.withConfig({ displayName: "NoStreams", componentId: "sc-fu67q1" }) `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.primaryLight};
`;
const StreamDetails = styled.span.withConfig({ displayName: "StreamDetails", componentId: "sc-1bx5b8f" }) `
    font-size: 16px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const StreamId = styled(GridCell).withConfig({ displayName: "StreamId", componentId: "sc-15suly" }) `
    font-weight: ${MEDIUM};
`;
const StreamDescription = styled(GridCell).withConfig({ displayName: "StreamDescription", componentId: "sc-1omv80d" }) `
    font-weight: ${REGULAR};
`;
const Heading = styled.div.withConfig({ displayName: "Heading", componentId: "sc-31apau" }) `
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 16px;
    align-items: center;
    padding: 30px 24px;

    @media ${TABLET} {
        padding: 45px 40px;
    }

    @media ${DESKTOP} {
        padding: 55px 60px;
    }
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-11iuu44" }) `
    font-size: 34px;
    line-height: 48px;
    color: ${COLORS.primary};
`;
const Stat = styled.div.withConfig({ displayName: "Stat", componentId: "sc-1yys8i5" }) `
    color: ${COLORS.primaryLight};
    background-color: ${COLORS.secondary};
    font-size: 18px;
    line-height: 16px;
    padding: 16px;

    strong {
        font-weight: ${MEDIUM};
    }
`;
const OrderDirectionIcon = styled(SvgIcon).withConfig({ displayName: "OrderDirectionIcon", componentId: "sc-clv48y" }) `
    width: 12px;
    transform: ${({ direction }) => direction === OrderDirection.Asc ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 180ms ease-in-out;
    margin-left: 8px;
`;
const DirectionDefaults = {
    [OrderBy.Id]: OrderDirection.Asc,
    [OrderBy.PeerCount]: OrderDirection.Desc,
    [OrderBy.MessagesPerSecond]: OrderDirection.Desc,
};
function isIndexerStream(stream) {
    return stream.peerCount !== undefined;
}
const StreamTable = ({ title = 'Streams', streams, streamStats, loadMore, hasMoreResults, showGlobalStats, orderBy, orderDirection, onSortChange, noStreamsText = 'No streams that match your query', }) => {
    const [globalStats, setGlobalStats] = useState(null);
    const isMounted = useIsMounted();
    const handleHeaderClick = useCallback((field) => {
        var _a;
        if (typeof onSortChange === 'function') {
            let newDirection = (_a = DirectionDefaults[field]) !== null && _a !== void 0 ? _a : OrderDirection.Desc;
            // If field was not changed, flip the direction
            if (orderBy === field) {
                newDirection = Number(!orderDirection);
            }
            onSortChange(field, newDirection);
        }
    }, [onSortChange, orderDirection, orderBy]);
    useEffect(() => {
        const loadStats = async () => {
            const result = await getGlobalStatsFromIndexer();
            if (isMounted()) {
                setGlobalStats(result);
            }
        };
        if (showGlobalStats) {
            loadStats();
        }
    }, [isMounted, showGlobalStats]);
    return (React.createElement(Container, null,
        React.createElement(Heading, null,
            React.createElement(Title, null, title),
            showGlobalStats && globalStats != null && (React.createElement(React.Fragment, null,
                React.createElement(Stat, null,
                    "Streams ",
                    React.createElement("strong", null, globalStats.streamCount)),
                React.createElement(Stat, null,
                    "Msg/s",
                    ' ',
                    React.createElement("strong", null, globalStats.messagesPerSecond.toFixed(0)))))),
        React.createElement(Table, null,
            React.createElement(TableHeader, null,
                React.createElement(GridCell, { onClick: () => handleHeaderClick(OrderBy.Id) },
                    "Stream ID",
                    orderBy === OrderBy.Id && (React.createElement(OrderDirectionIcon, { name: "caretDown", direction: orderDirection !== null && orderDirection !== void 0 ? orderDirection : OrderDirection.Asc }))),
                React.createElement(GridCell, { onlyTablet: true }, "Description"),
                React.createElement(GridCell, { onlyDesktop: true, onClick: () => handleHeaderClick(OrderBy.PeerCount) },
                    "Live peers",
                    orderBy === OrderBy.PeerCount && (React.createElement(OrderDirectionIcon, { name: "caretDown", direction: orderDirection !== null && orderDirection !== void 0 ? orderDirection : OrderDirection.Asc }))),
                React.createElement(GridCell, { onlyDesktop: true, onClick: () => handleHeaderClick(OrderBy.MessagesPerSecond) },
                    "Msg/s",
                    orderBy === OrderBy.MessagesPerSecond && (React.createElement(OrderDirectionIcon, { name: "caretDown", direction: orderDirection !== null && orderDirection !== void 0 ? orderDirection : OrderDirection.Asc }))),
                React.createElement(GridCell, { onlyDesktop: true }, "Access"),
                React.createElement(GridCell, { onlyDesktop: true }, "Publishers"),
                React.createElement(GridCell, { onlyDesktop: true }, "Subscribers")),
            React.createElement(TableRows, { rowCount: streams.length },
                streams.map((s) => {
                    var _a, _b, _c, _d;
                    const stats = streamStats && streamStats[s.id] != null
                        ? streamStats[s.id]
                        : null;
                    let publisherCount;
                    let subscriberCount;
                    let description;
                    let peerCount;
                    let messagesPerSecond;
                    if (s instanceof Stream) {
                        const indexerStats = stats;
                        description = (_a = s.getMetadata().description) !== null && _a !== void 0 ? _a : '';
                        publisherCount = indexerStats === null || indexerStats === void 0 ? void 0 : indexerStats.publisherCount;
                        subscriberCount = indexerStats === null || indexerStats === void 0 ? void 0 : indexerStats.subscriberCount;
                        peerCount = indexerStats === null || indexerStats === void 0 ? void 0 : indexerStats.peerCount;
                        messagesPerSecond = indexerStats === null || indexerStats === void 0 ? void 0 : indexerStats.messagesPerSecond;
                    }
                    else if (isIndexerStream(s)) {
                        publisherCount = s.publisherCount;
                        subscriberCount = s.subscriberCount;
                        // For pub/sub count, show values from stats (The Graph) once the data is available.
                        // Until that show (possibly wrong) value from the indexer. One exception is that
                        // if we have more than 100 permissions, use indexer (because The Graph caps subentity count at 100).
                        const graphStats = stats;
                        if (graphStats != null &&
                            (graphStats === null || graphStats === void 0 ? void 0 : graphStats.publisherCount) !== 100) {
                            publisherCount = graphStats.publisherCount;
                        }
                        if (graphStats != null &&
                            (graphStats === null || graphStats === void 0 ? void 0 : graphStats.subscriberCount) !== 100) {
                            subscriberCount = graphStats.subscriberCount;
                        }
                        description = (_b = s.description) !== null && _b !== void 0 ? _b : '';
                        peerCount = s.peerCount;
                        messagesPerSecond = s.messagesPerSecond;
                    }
                    else {
                        publisherCount = s.publisherCount;
                        subscriberCount = s.subscriberCount;
                        description = (_d = (_c = s.metadata) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : '';
                        peerCount = stats === null || stats === void 0 ? void 0 : stats.peerCount;
                        messagesPerSecond = stats === null || stats === void 0 ? void 0 : stats.messagesPerSecond;
                    }
                    return (React.createElement(TableRow, { key: s.id, as: Link, to: routes.streams.show({ id: s.id }) },
                        React.createElement(StreamDetails, null,
                            React.createElement(StreamId, { title: s.id }, truncateStreamName(s.id, 40)),
                            '\n',
                            React.createElement(StreamDescription, { notOnTablet: true }, description)),
                        React.createElement(GridCell, { onlyTablet: true }, description),
                        React.createElement(GridCell, { onlyDesktop: true }, peerCount !== null && peerCount !== void 0 ? peerCount : '-'),
                        React.createElement(GridCell, { onlyDesktop: true }, messagesPerSecond !== null && messagesPerSecond !== void 0 ? messagesPerSecond : '-'),
                        React.createElement(GridCell, { onlyDesktop: true }, subscriberCount == null ? 'Public' : 'Private'),
                        React.createElement(GridCell, { onlyDesktop: true }, publisherCount === null
                            ? '∞'
                            : publisherCount !== null && publisherCount !== void 0 ? publisherCount : '-'),
                        React.createElement(GridCell, { onlyDesktop: true }, subscriberCount === null
                            ? '∞'
                            : subscriberCount !== null && subscriberCount !== void 0 ? subscriberCount : '-')));
                }),
                streams.length === 0 && React.createElement(NoStreams, null, noStreamsText))),
        loadMore != null && (React.createElement(LoadMore, { hasMoreSearchResults: !!hasMoreResults, onClick: () => {
                loadMore();
            }, preserveSpace: true }))));
};
export default StreamTable;
export const StreamTableLight = ({ streamIds, title = 'Streams' }) => {
    return (React.createElement(Container, null,
        React.createElement(Heading, null,
            React.createElement(Title, null, title)),
        React.createElement(Table, null,
            React.createElement(TableHeader, null,
                React.createElement(GridCell, null, "Stream ID"),
                React.createElement(GridCell, { onlyTablet: true }, "Description"),
                React.createElement(GridCell, { onlyDesktop: true }, "Live peers"),
                React.createElement(GridCell, { onlyDesktop: true }, "Msg/s"),
                React.createElement(GridCell, { onlyDesktop: true }, "Access"),
                React.createElement(GridCell, { onlyDesktop: true }, "Publishers"),
                React.createElement(GridCell, { onlyDesktop: true }, "Subscribers")),
            React.createElement(TableRows, { rowCount: streamIds.length }, streamIds.map((streamId) => (React.createElement(TableRow, { key: streamId, as: Link, to: routes.streams.show({ id: streamId }) },
                React.createElement(StreamDetails, null,
                    React.createElement(StreamId, { title: streamId }, truncateStreamName(streamId, 40))),
                React.createElement(GridCell, { onlyTablet: true }),
                React.createElement(GridCell, { onlyDesktop: true }, "-"),
                React.createElement(GridCell, { onlyDesktop: true }, "-"),
                React.createElement(GridCell, { onlyDesktop: true }, "-"),
                React.createElement(GridCell, { onlyDesktop: true }, "-"),
                React.createElement(GridCell, { onlyDesktop: true }, "-"))))))));
};
