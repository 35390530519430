import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import styled from 'styled-components';
import BN from 'bignumber.js';
import capitalize from 'lodash/capitalize';
import { useDebouncedCallback } from 'use-debounce';
import SvgIcon from '$shared/components/SvgIcon';
import { getDataAddress, getTokenInformation } from '$mp/utils/web3';
import useIsMounted from '$shared/hooks/useIsMounted';
import { COLORS, MAX_CONTENT_WIDTH, MEDIUM, REGULAR } from '$shared/utils/styled';
import { Radio } from '$shared/components/Radio';
import Text from '$ui/Text';
import Button from '$shared/components/Button';
import SelectField2 from '$mp/components/SelectField2';
import { contractCurrencies } from '$shared/utils/constants';
import useValidation from '$mp/containers/ProductController/useValidation';
import { SeverityLevel } from '$mp/containers/ProductController/ValidationContextProvider';
import { pricePerSecondFromTimeUnit } from '$mp/utils/price';
import { timeUnits } from '$shared/utils/timeUnit';
import { errorToast } from '$utils/toast';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1wo40u9" }) `
    color: ${COLORS.primary};
    max-width: ${MAX_CONTENT_WIDTH};
`;
const Heading = styled.p.withConfig({ displayName: "Heading", componentId: "sc-29x3fr" }) `
    color: black;
    font-weight: ${REGULAR};
    font-size: 20px;
    margin-bottom: 22px;
`;
const Description = styled.p.withConfig({ displayName: "Description", componentId: "sc-1dopzcv" }) `
    font-size: 16px;
    margin-bottom: 32px;
`;
const Form = styled.div.withConfig({ displayName: "Form", componentId: "sc-a6xias" }) `
    border-radius: 4px;
    background-color: ${COLORS.inputBackground};
    padding: 24px;
    margin-bottom: 32px;
`;
const RadioContainer = styled.div.withConfig({ displayName: "RadioContainer", componentId: "sc-zwl62r" }) `
    background-color: white;
    border-radius: 4px;

    .radio {
        padding: 24px 20px;
        width: 100%;
    }

    &.data-token-radio-container {
        margin-bottom: 16px;
    }
`;
const RadioLabel = styled.div.withConfig({ displayName: "RadioLabel", componentId: "sc-1gjp09m" }) `
    display: flex;
    align-items: center;
    justify-content: space-between;
    .data-icon {
        width: 24px;
        height: 24px;
    }
`;
const CustomTokenAddressInputContainer = styled.div.withConfig({ displayName: "CustomTokenAddressInputContainer", componentId: "sc-12jdvlr" }) `
    padding: 0 20px 24px;
    label {
        font-weight: ${MEDIUM};
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 9px;
    }
`;
const SetTokenContainer = styled.div.withConfig({ displayName: "SetTokenContainer", componentId: "sc-oyuz08" }) `
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;
const PriceContainer = styled.div.withConfig({ displayName: "PriceContainer", componentId: "sc-l1hlwk" }) `
    display: flex;
    margin-top: 16px;
`;
const PriceInputWrap = styled.div.withConfig({ displayName: "PriceInputWrap", componentId: "sc-xl8un6" }) `
    position: relative;
    flex: 1;
    margin-right: 16px;
    .price-input {
        padding-right: 60px;
        &:disabled {
            background-color: white;
            opacity: 1;
        }
    }
    .token-symbol {
        position: absolute;
        right: 12px;
        top: 0;
        height: 100%;
        font-size: 14px;
        border-left: 1px solid ${COLORS.separator};
        padding-left: 12px;
        color: ${COLORS.primaryLight};
        display: flex;
        align-items: center;
    }
`;
const SelectContainer = styled.div.withConfig({ displayName: "SelectContainer", componentId: "sc-5swr73" }) `
    [class*='-control'] {
        min-height: 40px;
        border: none;
        &:hover {
            border: none;
        }
    }
`;
const options = Object.values(timeUnits).map((unit) => ({
    label: `Per ${unit}`,
    value: unit,
}));
const TokenSelector = ({ disabled, onChange, chain, validationFieldName, value, tokenChangeDisabled, }) => {
    var _a, _b, _c;
    const chainId = chain === null || chain === void 0 ? void 0 : chain.id;
    const dataAddress = useMemo(() => (chainId ? getDataAddress(chainId).toLowerCase() : ''), [chainId]);
    const isMounted = useIsMounted();
    const [selection, setSelection] = useState((((value === null || value === void 0 ? void 0 : value.tokenAddress) && (value === null || value === void 0 ? void 0 : value.tokenAddress) === dataAddress) ||
        (value === null || value === void 0 ? void 0 : value.tokenAddress) == null)
        ? contractCurrencies.DATA
        : contractCurrencies.PRODUCT_DEFINED);
    const [customTokenAddress, setCustomTokenAddress] = useState((value === null || value === void 0 ? void 0 : value.tokenAddress) && (value === null || value === void 0 ? void 0 : value.tokenAddress) !== dataAddress
        ? value.tokenAddress
        : '');
    const [selectedTokenAddress, setSelectedTokenAddress] = useState((_a = value === null || value === void 0 ? void 0 : value.tokenAddress) === null || _a === void 0 ? void 0 : _a.toLowerCase());
    const [tokenSymbol, setTokenSymbol] = useState(null);
    const [price, setPrice] = useState((_b = value === null || value === void 0 ? void 0 : value.price) === null || _b === void 0 ? void 0 : _b.toString());
    const [timeUnit, setTimeUnit] = useState((value === null || value === void 0 ? void 0 : value.timeUnit) || timeUnits.day);
    const [tokenDecimals, setTokenDecimals] = useState(18);
    const [isEditable, setIsEditable] = useState(false);
    const { setStatus, clearStatus, isValid } = useValidation(validationFieldName);
    const pricingTokenAddress = (_c = value === null || value === void 0 ? void 0 : value.tokenAddress) === null || _c === void 0 ? void 0 : _c.toLowerCase();
    const debouncedOnChange = useDebouncedCallback(onChange, 50);
    useEffect(() => {
        clearStatus();
    }, [chain, clearStatus]);
    useEffect(() => {
        if (pricingTokenAddress && chainId) {
            let loading = true;
            const check = async () => {
                if (pricingTokenAddress === dataAddress) {
                    setSelection(contractCurrencies.DATA);
                }
                else if (pricingTokenAddress != null) {
                    setSelection(contractCurrencies.PRODUCT_DEFINED);
                    setCustomTokenAddress(pricingTokenAddress);
                }
                const info = await getTokenInformation(pricingTokenAddress, chainId);
                if (!isMounted()) {
                    return;
                }
                if (!loading) {
                    return;
                }
                if (info) {
                    clearStatus();
                    setTokenSymbol(info.symbol);
                    setTokenDecimals(info.decimals);
                }
                else {
                    errorToast({
                        title: 'Invalid token contract address',
                        desc: 'This is not an ERC-20 token contract',
                    });
                    setStatus(SeverityLevel.ERROR, 'This is not an ERC-20 token contract');
                    setTokenSymbol(null);
                    setTokenDecimals(null);
                }
            };
            check();
            // Allow only latest load operation
            return () => {
                loading = false;
            };
        }
    }, [pricingTokenAddress, isMounted, clearStatus, setStatus, chainId, dataAddress]);
    useEffect(() => {
        if (!selection || !chainId) {
            return;
        }
        if (selection === contractCurrencies.DATA) {
            setCustomTokenAddress('');
            setSelectedTokenAddress(getDataAddress(chainId));
            setIsEditable(false);
            setTokenSymbol(null);
        }
        else if (selection === contractCurrencies.PRODUCT_DEFINED) {
            setSelectedTokenAddress(undefined);
            setIsEditable(customTokenAddress.length === 0);
            setTokenSymbol(null);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection, chainId]);
    const handleChange = useCallback((changes) => {
        const outputPrice = changes.price || price;
        const outputTimeUnit = changes.timeUnit || timeUnit;
        const output = {
            price: outputPrice ? new BN(outputPrice) : undefined,
            timeUnit: outputTimeUnit,
            tokenAddress: selectedTokenAddress === null || selectedTokenAddress === void 0 ? void 0 : selectedTokenAddress.toLowerCase(),
            pricePerSecond: outputPrice && outputTimeUnit && tokenDecimals
                ? new BN(pricePerSecondFromTimeUnit(new BN(outputPrice), outputTimeUnit, new BN(tokenDecimals)))
                : undefined,
        };
        debouncedOnChange(output);
    }, [price, selectedTokenAddress, timeUnit, tokenDecimals, debouncedOnChange]);
    useEffect(() => {
        var _a;
        if (selection === contractCurrencies.PRODUCT_DEFINED && !selectedTokenAddress) {
            return;
        }
        if ((selectedTokenAddress === null || selectedTokenAddress === void 0 ? void 0 : selectedTokenAddress.toUpperCase()) !== ((_a = value === null || value === void 0 ? void 0 : value.tokenAddress) === null || _a === void 0 ? void 0 : _a.toUpperCase())) {
            handleChange({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTokenAddress, value, selection]);
    return (React.createElement(Container, null,
        React.createElement(Heading, null,
            "Set the payment token and price on the ",
            chain != null ? capitalize(chain.name) : 'selected',
            " chain"),
        React.createElement(Description, null, "You can set a price for others to access the streams in your project. The price can be set in DATA or any other ERC-20 token."),
        React.createElement(Form, null,
            React.createElement(RadioContainer, { className: 'data-token-radio-container' },
                React.createElement(Radio, { id: 'data-token', name: 'token-selector-' + (chain === null || chain === void 0 ? void 0 : chain.name), label: React.createElement(RadioLabel, null,
                        React.createElement("span", null, "DATA Token"),
                        React.createElement(SvgIcon, { name: 'DATAColor', className: 'data-icon' })), value: contractCurrencies.DATA, disabled: disabled || tokenChangeDisabled, disabledReason: 'You need to select the chain first!', onChange: setSelection, className: 'radio', checked: selection === contractCurrencies.DATA })),
            React.createElement(RadioContainer, null,
                React.createElement(Radio, { id: 'custom-token', name: 'token-selector-' + (chain === null || chain === void 0 ? void 0 : chain.name), label: React.createElement(RadioLabel, null,
                        React.createElement("span", null, "Custom Token")), value: contractCurrencies.PRODUCT_DEFINED, disabled: disabled || tokenChangeDisabled, disabledReason: 'You need to select the chain first!', onChange: setSelection, className: 'radio', checked: selection === contractCurrencies.PRODUCT_DEFINED }),
                React.createElement(CustomTokenAddressInputContainer, { className: 'custom-' },
                    React.createElement("label", null, "Token contract address"),
                    React.createElement(Text, { autoComplete: "off", disabled: selection !== contractCurrencies.PRODUCT_DEFINED ||
                            disabled ||
                            !isEditable ||
                            tokenChangeDisabled, placeholder: 'e.g 0xdac17f958d2ee523a2206206994597c13d831ec7', value: customTokenAddress, onChange: (e) => setCustomTokenAddress(e.target.value), selectAllOnFocus: true, smartCommit: true, invalid: !isValid }),
                    React.createElement(SetTokenContainer, null,
                        React.createElement(Button, { onClick: () => {
                                setSelectedTokenAddress(customTokenAddress);
                            }, disabled: disabled }, "Set Custom Token")))),
            React.createElement(PriceContainer, null,
                React.createElement(PriceInputWrap, null,
                    React.createElement(Text, { className: 'price-input', placeholder: 'Set your price', onChange: (event) => {
                            handleChange({ price: event.target.value });
                            setPrice(event.target.value);
                        }, defaultValue: (value === null || value === void 0 ? void 0 : value.price) ? value.price.toString() : undefined, disabled: disabled }),
                    tokenSymbol && (React.createElement("span", { className: 'token-symbol' }, tokenSymbol))),
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { whiteVariant: true, placeholder: 'Unit', options: options, isClearable: false, value: timeUnit, onChange: (selected) => {
                            handleChange({ timeUnit: selected });
                            setTimeUnit(selected);
                        }, disabled: disabled }))))));
};
export default TokenSelector;
