var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import styled from 'styled-components';
import UnstyledFallbackImage from '$shared/components/FallbackImage';
import { isEthereumAddress } from '$mp/utils/validate';
import SvgIcon from '$shared/components/SvgIcon';
import Initials from './Initials';
import Identicon from './Identicon';
const FallbackImage = styled(UnstyledFallbackImage).withConfig({ displayName: "FallbackImage", componentId: "sc-83ntbu" }) `
    display: block;
    height: 100%;
    width: 100%;
`;
const UnstyledAvatarImage = (_a) => {
    var { username, name, src, upload = false } = _a, props = __rest(_a, ["username", "name", "src", "upload"]);
    const placeholder = useMemo(() => {
        if (isEthereumAddress(username)) {
            return React.createElement(Identicon, { id: username });
        }
        if (name) {
            const initials = (name || '')
                .split(/\s+/)
                .filter(Boolean)
                .map((s) => s[0])
                .slice(0, 2)
                .join('')
                .toUpperCase();
            if (initials) {
                return React.createElement(Initials, null, initials);
            }
        }
        return React.createElement(SvgIcon, { name: upload ? 'emptyAvatarUpload' : 'profileMan' });
    }, [username, name, upload]);
    return (React.createElement("div", Object.assign({}, props),
        React.createElement(FallbackImage, { alt: name || '', src: src || '', placeholder: placeholder })));
};
const AvatarImage = styled(UnstyledAvatarImage).withConfig({ displayName: "AvatarImage", componentId: "sc-1vihyll" }) ``;
export default AvatarImage;
