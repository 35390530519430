var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DESKTOP, MEDIUM, REGULAR, TABLET } from '$shared/utils/styled';
const termNames = {
    redistribution: 'Redistribution',
    commercialUse: 'Commercial use',
    reselling: 'Reselling',
    storage: 'Storage',
};
const getTermStrings = (ids) => ids.reduce((result, id, index) => {
    const term = termNames[id];
    if (index !== 0) {
        const separator = index === ids.length - 1 ? ' & ' : ', ';
        return `${result}${separator}${term.toLowerCase()}`;
    }
    return term;
}, '');
const Terms = (_a) => {
    var _b = _a.terms, { termsUrl = '', termsName = '' } = _b, terms = __rest(_b, ["termsUrl", "termsName"]);
    const entries = Object.entries(terms);
    const permitted = entries.filter((e) => e[1] === true).map((e) => e[0]);
    const notPermitted = entries.filter((e) => e[1] === false).map((e) => e[0]);
    const permittedStr = useMemo(() => getTermStrings(permitted), [permitted]);
    const notPermittedStr = useMemo(() => getTermStrings(notPermitted), [notPermitted]);
    if (!permitted.length && !notPermitted.length && !termsUrl) {
        return null;
    }
    return (React.createElement(TermsContainer, null,
        React.createElement("h3", null, "Terms and conditions"),
        React.createElement("div", null,
            (permitted.length > 0 || notPermitted.length > 0) && (React.createElement("p", null,
                React.createElement("strong", null, "Basic terms"),
                ' ',
                permitted.length > 0 && (React.createElement(React.Fragment, null,
                    permittedStr,
                    permitted.length === 1
                        ? ' is permitted.'
                        : ' are permitted.',
                    ' ')),
                notPermitted.length > 0 && (React.createElement(React.Fragment, null,
                    notPermittedStr,
                    notPermitted.length === 1 ? ' is not' : ' are not')),
                permitted.length === 0 && ' permitted',
                notPermitted.length > 0 && '.')),
            !!termsUrl && (React.createElement("p", null,
                React.createElement("strong", null, "Detailed terms"),
                ' ',
                React.createElement("strong", null,
                    React.createElement("a", { href: termsUrl, target: "_blank", rel: "noopener noreferrer" }, termsName != null && termsName.length > 0
                        ? termsName
                        : termsUrl)))))));
};
const TermsContainer = styled.div.withConfig({ displayName: "TermsContainer", componentId: "sc-s9joto" }) `
    padding: 30px 24px;
    background-color: white;
    border-radius: 16px;
    margin-top: 16px;
    font-size: 14px;
    line-height: 24px;

    h3 {
        font-size: 34px;
        line-height: 64px;
        font-weight: ${REGULAR};
    }

    strong {
        font-weight: ${MEDIUM};
    }

    @media (${TABLET}) {
        margin-top: 24px;
        flex-direction: row;
        padding: 45px 40px;
    }

    @media (${DESKTOP}) {
        padding: 30px 55px;
    }
`;
export default Terms;
