var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
const UnstyledRect = (_a) => {
    var { ratio = '1:1', height } = _a, props = __rest(_a, ["ratio", "height"]);
    return (React.createElement("svg", Object.assign({}, props, { height: height, viewBox: ratio == null ? undefined : `0 0 ${ratio.split(/[:x]/).join(' ')}`, width: height == null ? undefined : '100%', xmlns: "http://www.w3.org/2000/svg" })));
};
const Rect = styled(UnstyledRect).withConfig({ displayName: "Rect", componentId: "sc-pcca54" }) `
    display: block;
`;
export default Rect;
