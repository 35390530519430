var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { COLORS, TABLET } from '$shared/utils/styled';
import { Bits, setBits, unsetBits } from '$shared/stores/streamEditor';
import PermissionEditor from '$app/src/pages/AbstractStreamEditPage/AccessControlSection/PermissionEditor';
import { isEthereumAddress } from '$mp/utils/validate';
import address0 from '$utils/address0';
import UnstyledLabel from '$ui/Label';
import UnstyledErrors, { MarketplaceTheme } from '$ui/Errors';
import Text from '$ui/Text';
import FormModal from './FormModal';
import { RejectionReason } from './BaseModal';
const Separator = styled.div.withConfig({ displayName: "Separator", componentId: "sc-7cuu50" }) `
    border-bottom: 1px solid ${COLORS.separator};
    margin: 24px 0;

    @media ${TABLET} {
        border: 0;
        margin: 40px 0 0;
    }
`;
const Label = styled(UnstyledLabel).withConfig({ displayName: "Label", componentId: "sc-19nrs63" }) `
    display: flex;
`;
const Errors = styled(UnstyledErrors).withConfig({ displayName: "Errors", componentId: "sc-1jg95pv" }) `
    display: flex;
`;
const MessagedObject = z.object({
    message: z.string(),
});
function isMessaged(e) {
    return MessagedObject.safeParse(e).success;
}
export default function NewStreamPermissionsModal(_a) {
    var { onReject, onResolve, onBeforeSubmit } = _a, props = __rest(_a, ["onReject", "onResolve", "onBeforeSubmit"]);
    const [permissionBits, setPermissionBits] = useState(0);
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const cancelLabel = address || permissionBits !== 0 ? 'Cancel' : undefined;
    return (React.createElement(FormModal, Object.assign({}, props, { title: "Add a new account", onReject: onReject, onBeforeAbort: (reason) => {
            return ((permissionBits === 0 && address === '') ||
                reason !== RejectionReason.Backdrop);
        }, onSubmit: () => {
            if (address.toLowerCase() === address0) {
                return void setError('Invalid address');
            }
            if (address.length === 0) {
                return void setError('Address required');
            }
            if (!isEthereumAddress(address)) {
                return void setError('Invalid address format');
            }
            const result = {
                account: address,
                bits: permissionBits,
            };
            try {
                onBeforeSubmit === null || onBeforeSubmit === void 0 ? void 0 : onBeforeSubmit(result);
            }
            catch (e) {
                if (isMessaged(e)) {
                    return void setError(e.message);
                }
                return void setError('Something bad happened');
            }
            onResolve === null || onResolve === void 0 ? void 0 : onResolve(result);
        }, canSubmit: !!address, submitLabel: "Add new account", cancelLabel: cancelLabel }),
        React.createElement("div", null,
            React.createElement(Label, null, "Wallet address"),
            React.createElement(Text, { onCommit: (value) => {
                    setAddress(value);
                    setError('');
                }, placeholder: "0x..." }),
            !!error && (React.createElement(Errors, { theme: MarketplaceTheme, overlap: true }, error))),
        React.createElement(Separator, null),
        React.createElement(PermissionEditor, { address: address, permissionBits: permissionBits, onChange: (permission, enabled) => {
                setPermissionBits((enabled ? setBits : unsetBits)(permissionBits, Bits[permission]));
            } })));
}
