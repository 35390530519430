import React, { useRef } from 'react';
import styled from 'styled-components';
import Label from '$ui/Label';
import getCoreConfig from '$app/src/getters/getCoreConfig';
import StorageNodeItem from './StorageNodeItem';
function UnstyledStorageNodeList({ className, disabled = false }) {
    const { current: { storageNodes: storageNodes }, } = useRef(getCoreConfig());
    return (React.createElement("div", { className: className },
        React.createElement(Label, null, disabled ? 'Storage nodes' : 'Choose storage nodes (one or more)'),
        React.createElement("ul", { "data-test-hook": "Storage nodes" }, storageNodes.map(({ address, name }) => (React.createElement("li", { key: address },
            React.createElement(StorageNodeItem, { address: address, disabled: disabled }, name)))))));
}
const StorageNodes = styled(UnstyledStorageNodeList).withConfig({ displayName: "StorageNodes", componentId: "sc-nu5bdy" }) `
    margin-bottom: 40px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;
export default StorageNodes;
