import React, { useState } from 'react';
import styled from 'styled-components';
import { CodeSnippet, Tabs } from '@streamr/streamr-layout';
import { COLORS, MEDIUM } from '$shared/utils/styled';
import SvgIcon from '$shared/components/SvgIcon';
import Button from '$shared/components/Button';
import SelectField2 from '$mp/components/SelectField2';
import { truncateStreamName } from '$shared/utils/text';
import useCopy from '$shared/hooks/useCopy';
function stripIndent(code) {
    let minIndent = Number.POSITIVE_INFINITY;
    (code.match(/^[ \t]*(?=\S)/gm) || ['']).forEach(({ length }) => {
        if (length < minIndent) {
            minIndent = length;
        }
    });
    return code
        .replace(new RegExp(`^[ \\t]{${minIndent}}`, 'gm'), '')
        .trim()
        .replace(/^\s+$/gm, '');
}
const Snippet = {
    lightNodeHeader() {
        return `
            // Run a Streamr node right inside your JS app
            const StreamrClient = require('streamr-client')
            const streamr = new StreamrClient({
                auth: {
                    privateKey: 'ethereum-private-key' 
                }
            })
        `;
    },
    lightNodeSubscribe(streamId) {
        return `
            ${this.lightNodeHeader()}
            // Subscribe to a stream of messages
            streamr.subscribe('${streamId}', (msg) => {
                // Handle incoming messages
            })
        `;
    },
    lightNodePublish(streamId) {
        return `
            ${this.lightNodeHeader()}
            // Publish messages to a stream
            streamr.publish('${streamId}', {
                hello: 'world',
            })
        `;
    },
    websocketHeader(streamId) {
        return `
            // You'll want to URI-encode the stream id
            const streamId = encodeURIComponent('${streamId}')
        `;
    },
    websocketPublish(streamId) {
        return `
            ${this.websocketHeader(streamId)}
            // Connect to the Websocket plugin on your Streamr 
            // node and send JSON messages to publish them
            const pub = ws.connect(\`ws://my-streamr-node:7170/streams/\${streamId}/publish\`)
            pub.send({
                hello: 'world',
            })
        `;
    },
    websocketSubscribe(streamId) {
        return `
            ${this.websocketHeader(streamId)}
            // Connect to the Websocket plugin on your Streamr 
            // node and subscribe to a stream of messages
            const sub = ws.connect(\`ws://my-streamr-node:7170/streams/${streamId}/subscribe\`)
            sub.onmessage = (msg) => {
                // Handle incoming messages
            }
        `;
    },
    httpSubscribe() {
        return `
            // The Subscribe over HTTP is not available, as in it's not a valid selection.
        `;
    },
    httpPublish(streamId) {
        return `
            // Use your favourite language and HTTP library!

            // You'll want to URI-encode the stream id
            const streamId = encodeURIComponent('${streamId}')

            // Publish messages to a stream by POSTing JSON 
            // to the HTTP plugin on your Streamr node
            http.post(\`http://my-streamr-node:7171/streams/\${streamId}\`, {
                hello: 'world'
            })
        `;
    },
    mqttHeader() {
        return `
            // Use your favourite language and MQTT library!

            // Connect to MQTT plugin on your Streamr node
            mqtt.connect('mqtt://my-streamr-node')
        `;
    },
    mqttSubscribe(streamId) {
        return `
            ${this.mqttHeader()}
            // Subscribe to a stream of messages
            mqtt.subscribe('${streamId}', (msg) => {
                // Handle incoming messages
            })
        `;
    },
    mqttPublish(streamId) {
        return `
            ${this.mqttHeader()}
            // Publish a message to a stream
            mqtt.publish('${streamId}', {
                hello: 'world',
            })
        `;
    },
};
export const StreamConnect = ({ streams, }) => {
    const [streamId, setSelectedStream] = useState(streams[0]);
    const [action, setAction] = useState('subscribe');
    const [nodeType, setNodeType] = useState('lightNode');
    const [currentProtocol, setCurrentProtocol] = useState('websocket');
    const lightNodeSnippet = stripIndent(action === 'publish'
        ? Snippet.lightNodePublish(streamId)
        : Snippet.lightNodeSubscribe(streamId));
    const websocketSnippet = stripIndent(action === 'publish'
        ? Snippet.websocketPublish(streamId)
        : Snippet.websocketSubscribe(streamId));
    const httpSnippet = stripIndent(action === 'subscribe' ? Snippet.httpSubscribe() : Snippet.httpPublish(streamId));
    const mqttSnippet = stripIndent(action === 'publish'
        ? Snippet.mqttPublish(streamId)
        : Snippet.mqttSubscribe(streamId));
    const { copy } = useCopy();
    const currentBrokerSnippet = (() => {
        switch (currentProtocol) {
            case 'websocket':
                return websocketSnippet;
            case 'http':
                return httpSnippet;
            case 'mqtt':
                return mqttSnippet;
        }
    })();
    return (React.createElement("div", { className: 'row' },
        React.createElement("div", { className: 'col-lg-7' },
            React.createElement(StreamConnectHeader, null, "Connect"),
            React.createElement(StreamConnectText, null, "Applications publish and subscribe to streams via Streamr nodes. In other words, nodes are the access points to the Streamr Network. To connect your application to streams, you interface it with a Streamr node."),
            React.createElement(StreamConnectSubHeader, null, "There are two strategies for interfacing applications with Streamr nodes:"),
            React.createElement(StreamConnectList, null,
                React.createElement("li", null,
                    React.createElement("strong", null, "Light node: "),
                    "the node is imported to your application as a library and runs locally as part of your application"),
                React.createElement("li", null,
                    React.createElement("strong", null, "Broker node: "),
                    "the node runs separately, and your application connects to it remotely using one of the supported protocols")),
            React.createElement(SnippetSelectorContainer, null,
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { placeholder: '', options: [
                            { label: 'Subscribe', value: 'subscribe' },
                            { label: 'Publish', value: 'publish' },
                        ], value: action, isClearable: false, onChange: (action) => {
                            setAction(action);
                        }, noShrink: true, fullWidth: true })),
                React.createElement("span", null, "to"),
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { placeholder: '', options: streams.map((streamId) => ({
                            value: streamId,
                            label: truncateStreamName(streamId),
                        })), value: streamId, isClearable: false, onChange: (streamId) => {
                            setSelectedStream(streamId);
                        }, fullWidth: true })),
                React.createElement("span", null, "using"),
                React.createElement(SelectContainer, null,
                    React.createElement(SelectField2, { placeholder: '', options: [
                            { label: 'Light node', value: 'lightNode' },
                            { label: 'Broker node', value: 'brokerNode' },
                        ], value: nodeType, isClearable: false, onChange: (nodeType) => {
                            setNodeType(nodeType);
                        }, noShrink: true, fullWidth: true }))),
            nodeType === 'lightNode' && (React.createElement(React.Fragment, null,
                React.createElement(StreamConnectLightNodeSnippetContainer, null,
                    React.createElement(CodeSnippet, { language: 'javascript' }, lightNodeSnippet)),
                React.createElement(StreamConnectSnippetCopyContainer, null,
                    React.createElement(Button, { kind: "secondary", onClick: () => void copy(lightNodeSnippet, {
                            toastMessage: 'Copied',
                        }) }, "Copy")))),
            nodeType === 'brokerNode' && (React.createElement(BrokerNodeSnippetContainer, null,
                React.createElement(Tabs, { selected: currentProtocol, onSelect: (tab) => setCurrentProtocol(tab) },
                    React.createElement(Tabs.Item, { label: 'Websocket', value: 'websocket', key: 0 },
                        React.createElement(CodeSnippet, { language: 'javascript' }, websocketSnippet)),
                    React.createElement(Tabs.Item, { label: 'HTTP', value: 'http', key: 1 },
                        React.createElement(CodeSnippet, { language: 'javascript' }, httpSnippet)),
                    React.createElement(Tabs.Item, { label: 'MQTT', value: 'mqtt', key: 2 },
                        React.createElement(CodeSnippet, { language: 'javascript' }, mqttSnippet))),
                React.createElement(StreamConnectSnippetCopyContainer, null,
                    React.createElement(Button, { kind: "secondary", onClick: () => void copy(currentBrokerSnippet, {
                            toastMessage: 'Copied',
                        }) }, "Copy"))))),
        React.createElement(RightColumn, { className: 'col-lg-4 offset-lg-1' },
            React.createElement(StreamConnectLink, { href: 'https://www.npmjs.com/package/@streamr/cli-tools', target: '_blank', rel: 'noreferrer noopener' },
                React.createElement("span", null, "Streamr command line tool"),
                React.createElement(SvgIcon, { name: 'linkOut' })),
            React.createElement(StreamConnectLink, { href: 'https://www.npmjs.com/package/streamr-client', target: '_blank', rel: 'noreferrer noopener' },
                React.createElement("span", null, "Streamr JavaScript Client quickstart"),
                React.createElement(SvgIcon, { name: 'linkOut' })))));
};
const RightColumn = styled.div.withConfig({ displayName: "RightColumn", componentId: "sc-tm7skx" }) `
    padding-top: 88px;
`;
const StreamConnectHeader = styled.p.withConfig({ displayName: "StreamConnectHeader", componentId: "sc-1o82ust" }) `
    font-size: 24px;
    color: ${COLORS.primary};
    line-height: 64px;
    margin-bottom: 24px;
`;
const StreamConnectText = styled.p.withConfig({ displayName: "StreamConnectText", componentId: "sc-7e7sok" }) `
    font-size: 16px;
    color: ${COLORS.primary};
`;
const StreamConnectSubHeader = styled.p.withConfig({ displayName: "StreamConnectSubHeader", componentId: "sc-1ntbjgu" }) `
    font-size: 20px;
    color: ${COLORS.primary};
    font-weight: ${MEDIUM};
    margin-bottom: 24px;
`;
const StreamConnectLightNodeSnippetContainer = styled.div.withConfig({ displayName: "StreamConnectLightNodeSnippetContainer", componentId: "sc-19ci0ls" }) `
    border: 1px solid ${COLORS.separator};
    background-color: white;
    margin-top: 30px;
`;
const StreamConnectSnippetCopyContainer = styled.div.withConfig({ displayName: "StreamConnectSnippetCopyContainer", componentId: "sc-1mahpp2" }) `
    border: 1px solid ${COLORS.separator};
    border-top: none;
    background-color: white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const StreamConnectList = styled.ul.withConfig({ displayName: "StreamConnectList", componentId: "sc-1c11pva" }) `
    padding: 0;
    list-style-position: inside;
    margin-bottom: 36px;
    li {
        font-size: 16px;
    }
`;
const StreamConnectLink = styled.a.withConfig({ displayName: "StreamConnectLink", componentId: "sc-1liwix1" }) `
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${COLORS.secondaryLight};
    padding: 10px 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    span {
        color: ${COLORS.primary};
    }
    svg {
        color: ${COLORS.primary};
    }
`;
const SnippetSelectorContainer = styled.div.withConfig({ displayName: "SnippetSelectorContainer", componentId: "sc-24kodt" }) `
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    align-items: center;

    > * {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
`;
const BrokerNodeSnippetContainer = styled.div.withConfig({ displayName: "BrokerNodeSnippetContainer", componentId: "sc-1u71ehu" }) `
    margin-top: 30px;
`;
const SelectContainer = styled.div.withConfig({ displayName: "SelectContainer", componentId: "sc-f5dgl0" }) `
    height: 100%;
    min-width: 140px;
`;
