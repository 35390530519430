import React, { useCallback, useContext, useEffect, useMemo, } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { ValidationContext, ValidationContextProvider, } from '$mp/containers/ProductController/ValidationContextProvider';
import { ProjectControllerContext, ProjectControllerProvider, } from '$mp/containers/ProjectEditing/ProjectController';
import { ProjectStateContext, ProjectStateContextProvider, } from '$mp/contexts/ProjectStateContext';
import usePreventNavigatingAway from '$shared/hooks/usePreventNavigatingAway';
import Layout from '$shared/components/Layout';
import { EditorNav } from '$mp/containers/ProjectEditing/EditorNav';
import styles from '$shared/components/Layout/layout.pcss';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import { DetailsPageHeader } from '$shared/components/DetailsPageHeader';
import { ProjectEditor } from '$mp/containers/ProjectEditing/ProjectEditor';
import { LoadedProjectContextProvider, useLoadedProject, } from '$mp/contexts/LoadedProjectContext';
import PrestyledLoadingIndicator from '$shared/components/LoadingIndicator';
import { MarketplaceLoadingView } from '$mp/containers/ProjectPage/MarketplaceLoadingView';
import { getProjectTitleForEditor } from '$mp/containers/ProjectPage/utils';
import ProjectLinkTabs from '$app/src/pages/ProjectPage/ProjectLinkTabs';
import { ProjectPermission, useProjectAbility } from '$shared/stores/projectAbilities';
import getCoreConfig from '$app/src/getters/getCoreConfig';
import useIsMounted from '$shared/hooks/useIsMounted';
import { ProjectDraftContext, useInitProject, useIsProjectFetching, useProject, } from '$shared/stores/projectEditor';
import { useWalletAccount } from '$shared/stores/wallet';
import routes from '$routes';
const UnstyledEditProjectPage = () => {
    const { state: project } = useContext(ProjectStateContext);
    const { isAnyTouched, resetTouched } = useContext(ValidationContext);
    const { publishInProgress } = useContext(ProjectControllerContext);
    const { loadedProject } = useLoadedProject();
    usePreventNavigatingAway({
        isDirty: useCallback(() => {
            if (publishInProgress) {
                return false;
            }
            return !isEqual(loadedProject, project) && isAnyTouched();
        }, [publishInProgress, loadedProject, project, isAnyTouched]),
    });
    const nonEditableSalePointChains = useMemo(() => Object.values((loadedProject === null || loadedProject === void 0 ? void 0 : loadedProject.salePoints) || {}).map((salePoint) => salePoint.chainId), [loadedProject]);
    const isMounted = useIsMounted();
    const { chainId } = getCoreConfig().projectRegistry;
    const canEdit = useProjectAbility(chainId, (project === null || project === void 0 ? void 0 : project.id) || undefined, useWalletAccount(), ProjectPermission.Edit);
    const navigate = useNavigate();
    useEffect(() => {
        if (isMounted() && canEdit === false) {
            navigate(routes.projects.overview({
                id: project.id,
            }), {
                replace: true,
            });
        }
    }, [isMounted, canEdit, navigate, project.id]);
    useEffect(() => {
        resetTouched();
    }, [resetTouched]);
    const pageTitle = useMemo(() => {
        return getProjectTitleForEditor(project);
    }, [project]);
    return (React.createElement(Layout, { nav: React.createElement(EditorNav, { isNewProject: false, editedProductHasChanged: !isEqual(loadedProject, project) }), innerClassName: styles.greyInner },
        React.createElement(MarketplaceHelmet, { title: 'Edit project' }),
        React.createElement(DetailsPageHeader, { pageTitle: pageTitle, rightComponent: React.createElement(ProjectLinkTabs, null) }),
        React.createElement(LoadingIndicator, { loading: publishInProgress }),
        React.createElement(ProjectEditor, { nonEditableSalePointChains: nonEditableSalePointChains })));
};
const StyledEditProjectPage = styled(UnstyledEditProjectPage).withConfig({ displayName: "StyledEditProjectPage", componentId: "sc-1uwm6rs" }) `
    position: absolute;
    top: 0;
    height: 2px;
`;
const LoadingIndicator = styled(PrestyledLoadingIndicator).withConfig({ displayName: "LoadingIndicator", componentId: "sc-1gg3mo2" }) `
    top: 2px;
`;
const EditProjectInnerContainer = (props) => {
    const { loadedProject } = useLoadedProject();
    const project = useProject({ hot: true });
    const isFetching = useIsProjectFetching();
    if (isFetching) {
        return React.createElement(MarketplaceLoadingView, null);
    }
    return (React.createElement(ProjectStateContextProvider, { initState: project },
        React.createElement(ValidationContextProvider, null,
            React.createElement(ProjectControllerProvider, null,
                React.createElement(StyledEditProjectPage, Object.assign({}, props))))));
};
const EditProjectContainer = (props) => {
    const { id: projectId = 'new' } = useParams();
    return (React.createElement(ProjectDraftContext.Provider, { value: useInitProject(projectId === 'new' ? undefined : decodeURIComponent(projectId)) },
        React.createElement(LoadedProjectContextProvider, null,
            React.createElement(EditProjectInnerContainer, Object.assign({}, props)))));
};
export default EditProjectContainer;
