/* eslint-disable max-len */
import React from 'react';
export const GitHub = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M11.999 0C5.373 0 0 5.279 0 11.791c0 5.21 3.438 9.629 8.207 11.188.6.109.819-.255.819-.568 0-.28-.01-1.021-.017-2.005-3.337.712-4.042-1.581-4.042-1.581-.546-1.362-1.332-1.725-1.332-1.725-1.09-.731.082-.717.082-.717 1.205.084 1.838 1.216 1.838 1.216 1.07 1.801 2.81 1.281 3.493.98.109-.762.419-1.282.762-1.577-2.665-.297-5.467-1.31-5.467-5.827 0-1.287.468-2.34 1.236-3.164-.124-.298-.536-1.498.118-3.12 0 0 1.007-.318 3.3 1.208A11.685 11.685 0 0 1 12 5.702c1.02.005 2.046.135 3.005.397C17.295 4.573 18.3 4.89 18.3 4.89c.655 1.623.243 2.823.12 3.12.77.824 1.233 1.878 1.233 3.165 0 4.53-2.806 5.526-5.478 5.818.43.364.814 1.084.814 2.184 0 1.576-.015 2.847-.015 3.234 0 .315.217.682.825.567C20.565 21.416 24 17 24 11.791 24 5.28 18.627 0 11.999 0", fill: "currentColor", fillRule: "evenodd" })));
export const Medium = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M0 0h24v24H0V0zm5.727 8.027v7.126a.82.82 0 0 1-.218.707l-1.694 2.054v.271h4.803v-.27L6.924 15.86a.85.85 0 0 1-.233-.707V8.99l4.215 9.195h.49l3.62-9.195v7.33c0 .195 0 .232-.128.36l-1.302 1.264v.271h6.322v-.27l-1.257-1.235a.376.376 0 0 1-.143-.36V7.281a.376.376 0 0 1 .143-.362l1.287-1.234v-.27h-4.456l-3.176 7.923-3.613-7.924H4.019v.271L5.524 7.5a.624.624 0 0 1 .203.527z", fill: "currentColor" })));
export const Peepeth = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { d: "M22.665 12.513c-1.269-1.527-3.205-3.036-5.04-2.751-1.836.285-2.804 1.442-3.138 1.795.05-.872.801-3.07 2.97-3.07 3.672 0 5.008 3.07 5.208 4.026zm-21.313 0c.2-.956 1.535-4.026 5.207-4.026 2.17 0 2.92 2.198 2.97 3.07-.333-.353-1.3-1.51-3.137-1.795-1.836-.285-3.772 1.224-5.04 2.75z", opacity: ".503" }),
        React.createElement("path", { d: "M3.466 13.394c.128.168.33.276.556.276a.704.704 0 1 0 .435-1.257 1.757 1.757 0 0 1 2.386 1.643c0 .973-.785 1.761-1.753 1.761a1.757 1.757 0 0 1-1.752-1.761c0-.234.045-.457.128-.662zm13.836 0c.128.168.33.276.556.276a.704.704 0 1 0 .435-1.257 1.757 1.757 0 0 1 2.386 1.643c0 .973-.785 1.761-1.753 1.761a1.757 1.757 0 0 1-1.752-1.761c0-.234.045-.457.128-.662zM.778 16.824c-1.552-2.987-.567-7.6 1.069-10.133C3.482 4.16 5.769 1.98 12.628 0c-.2.403-.267.42-.6 1.04 3.087 0 6.074 1.09 8.695 3.656 2.62 2.567 4.34 7.9 2.52 12.127 0-1.446-.16-3.928-1.152-5.736-.717-1.308-2.653-2.801-4.79-2.6-2.506.236-2.837 2.7-2.837 3.791 0 1.09.567 2.063 1.302 3.74-.4-.447-1.63-1.867-3.689-4.26a472.851 472.851 0 0 1-3.822 4.294c.501-1.191.768-1.627 1.252-3.17.084-.445.09-.905.025-1.353-.216-1.512-1.227-2.883-2.695-3.025-1.903-.185-4.08 1.003-4.99 2.75-.551 1.058-1.052 2.886-1.069 5.57z" }),
        React.createElement("path", { d: "M8.248 16.794l3.785-1.212v2.834l-3.788-1.618z" }),
        React.createElement("path", { opacity: ".703", d: "M12.033 18.93v2.054L8.245 17.31l3.788 1.62z" }),
        React.createElement("path", { opacity: ".497", d: "M12.035 15.59l-3.79 1.208 3.79-4.352z" }),
        React.createElement("path", { d: "M15.819 16.794l-3.786-1.212v2.834l3.789-1.618z" }),
        React.createElement("path", { opacity: ".499", d: "M12.033 18.93v2.054l3.789-3.674-3.789 1.62z" }),
        React.createElement("path", { opacity: ".697", d: "M12.031 15.59l3.791 1.208-3.79-4.352z" }))));
export const Reddit = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M15.8 14.195a1.8 1.8 0 1 1 0-3.6 1.8 1.8 0 0 1 0 3.6m-.048 3.004c-1.37.741-2.639.994-3.728.994-2.248 0-3.74-1.073-3.831-1.14a.48.48 0 0 1-.104-.664.462.462 0 0 1 .651-.105c.12.087 2.906 2.06 6.574.077a.464.464 0 0 1 .631.196.479.479 0 0 1-.193.642M6.8 12.395a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0M19.8 2.2a1.4 1.4 0 0 1 0 2.799c-.772 0-1.4-.628-1.4-1.4 0-.772.628-1.4 1.4-1.4m4.2 8.215c0-1.499-1.231-2.718-2.744-2.718a2.75 2.75 0 0 0-1.837.7c-1.848-1.173-4.224-1.859-6.789-1.963l1.364-4.429 3.659 1.542c0 .016-.003.031-.003.047 0 1.205.99 2.185 2.206 2.185 1.217 0 2.207-.98 2.207-2.185 0-1.204-.99-2.184-2.207-2.184-.895 0-1.666.531-2.011 1.292L13.89 1.034a.434.434 0 0 0-.58.268l-1.577 5.119c-2.713.034-5.246.716-7.2 1.937a2.757 2.757 0 0 0-1.79-.662C1.231 7.696 0 8.916 0 10.414a2.69 2.69 0 0 0 1.254 2.279c-.046.269-.07.54-.07.812 0 1.928 1.144 3.728 3.222 5.067 2.02 1.302 4.698 2.02 7.54 2.02 2.842 0 5.52-.718 7.54-2.02 2.078-1.34 3.223-3.14 3.223-5.067 0-.25-.022-.501-.061-.75A2.709 2.709 0 0 0 24 10.414", fill: "currentColor", fillRule: "evenodd" })));
export const Telegram = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M11.995.045C5.37.045 0 5.415 0 12.04c0 6.624 5.37 11.994 11.995 11.994 6.624 0 11.994-5.37 11.994-11.994C23.99 5.415 18.62.045 11.995.045zM7.824 13.477l-3.027-1.02s-.464-.164-.509-.523c-.045-.36.524-.555.524-.555l12.03-4.719s.99-.434.99.285l-2.143 10.801s-.3.75-1.124.39l-3.048-2.337-1.956 1.783s-.152.116-.32.043l.395-3.37s5.483-4.928 5.708-5.138c.225-.21.15-.255.15-.255.015-.254-.405 0-.405 0l-7.265 4.615z", fill: "currentColor", fillRule: "evenodd" })));
export const Trello = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M21 0a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h18zm-1.56 3.12H15a1.44 1.44 0 0 0-1.44 1.44v7.62c0 .795.645 1.44 1.44 1.44h4.44a1.44 1.44 0 0 0 1.44-1.44V4.56a1.44 1.44 0 0 0-1.44-1.44zM9 3.12H4.56a1.44 1.44 0 0 0-1.44 1.44v13.62c0 .795.645 1.44 1.44 1.44H9a1.44 1.44 0 0 0 1.44-1.44V4.56A1.44 1.44 0 0 0 9 3.12z", fill: "currentColor", fillRule: "evenodd" })));
export const Twitter = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M7.539 21.592c9.056 0 14.012-7.54 14.012-14.067 0-.212 0-.425-.01-.637A10.055 10.055 0 0 0 24 4.324a9.95 9.95 0 0 1-2.833.78 4.97 4.97 0 0 0 2.17-2.738 9.773 9.773 0 0 1-3.13 1.196A4.906 4.906 0 0 0 16.614 2c-2.718 0-4.927 2.218-4.927 4.946 0 .386.048.762.125 1.128C7.72 7.872 4.09 5.895 1.66 2.906A4.96 4.96 0 0 0 1 5.394c0 1.716.874 3.23 2.19 4.117a4.966 4.966 0 0 1-2.23-.617v.067a4.95 4.95 0 0 0 3.948 4.85 4.784 4.784 0 0 1-1.297.174c-.317 0-.624-.03-.922-.087a4.927 4.927 0 0 0 4.6 3.432 9.853 9.853 0 0 1-6.117 2.121A9.44 9.44 0 0 1 0 19.384a13.968 13.968 0 0 0 7.539 2.208", fill: "currentColor" })));
export const Youtube = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M23.498 6.64C24 8.524 24 12.455 24 12.455s0 3.93-.502 5.814a3.016 3.016 0 0 1-2.121 2.135c-1.872.505-9.377.505-9.377.505s-7.505 0-9.377-.505A3.016 3.016 0 0 1 .501 18.27C0 16.385 0 12.455 0 12.455s0-3.93.501-5.815a3.015 3.015 0 0 1 2.122-2.135C4.495 4 12 4 12 4s7.505 0 9.377.505a3.015 3.015 0 0 1 2.121 2.135zM9.545 16.023l6.273-3.568-6.273-3.569v7.137z", fill: "currentColor", fillRule: "evenodd" })));
export const LinkedIn = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M2.87 5.351c1.791 0 2.906-1.19 2.906-2.676C5.743 1.157 4.661 0 2.906 0 1.147 0 0 1.157 0 2.675c0 1.486 1.114 2.676 2.838 2.676h.033zM.304 22.95V7.465h5.135v15.484H.303zM8 22.847S8.067 8.816 8 7.364h5.135v2.245H13.1C13.776 8.552 14.992 7 17.763 7c3.378 0 5.911 2.213 5.911 6.97v8.877H18.54v-8.283c0-2.081-.743-3.502-2.602-3.502-1.418 0-2.263.958-2.634 1.883-.136.33-.17.793-.17 1.255v8.647H8z", fill: "currentColor", fillRule: "evenodd" })));
export const Facebook = ({ className }) => (React.createElement("svg", { viewBox: "0 0 12 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M3 8H0v4h3v12h5V12h3.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8z", fill: "currentColor", fillRule: "nonzero" })));
export const Instagram = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M14.829 6.302c-.738-.034-.96-.04-2.829-.04-1.869 0-2.09.007-2.828.04-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828 0 1.869.008 2.09.041 2.829.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829 0-1.869-.007-2.09-.04-2.828-.088-1.883-.973-2.783-2.87-2.87zM12 15.595a3.595 3.595 0 110-7.19 3.595 3.595 0 010 7.19zm3.737-6.491a.84.84 0 110-1.68.84.84 0 010 1.68zM14.333 12a2.333 2.333 0 11-4.666 0 2.333 2.333 0 014.666 0zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072C5.008 14.14 5 13.901 5 12c0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071C9.861 5.008 10.099 5 12 5c1.901 0 2.14.008 2.887.043 2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z", fill: "currentColor", fillRule: "nonzero" })));
export const Web = ({ className }) => (React.createElement("svg", { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", className: className },
    React.createElement("path", { d: "M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm3.994 11.5a19.572 19.572 0 00-.295-3h2.365c.517.894.842 1.913.918 3h-2.988zm-1 0H9.006c.026-1.06.135-2.075.312-3h5.364c.177.925.286 1.94.312 3zm-.138-5.893A7.022 7.022 0 0117.362 7.5h-1.876a11.851 11.851 0 00-.63-1.893zM13.5 5.161c.382.61.706 1.41.955 2.339H9.546c.249-.929.573-1.729.955-2.34a7.026 7.026 0 012.998 0zm2.495 7.339h2.988a6.958 6.958 0 01-.918 3h-2.365c.169-.937.27-1.95.295-3zm-1 0a18.447 18.447 0 01-.312 3H9.318a18.447 18.447 0 01-.312-3h5.988zm-.138 5.893c.247-.564.458-1.2.63-1.893h1.876a7.022 7.022 0 01-2.506 1.893zm-1.357.446a7.026 7.026 0 01-2.998 0c-.382-.61-.706-1.41-.955-2.339h4.908c-.249.929-.573 1.729-.955 2.34zM5.936 8.5h2.365c-.169.937-.27 1.95-.295 3H5.018a6.958 6.958 0 01.918-3zm.702-1a7.022 7.022 0 012.506-1.893c-.247.564-.458 1.2-.63 1.893H6.638zm-.702 8a6.958 6.958 0 01-.918-3h2.988c.024 1.05.126 2.063.295 3H5.936zm.702 1h1.876c.172.692.383 1.33.63 1.893A7.022 7.022 0 016.638 16.5zM12 4a8 8 0 100 16 8 8 0 000-16z", fill: "currentColor", fillRule: "evenodd" })));
