import React, { useCallback, useEffect, useState, } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import ProjectsComponent, { ProjectsContainer } from '$mp/components/Projects';
import ActionBar from '$mp/components/ActionBar';
import Layout from '$shared/components/Layout';
import Footer from '$shared/components/Layout/Footer';
import LoadingIndicator from '$shared/components/LoadingIndicator';
import useModal from '$shared/hooks/useModal';
import CreateProjectModal from '$mp/containers/CreateProjectModal';
import { getProjects, searchProjects, } from '$app/src/services/projects';
import useDeepEqualMemo from '$shared/hooks/useDeepEqualMemo';
import { useWalletAccount } from '$shared/stores/wallet';
import styles from './projects.pcss';
const PAGE_SIZE = 16;
const EMPTY_FILTER = {
    search: '',
    type: null,
    owner: null,
};
const ProjectsPage = () => {
    var _a, _b;
    const [filterValue, setFilter] = useState(EMPTY_FILTER);
    const filter = useDeepEqualMemo(filterValue);
    const { api: createProductModal } = useModal('marketplace.createProduct');
    const account = useWalletAccount();
    const query = useInfiniteQuery({
        queryKey: ['projects', filter.owner, filter.search, filter.type],
        queryFn: (ctx) => {
            var _a, _b;
            if (filter.search != null && filter.search.length > 0) {
                return searchProjects(filter.search, PAGE_SIZE, ctx.pageParam, filter.type);
            }
            else {
                return getProjects((_a = filter.owner) !== null && _a !== void 0 ? _a : undefined, PAGE_SIZE, ctx.pageParam, (_b = filter.type) !== null && _b !== void 0 ? _b : undefined);
            }
        },
        getNextPageParam: (lastPage, pages) => {
            return lastPage.hasNextPage ? pages.flatMap((p) => p.projects).length : null;
        },
        staleTime: 60 * 1000,
        keepPreviousData: true,
    });
    const onFilterChange = useCallback((newFilter) => {
        setFilter((prev) => (Object.assign(Object.assign({}, prev), newFilter)));
    }, []);
    const onSearchChange = useCallback((search) => {
        setFilter((prev) => (Object.assign(Object.assign({}, prev), { search })));
    }, []);
    const onFilterByAuthorChange = useCallback((myProjects) => {
        setFilter((prev) => (Object.assign(Object.assign({}, prev), { owner: myProjects && account ? account : null })));
    }, [account]);
    useEffect(() => {
        setFilter((filter) => filter.owner && account ? Object.assign(Object.assign({}, filter), { owner: account }) : filter);
    }, [account]);
    const noOwnProjects = !!filter.owner && !filter.search && !filter.type;
    return (React.createElement(Layout, { className: styles.projectsListPage, framedClassName: styles.productsFramed, innerClassName: styles.productsInner, footer: false },
        React.createElement(MarketplaceHelmet, { title: "Projects" }),
        React.createElement(ActionBar, { filter: filter, onFilterChange: onFilterChange, onSearchChange: onSearchChange, onCreateProject: () => createProductModal.open(), onFilterByAuthorChange: onFilterByAuthorChange, isUserAuthenticated: !!account }),
        React.createElement(LoadingIndicator, { loading: query.isLoading || query.isFetching || query.isFetchingNextPage }),
        React.createElement(CreateProjectModal, null),
        React.createElement(ProjectsContainer, null,
            React.createElement(ProjectsComponent, { projects: (_b = (_a = query.data) === null || _a === void 0 ? void 0 : _a.pages.flatMap((d) => d.projects)) !== null && _b !== void 0 ? _b : [], currentUserAddress: account, error: query.error, type: "projects", isFetching: query.status === 'loading', loadProducts: () => query.fetchNextPage(), hasMoreSearchResults: query.hasNextPage, noOwnProjects: noOwnProjects })),
        React.createElement(Footer, null)));
};
export default ProjectsPage;
