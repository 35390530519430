var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useCallback, useState, useContext, } from 'react';
import useIsMounted from '$shared/hooks/useIsMounted';
import { validate as validateProduct } from '$mp/utils/product';
export var SeverityLevel;
(function (SeverityLevel) {
    SeverityLevel["INFO"] = "info";
    SeverityLevel["WARNING"] = "warning";
    SeverityLevel["ERROR"] = "error";
})(SeverityLevel || (SeverityLevel = {}));
export const ValidationContext = React.createContext({});
const validationErrors = {
    name: 'Product name cannot be empty',
    description: 'Product description cannot be empty',
    creator: "Missing or invalid creator's name",
    imageUrl: 'Product must have a cover image',
    streams: 'No streams selected',
    termsOfUse: 'Invalid URL for detailed terms',
    adminFee: 'Invalid admin fee',
    existingDUAddress: 'No Data Union address selected',
    'contact.url': 'Invalid URL',
    'contact.twitter': 'Invalid URL',
    'contact.linkedIn': 'Invalid URL',
    'contact.reddit': 'Invalid URL',
    'contact.telegram': 'Invalid URL',
    'contact.email': 'Email address is required',
    salePoints: 'Missing or invalid payment information',
    // keep in mind that we need to provide error messages for each possible chain - otherwise the error messages might not show up
    'salePoints.gnosis': 'Invalid payment information for the Gnosis chain',
    'salePoints.polygon': 'Invalid payment information for the Polygon chain',
    'salePoints.ethereum': 'Invalid payment information for the Ethereum chain',
    'salePoints.dev0': 'Invalid payment information for the dev0 chain',
    'salePoints.dev1': 'Invalid payment information for the dev1 chain',
};
function useValidationContextImplementation() {
    const [status, setStatusState] = useState({});
    const [touched, setTouchedState] = useState({});
    const setTouched = useCallback((name, value = true) => {
        setTouchedState((existing) => (Object.assign(Object.assign({}, existing), { [name]: !!value })));
    }, [setTouchedState]);
    const isTouched = useCallback((name) => !!touched[name], [touched]);
    const isAnyTouched = useCallback(() => Object.values(touched).some(Boolean), [touched]);
    const resetTouched = useCallback(() => setTouchedState({}), []);
    const isMounted = useIsMounted();
    const setStatus = useCallback((name, level, message) => {
        if (!isMounted()) {
            return;
        }
        if (!name) {
            throw new Error('validation needs a name');
        }
        setStatusState((state) => (Object.assign(Object.assign({}, state), { [name]: {
                level,
                message,
            } })));
    }, [setStatusState, isMounted]);
    const clearStatus = useCallback((name) => {
        if (!isMounted()) {
            return;
        }
        if (!name) {
            throw new Error('validation needs a name');
        }
        setStatusState((_a) => {
            var _b = name, _ = _a[_b], newState = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
            return newState;
        });
    }, [setStatusState, isMounted]);
    const isValid = useCallback((name) => !status[name], [status]);
    const validate = useCallback((product) => {
        if (!isMounted() || !product) {
            return;
        }
        const invalidFields = validateProduct(product);
        const result = {};
        Object.keys(validationErrors).forEach((field) => {
            if (invalidFields[field]) {
                setStatus(field, SeverityLevel.ERROR, validationErrors[field]);
                result[field] = {
                    level: SeverityLevel.ERROR,
                    message: validationErrors[field],
                };
            }
            else {
                clearStatus(field);
            }
        });
        return result;
    }, [setStatus, clearStatus, isMounted]);
    return useMemo(() => ({
        setStatus,
        clearStatus,
        isValid,
        touched,
        setTouched,
        isTouched,
        isAnyTouched,
        resetTouched,
        status,
        validate,
    }), [
        status,
        setStatus,
        isValid,
        touched,
        setTouched,
        isTouched,
        isAnyTouched,
        resetTouched,
        clearStatus,
        validate,
    ]);
}
export const ValidationContextProvider = ({ children }) => {
    return (React.createElement(ValidationContext.Provider, { value: useValidationContextImplementation() }, children || null));
};
export const useValidationContext = () => {
    return useContext(ValidationContext);
};
