import React, { useCallback, useMemo, useEffect, useState, } from 'react';
import styled from 'styled-components';
import { TABLET } from '$shared/utils/styled';
import StreamTable, { StreamTableLight } from '$shared/components/StreamTable';
import { getStreamsFromIndexer } from '$app/src/services/streams';
import useLoadProductStreamsCallback from '$mp/containers/ProductController/useLoadProductStreamsCallback';
const PAGE_SIZE = 5;
const INITIAL_OFFSET = 2 * PAGE_SIZE;
const StreamsContainer = styled.div.withConfig({ displayName: "StreamsContainer", componentId: "sc-1ntpmz" }) `
    background-color: white;
    border-radius: 16px;
    margin-top: 16px;
    @media (${TABLET}) {
        margin-top: 24px;
    }
`;
export default function Streams({ streams: streamsProp }) {
    const [streams, setStreams] = useState([]);
    const [streamStats, setStreamStats] = useState({});
    const [offset, setOffset] = useState(INITIAL_OFFSET);
    const appendStreams = useCallback((streams) => {
        setStreams((prev) => [...prev, ...streams]);
    }, []);
    const loadStreams = useLoadProductStreamsCallback({
        setProductStreams: appendStreams,
    });
    useEffect(() => {
        loadStreams(streamsProp.slice(0, INITIAL_OFFSET));
    }, [streamsProp, loadStreams]);
    useEffect(() => {
        const getStreamStats = async () => {
            try {
                const stats = await getStreamsFromIndexer(streams.map((s) => s.id));
                if (stats && stats.length > 0) {
                    setStreamStats((prev) => (Object.assign(Object.assign({}, prev), Object.fromEntries(stats.map((is) => [is.id, is])))));
                }
            }
            catch (e) {
                console.warn('Fetching stream stats failed', e);
            }
        };
        getStreamStats();
    }, [streams]);
    const hasMoreResults = useMemo(() => offset < streamsProp.length, [offset, streamsProp]);
    const onLoadMore = useCallback(() => {
        loadStreams(streamsProp.slice(offset, offset + PAGE_SIZE));
        setOffset(offset + PAGE_SIZE);
    }, [offset, setOffset, loadStreams, streamsProp]);
    return (React.createElement(React.Fragment, null,
        streamsProp &&
            streamsProp.length > 0 &&
            (!streams || streams.length === 0) && (React.createElement(StreamsContainer, null,
            React.createElement(StreamTableLight, { streamIds: streamsProp.slice(0, INITIAL_OFFSET) }))),
        streams && streams.length > 0 && (React.createElement(StreamsContainer, null,
            React.createElement(StreamTable, { streams: streams, loadMore: onLoadMore, hasMoreResults: hasMoreResults, streamStats: streamStats, showGlobalStats: false })))));
}
