var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
const Helmet = (_a) => {
    var { description, image, suffix, title } = _a, props = __rest(_a, ["description", "image", "suffix", "title"]);
    return (React.createElement(ReactHelmet, Object.assign({}, props, { title: [title, suffix].filter(Boolean).join(' | ') }),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:title", content: title }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:image", content: image }),
        React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
        React.createElement("meta", { name: "twitter:title", content: title }),
        React.createElement("meta", { name: "twitter:description", content: description }),
        React.createElement("meta", { name: "twitter:image", content: image })));
};
export const MarketplaceHelmet = (_a) => {
    var { description = 'Discover, create and consume data streams on the Streamr Hub', image = 'https://streamr.network/resources/social/marketplace.png', title, suffix } = _a, props = __rest(_a, ["description", "image", "title", "suffix"]);
    return (React.createElement(Helmet, Object.assign({}, props, { description: description, image: image, suffix: suffix || 'Streamr Hub', title: title })));
};
