var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { DropdownItem as RsDropdownItem } from 'reactstrap';
import SvgIcon from '$shared/components/SvgIcon';
const TickIcon = styled(SvgIcon).withConfig({ displayName: "TickIcon", componentId: "sc-1pb8n0k" }) `
    width: 10px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 10px;
`;
const StyledDropdownItem = styled(RsDropdownItem).withConfig({ displayName: "StyledDropdownItem", componentId: "sc-14jpgut" }) `
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    padding: 10px 16px !important;

    &&[data-tick-position='left'] {
        padding-left: 32px;

        ${TickIcon} {
            left: 10px;
            right: auto;
        }
    }
`;
const PopoverItem = (_a) => {
    var { children, active, onClick, leftTick, disabled } = _a, rest = __rest(_a, ["children", "active", "onClick", "leftTick", "disabled"]);
    return (React.createElement(StyledDropdownItem, Object.assign({}, rest, { onClick: onClick, "data-tick-position": leftTick ? 'left' : 'right', disabled: disabled }),
        children,
        active && React.createElement(TickIcon, { name: "tick" })));
};
export default PopoverItem;
