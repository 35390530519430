import React from 'react';
import styled from 'styled-components';
import { Footer as UnstyledLayoutFooter, FooterColumn, FooterColumns as UnstyledFooterColumns, MadeBy as UnstyledMadeBy, SocialChannels, } from '@streamr/streamr-layout';
import { COLORS } from '$shared/utils/styled';
import routes from '$routes';
const MadeBy = styled(UnstyledMadeBy).withConfig({ displayName: "MadeBy", componentId: "sc-fecw0c" }) `
    padding: 0 0 32px;
    text-align: center;

    @media (min-width: 1200px) {
        padding: 32px 0;
        text-align: left;
    }
`;
const LayoutFooter = styled(UnstyledLayoutFooter).withConfig({ displayName: "LayoutFooter", componentId: "sc-18d3oz5" }) `
    background-color: ${COLORS.secondary};
`;
const FooterColumns = styled(UnstyledFooterColumns).withConfig({ displayName: "FooterColumns", componentId: "sc-1p215zp" }) `
    border-top: ${({ separate }) => (separate ? '1px' : '0')} solid #d8d8d8;
`;
const Footer = ({ topBorder = false }) => (React.createElement(LayoutFooter, null,
    React.createElement(FooterColumns, { separate: topBorder },
        React.createElement(FooterColumn, { title: "Discover" },
            React.createElement("a", { href: routes.root() }, "Top"),
            React.createElement("a", { href: routes.site.discover.dataToken() }, "DATA Token"),
            React.createElement("a", { href: routes.site.discover.dataUnions() }, "Data Unions"),
            React.createElement("a", { href: routes.site.discover.marketplace() }, "Marketplace"),
            React.createElement("a", { href: routes.site.discover.network() }, "Network")),
        React.createElement(FooterColumn, { title: "Project" },
            React.createElement("a", { href: routes.site.about() }, "About"),
            React.createElement("a", { href: routes.site.roadmap() }, "Roadmap"),
            React.createElement("a", { href: routes.site.ecosystem() }, "Ecosystem"),
            React.createElement("a", { href: routes.site.papers() }, "Papers"),
            React.createElement("a", { href: routes.community.medium() }, "Blog")),
        React.createElement(FooterColumn, { title: "Developers" },
            React.createElement("a", { href: "https://docs.streamr.network/" }, "Docs"),
            React.createElement("a", { href: routes.site.fund() }, "Data Fund"),
            React.createElement("a", { href: routes.site.design() }, "Design Assets")),
        React.createElement(FooterColumn, { title: "Apps" },
            React.createElement("a", { href: routes.networkExplorer() }, "Network Explorer"),
            React.createElement("a", { href: routes.hub() }, "Hub")),
        React.createElement(FooterColumn, { title: "Contact" },
            React.createElement("a", { href: routes.contact.general() }, "General"),
            React.createElement("a", { href: routes.contact.media() }, "Media"),
            React.createElement("a", { href: routes.contact.jobs() }, "Jobs"),
            React.createElement("a", { href: routes.contact.labs() }, "Business")),
        React.createElement(FooterColumn, { title: "Legal" },
            React.createElement("a", { href: routes.tos() }, "Terms & Conditions"),
            React.createElement("a", { href: routes.privacyPolicy() }, "Privacy"))),
    React.createElement(SocialChannels, null),
    React.createElement(MadeBy, null)));
export default Footer;
