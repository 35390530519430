import React, { useState } from 'react';
import styled from 'styled-components';
import { truncate } from '$shared/utils/text';
import SvgIcon from '$shared/components/SvgIcon';
import { COLORS } from '$shared/utils/styled';
import { Bits, matchBits, setBits, unsetBits, useDraftId, useStreamEditorStore, } from '$shared/stores/streamEditor';
import { useWalletAccount } from '$shared/stores/wallet';
import UnstyledPermissionEditor from './PermissionEditor';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-12ld2wy" }) `
    background: #ffffff;
    border-radius: 4px;
    display: grid;
    grid-template-rows: 1fr auto auto;
    align-items: center;
    transition: all 180ms ease-in-out;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-gwayqn" }) `
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    align-items: center;
    border-bottom: ${({ $isOpen }) => ($isOpen ? '1px' : '0px')} solid ${COLORS.separator};
    height: 72px;
    padding: 21px;

    &:hover {
        cursor: pointer;
    }
`;
const Labels = styled.div.withConfig({ displayName: "Labels", componentId: "sc-1sxj4pz" }) `
    display: flex;
    overflow: hidden;
`;
const Label = styled.div.withConfig({ displayName: "Label", componentId: "sc-ilp9a" }) `
    color: ${COLORS.primary};
    background-color: ${COLORS.secondary};
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
    margin-right: 8px;
`;
const YouLabel = styled(Label).withConfig({ displayName: "YouLabel", componentId: "sc-1vghh0j" }) `
    color: white;
    background-color: ${COLORS.primaryLight};
`;
const PermissionEditor = styled(UnstyledPermissionEditor).withConfig({ displayName: "PermissionEditor", componentId: "sc-1s2iujr" }) `
    padding: 21px;
`;
const DropdownCaret = styled(SvgIcon).withConfig({ displayName: "DropdownCaret", componentId: "sc-lwbikn" }) `
    display: block;
    color: ${COLORS.primaryLight};
    width: 12px;
    height: 12px;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 180ms ease-in-out;
`;
const PermissionItem = ({ disabled, address, permissionBits, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const account = useWalletAccount();
    const operations = Object.keys(Bits).filter((permission) => matchBits(Bits[permission], permissionBits));
    const draftId = useDraftId();
    const setPermissions = useStreamEditorStore(({ setPermissions }) => setPermissions);
    return (React.createElement(Container, null,
        React.createElement(Title, { "$isOpen": isOpen, onClick: () => setIsOpen((prev) => !prev) },
            isOpen ? address : truncate(address),
            isOpen ? (React.createElement("div", null)) : (React.createElement(Labels, null,
                (account === null || account === void 0 ? void 0 : account.toLowerCase()) === address.toLowerCase() && (React.createElement(YouLabel, null, "You")),
                operations.map((op) => (React.createElement(Label, { key: op }, op.replace(/^\w/, (s) => s.toUpperCase())))))),
            React.createElement(DropdownCaret, { name: "caretDown", "$isOpen": isOpen })),
        isOpen && (React.createElement(PermissionEditor, { address: address, permissionBits: permissionBits, disabled: disabled, onChange: (permission, enabled) => {
                if (!draftId) {
                    return;
                }
                setPermissions(draftId, address, (enabled ? setBits : unsetBits)(permissionBits, Bits[permission]));
            } }))));
};
export default PermissionItem;
