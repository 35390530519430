import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect, { components, } from 'react-select';
import { StyledCaretIcon, StyledDropdownIndicator, StyledOption, getControlStyles, getMenuListStyles, getMenuStyles, getOptionStyles, getPlaceholderStyles, getSingleValueStyles, getClearIndicatorStyles, StyledCloseIcon, getWhiteControlStyles, getWhitePlaceholderStyles, getWhiteMenuStyles, getWhiteMenuListStyles, getWhiteOptionStyles, StyledWhiteDropdownOption, } from './selectField2.styles';
const DropdownIndicator = (props) => {
    return (components.DropdownIndicator && (React.createElement(StyledDropdownIndicator, Object.assign({}, props),
        React.createElement(StyledCaretIcon, { name: "caretDown", className: (props.selectProps.menuIsOpen ? 'rotated' : '') +
                (props.selectProps.isDisabled ? 'disabled' : '') }))));
};
const ClearIndicator = (props) => {
    return (components.ClearIndicator && (React.createElement(components.ClearIndicator, Object.assign({}, props),
        React.createElement(StyledCloseIcon, { name: 'close', className: props.selectProps.menuIsOpen ? 'menu-is-open' : '' }))));
};
const SelectField2 = ({ placeholder, options, value, onChange, disabled, isClearable = true, whiteVariant = false, noShrink = false, fullWidth = false, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState();
    const defaultStyles = useMemo(() => ({
        control: (styles, props) => getControlStyles(styles, props.isFocused, isOpen, disabled, noShrink, fullWidth),
        placeholder: (styles) => getPlaceholderStyles(styles, isOpen, disabled),
        singleValue: (styles) => getSingleValueStyles(styles, isOpen, disabled, noShrink),
        menu: (styles) => getMenuStyles(styles),
        menuList: (styles) => getMenuListStyles(styles),
        option: (styles, props) => getOptionStyles(styles, props.isSelected),
        clearIndicator: (styles) => getClearIndicatorStyles(styles, isOpen),
    }), [disabled, fullWidth, isOpen, noShrink]);
    const whiteVariantStyles = useMemo(() => ({
        placeholder: (styles) => getWhitePlaceholderStyles(styles),
        control: (styles, props) => getWhiteControlStyles(styles, props.isFocused, disabled, noShrink, fullWidth),
        menu: (styles) => getWhiteMenuStyles(styles),
        menuList: (styles) => getWhiteMenuListStyles(styles),
        option: (styles, props) => getWhiteOptionStyles(styles, props.isSelected),
    }), [disabled, fullWidth, noShrink]);
    useEffect(() => {
        setSelected(value);
    }, [value]);
    const handleChange = (option) => {
        if (disabled) {
            return;
        }
        setSelected(option === null || option === void 0 ? void 0 : option.value);
        onChange(option === null || option === void 0 ? void 0 : option.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactSelect, { placeholder: placeholder, options: options, isSearchable: false, isClearable: isClearable, isDisabled: disabled, menuIsOpen: isOpen, onMenuOpen: () => {
                setIsOpen(true);
            }, onMenuClose: () => {
                setIsOpen(false);
            }, components: {
                DropdownIndicator,
                ClearIndicator,
                IndicatorSeparator: () => React.createElement(React.Fragment, null),
                Option: whiteVariant ? StyledWhiteDropdownOption : StyledOption,
            }, value: selected ? options.find((option) => option.value === selected) : null, onChange: (option) => handleChange(option), styles: whiteVariant ? whiteVariantStyles : defaultStyles, menuPortalTarget: document.body })));
};
export default SelectField2;
