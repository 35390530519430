import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import Button from '$shared/components/Button';
import Text from '$ui/Text';
import SelectField2 from '$mp/components/SelectField2';
import { timeUnits } from '$shared/utils/timeUnit';
import { COLORS, LIGHT, MEDIUM } from '$shared/utils/styled';
import { getConfigForChain } from '$shared/web3/config';
import NetworkIcon from '$shared/components/NetworkIcon';
import TokenLogo from '$shared/components/TokenLogo';
import { formatChainName } from '$shared/utils/chains';
import { fromDecimals } from '$mp/utils/math';
import { priceForTimeUnits } from '$mp/utils/price';
import address0 from '$utils/address0';
import useIsMounted from '$shared/hooks/useIsMounted';
import { getAllowance } from '$app/src/getters';
import ProjectModal, { Actions } from './ProjectModal';
import { RejectionReason } from './BaseModal';
const options = [timeUnits.hour, timeUnits.day, timeUnits.week, timeUnits.month].map((unit) => ({
    label: `${unit.charAt(0).toUpperCase()}${unit.slice(1)}s`,
    value: unit,
}));
const PeriodContainer = styled.div.withConfig({ displayName: "PeriodContainer", componentId: "sc-ryxjb6" }) `
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #ffffff;
    border-radius: 8px;
    gap: 16px;
    padding: 32px 24px;
`;
const DetailsContainer = styled.div.withConfig({ displayName: "DetailsContainer", componentId: "sc-1bhgrol" }) `
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    margin-top: 12px;
`;
const Chain = styled.div.withConfig({ displayName: "Chain", componentId: "sc-17w5t27" }) `
    display: grid;
    grid-template-columns: auto 1fr;
    background: ${COLORS.secondary};
    border-radius: 8px;
    padding: 18px 16px;
    gap: 8px;
    align-items: center;
`;
const ChainIcon = styled(NetworkIcon).withConfig({ displayName: "ChainIcon", componentId: "sc-1cfvziq" }) `
    width: 32px;
    height: 32px;
`;
const ChainName = styled.div.withConfig({ displayName: "ChainName", componentId: "sc-14cbza9" }) `
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
`;
const Amount = styled.span.withConfig({ displayName: "Amount", componentId: "sc-e9c8sn" }) `
    font-size: 44px;
    font-weight: ${LIGHT};
`;
const AmountBoxInner = styled.div.withConfig({ displayName: "AmountBoxInner", componentId: "sc-1wnrko6" }) `
    padding: 0 16px;
    width: max-content;
`;
const AmountBox = styled.div.withConfig({ displayName: "AmountBox", componentId: "sc-1kk3jvy" }) `
    background: #f5f5f5;
    border-radius: 8px;
    position: relative;
    margin-top: 12px;
    line-height: normal;
`;
const AmountScrollable = styled.div.withConfig({ displayName: "AmountScrollable", componentId: "sc-1q3e3j0" }) `
    max-width: 100%;
    overflow: auto;
    padding: 44px 0;
`;
const AmountBarInner = styled.div.withConfig({ displayName: "AmountBarInner", componentId: "sc-16lz2fn" }) `
    padding: 0 16px;

    > div {
        height: 19px;
        display: flex;
        align-items: center;
    }

    > div > div {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    > div > * + div {
        margin-left: 12px;
    }

    > div > span {
        flex-grow: 1;
    }
`;
const AmountBar = styled.div.withConfig({ displayName: "AmountBar", componentId: "sc-168ggw0" }) `
    letter-spacing: 0.05em;
    font-size: 12px;
    white-space: nowrap;
    width: 100%;
    color: #525252;
    text-transform: uppercase;
    position: absolute;
    padding: 18px 0;
    pointer-events: none;

    * + & {
        color: #a3a3a3;
        top: 90px;
    }

    em {
        background: white;
        padding: 2px 4px;
        border-radius: 2px;
        font-style: normal;
        margin-right: 0.25em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const Currency = styled.span.withConfig({ displayName: "Currency", componentId: "sc-1tihkki" }) `
    font-weight: ${MEDIUM};
    font-size: 12px;
    line-height: 24px;
    margin-left: 8px;
`;
export default function AccessPeriodModal({ account = address0, backable = false, balance = '0', chainId = 137, onReject, onResolve, pricePerSecond = '0', quantity = 1, tokenAddress = address0, tokenDecimals = '18', tokenSymbol = 'DATA', unit = timeUnits.hour, usdRate = 1, }) {
    const [length, setLength] = useState(`${quantity}`);
    const [selectedUnit, setSelectedUnit] = useState(unit);
    useEffect(() => {
        setLength(`${quantity}`);
    }, [quantity]);
    useEffect(() => {
        setSelectedUnit(unit);
    }, [unit]);
    const chainName = formatChainName(getConfigForChain(chainId).name);
    let price = fromDecimals(priceForTimeUnits(pricePerSecond, length, selectedUnit), new BigNumber(tokenDecimals));
    if (!price.isFinite() || price.isLessThanOrEqualTo(0)) {
        price = new BigNumber(0);
    }
    const disabled = price.isEqualTo(0);
    const usdPrice = price.multipliedBy(usdRate);
    const [isSubmitting, setIsSubmitting] = useState(false);
    /**
     * It's safe to use `useIsMounted` here because even if we rerender the component
     * for a different purchase it'll be disabled if there's one already being processed.
     */
    const isMounted = useIsMounted();
    return (React.createElement(ProjectModal, { backable: backable, closeOnEscape: true, onReject: onReject, title: "Choose access period" },
        React.createElement("form", { onSubmit: async (e) => {
                e.preventDefault();
                if (disabled || isSubmitting) {
                    return;
                }
                try {
                    setIsSubmitting(true);
                    const bnQuantity = new BigNumber(length);
                    if (!bnQuantity.isFinite() || bnQuantity.isEqualTo(0)) {
                        throw new Error('Invalid quantity');
                    }
                    const allowance = await getAllowance(chainId, tokenAddress, account, {
                        recover: true,
                    });
                    const newQuantity = bnQuantity.toNumber();
                    const total = priceForTimeUnits(pricePerSecond, newQuantity, selectedUnit);
                    onResolve === null || onResolve === void 0 ? void 0 : onResolve({
                        quantity: newQuantity,
                        unit: selectedUnit,
                        exceedsAllowance: allowance.isLessThan(total),
                    });
                }
                catch (e) {
                    onReject === null || onReject === void 0 ? void 0 : onReject(e);
                }
                finally {
                    if (isMounted()) {
                        setIsSubmitting(false);
                    }
                }
            } },
            React.createElement(PeriodContainer, null,
                React.createElement(Text, { value: length, onChange: (e) => void setLength(e.target.value), onCommit: setLength, autoFocus: true, name: "quantity", disabled: isSubmitting }),
                React.createElement(SelectField2, { placeholder: "Select a time unit", options: options, value: selectedUnit, onChange: setSelectedUnit, isClearable: false, fullWidth: true, disabled: isSubmitting })),
            React.createElement(DetailsContainer, null,
                React.createElement(Chain, null,
                    React.createElement(ChainIcon, { chainId: chainId }),
                    React.createElement(ChainName, null, chainName)),
                React.createElement(AmountBox, null,
                    React.createElement(AmountBar, null,
                        React.createElement(AmountBarInner, null,
                            React.createElement("div", null,
                                React.createElement("em", null, new BigNumber(balance).toFixed(3)),
                                ' ',
                                React.createElement("span", null, "Balance"),
                                React.createElement("div", null,
                                    React.createElement(TokenLogo, { chainId: chainId, contractAddress: tokenAddress, symbol: tokenSymbol }))))),
                    React.createElement(AmountScrollable, null,
                        React.createElement(AmountBoxInner, null,
                            React.createElement(Amount, null, price.toFixed(3)),
                            React.createElement(Currency, null, tokenSymbol))),
                    React.createElement(AmountBar, null,
                        React.createElement(AmountBarInner, null,
                            React.createElement("div", null,
                                React.createElement("div", null,
                                    "Approx ",
                                    usdPrice.toFixed(2),
                                    " USD")))))),
            React.createElement(Actions, null,
                !backable && (React.createElement(Button, { kind: "link", type: "button", onClick: () => void (onReject === null || onReject === void 0 ? void 0 : onReject(RejectionReason.CancelButton)) }, "Cancel")),
                React.createElement(Button, { type: "submit", disabled: disabled || isSubmitting, waiting: isSubmitting }, "Pay now")))));
}
