var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Popover from '$shared/components/Popover';
import '$shared/types/common-types';
const ActionContainer = styled.div.withConfig({ displayName: "ActionContainer", componentId: "sc-1xcxxmx" }) `
    display: inline-block;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);

    ${({ open }) => !!open &&
    css `
            z-index: 2;
        `}
`;
const UnstyledWithInputActions = (_a) => {
    var { actions, disabled, children = null } = _a, props = __rest(_a, ["actions", "disabled", "children"]);
    const [open, setOpen] = useState(false);
    return (React.createElement(React.Fragment, null, !actions || !actions.length ? (children) : (React.createElement("div", Object.assign({}, props),
        children,
        React.createElement(ActionContainer, { open: open },
            React.createElement(Popover, { disabled: disabled, title: "Actions", type: "grayMeatball", menuProps: {
                    right: true,
                }, onMenuToggle: setOpen, caret: false }, actions))))));
};
const WithInputActions = styled(UnstyledWithInputActions).withConfig({ displayName: "WithInputActions", componentId: "sc-1hub62t" }) `
    position: relative;
`;
export default WithInputActions;
