var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { truncate } from '$shared/utils/text';
import { MEDIUM } from '$shared/utils/styled';
export const Name = styled.div.withConfig({ displayName: "Name", componentId: "sc-1d8sste" }) `
    font-weight: ${MEDIUM};
    line-height: 1em;
`;
export const Username = styled.div.withConfig({ displayName: "Username", componentId: "sc-wa8fbi" }) `
    color: #a3a3a3;
    line-height: 1em;
`;
const UnstyledAvatarless = (_a) => {
    var { source } = _a, props = __rest(_a, ["source"]);
    return (React.createElement("div", Object.assign({}, props),
        React.createElement(Username, { title: source },
            truncate(source),
            "\u200C")));
};
export const Avatarless = styled(UnstyledAvatarless).withConfig({ displayName: "Avatarless", componentId: "sc-1ajjw23" }) ``;
