var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import ReactIdenticon from 'react-identicons';
const UnstyledIdenticon = (_a) => {
    var { id, size } = _a, props = __rest(_a, ["id", "size"]);
    return (React.createElement("div", Object.assign({}, props),
        React.createElement("svg", { viewBox: "0 0 1 1", xmlns: "http://www.w3.org/2000/svg" }),
        React.createElement(ReactIdenticon, { string: id, size: size })));
};
const Identicon = styled(UnstyledIdenticon).withConfig({ displayName: "Identicon", componentId: "sc-1twrpys" }) `
    position: relative;

    .identicon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50% !important;
        height: 50% !important;
    }
`;
Identicon.defaultProps = {
    size: 80,
};
export default Identicon;
