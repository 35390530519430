import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { REGULAR, TABLET } from '$shared/utils/styled';
import Button from '$shared/components/Button';
import { ProjectType } from '$shared/types';
import PaymentRate from '$mp/components/PaymentRate';
import { formatChainName } from '$shared/utils/chains';
import { WhiteBox } from '$shared/components/WhiteBox';
import { getConfigForChain } from '$shared/web3/config';
import { timeUnits } from '$shared/utils/timeUnit';
import { getProjectTypeName } from '$app/src/getters';
import { useIsProjectBeingPurchased, usePurchaseCallback } from '$shared/stores/purchases';
import { errorToast } from '$utils/toast';
import { useDoesUserHaveAccess } from '$shared/stores/projectEditor';
import { isAbandonment } from '$app/src/modals/ProjectModal';
import routes from '$routes';
const DescriptionContainer = styled.div.withConfig({ displayName: "DescriptionContainer", componentId: "sc-n4i6ir" }) `
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: ${REGULAR};
    flex-direction: column;

    p {
        margin: 0;
    }

    a {
        width: 100%;
        margin-top: 20px;
    }

    @media (${TABLET}) {
        flex-direction: row;

        a {
            width: auto;
            margin-top: 0;
            margin-left: 20px;
        }
    }
`;
export default function AccessManifest({ projectId, projectType, salePoints }) {
    const [firstSalePoint, ...otherSalePoints] = salePoints;
    const prefix = `The streams in this ${getProjectTypeName(projectType)}`;
    const count = otherSalePoints.length;
    const purchase = usePurchaseCallback();
    const hasAccess = useDoesUserHaveAccess();
    const isBeingPurchased = useIsProjectBeingPurchased(projectId);
    if (!firstSalePoint) {
        return null;
    }
    const { pricePerSecond, chainId, pricingTokenAddress } = firstSalePoint;
    return (React.createElement(WhiteBox, { className: 'with-padding' },
        React.createElement(DescriptionContainer, null,
            projectType === ProjectType.OpenData ? (React.createElement("p", null,
                prefix,
                " are public and can be accessed for ",
                React.createElement("strong", null, "free"),
                ".")) : (React.createElement("p", null,
                prefix,
                " can be accessed for",
                ' ',
                React.createElement("strong", null,
                    ' ',
                    React.createElement(PaymentRate, { amount: new BigNumber(pricePerSecond), chainId: chainId, pricingTokenAddress: pricingTokenAddress, timeUnit: timeUnits.hour, tag: "span" })),
                ' ',
                "on",
                ' ',
                React.createElement("strong", null, formatChainName(getConfigForChain(chainId).name)),
                count > 0 && (React.createElement(React.Fragment, null,
                    "and on ",
                    count,
                    " other chain",
                    count > 1 && 's')),
                ".")),
            hasAccess === true && (React.createElement(Button, { tag: Link, to: routes.projects.connect({ id: projectId }) }, "Connect")),
            hasAccess === false && (React.createElement(Button, { type: "button", waiting: isBeingPurchased, onClick: async () => {
                    try {
                        await purchase(projectId);
                    }
                    catch (e) {
                        if (isAbandonment(e)) {
                            return;
                        }
                        console.warn('Purchase failed', e);
                        errorToast({
                            title: 'Purchase failed',
                        });
                    }
                } }, "Get access")),
            typeof hasAccess === 'undefined' && (React.createElement(Button, { type: "button", waiting: true }, "Loading\u2026")))));
}
