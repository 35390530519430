import React, { useEffect, useState } from 'react';
import Provider from 'streamr-client-react';
import getClientConfig from '$app/src/getters/getClientConfig';
import { getWalletProvider, useWalletAccount } from '../stores/wallet';
export default function StreamrClientProvider({ children }) {
    const account = useWalletAccount();
    const [config, setConfig] = useState(getClientConfig());
    useEffect(() => {
        let mounted = true;
        async function fn() {
            if (!account) {
                return;
            }
            try {
                const provider = await getWalletProvider();
                if (!mounted) {
                    return;
                }
                setConfig((current) => (Object.assign(Object.assign({}, current), { auth: Object.assign(Object.assign({}, (current.auth || {})), { ethereum: provider }) })));
            }
            catch (e) {
                console.warn('Failed to update config', e);
            }
        }
        fn();
        return () => {
            mounted = false;
        };
    }, [account]);
    return React.createElement(Provider, Object.assign({}, config), children);
}
