import React from 'react';
import { MarketplaceHelmet } from '$shared/components/Helmet';
import Layout from '$shared/components/Layout';
import { useIsNewProject, useIsProjectBusy, useProject, } from '$app/src/shared/stores/projectEditor';
import { getProjectTypeTitle } from '$app/src/getters';
import { DetailsPageHeader } from '$shared/components/DetailsPageHeader';
import ProjectLinkTabs from '$app/src/pages/ProjectPage/ProjectLinkTabs';
import LoadingIndicator from '$shared/components/LoadingIndicator';
import { ProjectType } from '$shared/types';
import { ProjectPageContainer } from '$shared/components/ProjectPage';
import { ProjectHeroContainer } from '$mp/containers/ProjectPage/Hero/ProjectHero2.styles';
import EditorNav from './EditorNav';
export default function ProjectEditorPage() {
    const { type, creator } = useProject({ hot: true });
    const isNew = useIsNewProject();
    const busy = useIsProjectBusy();
    return (React.createElement(Layout, { gray: true, nav: React.createElement(EditorNav, null) },
        React.createElement(MarketplaceHelmet, { title: isNew ? 'Create a new project' : 'Edit project' }),
        React.createElement(DetailsPageHeader, { pageTitle: !!creator && (React.createElement(React.Fragment, null,
                getProjectTypeTitle(type),
                " by",
                React.createElement("strong", null,
                    "\u00A0",
                    creator,
                    " "))), rightComponent: React.createElement(ProjectLinkTabs, null) }),
        React.createElement(LoadingIndicator, { loading: busy }),
        React.createElement(ProjectPageContainer, null,
            React.createElement(ProjectHeroContainer, { overflowVisible: true }),
            type === ProjectType.PaidData && (React.createElement(React.Fragment, null)),
            type === ProjectType.DataUnion && (React.createElement(React.Fragment, null)))));
}
