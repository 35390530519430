import styled, { css } from 'styled-components';
import { components } from 'react-select';
import SvgIcon from '$shared/components/SvgIcon';
import { COLORS } from '$shared/utils/styled';
import CheckmarkIcon from '$shared/assets/icons/checkmark.svg';
import BlackCheckmarkIcon from '$shared/assets/icons/checkmark-black.svg';
const transitionTime = 150;
export const StyledCaretIcon = styled(SvgIcon).withConfig({ displayName: "StyledCaretIcon", componentId: "sc-54nop3" }) `
    height: 8px;
    width: 10px;
    transition: all ${transitionTime}ms ease-in-out;
    color: ${COLORS.primaryLight};
    &.rotated {
        transform: rotate(180deg);
        color: ${COLORS.primaryContrast};
    }
    &.disabled {
        color: ${COLORS.disabled};
    }
`;
export const StyledDropdownIndicator = styled(components.DropdownIndicator).withConfig({ displayName: "StyledDropdownIndicator", componentId: "sc-1cc3dzs" }) `
    margin-right: 10px;
`;
export const StyledCloseIcon = styled(SvgIcon).withConfig({ displayName: "StyledCloseIcon", componentId: "sc-1tjlo5p" }) `
    color: ${COLORS.primaryLight};
    &.menu-is-open {
        color: ${COLORS.primaryContrast};
    }
`;
const StyledOptionAfter = css `
    :after {
        content: ' ';
        background: url('${CheckmarkIcon}') center center no-repeat;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 12px;
        top: 12px;
    }
`;
export const StyledOption = styled(components.Option).withConfig({ displayName: "StyledOption", componentId: "sc-1vkkmf7" }) `
    cursor: pointer;
    color: ${COLORS.primaryLight};
    font-size: 14px;
    font-weight: 400;
    transition: all ${transitionTime}ms ease-in-out;
    position: relative;
    &:active {
        background-color: ${COLORS.primary} !important;
    }
    &:focus {
        background-color: ${COLORS.focus};
    }

    ${(props) => {
    if (props.isSelected) {
        return StyledOptionAfter;
    }
}}
`;
export const getControlStyles = (styles, isFocused, isOpen, isDisabled, noShrink, fullWidth) => {
    const styleObject = Object.assign(Object.assign({}, styles), { backgroundColor: COLORS.secondary, borderRadius: '4px', border: 'none', fontWeight: 500, width: 'auto', display: 'inline-flex', transition: `background-color ${transitionTime}ms ease-in-out`, cursor: 'pointer' });
    if (isFocused) {
        styleObject.boxShadow = `none`;
    }
    if (isOpen) {
        styleObject.backgroundColor = COLORS.primary;
        styleObject.color = COLORS.primaryContrast;
    }
    if (isDisabled) {
        styleObject.cursor = 'not-allowed';
    }
    if (noShrink) {
        styleObject.flexWrap = 'nowrap';
    }
    if (fullWidth) {
        styleObject.width = '100%';
    }
    return styleObject;
};
export const getPlaceholderStyles = (styles, isOpen, isDisabled) => {
    const styleObject = Object.assign(Object.assign({}, styles), { color: COLORS.primaryLight, display: 'block', position: 'relative', top: '0', transform: 'translateY(0)', fontSize: '14px', lineHeight: '36px', fontWeight: 500, transition: `color ${transitionTime}ms ease-in-out` });
    if (isOpen) {
        styleObject.color = COLORS.primaryContrast;
    }
    if (isDisabled) {
        styleObject.color = COLORS.disabled;
    }
    return styleObject;
};
export const getSingleValueStyles = (styles, isOpen, isDisabled, noShrink) => {
    const styleObject = Object.assign(Object.assign({}, styles), { position: 'relative', maxWidth: '100%', top: 0, transform: 'translateY(0)', fontSize: '14px', fontWeight: 500, lineHeight: '36px', color: COLORS.primaryLight });
    if (isOpen) {
        styleObject.color = COLORS.primaryContrast;
    }
    if (isDisabled) {
        styleObject.color = COLORS.disabled;
    }
    if (noShrink) {
        styleObject.overflow = 'unset';
    }
    return styleObject;
};
export const getMenuStyles = (styles) => {
    return Object.assign(Object.assign({}, styles), { background: '#FFF', boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)', border: 'none', borderRadius: '8px', color: COLORS.primary, width: '280px', marginTop: '8px', padding: 0 });
};
export const getMenuListStyles = (styles) => {
    return Object.assign(Object.assign({}, styles), { padding: 0, borderRadius: '8px' });
};
export const getOptionStyles = (styles, isSelected) => {
    const styleObject = Object.assign(Object.assign({}, styles), { cursor: 'pointer', color: COLORS.primaryLight, fontSize: '14px', fontWeight: 400, lineHeight: '18px' });
    if (isSelected) {
        styleObject.backgroundColor = COLORS.primaryLight;
        styleObject.color = COLORS.primaryContrast;
    }
    return styleObject;
};
export const getClearIndicatorStyles = (styles, isOpen) => {
    const styleObject = Object.assign(Object.assign({}, styles), { color: COLORS.primaryLight, transition: `color ${transitionTime}ms ease-in-out` });
    if (isOpen) {
        styleObject.color = COLORS.primaryContrast;
    }
    return styleObject;
};
const SelectedWhiteOption = css `
    :after {
        content: ' ';
        background: url('${BlackCheckmarkIcon}') center center no-repeat;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 12px;
        top: 18px;
    }
`;
export const StyledWhiteDropdownOption = styled(components.Option).withConfig({ displayName: "StyledWhiteDropdownOption", componentId: "sc-12p5i3g" }) `
    position: relative;
    &:active {
        //background-color: ${COLORS.primary} !important;
    }
    &:focus {
        background-color: ${COLORS.focus};
    }

    ${(props) => {
    if (props.isSelected) {
        return SelectedWhiteOption;
    }
}}
`;
export const getWhitePlaceholderStyles = (styles) => {
    return Object.assign(Object.assign({}, styles), { color: COLORS.disabled });
};
export const getWhiteControlStyles = (styles, isFocused, isDisabled, noShrink, fullWidth) => {
    const styleObject = Object.assign(Object.assign({}, styles), { fontSize: '14px', borderColor: COLORS.secondaryHover, width: 'auto', display: 'inline-flex' });
    if (isFocused) {
        styleObject.borderColor = COLORS.focus;
        styleObject.boxShadow = `0 0 0 1px ${COLORS.focus}`;
    }
    if (isDisabled) {
        styleObject.cursor = 'not-allowed';
        styleObject.border = `1px solid ${COLORS.separator}`;
        styleObject.backgroundColor = 'white';
    }
    if (noShrink) {
        styleObject.flexWrap = 'nowrap';
    }
    if (fullWidth) {
        styleObject.width = '100%';
    }
    return styleObject;
};
export const getWhiteMenuStyles = (styles) => {
    return Object.assign(Object.assign({}, styles), { background: '#FFF', border: 'none', borderRadius: '8px', color: COLORS.primary, padding: 0, boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)', marginTop: '4px', width: '280px' });
};
export const getWhiteMenuListStyles = (styles) => {
    return Object.assign(Object.assign({}, styles), { padding: 0, borderRadius: '8px' });
};
export const getWhiteOptionStyles = (styles, isSelected) => {
    const styleObject = Object.assign(Object.assign({}, styles), { cursor: 'pointer', color: COLORS.primaryLight, fontSize: '14px', fontWeight: 400, lineHeight: '18px' });
    if (isSelected) {
        styleObject.color = COLORS.primaryLight;
        styleObject.backgroundColor = 'white';
    }
    return styleObject;
};
