import React, { useState } from 'react';
import styled from 'styled-components';
import WithInputActions from '$shared/components/WithInputActions';
import PopoverItem from '$shared/components/Popover/PopoverItem';
import Text from '$ui/Text';
import Label from '$ui/Label';
import useCopy from '$shared/hooks/useCopy';
import { COLORS } from '$app/src/shared/utils/styled';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-alx6pa" }) `
    padding: 24px;
    background: ${COLORS.inputBackground};
    border-radius: 4px;
`;
export const SecretItem = ({ secret, onEdit, onDelete, }) => {
    const { copy } = useCopy();
    const elementId = `secret-${secret.id}`;
    const [isRevealed, setIsRevealed] = useState(false);
    return (React.createElement(Container, null,
        React.createElement(Label, { htmlFor: elementId }, secret.name),
        React.createElement(WithInputActions, { actions: [
                React.createElement(PopoverItem, { key: "reveal", onClick: () => {
                        setIsRevealed((prev) => !prev);
                    } }, isRevealed ? 'Hide' : 'Reveal'),
                React.createElement(PopoverItem, { key: "copy", onClick: () => {
                        copy(secret.secret, {
                            toastMessage: 'Copied',
                        });
                    } }, "Copy"),
                React.createElement(PopoverItem, { key: "delete", onClick: () => onDelete(secret) }, "Delete"),
            ] },
            React.createElement(Text, { id: elementId, type: isRevealed ? 'text' : 'password', autoComplete: "off", defaultValue: secret.secret || '', selectAllOnFocus: true, readOnly: true }))));
};
