import React from 'react';
import styled from 'styled-components';
import { StreamPermission } from 'streamr-client';
import { MEDIUM } from '$shared/utils/styled';
import { Bits, useCurrentDraft, useDraftId, useStreamEditorStore, } from '$shared/stores/streamEditor';
import address0 from '$utils/address0';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1mfa29o" }) `
    background: #f1f1f1;
    border-radius: 4px;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1em;
    padding: 1.5em;
`;
const Item = styled.div.withConfig({ displayName: "Item", componentId: "sc-bghwub" }) `
    background: #ffffff;
    border-radius: 4px;
    height: 100px;
    display: flex;
    align-items: center;
`;
const Radio = styled.input.withConfig({ displayName: "Radio", componentId: "sc-1gcg5xy" }) `
    width: 16px;
    justify-self: center;
    align-self: center;
`;
const RadioContainer = styled.label.withConfig({ displayName: "RadioContainer", componentId: "sc-fd9wz7" }) `
    width: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: 48px auto 48px;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1poj013" }) `
    font-weight: ${MEDIUM};
    font-size: 16px;
    line-height: 20px;
`;
const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-cr31qu" }) `
    font-size: 14px;
    line-height: 24px;
`;
var StreamType;
(function (StreamType) {
    StreamType["Public"] = "public";
    StreamType["Private"] = "private";
})(StreamType || (StreamType = {}));
export default function StreamTypeSelector({ disabled }) {
    var _a, _b;
    const draftId = useDraftId();
    const setPermissions = useStreamEditorStore(({ setPermissions }) => setPermissions);
    const bits = ((_b = (_a = useCurrentDraft().permissions) === null || _a === void 0 ? void 0 : _a[address0]) === null || _b === void 0 ? void 0 : _b.bits) || null;
    const streamType = bits ? StreamType.Public : StreamType.Private;
    return (React.createElement(Container, null,
        React.createElement(Item, null,
            React.createElement(RadioContainer, { htmlFor: "public" },
                React.createElement(Radio, { id: "public", type: "radio", name: "streamType", checked: streamType === StreamType.Public, onChange: () => {
                        if (!draftId) {
                            return;
                        }
                        setPermissions(draftId, address0, Bits[StreamPermission.SUBSCRIBE]);
                    }, disabled: disabled }),
                React.createElement("div", null,
                    React.createElement(Title, null, "Public stream"),
                    React.createElement(Description, null, "Anyone can subscribe to the stream")))),
        React.createElement(Item, null,
            React.createElement(RadioContainer, { htmlFor: "private" },
                React.createElement(Radio, { id: "private", type: "radio", name: "streamType", checked: streamType === StreamType.Private, onChange: () => {
                        if (!draftId) {
                            return;
                        }
                        setPermissions(draftId, address0, null);
                    }, disabled: disabled }),
                React.createElement("div", null,
                    React.createElement(Title, null, "Private stream"),
                    React.createElement(Description, null, "Only Ethereum accounts listed below can subscribe to the stream."))))));
}
