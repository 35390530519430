import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLORS, MAX_CONTENT_WIDTH } from '$shared/utils/styled';
import WithInputActions from '$shared/components/WithInputActions';
import PopoverItem from '$shared/components/Popover/PopoverItem';
import Text from '$ui/Text';
import useCopy from '$shared/hooks/useCopy';
import { truncate } from '$shared/utils/text';
import { isEthereumAddress } from '$mp/utils/validate';
import useValidation from '$mp/containers/ProductController/useValidation';
import { SeverityLevel } from '$mp/containers/ProductController/ValidationContextProvider';
import { useWalletAccount } from '$shared/stores/wallet';
const Heading = styled.p.withConfig({ displayName: "Heading", componentId: "sc-1xxyoqn" }) `
    font-size: 20px;
    color: black;
    margin-top: 30px;
`;
const DescriptionText = styled.p.withConfig({ displayName: "DescriptionText", componentId: "sc-y4tu4y" }) `
    color: black;
    margin-bottom: 15px;
    margin-right: 55px;
    flex-shrink: 0;
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ikevdw" }) `
    background-color: ${COLORS.inputBackground};
    padding: 12px 24px;
    max-width: ${MAX_CONTENT_WIDTH};

    .beneficiary-address-input {
        margin: 0;
        input:disabled {
            background-color: white;
            opacity: 1;
        }
    }
`;
const UnstyledAddressItem = ({ className, name, address }) => (React.createElement("div", { className: className },
    React.createElement("div", null, `Fill ${name}`),
    !!address && React.createElement("div", { className: "address" }, truncate(address))));
const AddressItem = styled(UnstyledAddressItem).withConfig({ displayName: "AddressItem", componentId: "sc-1k2kagv" }) `
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > .address {
        color: #adadad;
        font-size: 10px;
        margin-top: -14px;
    }
`;
export const BeneficiaryAddress = ({ disabled, beneficiaryAddress, onChange, chainName, }) => {
    const { copy } = useCopy();
    const accountAddress = useWalletAccount();
    const { setStatus, clearStatus, isValid } = useValidation(`salePoints.${chainName}.beneficiaryAddress`);
    const [defaultValueWasSet, setDefaultValueWasSet] = useState(false);
    const inputRef = useRef();
    const onCopy = useCallback(() => {
        if (!beneficiaryAddress) {
            return;
        }
        copy(beneficiaryAddress, {
            toastMessage: 'Copied',
        });
    }, [copy, beneficiaryAddress]);
    useEffect(() => {
        if (!defaultValueWasSet && !beneficiaryAddress && !!accountAddress) {
            onChange(accountAddress);
            setDefaultValueWasSet(true);
        }
    }, [accountAddress, beneficiaryAddress, onChange, defaultValueWasSet]);
    const handleUpdate = (value) => {
        onChange(value.toLowerCase());
        const isValid = isEthereumAddress(value);
        if (isValid) {
            clearStatus();
        }
        else {
            setStatus(SeverityLevel.ERROR, 'Provided wallet address is invalid');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, null, "Set beneficiary"),
        React.createElement(DescriptionText, null,
            "This wallet address receives the payments for this product on ",
            chainName,
            ' ',
            "chain."),
        React.createElement(Container, null,
            React.createElement(WithInputActions, { disabled: disabled, className: 'beneficiary-address-input', actions: [
                    React.createElement(PopoverItem, { key: "useCurrent", onClick: () => {
                            if (!accountAddress) {
                                return;
                            }
                            handleUpdate(accountAddress);
                            if (inputRef.current) {
                                inputRef.current.value = accountAddress;
                            }
                        }, disabled: !accountAddress },
                        React.createElement(AddressItem, { name: "wallet address", address: accountAddress || 'Wallet locked' })),
                    React.createElement(PopoverItem, { key: "copy", disabled: !beneficiaryAddress, onClick: onCopy }, "Copy"),
                ] },
                React.createElement(Text, { ref: (input) => (inputRef.current = input), id: "beneficiaryAddress", autoComplete: "off", defaultValue: beneficiaryAddress || '', onCommit: (value) => {
                        handleUpdate(value);
                    }, placeholder: 'i.e. 0xa3d1F77ACfF0060F7213D7BF3c7fEC78df847De1', disabled: disabled, invalid: !isValid, selectAllOnFocus: true })))));
};
