import React from 'react';
import styled from 'styled-components';
import { SecretItem } from './SecretItem';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ccbi1a" }) `
    display: grid;
    grid-gap: 12px;
    margin: 32px 0;
`;
export const SecretList = ({ secrets, onEdit, onDelete, }) => {
    if (secrets == null || secrets.length === 0) {
        return null;
    }
    return (React.createElement(Container, null, secrets.map((secret) => (React.createElement(SecretItem, { key: secret.id, secret: secret, onEdit: onEdit, onDelete: onDelete })))));
};
