import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '$shared/components/Button';
import Text from '$ui/Text';
import UnstyledLabel from '$ui/Label';
import LightModal from '$app/src/modals/LightModal';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1b4czwc" }) `
    display: grid;
    grid-template-rows: auto auto 40px;
    gap: 32px;
    width: 100%;
`;
const Label = styled(UnstyledLabel).withConfig({ displayName: "Label", componentId: "sc-1plg71t" }) `
    display: flex;
`;
const Buttons = styled.div.withConfig({ displayName: "Buttons", componentId: "sc-4wkvb8" }) `
    justify-self: right;
    gap: 32px;
`;
export const SecretEditor = ({ secret, onReject, onResolve, }) => {
    const [name, setName] = useState('');
    const isNew = secret == null;
    return (React.createElement(LightModal, { title: isNew ? 'Add a shared secret' : 'Edit shared secret', onReject: onReject },
        React.createElement(Container, null,
            React.createElement("div", null,
                React.createElement(Label, null, "Secret name"),
                React.createElement(Text, { defaultValue: secret === null || secret === void 0 ? void 0 : secret.name, onCommit: (text) => {
                        setName(text);
                    }, placeholder: "Enter a secret name", selectAllOnFocus: true })),
            React.createElement("div", null,
                React.createElement(Label, null, "Shared secret"),
                React.createElement(Text, { defaultValue: secret === null || secret === void 0 ? void 0 : secret.secret, placeholder: "Shared secret will be automatically generated", disabled: true, selectAllOnFocus: true, readOnly: true })),
            React.createElement(Buttons, null,
                React.createElement(Button, { disabled: name == null || name.length === 0, onClick: async () => {
                        if (name == null || name.length === 0) {
                            return;
                        }
                        try {
                            if (onResolve != null) {
                                onResolve(name);
                            }
                        }
                        catch (e) {
                            console.error(e);
                        }
                    } }, isNew ? 'Add a shared secret' : 'Save'),
                React.createElement(Button, { kind: "link", onClick: () => {
                        if (onReject != null) {
                            onReject();
                        }
                    } }, "Cancel")))));
};
